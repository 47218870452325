import { useState, useContext } from "react";
import chart_icon from "../../../images/cloud_icon_figma.png";
import { LanguageContext } from "../../../App";

function ListResultsMenu({ onSelect }) {
  // arabic

  const { language } = useContext(LanguageContext);

  const [selectedItem, setSelectedItem] = useState("listresultbox");

  const btnSelectListResultsStyles = {
    backgroundColor:
      selectedItem === "listresultlist"
        ? "rgb(221, 238, 255)"
        : "rgb(255, 255, 255)",
  };

  const handlebtnSelectListResults = () => {
    setSelectedItem(
      selectedItem === "listresultlist" ? "listresultbox" : "listresultlist"
    );
    onSelect(
      selectedItem === "listresultlist" ? "listresultbox" : "listresultlist"
    );
  };

  return (
    <div className="componnets_drop_result_search">
      {language === "ar" ? (
        <button
          className="btn_twiiter_colors_result"
          style={btnSelectListResultsStyles}
          onClick={handlebtnSelectListResults}
        >
          {/* <img src={list_post} alt="" /> */}
          <img src={chart_icon} alt="" />
        </button>
      ) : (
        <button
          className="btn_twiiter_colors_result_en"
          style={btnSelectListResultsStyles}
          onClick={handlebtnSelectListResults}
        >
          <img src={chart_icon} alt="" />
        </button>
      )}
    </div>
  );
}

export default ListResultsMenu;
