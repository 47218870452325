import React, { useContext, useState } from "react";
import { LanguageContext } from "../../App";
import logo from "../../images/new_logo_menu.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import call_back_img from "../../images/call_back_later_img.png";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function DemoMessage({ saveLoginStatus, setIsSubmitted }) {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  return (
    <div className="body_massage_page">
      <div className="header_body_massage_page">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="petition_page_btn_top_header_right">
          <img src={logo} alt="" />
        </div>
      </div>

      <div
        className={`card_body_massage_page_login ${
          language === "ar" ? "arabic" : ""
        }`}
      >
        <img src={call_back_img} alt="" />
        {language === "ar" ? (
          <>
            <strong>تم استلام طلبك بنجاح</strong>
            <strong>سيتم التواصل معك قريبًا</strong>
          </>
        ) : (
          <>
            <span>The information has been sent successfully</span>
            <p> we will contact you later</p>
          </>
        )}
      </div>
      <div className="logout_icon_steps_filed">
        <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
      </div>
    </div>
  );
}

export default DemoMessage;
