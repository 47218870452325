import axios from "axios";
import { base_url, session_manager_url, tokenName } from "./config_backend";
import { getToken } from "./Login_backend";

export const getSessionInfo = async () => {
  // if(localStorage.getItem(tokenName)){
  //     await refreshToken()
  // }

  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .get(base_url + session_manager_url, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
