import React, { useContext, useEffect, useState } from "react";
import twitter_icon from "../../../images/twitter_x_35.png";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TopUsersTable from "./TopUsersTable";
import TopUsersTableTW from "./TopUsersTableTw";
import FacebookIcon from "../../../images/facebook.png";
import instagram_icon from "../../../images/instagram_35.png";
import TopUsersTableIN from "./TopUsersTableIN";
import { ThemeContext, LanguageContext } from "../../../App";
import TopUsersChart from "./TopUsersChart";
import TopUsersChartTw from "./TopUsersChartTw";
import TopUsersChartIn from "./TopUsersChartIn";
import { HashLoader } from "react-spinners";

function TopUsers({
  twitterPosts,
  facebookPosts,
  isTwitterActive,
  isFacebookActive,
  loadingStateFB,
  loadingStateTW,
  visibleTWTopUsers,
  visibleFBTopUsers,
  visibleINTopUsers,
  loadingStateIN,
  instagramPosts,
  isInstagramActive,
  showAllBtnTopUsers,
  selectedItem,
  onUsernameClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // popup download
  const [hashtagPlatformsdl, setHashtagPlatformsDl] = React.useState(null);

  const handleClickHashtagPlatformsdl = (event) => {
    setHashtagPlatformsDl(event.currentTarget);
  };

  const handleCloseHashtagPlatformsdl = () => {
    setHashtagPlatformsDl(null);
  };

  const open = Boolean(hashtagPlatformsdl);
  const id = open ? "dropdl_top_users" : undefined;

  const [currentSelectedTopUsers, setCurrentSelectedTopUsers] =
    useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedTopUsers(selectedItem);
  }, [selectedItem]);

  return (
    <div className={`top_users ${theme === "dark" ? "dark_mode" : ""}`}>
      {showAllBtnTopUsers && (
        <div className="top_users_page">
          {currentSelectedTopUsers === "topuser_chart" && (
            <div className="body_cloud_hashtag_cloud_words">
              {isTwitterActive && (
                <div className="twitter_top_users">
                  {loadingStateTW ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleTWTopUsers && showTwitterSection && (
                        <>
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  إكس
                                </span>
                                <img src={twitter_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {twitterPosts && twitterPosts.word && (
                                    <span>{twitterPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  X
                                </span>
                                <img src={twitter_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {twitterPosts && twitterPosts.word && (
                                    <span>{twitterPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  Download
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersChartTw
                              twitterPosts={twitterPosts}
                              loadingStateTW={loadingStateTW}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {isFacebookActive && (
                <div className="facebook_top_users">
                  {loadingStateFB ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleFBTopUsers && showFacebookSection && (
                        <>
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  فيسبوك
                                </span>
                                <img src={FacebookIcon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {facebookPosts && facebookPosts.word && (
                                    <span>{facebookPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  Facebook
                                </span>
                                <img src={FacebookIcon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {facebookPosts && facebookPosts.word && (
                                    <span>{facebookPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              <div className="items_header_release_process_body_dlbtn">
                                <Button
                                  className="btn_dl_items_header_release_process_body"
                                  onClick={handleClickHashtagPlatformsdl}
                                  startIcon={<MoreVertIcon />}
                                />

                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={hashtagPlatformsdl}
                                  onClose={handleCloseHashtagPlatformsdl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuList>
                                    <MenuItem
                                      onClick={handleCloseHashtagPlatformsdl}
                                    >
                                      Download
                                    </MenuItem>
                                  </MenuList>
                                </Popover>
                              </div>
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersChart
                              facebookPosts={facebookPosts}
                              loadingStateFB={loadingStateFB}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {isInstagramActive && (
                <div className="facebook_top_users">
                  {loadingStateIN ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleINTopUsers && showInstagramSection && (
                        <>
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  إنستغرام
                                </span>
                                <img src={instagram_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {instagramPosts && instagramPosts.word && (
                                    <span>{instagramPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  Instagram
                                </span>
                                <img src={instagram_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {instagramPosts && instagramPosts.word && (
                                    <span>{instagramPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  Download
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersChartIn
                              instagramPosts={instagramPosts}
                              loadingStateIN={loadingStateIN}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {currentSelectedTopUsers === "topuser_list" && (
            <div className="body_cloud_hashtag_cloud_words">
              {isTwitterActive && (
                <div className="twitter_top_users">
                  {loadingStateTW ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleTWTopUsers && showTwitterSection && (
                        <>
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  إكس
                                </span>
                                <img src={twitter_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {twitterPosts && twitterPosts.word && (
                                    <span>{twitterPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  X
                                </span>
                                <img src={twitter_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {twitterPosts && twitterPosts.word && (
                                    <span>{twitterPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  Download
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersTableTW
                              twitterPosts={twitterPosts}
                              loadingStateTW={loadingStateTW}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {isFacebookActive && (
                <div className="facebook_top_users">
                  {loadingStateFB ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleFBTopUsers && showFacebookSection && (
                        <div className="facebook_most_effective_users_border">
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  فيسبوك
                                </span>
                                <img src={FacebookIcon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {facebookPosts && facebookPosts.word && (
                                    <span>{facebookPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  Facebook
                                </span>
                                <img src={FacebookIcon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {facebookPosts && facebookPosts.word && (
                                    <span>{facebookPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  Download
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersTable
                              facebookPosts={facebookPosts}
                              loadingStateFB={loadingStateFB}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {isInstagramActive && (
                <div className="facebook_top_users">
                  {loadingStateIN ? (
                    <div className="spiner_postbox">
                      <HashLoader color="#458BDF" size={50} />
                    </div>
                  ) : (
                    <>
                      {visibleINTopUsers && showInstagramSection && (
                        <div className="facebook_most_effective_users_border">
                          {language === "ar" ? (
                            <div
                              className={`header_release_process_body ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  إنستغرام
                                </span>
                                <img src={instagram_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {instagramPosts && instagramPosts.word && (
                                    <span>{instagramPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  تحميل
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          ) : (
                            <div
                              className={`header_release_process_body_en ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              <div className="items_header_release_process_body">
                                <span
                                  className={`items_header_release_process_body_span ${
                                    theme === "dark" ? "dark_mode" : ""
                                  }`}
                                >
                                  Instagram
                                </span>
                                <img src={instagram_icon} alt="" />
                              </div>
                              <div className="items_header_release_process_body">
                                <span id="search_word_items_header_release_process_body">
                                  {instagramPosts && instagramPosts.word && (
                                    <span>{instagramPosts.word}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickHashtagPlatformsdl}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={hashtagPlatformsdl}
                              onClose={handleCloseHashtagPlatformsdl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem
                                  onClick={handleCloseHashtagPlatformsdl}
                                >
                                  Download
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div> */}
                            </div>
                          )}

                          <div className="body_cloud_hashtag_platforms_page">
                            <TopUsersTableIN
                              instagramPosts={instagramPosts}
                              loadingStateIN={loadingStateIN}
                              onUsernameClick={onUsernameClick}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TopUsers;
