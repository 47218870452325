import React, { useEffect, useRef, useContext } from "react";
import { LanguageContext } from "../../../App";
import * as echarts from "echarts";

const AxializationChartTW = ({ twitterPosts, onAxisClick }) => {
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (twitterPosts && twitterPosts.axis_chart) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = Object.keys(twitterPosts.axis_chart).map((key) => ({
        name: key,
        value: twitterPosts.axis_chart[key],
      }));

      if (wordCloudData.length === 0) {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text:
                        language === "ar"
                          ? "ليس هنالك معلومات"
                          : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      } else {
        const options = {
          tooltip: {
            trigger: "axis",

            axisPointer: {
              type: "none",
            },
            formatter: function (params) {
              const dataIndex = params[0].dataIndex;
              const itemName = wordCloudData[dataIndex].name;
              const itemValue = wordCloudData[dataIndex].value;
              const total = wordCloudData.reduce(
                (sum, item) => sum + item.value,
                0
              );
              const percentage = ((itemValue / total) * 100).toFixed(2);
              return `${itemName} : (${percentage}%)`;
            },
          },
          polar: {
            radius: [40, "80%"],
          },
          radiusAxis: {
            // max: 1,
            axisLabel: {
              show: false,
            },
          },
          angleAxis: {
            type: "category",
            data: wordCloudData.map((item) => item.name),
            axisLabel: {
              fontFamily: "Segoe UI",
              fontSize: 18,
              padding: [10, 10],
            },
            triggerEvent: true,
          },
          series: {
            type: "bar",
            data: wordCloudData.map((item) => item.value),
            coordinateSystem: "polar",
            radius: "80%",
            label: {
              show: true,
              position: "middle",
              // fontWeight: "bold",
              fontFamily: "Segoe UI",
              fontSize: "16",
              color:"#000000",

              formatter: function (params) {
                const total = wordCloudData.reduce(
                  (sum, item) => sum + item.value,
                  0
                );
                const index = params.dataIndex;
                const percentage = (
                  (wordCloudData[index].value / total) *
                  100
                ).toFixed(2);
                return `${percentage}%`;
              },
            },
            itemStyle: {
              color: "#1DA1F2", // رنگ پس‌زمینه داخل دایره
            },
          },
          animation: false,
        };

        chart.setOption(options);
        chart.on("click", (params) => {
          if (params.componentType === "angleAxis") {
            const selectedOption = params.value;
            onAxisClick(selectedOption);
          }
        });
        chart.resize();
      }

      return () => {
        chart.dispose();
      };
    }
  }, [twitterPosts, language, onAxisClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "400px",
        borderBottom: "2px solid #ccc",
        paddingBottom: "30px",
        marginBottom: "30px",
      }}
    />
  );
};

export default AxializationChartTW;
