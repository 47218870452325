import React from 'react';

const TempSkyBulletin = ({ children }) => (
    <div style={styles.container}>
    <div style={styles.beforeContent}></div>
    <div style={styles.beforeContentBottom}></div>
    <div style={styles.content}>
      {children}
    </div>
  </div>
);

const styles = {
  container: {
    width: '96%',
    height: '100%',
    background: 'mediumturquoise',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
  },

  beforeContent: {
    position: 'absolute',
    content: "",
    width: '20%',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    backgroundColor: 'lightblue',
    transition: 'all 0.5s',
    top:'0',
    right:'-2rem',
    borderRadius:'0 15px 0 100%',
  },
  beforeContentBottom:{
    position: 'absolute',
    content: "",
    width: '20%',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    backgroundColor: 'lightblue',
    transition: 'all 0.5s',
    bottom:'1rem',
    left:'-1rem',
    borderRadius:'0 100%  0 15px',
  },
  content: {
    maxWidth: '600px',
    textAlign:"center",
    margin: '0 auto', 
  },
};

export default TempSkyBulletin;
