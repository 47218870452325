import React, { useState, useEffect, useContext } from "react";
import ListResultsPostBox from "./ListResultsPostBox";
import ListResultsPostList from "./ListResultsPostList";
import { ThemeContext } from "../../../App";

function ListResults({
  selectedItem,
  facebookPosts,
  setFacebookPosts,
  twitterPosts,
  setTwitterPosts,
  isTwitterActive,
  isFacebookActive,
  loadingStateFB,
  loadingStateTW,
  visibleTWResult,
  visibleFBResult,
  showAllBtnResultList,
  instagramPosts,
  setInstagramPosts,
  isInstagramActive,
  loadingStateIN,
  visibleINResult,
  visibleVBPosts,
  isWebSiteActive,
  googlePosts,
  loadingStateGO,
  setGooglePostData,
  onUsernameClick,
  onDateClick,
  showTwitterSection,
  showFacebookSection,
  showInstagramSection,
  setIsCheckedIN,
  setIsCheckedTW,
  setIsCheckedFB,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  // تعیین وضعیت اولیه لود دو کامپوننت

  const [currentSelectedListResults, setCurrentSelectedListResults] =
    useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedListResults(selectedItem);
  }, [selectedItem]);

  return (
    <div className={`list_results ${theme === "dark" ? "dark_mode" : ""}`}>
      {showAllBtnResultList && (
        <div className="list_results_page">
          <div className="body_list_results_page">
            {currentSelectedListResults === "listresultbox" && (
              <ListResultsPostBox
                facebookPosts={facebookPosts}
                setFacebookPosts={setFacebookPosts}
                twitterPosts={twitterPosts}
                setTwitterPosts={setTwitterPosts}
                isTwitterActive={isTwitterActive}
                isFacebookActive={isFacebookActive}
                loadingStateFB={loadingStateFB}
                loadingStateTW={loadingStateTW}
                visibleTWResult={visibleTWResult}
                visibleFBResult={visibleFBResult}
                instagramPosts={instagramPosts}
                setInstagramPosts={setInstagramPosts}
                isInstagramActive={isInstagramActive}
                loadingStateIN={loadingStateIN}
                visibleINResult={visibleINResult}
                setIsCheckedFB={setIsCheckedFB}
                setIsCheckedTW={setIsCheckedTW}
                setIsCheckedIN={setIsCheckedIN}
                isWebSiteActive={isWebSiteActive}
                visibleVBPosts={visibleVBPosts}
                googlePosts={googlePosts}
                loadingStateGO={loadingStateGO}
                setGooglePostData={setGooglePostData}
                onUsernameClick={onUsernameClick}
                onDateClick={onDateClick}
                showTwitterSection={showTwitterSection}
                showFacebookSection={showFacebookSection}
                showInstagramSection={showInstagramSection}
              />
            )}
            {currentSelectedListResults === "listresultlist" && (
              <ListResultsPostList
                facebookPosts={facebookPosts}
                setFacebookPosts={setFacebookPosts}
                twitterPosts={twitterPosts}
                setTwitterPosts={setTwitterPosts}
                isTwitterActive={isTwitterActive}
                isFacebookActive={isFacebookActive}
                loadingStateFB={loadingStateFB}
                loadingStateTW={loadingStateTW}
                visibleTWResult={visibleTWResult}
                visibleFBResult={visibleFBResult}
                instagramPosts={instagramPosts}
                setInstagramPosts={setInstagramPosts}
                isInstagramActive={isInstagramActive}
                loadingStateIN={loadingStateIN}
                visibleINResult={visibleINResult}
                setIsCheckedFB={setIsCheckedFB}
                setIsCheckedTW={setIsCheckedTW}
                setIsCheckedIN={setIsCheckedIN}
                isWebSiteActive={isWebSiteActive}
                visibleVBPosts={visibleVBPosts}
                googlePosts={googlePosts}
                loadingStateGO={loadingStateGO}
                setGooglePostData={setGooglePostData}
                onUsernameClick={onUsernameClick}
                onDateClick={onDateClick}
                showTwitterSection={showTwitterSection}
                showFacebookSection={showFacebookSection}
                showInstagramSection={showInstagramSection}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ListResults;
