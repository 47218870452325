import axios from "axios";
import {
  base_url,
  import_file_profile_page,
  tokenName,
  price_show_user
} from "../../config_backend";
import { getToken } from "../../Login_backend";

export const addTaskUser = async (
  f_word,
  f_account,
  x_word,
  x_account,
  i_word,
  i_account,
  projectName,
  startDate,
  endDate
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  f_word = f_word === "" ? [] : f_word;
  f_account = f_account === "" ? [] : f_account;
  x_word = x_word === "" ? [] : x_word;
  x_account = x_account === "" ? [] : x_account;
  i_word = i_word === "" ? [] : i_word;
  i_account = i_account === "" ? [] : i_account;

  let requestData = {
    f_word: f_word,
    f_account: f_account,
    x_word: x_word,
    x_account: x_account,
    i_word: i_word,
    i_account: i_account,
    projectName: projectName,
    startDate: startDate,
    endDate: endDate
  };

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + import_file_profile_page, requestData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })
    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const showPriceUser = async (userId) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
  .get(base_url + price_show_user , {
      withCredentials: true,
      params : {
        userId
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
