import {
  Route,
  BrowserRouter,
  Routes,
  Outlet,
  // Navigate,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import SearchAll from "./pages/Public/SearchAll";
import PaymentUser from "./pages/User/PaymentUser";
import { authenticate } from "./backend/Login_backend";
import BaseNew from "./pages/Public/BaseNew";
import { getMyShow } from "./backend/Dashboard_backend";
import SeniorAnalyzerPage from "./pages/Public/SeniorAnalyzerPage";
import SeniorConfirmPage from "./pages/Public/SeniorConfirmPage";
import warning_icon from "./images/warning_icon.png";
import ScrapeData from "./pages/Admin/ScrapeData";
import BaseProjects from "./pages/Admin/BaseProjects";
import BaseUsers from "./pages/Admin/BaseUsers";
import BaseBot from "./pages/Admin/BaseBot";
import NewsPaper from "./pages/Public/NewsPaper";
import FilterMaker from "./components/SearchAll/FilterMaker/FilterMaker";
import ListBulletin from "./components/SearchAll/Bulletin/ListBulletin";
import TemplatesBulletin from "./components/SearchAll/Bulletin/TemplatesBulletin";
import PersonalInformation from "./pages/User/PersonalInformation";
import NotFound from "./pages/Public/NotFound";
import DemoReq from "./pages/Public/DemoReq";
import ProfileUser from "./pages/User/ProfileUser";
import InternetStatus from "./pages/Public/InternetStatus";
// import MaintenancePage from "./pages/Public/MaintenancePage";
import { toast } from "react-toastify";
import AdminPage from "./pages/Admin/AdminPage";
import Login from "./pages/Public/Login";
import DemoMessage from "./pages/Public/DemoMessage";

export const ThemeContext = React.createContext({
  theme: "light",
  toggleTheme: () => {},
});

export const LanguageContext = React.createContext({
  language: "ar",
  toggleLanguage: () => {},
});

const saveLoginStatus = (isLogged) => {
  sessionStorage.setItem("isLogged", isLogged ? "true" : "false");

  const expirationTime = new Date().getTime() + 8 * 60 * 60 * 1000;

  sessionStorage.setItem("expirationTime", expirationTime);

  setTimeout(() => {
    sessionStorage.removeItem("isLogged");
    sessionStorage.removeItem("expirationTime");
  }, 8 * 60 * 60 * 1000);
};

const getLoginStatus = () => {
  const isLogged = sessionStorage.getItem("isLogged");
  const expirationTime = sessionStorage.getItem("expirationTime");
  if (
    isLogged === "true" &&
    expirationTime &&
    new Date().getTime() < Number(expirationTime)
  ) {
    return true;
  } else {
    sessionStorage.removeItem("isLogged");
    sessionStorage.removeItem("expirationTime");
    return false;
  }
};

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const [language, setLanguage] = useState("ar");

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "ar" : "en");
  };

  const [errorMessages, setErrorMessages] = useState({});

  const [isSubmitted, setIsSubmitted] = useState(getLoginStatus());
  const [isChecked, setIsChecked] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [idUser, setIdUser] = useState("");
  const [idUserName, setIdUserName] = useState("");
  const [isNormalUser, setIsNormalUser] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isJuniorAnalyzer, setIsJuniorAnalyzer] = useState(false);
  const [isSeniorAnalyzer, setIsSeniorAnalyzer] = useState(false);
  const [isDefultDB, setIsDefultDB] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isIdentityChecked, setIsIdentityChecked] = useState(false);
  const [isTaskChecked, setIsTaskChecked] = useState(false);
  const [isPayChecked, setIsPayChecked] = useState(false);
  const [isPreperedChecked, setIsPreperedChecked] = useState(false);
  const [isProjectReadyChecked, setIsProjectReadyChecked] = useState(false);

  const [isDemoChecked, setIsDemoChecked] = useState(false);
  const [isRequestDemoChecked, setIsRequestDemoChecked] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var { username, pass } = document.forms[0];
    var result = await authenticate(username.value, pass.value);
    if (result.hasError) {
      if (
        result.errorText &&
        JSON.stringify(result.errorText).includes("account")
      ) {
        if (language === "ar") {
          result.errorText = "اسم المستخدم / كلمة المرور غير صحيحة";
        } else result.errorText = "Username/Password Incorrect";
      }
      setErrorMessages({ name: "email_pass", message: result.errorText });
    } else {
      await initCheck(true);
      // window.location.reload();
    }
  };

  const initCheck = async (isLogin) => {
    if (!isLogin && !getLoginStatus()) {
      setIsChecked(true);
      return;
    }
    const startMyShowResult = await handleStartMyShow();

    if (startMyShowResult === "Success") {
      setIsSubmitted(true);
      saveLoginStatus(true);
      setIsChecked(true);
    } else if (startMyShowResult === "NotLoggedIn") {
    } else if (startMyShowResult === "ServerError") {
      if (language === "en") {
        toast.error("Server Error");
      } else {
        toast.error("هناك خطأ في الاتصال");
      }
    } else {
      if (language === "en") {
        toast.error("Connection Error");
      } else {
        toast.error("هناك خطأ في الاتصال");
      }
    }
  };

  const handleStartMyShow = async () => {
    return new Promise(async (resolve) => {
      var result = await getMyShow();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          resolve("NotLoggedIn");
        } else if (result.status === 500) {
          resolve("ServerError");
        } else {
          resolve("ConnectionError");
        }
      } else {
        var data = result.data.data;
        console.log(data);

        setIdUser(data.id);
        setIdUserName(data.username);
        console.log("setIdUser:", data.id);

        setIsNormalUser(data.is_normal_user);

        setIsEmailChecked((prev) => {
          const emailValue = data.user_steps && data.user_steps.email;
          console.log("Email value:", emailValue);
          return emailValue !== undefined ? emailValue : prev;
        });

        setIsIdentityChecked((prev) => {
          const identityValue = data.user_steps && data.user_steps.identity;
          console.log("identity value:", identityValue);
          return identityValue !== undefined ? identityValue : prev;
        });

        setIsTaskChecked((prev) => {
          const taskValue = data.user_steps && data.user_steps.task;
          console.log("task value:", taskValue);
          return taskValue !== undefined ? taskValue : prev;
        });

        setIsPayChecked((prev) => {
          const paymentValue = data.user_steps && data.user_steps.payment;
          console.log("Payment value:", paymentValue);
          return paymentValue !== undefined ? paymentValue : prev;
        });

        setIsPreperedChecked((prev) => {
          const preparedValue = data.user_steps && data.user_steps.prepared;
          console.log("prepared value:", preparedValue);
          return preparedValue !== undefined ? preparedValue : prev;
        });

        setIsProjectReadyChecked((prev) => {
          const project_readyValue =
            data.user_steps && data.user_steps.project_ready;
          console.log("project_ready value:", project_readyValue);
          return project_readyValue !== undefined ? project_readyValue : prev;
        });

        setIsDemoChecked((prev) => {
          const demoValue = data.user_steps && data.user_steps.demo_accepted;
          console.log("isDemoChecked value:", demoValue);
          return demoValue !== undefined ? demoValue : prev;
        });

        setIsRequestDemoChecked((prev) => {
          const requestDemoValue =
            data.user_steps && data.user_steps.request_demo;
          console.log("request_demo value:", requestDemoValue);
          return requestDemoValue !== undefined ? requestDemoValue : prev;
        });

        if (data.user_permissions && data.user_permissions.main_admin) {
          setIsSuperUser(data.user_permissions.main_admin);
        }
        if (data.user_permissions && data.user_permissions.junior_analyzor) {
          setIsJuniorAnalyzer(data.user_permissions.junior_analyzor);
        }
        if (data.user_permissions && data.user_permissions.senior_analyzor) {
          setIsSeniorAnalyzer(data.user_permissions.senior_analyzor);
        }
        if (data.user_permissions && data.user_permissions.senior_analyzor) {
          setIsSeniorAnalyzer(data.user_permissions.senior_analyzor);
        }
        setIsDefultDB(data.dbName);

        resolve("Success");
      }
    });
  };

  const handleBack = async () => {
    window.history.back();
  };

  // جلوگیری از نمایش ارور ResizeObserver

  useEffect(() => {
    const checker = async () => {
      await initCheck(false);
    };
    checker();
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // no internet

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showGame, setShowGame] = useState(true);
  const soundRef = useRef(null);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!showGame && soundRef.current) {
      soundRef.current.pause();
      soundRef.current.currentTime = 0;
    }
  }, [showGame]);

  const closeGame = () => {
    setShowGame(false);
  };

  return (
    <>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
          {!isOnline && showGame && (
            <InternetStatus onClose={closeGame} ref={soundRef} />
          )}
          <BrowserRouter>
            <Routes>
              {/* <Route
                path="/"
                element={
                  isSubmitted ? (
                    <MaintenancePage />
                  ) : (
                    <MaintenancePage />
                  )
                }
              /> */}

              <Route
                path="/"
                element={
                  <Login
                    saveLoginStatus={saveLoginStatus}
                    setIsSubmitted={setIsSubmitted}
                    isSubmitted={isSubmitted}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                    handleSubmit={handleSubmit}
                    isSuperUser={isSuperUser}
                    isNormalUser={isNormalUser}
                    isJuniorAnalyzer={isJuniorAnalyzer}
                    isSeniorAnalyzer={isSeniorAnalyzer}
                    isEmailChecked={isEmailChecked}
                    isIdentityChecked={isIdentityChecked}
                    isTaskChecked={isTaskChecked}
                    isPayChecked={isPayChecked}
                    isPreperedChecked={isPreperedChecked}
                    isDemoChecked={isDemoChecked}
                    isProjectReadyChecked={isProjectReadyChecked}
                    isRequestDemoChecked={isRequestDemoChecked}
                  />
                }
              />

              <Route
                path="/base"
                element={
                  isSubmitted &&
                  (isSuperUser || isJuniorAnalyzer || isSeniorAnalyzer) ? (
                    <BaseNew
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isSeniorAnalyzer={isSeniorAnalyzer}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>

                        <div className="card_inner_error_massage_root_access">
                          {
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمحللين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                            /* language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for analysts.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : */
                             }

                          {
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          /* language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : */
                           
                           
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/home/*"
                element={
                  isSubmitted && (isSuperUser || isNormalUser) ? (
                    <SearchAll
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isDefultDB={isDefultDB}
                      isDemoChecked={isDemoChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for Users.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمستخدمين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/home/filter/"
                element={
                  isSubmitted && (isSuperUser || isNormalUser) ? (
                    <FilterMaker
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isDefultDB={isDefultDB}
                      idUserName={idUserName}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for Users.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمستخدمين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/home/news/"
                element={
                  isSubmitted && (isSuperUser || isNormalUser) ? (
                    <NewsPaper
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isDefultDB={isDefultDB}
                      idUserName={idUserName}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for Users.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمستخدمين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />
              <Route
                path="/seniorpage"
                element={
                  isSubmitted && isSeniorAnalyzer ? (
                    <SeniorAnalyzerPage
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isSeniorAnalyzer={isSeniorAnalyzer}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>

                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for senior
                                      analysts.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمحللين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}

                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/seniorconfirm"
                element={
                  isSubmitted && isSeniorAnalyzer ? (
                    <SeniorConfirmPage
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isSeniorAnalyzer={isSeniorAnalyzer}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for senior
                                      analysts.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمحللين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          <div className="bottom_card_inner_error_massage_root_access">
                            {!isSubmitted && <a href="/">Login</a>}
                            {isSubmitted && (
                              <button onClick={handleBack}>Back</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/personal-info"
                element={
                  isSubmitted && !isIdentityChecked ? (
                    <PersonalInformation
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isEmailChecked={isEmailChecked}
                      isIdentityChecked={isIdentityChecked}
                      isTaskChecked={isTaskChecked}
                      isPayChecked={isPayChecked}
                      isPreperedChecked={isPreperedChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>

                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>You have passed this stage</span>
                                    <span>Please enter your desired page.</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>لقد أكملت هذه الخطوة</span>
                                    <span>
                                      الرجاء الانتقال إلى الصفحة المطلوبة
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/profile"
                element={
                  isSubmitted && !isTaskChecked ? (
                    <ProfileUser
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isEmailChecked={isEmailChecked}
                      isIdentityChecked={isIdentityChecked}
                      isTaskChecked={isTaskChecked}
                      isPayChecked={isPayChecked}
                      isPreperedChecked={isPreperedChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>You have passed this stage</span>
                                    <span>Please enter your desired page.</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>لقد أكملت هذه الخطوة</span>
                                    <span>
                                      الرجاء الانتقال إلى الصفحة المطلوبة
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          <div className="bottom_card_inner_error_massage_root_access">
                            {!isSubmitted && <a href="/">Login</a>}
                            {isSubmitted && (
                              <button onClick={handleBack}>Back</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/petition"
                element={
                  isSubmitted && !isTaskChecked && !isDemoChecked ? (
                    <DemoReq
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isEmailChecked={isEmailChecked}
                      isIdentityChecked={isIdentityChecked}
                      isTaskChecked={isTaskChecked}
                      isPayChecked={isPayChecked}
                      isPreperedChecked={isPreperedChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>You have passed this stage</span>
                                    <span>Please enter your desired page.</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>لقد أكملت هذه الخطوة</span>
                                    <span>
                                      الرجاء الانتقال إلى الصفحة المطلوبة
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          <div className="bottom_card_inner_error_massage_root_access">
                            {!isSubmitted && <a href="/">Login</a>}
                            {isSubmitted && (
                              <button onClick={handleBack}>Back</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/demomessage"
                element={
                  isSubmitted ? (
                    <DemoMessage
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isEmailChecked={isEmailChecked}
                      isIdentityChecked={isIdentityChecked}
                      isTaskChecked={isTaskChecked}
                      isPayChecked={isPayChecked}
                      isPreperedChecked={isPreperedChecked}
                      isRequestDemoChecked={isRequestDemoChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>You have passed this stage</span>
                                    <span>Please enter your desired page.</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>لقد أكملت هذه الخطوة</span>
                                    <span>
                                      الرجاء الانتقال إلى الصفحة المطلوبة
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}

                          <div className="bottom_card_inner_error_massage_root_access">
                            {!isSubmitted && <a href="/">Login</a>}
                            {isSubmitted && (
                              <button onClick={handleBack}>Back</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/home/bulletin/"
                element={
                  isSubmitted && (isSuperUser || isNormalUser) ? (
                    <ListBulletin
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isDefultDB={isDefultDB}
                      idUserName={idUserName}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for Users.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمستخدمين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/home/temp_bulletin/"
                element={
                  isSubmitted && (isSuperUser || isNormalUser) ? (
                    <TemplatesBulletin
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isSuperUser={isSuperUser}
                      isDefultDB={isDefultDB}
                      idUserName={idUserName}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      You do not have permission to access this
                                      page
                                    </span>
                                    <span>
                                      this page is specifically for Users.
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>
                                      لا تملك صلاحية الوصول لهذه الصفحة
                                    </span>
                                    <span>الصفحة مخصصة للمستخدمين فحسب</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}

                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route
                path="/pay"
                element={
                  isSubmitted && !isPreperedChecked ? (
                    <PaymentUser
                      saveLoginStatus={saveLoginStatus}
                      setIsSubmitted={setIsSubmitted}
                      isEmailChecked={isEmailChecked}
                      isIdentityChecked={isIdentityChecked}
                      isTaskChecked={isTaskChecked}
                      isPayChecked={isPayChecked}
                      isPreperedChecked={isPreperedChecked}
                    />
                  ) : isChecked ? (
                    <div className="root_access_page">
                      <div className="error_massage_root_access">
                        <div className="circle_error_massage_root_access"></div>
                        <div className="circle_error_massage_root_access"></div>
                        <div className="card_inner_error_massage_root_access">
                          {language === "en" ? (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>You have passed this stage</span>
                                    <span>Please enter your desired page.</span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      You haven't logged into the website yet
                                    </span>
                                    <span>
                                      Please first log in through the login page
                                      to access the site.
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="top_card_inner_error_massage_root_access">
                              <img src={warning_icon} alt="" />
                              <span>
                                {isSubmitted ? (
                                  <p>
                                    <span>لقد أكملت هذه الخطوة</span>
                                    <span>
                                      الرجاء الانتقال إلى الصفحة المطلوبة
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    <span>
                                      لم تقم بتسجيل دخولك إلى الموقع بعد
                                    </span>
                                    <span>
                                      يرجى الدخول أولاً عبر صفحة تسجيل الدخول
                                      للوصول إلى الموقع
                                    </span>
                                  </p>
                                )}
                              </span>
                            </div>
                          )}
                          {language === "en" ? (
                            <div className="bottom_card_inner_error_massage_root_access">
                              {!isSubmitted && <a href="/">Login</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>Back</button>
                              )}
                            </div>
                          ) : (
                            <div className="bottom_card_inner_error_massage_root_access ar">
                              {!isSubmitted && <a href="/"> تسجيل الدخول</a>}
                              {isSubmitted && (
                                <button onClick={handleBack}>الرجوع</button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
            <Outlet />
          </BrowserRouter>
        </LanguageContext.Provider>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
