import avatar_icon from "../../../svg/user_avatar.svg";
import LinkIcon from "@mui/icons-material/Link";
import { HashLoader } from "react-spinners";
import { twitter_profile_url } from "../../../backend/config_backend";
import { ThemeContext, LanguageContext } from "../../../App";
import React, { useContext } from "react";

function MostEffectiveUsersTableViewTW({
  twitterPosts,
  loadingStateTW,
  onUsernameClick,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // const formatFileName = (name) => {
  //   return name
  //     .replaceAll(".", "")
  //     .replaceAll("%", "")
  //     .replaceAll("?", "")
  //     .replaceAll("=", "")
  //     .replaceAll("/", "")
  //     .substring(0, 30)
  //     .toLowerCase();
  // };

  return (
    <div className="top_users_table">
      <div className="top_users_table_page">
        {loadingStateTW ? (
          <div className="spiner_postbox">
            <HashLoader color="#458BDF" size={50} />
          </div>
        ) : (
          <>
            {language === "ar" ? (
              <div
                className={`header_top_users_table ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                <span>اسم المستخدم</span>
                <span>مجموع مشاهدة</span>
                <span>الرابط</span>
              </div>
            ) : (
              <div
                className={`header_top_users_table_en ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                <span>Username</span>
                <span>Total Views</span>

                <span>External Link</span>
              </div>
            )}

            {Object.entries(twitterPosts.user_stats)
              .sort(([, statsA], [, statsB]) => statsB.views - statsA.views)
              .slice(0, 10)
              .map(([username, stats]) => (
                <div
                  className={`body_top_users_table_page ${
                    language === "ar" ? "body_top_users_table_page_ar" : ""
                  } ${theme === "dark" ? "dark_mode" : ""}`}
                  key={username}
                >
                  <div
                    className={`items_body_top_users_table_page ${
                      language === "ar"
                        ? "items_body_top_users_table_page_ar"
                        : ""
                    }`}
                  >
                    <img
                      src={
                        twitter_profile_url + username + ".png"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = avatar_icon;
                      }}
                      alt=""
                      onClick={() => onUsernameClick(username)}
                      id="img_top_user_on_click_cursor"
                    />
                    <div
                      className={`username_body_top_users_table_page ${
                        language === "ar"
                          ? "username_body_top_users_table_page_ar"
                          : ""
                      }`}
                    >
                      <span>{stats.name}</span>
                      <span>{username}</span>
                    </div>
                  </div>
                  <div
                    className={`items_body_top_users_table_page ${
                      language === "ar"
                        ? "items_body_top_users_table_page_ar"
                        : ""
                    }`}
                  >
                    <span>{stats.views}</span>
                  </div>
                  <div
                    className={`items_body_top_users_table_page_link ${
                      language === "ar"
                        ? "items_body_top_users_table_page_link_ar"
                        : ""
                    }`}
                  >
                    <button className="icon_link_items_body_top_users_table_page">
                      <a
                        href={stats.page_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkIcon />
                      </a>
                    </button>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default MostEffectiveUsersTableViewTW;
