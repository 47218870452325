import React, { useState, useContext, useEffect } from "react";
import "../../css/User/ProfileUser.css";
import { BsTwitterX } from "react-icons/bs";
import { SlSocialInstagram } from "react-icons/sl";
import { ImFacebook2 } from "react-icons/im";
import export_cloud from "../../images/export_cloud.png";
import import_cloud from "../../images/import_cloud.png";
import { toast } from "react-toastify";
import Swal from "sweetalert2/src/sweetalert2.js";
import ExcelJS from "exceljs";
import { LanguageContext } from "../../App";
import { getToken } from "../../backend/Login_backend";
import axios from "axios";
import {
  base_url,
  import_file_profile_page,
} from "../../backend/config_backend";
import logo_mesbar from "../../images/new_logo_menu.png";
import tick_step from "../../images/tick_step_blue.png";
import { addTaskUser } from "../../backend/Admin/UserManager/SendTaskManager";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function ProfileUser({ saveLoginStatus, setIsSubmitted }) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  // import excel file

  const [alertShown, setAlertShown] = useState(false);

  const uploadExcelFileToBackend = async (file, fileName) => {
    try {
      const formData = new FormData();
      formData.append(fileName, file);

      const response = await axios.post(
        base_url + import_file_profile_page,
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        }
      );
      if (language === "en") {
        toast.success("File uploaded successfully");
      } else {
        toast.success("تم رفع الملف بنجاح.");
      }
      return response.data;
    } catch (error) {
      if (language === "en") {
        toast.error("Error uploading Excel file");
      } else {
        toast.error("حدث خطأ خلال عملية رفع ملف الإكسل.");
      }
      console.error("Error uploading Excel file:", error);
      throw error;
    }
  };

  const validateExcelTemplate = async (file) => {
    try {
      const workbook = await readExcelFile(file);
      const sheet = workbook.getWorksheet(1);

      if (sheet) {
        const headerRow = sheet.getRow(1);

        // Check header row values
        if (
          headerRow.getCell("A").value === "Word" &&
          headerRow.getCell("B").value === "Account"
        ) {
          return true;
        } else {
          if (language === "en") {
            Swal.fire(
              "Attention!",
              "Invalid header row. Please check the file and try again.",
              "error"
            );
          } else {
            Swal.fire(
              "انتباه",
              "رأس الجدول في الملف غير صحيح. يرجى التحقق من الملف وإعادة المحاولة.",
              "error"
            );
          }
          return false;
        }
      } else {
        if (language === "en") {
          Swal.fire(
            "Attention!",
            "Invalid Excel file. Please provide a valid template.",
            "error"
          );
        } else {
          Swal.fire(
            "انتباه",
            "ملف الإكسل غير صالح. يرجى تقديم قالب صالح.",
            "error"
          );
        }
        return false;
      }
    } catch (error) {
      console.error("Error validating Excel template:", error);
      throw error;
    }
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(data);
          resolve(workbook);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const importAndUploadFile = async (sheetName, fileName) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx";

    fileInput.addEventListener("change", async (event) => {
      try {
        const file = event.target.files[0];

        // Validate file name
        const expectedFileName = `${fileName}Temp.xlsx`;
        if (file.name !== expectedFileName) {
          if (language === "en") {
            Swal.fire(
              "Attention!",
              `Invalid file name. Please upload the ${fileName} sample file.`,
              "error"
            );
          } else {
            Swal.fire(
              "انتباه",
              `اسم الملف غير صالح. يرجى تحميل ${fileName} ملف العينة.`,
              "error"
            );
          }
          return;
        }

        // Validate Excel template
        const isValidTemplate = await validateExcelTemplate(file);

        if (isValidTemplate) {
          // Extract data from Excel file
          const workbook = await readExcelFile(file);
          const sheet = workbook.getWorksheet(1);

          if (sheet) {
            const headerRow = sheet.getRow(1);

            if (
              headerRow.getCell("A").value === "Word" &&
              headerRow.getCell("B").value === "Account"
            ) {
              await uploadExcelFileToBackend(file, fileName);
            } else {
              if (language === "en") {
                Swal.fire(
                  "Attention!",
                  "Invalid header row. Please check the file and try again.",
                  "error"
                );
              } else {
                Swal.fire(
                  "انتباه",
                  "رأس الجدول في الملف غير صحيح. يرجى التحقق من الملف وإعادة المحاولة.",
                  "error"
                );
              }
            }
          } else {
            if (language === "en") {
              Swal.fire(
                "Attention!",
                "Invalid Excel file. Please provide a valid template.",
                "error"
              );
            } else {
              Swal.fire(
                "انتباه",
                "ملف الإكسل غير صالح. يرجى تقديم قالب صالح.",
                "error"
              );
            }
          }
        } else {
          if (language === "en") {
            Swal.fire(
              "Attention!",
              "Invalid Excel file. Please provide a valid template.",
              "error"
            );
          } else {
            Swal.fire(
              "انتباه",
              "ملف الإكسل غير صالح. يرجى تقديم قالب صالح.",
              "error"
            );
          }
        }
      } catch (error) {
        console.error(`Error handling ${fileName} Excel file:`, error);
        if (language === "en") {
          toast.error(`Error handling ${fileName} Excel file:`, error);
        } else {
          toast.error(`معالجة الأخطاء ${fileName} ملف إكسل:`, error);
        }
      }
    });

    fileInput.click();
  };

  const createAndDownloadExcelTemplate = (sheetName, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetName);

    sheet.columns = [
      { header: "Word", key: "word", width: 40 },
      { header: "Account", key: "account", width: 40 },
    ];

    sheet.addRow({ word: "", account: "" });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}Temp.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (!alertShown) {
        setAlertShown(true);
        const message =
          language === "ar"
            ? "املأ الملف الذي تم تحميله وفقًا للحقول المحددة ثم قم بتحميله في قسم الاستيراد"
            : "Fill in the downloaded file according to the specified fields, then upload it in the import section";
        Swal.fire("!انتباه", message, "warning");
      }
    });
  };

  const [formData, setFormData] = useState({
    f_word: "",
    f_account: "",
    x_word: "",
    x_account: "",
    i_word: "",
    i_account: "",
    projectName: "",
    startDate: "",
    endDate: "",
  });

  const handleProjectNameChange = (e) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(e.target.value)) {
      setFormData({ ...formData, projectName: e.target.value });
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "Project Name should contain only English letters.",
      });
    }
  };

  const handleConfirmAdd = () => {
    try {
      const isFacebookEmpty =
        wordList.length === 0 && userNameList.length === 0;
      const isTwitterEmpty =
        wordListX.length === 0 && userNameListX.length === 0;
      const isInstagramEmpty =
        wordListIN.length === 0 && userNameListIN.length === 0;

      let warningText = "";
      if (isFacebookEmpty) {
        warningText += "Facebook is empty.\n";
      }
      if (isTwitterEmpty) {
        warningText += "Twitter is empty.\n";
      }
      if (isInstagramEmpty) {
        warningText += "Instagram is empty.\n";
      }

      const projectNameRegex = /^[a-zA-Z\s]*$/;
      if (!projectNameRegex.test(formData.projectName)) {
        warningText += "Project Name should contain only English letters.\n";
      }

      // بررسی تاریخ شروع و پایان
      if (
        formData.startDate &&
        formData.endDate &&
        new Date(formData.startDate) >= new Date(formData.endDate)
      ) {
        warningText += "End Date should be later than Start Date.\n";
      }

      if (warningText) {
        Swal.fire({
          title: "Are you sure?",
          text: warningText,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, sure it!",
        }).then((result) => {
          if (result.isConfirmed) {
            sendRequest();
          }
        });
      } else {
        sendRequest();
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const sendRequest = async () => {
    try {
      toast.warning(
        language === "ar"
          ? "جاري إرسال طلبك. يرجى الانتظار لحظة"
          : "Your request is being sent. Please wait a moment"
      );
      const result = await addTaskUser(
        wordList,
        userNameList,
        wordListX,
        userNameListX,
        wordListIN,
        userNameListIN,
        formData.projectName,
        formData.startDate,
        formData.endDate
      );
      console.error(result);

      if (!result.hasError) {
        setFormData({
          f_word: "",
          f_account: "",
          x_word: "",
          x_account: "",
          i_word: "",
          i_account: "",
          projectName: "",
          startDate: "",
          endDate: "",
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Task success Send",
          showConfirmButton: false,
          timer: 1500,
        });
        // window.location.href = "/";
        window.location.assign("/");
      } else {
        console.error("Request failed:", result.errorText);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleStartDateChange = (e) => {
    setFormData({ ...formData, startDate: e.target.value });
  };

  const handleEndDateChange = (e) => {
    if (new Date(formData.startDate) >= new Date(e.target.value)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Date",
        text: "End Date should be later than Start Date.",
      });
    } else {
      setFormData({ ...formData, endDate: e.target.value });
    }
  };

  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (tabNumber) => {
    setCurrentTab(tabNumber);
  };

  // input add word facebook

  const [inputWordFB, setInputWordFB] = useState("");
  const [inputUserNameFB, setInputUserNameFB] = useState("");
  const [wordList, setWordList] = useState([]);
  const [userNameList, setUserNameList] = useState([]);

  const handleInputAddWordFB = (e) => {
    setInputWordFB(e.target.value);
  };

  const handleInputAddUserNameFB = (e) => {
    setInputUserNameFB(e.target.value);
  };

  const handleAddWordFB = () => {
    if (inputWordFB.trim() !== "") {
      setWordList([...wordList, inputWordFB]);
      setInputWordFB("");
    }
  };
  const handleAddUserNameFB = () => {
    if (inputUserNameFB.trim() !== "") {
      setUserNameList([...userNameList, inputUserNameFB]);
      setInputUserNameFB("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddWordFB();
      handleAddUserNameFB();
    }
  };

  const handleRemoveWordFB = (index) => {
    const newWordList = [...wordList];
    newWordList.splice(index, 1);
    setWordList(newWordList);
  };

  const handleRemoveUserNameFB = (index) => {
    const newUserList = [...userNameList];
    newUserList.splice(index, 1);
    setUserNameList(newUserList);
  };

  const WordsFB = () => {
    return wordList.map((word, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {word}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveWordFB(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  const UserNamesFB = () => {
    return userNameList.map((userName, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {userName}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveUserNameFB(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  // input add word X

  const [inputWordX, setInputWordX] = useState("");
  const [inputUserNameX, setInputUserNameX] = useState("");
  const [wordListX, setWordListX] = useState([]);
  const [userNameListX, setUserNameListX] = useState([]);

  const handleInputAddWordX = (e) => {
    setInputWordX(e.target.value);
  };

  const handleInputAddUserNameX = (e) => {
    setInputUserNameX(e.target.value);
  };

  const handleAddWordX = () => {
    if (inputWordX.trim() !== "") {
      setWordListX([...wordListX, inputWordX]);
      setInputWordX("");
    }
  };
  const handleAddUserNameX = () => {
    if (inputUserNameX.trim() !== "") {
      setUserNameListX([...userNameListX, inputUserNameX]);
      setInputUserNameX("");
    }
  };

  const handleKeyPressX = (e) => {
    if (e.key === "Enter") {
      handleAddWordX();
      handleAddUserNameX();
    }
  };

  const handleRemoveWordX = (index) => {
    const newWordListX = [...wordListX];
    newWordListX.splice(index, 1);
    setWordListX(newWordListX);
  };

  const handleRemoveUserNameX = (index) => {
    const newUserListX = [...userNameListX];
    newUserListX.splice(index, 1);
    setUserNameListX(newUserListX);
  };

  const WordsX = () => {
    return wordListX.map((word, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {word}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveWordX(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  const UserNamesX = () => {
    return userNameListX.map((userName, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {userName}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveUserNameX(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  // input add word INSTAGRAN

  const [inputWordIN, setInputWordIN] = useState("");
  const [inputUserNameIN, setInputUserNameIN] = useState("");
  const [wordListIN, setWordListIN] = useState([]);
  const [userNameListIN, setUserNameListIN] = useState([]);

  const handleInputAddWordIN = (e) => {
    setInputWordIN(e.target.value);
  };

  const handleInputAddUserNameIN = (e) => {
    setInputUserNameIN(e.target.value);
  };

  const handleAddWordIN = () => {
    if (inputWordIN.trim() !== "") {
      setWordListIN([...wordListIN, inputWordIN]);
      setInputWordIN("");
    }
  };
  const handleAddUserNameIN = () => {
    if (inputUserNameIN.trim() !== "") {
      setUserNameListIN([...userNameListIN, inputUserNameIN]);
      setInputUserNameIN("");
    }
  };

  const handleKeyPressIN = (e) => {
    if (e.key === "Enter") {
      handleAddWordIN();
      handleAddUserNameIN();
    }
  };

  const handleRemoveWordIN = (index) => {
    const newWordListIN = [...wordListIN];
    newWordListIN.splice(index, 1);
    setWordListIN(newWordListIN);
  };

  const handleRemoveUserNameIN = (index) => {
    const newUserListIN = [...userNameListIN];
    newUserListIN.splice(index, 1);
    setUserNameListIN(newUserListIN);
  };

  const WordsIN = () => {
    return wordListIN.map((word, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {word}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveWordIN(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  const UserNamesIN = () => {
    return userNameListIN.map((userName, index) => (
      <div
        key={index}
        className="rows_body_items_profile_page_items_input_list_add"
      >
        <div className="rows_body_items_profile_page_items_input_list_add_words">
          {userName}
        </div>
        <button
          className="button__remove_list_words_profile_user"
          onClick={() => handleRemoveUserNameIN(index)}
        >
          ✖
        </button>
      </div>
    ));
  };

  // progress bar

  // const [activeStep, setActiveStep] = useState(1);

  let activeStep = 1;

  useEffect(() => {
    const progress = document.getElementById(
      "profile_page_progressbar_progress"
    );
    progress.style.width = `${(activeStep - 1) * 33}%`;
  }, [activeStep]);

  const getCircleClass = (step) => {
    return activeStep > step
      ? "circle_profile_page_progressbar_completed"
      : "circle_profile_page_progressbar";
  };

  const getCircleContent = (step) => {
    if (activeStep > step) {
      return <img src={tick_step} alt="Completed" className="checkmark_icon" />;
    }
    return step;
  };

  return (
    <div className="profile_page">
      <div className="login_page_header">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <img src={logo_mesbar} alt="" />
        </div>
      </div>

      <div className="profile_page_progressbar sendtask">
        <div
          className={`progress_container ${
            language === "ar" ? "progress_container_ar" : ""
          }`}
        >
          <div
            className={`profile_page_progressbar_progress ${
              language === "ar" ? "profile_page_progressbar_progress_ar" : ""
            }`}
            id="profile_page_progressbar_progress"
          ></div>
          {[1, 2, 3, 4].map((step) => (
            <div
              key={step}
              className={`text_wrap_profile_page_progressbar ${
                activeStep >= step ? "active" : ""
              }`}
            >
              <div className={getCircleClass(step)}>
                {getCircleContent(step)}
              </div>
              {language === "en" ? (
                <p className="text_profile_page_progressbar">
                  {step === 1 && "define project"}
                  {step === 2 && "verification"}
                  {step === 3 && "payment"}
                  {step === 4 && "data prep"}
                </p>
              ) : (
                <p className="text_profile_page_progressbar">
                  {step === 1 && "إنشاء المشروع"}
                  {step === 2 && "التأکيد"}
                  {step === 3 && "الدفع"}
                  {step === 4 && "إعداد المعلومات"}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="profile_page_body">
        <div className="tabs_rows_body_profile_page_tabs_btn_header">
          <div
            className={`tab_rows_body_profile_page_tabs_btn_header ${
              currentTab === 0 ? "active" : ""
            }`}
            onClick={() => changeTab(0)}
          >
            <div className="title">
              <BsTwitterX id="tab_rows_body_profile_page_tabs_btn_header_icon_social" />
            </div>
          </div>
          <div
            className={`tab_rows_body_profile_page_tabs_btn_header ${
              currentTab === 1 ? "active" : ""
            }`}
            onClick={() => changeTab(1)}
          >
            <div className="title">
              <ImFacebook2 id="tab_rows_body_profile_page_tabs_btn_header_icon_social" />
            </div>
          </div>
          <div
            className={`tab_rows_body_profile_page_tabs_btn_header ${
              currentTab === 2 ? "active" : ""
            }`}
            onClick={() => changeTab(2)}
          >
            <div className="title">
              <SlSocialInstagram id="tab_rows_body_profile_page_tabs_btn_header_icon_social" />
            </div>
          </div>
        </div>
        <div className="profile_user_tabs">
          <div className="left_profile_user_tabs">
            {language === "en" ? (
              <div className="left_profile_user_tabs_items">
                <div className="left_profile_user_tabs_items_col">
                  <label>Project Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name..."
                    value={formData.projectName}
                    onChange={handleProjectNameChange}
                  />
                </div>
                <div className="left_profile_user_tabs_items_col">
                  <label>Start Date</label>
                  <input
                    type="date"
                    value={formData.startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div className="left_profile_user_tabs_items_col">
                  <label>End Date</label>
                  <input
                    type="date"
                    value={formData.endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
                {currentTab === 0 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("Facebook", "f_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("Facebook", "f_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
                {currentTab === 1 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("X", "x_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("X", "x_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
                {currentTab === 2 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("Instagram", "i_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("Instagram", "i_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="left_profile_user_tabs_items arabic">
                <div className="left_profile_user_tabs_items_col arabic">
                  <label>تاريخ الانتهاء</label>
                  <input
                    type="date"
                    value={formData.endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
                <div className="left_profile_user_tabs_items_col arabic">
                  <label>تاریخ البدء</label>
                  <input
                    type="date"
                    value={formData.startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div className="left_profile_user_tabs_items_col arabic">
                  <label>إسم المشروع</label>
                  <input
                    type="text"
                    placeholder="Instagram data "
                    value={formData.projectName}
                    onChange={handleProjectNameChange}
                  />
                </div>
                {currentTab === 0 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("Facebook", "f_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("Facebook", "f_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
                {currentTab === 1 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("X", "x_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("X", "x_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
                {currentTab === 2 && (
                  <div className="left_profile_user_tabs_items_col_hor">
                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() => importAndUploadFile("Instagram", "i_file")}
                    >
                      <img src={export_cloud} alt="" />
                    </button>

                    <button
                      className="open_file_rows_body_items_profile_page"
                      onClick={() =>
                        createAndDownloadExcelTemplate("Instagram", "i_file")
                      }
                    >
                      <img src={import_cloud} alt="" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="right_profile_user_tabs">
            <div className="rows_body_profile_page">
              <div className="rows_body_profile_page_tabs_btn_header">
                {currentTab === 0 && (
                  <div className="body_profile_page">
                    {language === "ar" ? (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameX}
                              onChange={handleInputAddUserNameX}
                              onKeyPress={handleKeyPressX}
                              placeholder="أدخل حسابات المستخدمين المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddUserNameX}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف اسم المستخدم
                              <span>+</span>
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesX()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordX}
                              onChange={handleInputAddWordX}
                              onKeyPress={handleKeyPressX}
                              placeholder="أدخل الكلمات المفتاحية المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddWordX}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف کلمة المفتاحية
                              <span>+</span>
                            </button>
                          </div>

                          <div className="rows_body_items_profile_page_words_show">
                            {WordsX()}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordX}
                              onChange={handleInputAddWordX}
                              onKeyPress={handleKeyPressX}
                              placeholder="Enter your keywords..."
                            />
                            <button
                              onClick={handleAddWordX}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {WordsX()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameX}
                              onChange={handleInputAddUserNameX}
                              onKeyPress={handleKeyPressX}
                              placeholder="Enter your user accounts..."
                            />
                            <button
                              onClick={handleAddUserNameX}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesX()}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {currentTab === 1 && (
                  <div className="body_profile_page">
                    {language === "ar" ? (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameFB}
                              onChange={handleInputAddUserNameFB}
                              onKeyPress={handleKeyPress}
                              placeholder="أدخل حسابات المستخدمين المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddUserNameFB}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف اسم المستخدم
                              <span>+</span>
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesFB()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordFB}
                              onChange={handleInputAddWordFB}
                              onKeyPress={handleKeyPress}
                              placeholder="أدخل الكلمات المفتاحية المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddWordFB}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف کلمة المفتاحية
                              <span>+</span>
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {WordsFB()}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordFB}
                              onChange={handleInputAddWordFB}
                              onKeyPress={handleKeyPress}
                              placeholder="Enter your keywords..."
                            />
                            <button
                              onClick={handleAddWordFB}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {WordsFB()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameFB}
                              onChange={handleInputAddUserNameFB}
                              onKeyPress={handleKeyPress}
                              placeholder="Enter your user accounts..."
                            />
                            <button
                              onClick={handleAddUserNameFB}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesFB()}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {currentTab === 2 && (
                  <div className="body_profile_page">
                    {language === "ar" ? (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameIN}
                              onChange={handleInputAddUserNameIN}
                              onKeyPress={handleKeyPressIN}
                              placeholder="أدخل حسابات المستخدمين المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddUserNameIN}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف اسم المستخدم
                              <span>+</span>
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesIN()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordIN}
                              onChange={handleInputAddWordIN}
                              onKeyPress={handleKeyPressIN}
                              placeholder="أدخل الكلمات المفتاحية المطلوبة لديك"
                            />
                            <button
                              onClick={handleAddWordIN}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              أضف کلمة المفتاحية
                              <span>+</span>
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {WordsIN()}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputWordIN}
                              onChange={handleInputAddWordIN}
                              onKeyPress={handleKeyPressIN}
                              placeholder="Enter your keywords..."
                            />
                            <button
                              onClick={handleAddWordIN}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {WordsIN()}
                          </div>
                        </div>
                        <div className="rows_body_items_profile_page">
                          <div className="rows_body_items_profile_page_items_input">
                            <input
                              type="text"
                              className="input_add_words_rows_body_items_profile_page"
                              value={inputUserNameIN}
                              onChange={handleInputAddUserNameIN}
                              onKeyPress={handleKeyPressIN}
                              placeholder="Enter your user accounts..."
                            />
                            <button
                              onClick={handleAddUserNameIN}
                              className="button__submit_add_rows_body_items_profile_page"
                            >
                              <span>+</span>
                              ADD
                            </button>
                          </div>
                          <div className="rows_body_items_profile_page_words_show">
                            {UserNamesIN()}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile_page_footer_btn_send">
        {language === "en" ? (
          <div className="profile_user_btn_send_item en">
            <button
              onClick={handleConfirmAdd}
              className="profile_user_btn_send en"
            >
              Request to verify your project
            </button>
          </div>
        ) : (
          <div className="profile_user_btn_send_item">
            <button
              onClick={handleConfirmAdd}
              className="profile_user_btn_send"
            >
              طلب تأکيد المشروع
            </button>
          </div>
        )}
      </div>
      <div className="logout_icon_steps_filed">
        <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
      </div>
    </div>
  );
}

export default ProfileUser;
