import axios from "axios";
import {
  base_url,
  tokenName,
  add_subject_url,
  add_subSubject_url,
  delete_subject_url,
  delete_sub_subject_url
} from "../../config_backend";
import { getToken } from "../../Login_backend";

export const addTopic = async (
  nameProject,
  subjectName,
) => {
  console.log("🚀 ~ file: TopicManager.js:13 ~ subjectName:",nameProject, subjectName)
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;
  var errorText = "";
  let formData = new FormData();
  formData.append("projectName", nameProject);
  formData.append("subjectName", subjectName);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_subject_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const deleteTopic = async (
  nameProject,
  subjectName,
) => {
  console.log("🚀 ~ file: TopicManager.js:68 ~ subjectName:", subjectName,nameProject)
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;
  var errorText = "";
  let formData = new FormData();
  formData.append("projectName", nameProject);
  formData.append("subjectName", subjectName);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + delete_subject_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const deleteSubTopic = async (
  nameProject,
  subjectName,
  subSubjectName,
) => {
  console.log("🚀 ~ file: TopicManager.js:68 ~ subjectName:", subjectName,nameProject)
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;
  var errorText = "";
  let formData = new FormData();
  formData.append("projectName", nameProject);
  formData.append("subjectName", subjectName);
  formData.append("sub_subjectName", subSubjectName);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + delete_sub_subject_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addSubTopic = async (
  nameProject,
  subjectName,
  subSubjectName,
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;
  var errorText = "";
  let formData = new FormData();
  formData.append("projectName", nameProject);
  formData.append("subjectName", subjectName);
  formData.append("sub_subjectName", subSubjectName);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_subSubject_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
