import { useState, useContext } from "react";
import chart_icon from "../../../images/cloud_icon_figma.png";
import { LanguageContext } from "../../../App";

function EmotionalAnalysisMenu({ onSelect }) {
  // arabic

  const { language } = useContext(LanguageContext);

  const [selectedItem, setSelectedItem] = useState("emotional_radar");

  const btnSelectEmotionalAnalysisStyles = {
    backgroundColor:
      selectedItem === "emotional_linear"
        ? "rgb(221, 238, 255)"
        : "rgb(255, 255, 255)",
  };

  const handlebtnSelectEmotionalAnalysis = () => {
    setSelectedItem(
      selectedItem === "emotional_radar"
        ? "emotional_linear"
        : "emotional_radar"
    );
    onSelect(
      selectedItem === "emotional_radar"
        ? "emotional_linear"
        : "emotional_radar"
    );
  };

  return (
    <div className="componnets_drop_result_search">
      {language === "ar" ? (
        <button
          className="btn_twiiter_colors_result"
          style={btnSelectEmotionalAnalysisStyles}
          onClick={handlebtnSelectEmotionalAnalysis}
        >
          <img src={chart_icon} alt="" />
        </button>
      ) : (
        <button
          className="btn_twiiter_colors_result_en"
          style={btnSelectEmotionalAnalysisStyles}
          onClick={handlebtnSelectEmotionalAnalysis}
        >
          <img src={chart_icon} alt="" />
        </button>
      )}
    </div>
  );
}

export default EmotionalAnalysisMenu;
