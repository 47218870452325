export const DropMenu = {
    submenu : [
        { Key: 1, Value: 'Facebook' },
        { Key: 2, Value: 'Twitter' }, 
        { Key: 3, Value: 'Instagram' }, 
        { Key: 4, Value: 'Telegram' }, 
    ]
}

export const DropMenuAr = {
    submenu : [
        { Key: 1, Value: 'فیسبوك' },
        { Key: 2, Value: 'توییتر' }, 
        { Key: 3, Value: 'اینستا' }, 
        { Key: 4, Value: 'تلکرام' }, 
    ]
}


export const ChooseRanking = {
    submenu : [
        { Key: 1, Value: 'Post Creates' , parameter: 'delta_time'}, 
        { Key: 2, Value: 'Most Likes' , parameter: 'likes'},
        { Key: 3, Value: 'Most Shares' , parameter: 'shares'}, 
        { Key: 4, Value: 'Most Comments' , parameter: 'comments'}, 
        { Key: 5, Value: 'Influence Score' , parameter: 'likes'}, 
    ]
}

export const ChooseRankingAr = {
    submenu : [
        { Key: 1, Value: 'إنشاء منشور' , parameter: 'delta_time' },
        { Key: 2, Value: 'أكثر إعجابًا', parameter: 'likes' },
        { Key: 3, Value: 'أكثر مشاركة', parameter: 'shares' }, 
        { Key: 4, Value: 'أكثر تعليقًا', parameter: 'comments' }, 
        { Key: 5, Value: 'نقاط التأثير', parameter: 'likes' }, 
    ]
}

export const ChooseNumber = {
    submenu : [
        { Key: 1, Value: 'Post' },

    ]
}

export const ChooseNumberAr = {
    submenu : [
        { Key: 1, Value: 'بريد' },

    ]
}
