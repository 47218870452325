import { useState, useContext } from "react";
import chart_icon from "../../../images/cloud_icon_figma.png";
import { LanguageContext } from "../../../App";

function CloudPlatformsExistMenu({ onSelect }) {
  // arabic

  const { language } = useContext(LanguageContext);

  const [selectedItem, setSelectedItem] = useState("wordcloudplatform");

  const btnSelectChartCloudPlatformStyles = {
    backgroundColor:
      selectedItem === "chartwordplatform"
        ? "rgb(221, 238, 255)"
        : "rgb(255, 255, 255)",
  };

  const handlebtnSelectChartCloudPlatform = () => {
    setSelectedItem(
      selectedItem === "chartwordplatform"
        ? "wordcloudplatform"
        : "chartwordplatform"
    );
    onSelect(
      selectedItem === "chartwordplatform"
        ? "wordcloudplatform"
        : "chartwordplatform"
    );
  };

  return (
    <div className="componnets_drop_result_search">
      {language === "ar" ? (
        <button
          className="btn_twiiter_colors_result"
          style={btnSelectChartCloudPlatformStyles}
          onClick={handlebtnSelectChartCloudPlatform}
        >
          <img src={chart_icon} alt="" />
        </button>
      ) : (
        <button
          className="btn_twiiter_colors_result_en"
          style={btnSelectChartCloudPlatformStyles}
          onClick={handlebtnSelectChartCloudPlatform}
        >
          <img src={chart_icon} alt="" />
        </button>
      )}
    </div>
  );
}

export default CloudPlatformsExistMenu;
