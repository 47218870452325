import axios from "axios";
import {
  base_url,
  admin_user_add_url,
  admin_user_list_url,
  user_delete_url,
  tokenName,
  admin_user_update_url,
  user_list_url,
  user_add_url,
  user_update_url,
  register_user,
  rest_pass_user,
  add_personal_information,
  get_personal_information,
  get_users_demo,
  send_req_user_demo,
  update_user_demo,
  changeExpiry_user_demo,
  user_task_list
} from "../../config_backend";
import { getToken } from "../../Login_backend";

export const deleteAdminUserLists = async (userid) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .delete(base_url + user_delete_url + userid, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const updateTickAdminUserLists = async (
  userid,
  main_admin,
  senior_analyzor,
  junior_analyzor,
  username
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();
  formData.append("username", username);

  formData.append("user_permissions.main_admin", main_admin);
  formData.append("user_permissions.senior_analyzor", senior_analyzor);
  formData.append("user_permissions.junior_analyzor", junior_analyzor);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .put(base_url + admin_user_update_url + userid + "/", formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const updateMainAdminUserLists = async (
  userid,
  username,
  email,
  main_admin,
  senior_analyzor,
  junior_analyzor
  // dbName
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();

  formData.append("username", username);
  formData.append("email", email);
  // formData.append("dbName", dbName);

  formData.append("user_permissions.main_admin", main_admin);
  formData.append("user_permissions.senior_analyzor", senior_analyzor);
  formData.append("user_permissions.junior_analyzor", junior_analyzor);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .put(base_url + admin_user_update_url + userid + "/", formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addAdminUserLists = async (username, email, password, dbName) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();
  formData.append("username", username);
  formData.append("email", email);
  formData.append("password", password);
  formData.append("dbName", dbName);

  // formData.append("dbName", "new_fb2");
  formData.append("user_permissions.main_admin", false);
  formData.append("user_permissions.senior_analyzor", false);
  formData.append("user_permissions.junior_analyzor", true);

  formData.append("user_steps.email", false);
  formData.append("user_steps.identity", false);
  formData.append("user_steps.payment", false);
  formData.append("user_steps.prepared", false);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + admin_user_add_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getAdminUserLists = async (nextLink, pageSize = 50) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + admin_user_list_url;
  if (nextLink && nextLink !== "") url = nextLink;
  await axios
    .get(url, {
      withCredentials: true,
      params: {
        page_size: pageSize,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addUserLists = async (username, email, password, dbName) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("username", username);
  formData.append("email", email);
  formData.append("password", password);
  formData.append("dbName", dbName);
  formData.append("user_permissions.main_admin", false);
  formData.append("user_permissions.senior_analyzor", false);
  formData.append("user_permissions.junior_analyzor", false);

  formData.append("user_steps.email", false);
  formData.append("user_steps.identity", false);
  formData.append("user_steps.payment", false);
  formData.append("user_steps.prepared", false);

  formData.append("is_normal_user", true);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + user_add_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getUserLists = async (nextLink, pageSize = 50) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }
  var url = base_url + user_list_url;
  if (nextLink && nextLink !== "") url = nextLink;
  await axios
    .get(url, {
      withCredentials: true,
      params: {
        page_size: pageSize,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const updateMainUserLists = async (
  userid,
  username,
  email,
  // dbName,
  user_steps_email,
  identity,
  payment,
  prepared,
  task,
  projectReady
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();

  formData.append("username", username);
  formData.append("email", email);
  // formData.append("dbName", dbName);

  formData.append("user_permissions.main_admin", false);
  formData.append("user_permissions.senior_analyzor", false);
  formData.append("user_permissions.junior_analyzor", false);

  formData.append("user_steps.email", user_steps_email);
  formData.append("user_steps.identity", identity);
  formData.append("user_steps.task", task);
  formData.append("user_steps.payment", payment);
  formData.append("user_steps.prepared", prepared);
  formData.append("user_steps.project_ready", projectReady);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .put(base_url + user_update_url + userid + "/", formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const updateTickUserLists = async (
  userid,
  username,
  email,
  identity,
  task,
  payment,
  prepared,
  projectReady,
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();
  console.log(
    "Update: ",
    userid,
    email,
    identity,
    task,
    payment,
    prepared,
    username,
    projectReady
  );

  formData.append("username", username);

  formData.append("user_permissions.main_admin", false);
  formData.append("user_permissions.senior_analyzor", false);
  formData.append("user_permissions.junior_analyzor", false);

  formData.append("user_steps.email", email);
  formData.append("user_steps.identity", identity);
  formData.append("user_steps.task", task);
  formData.append("user_steps.payment", payment);
  formData.append("user_steps.prepared", prepared);
  formData.append("user_steps.project_ready", projectReady);


  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .put(base_url + user_update_url + userid + "/", formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const registerUser = async (username, email, password) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("username", username);
  formData.append("email", email);
  formData.append("password", password);
  var data = undefined;
  await axios
    .post(base_url + register_user, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        //Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const restPasswordUser = async (userNameRestPass, emailRestPass) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formDataRestPass = new FormData();
  formDataRestPass.append("username", userNameRestPass);
  formDataRestPass.append("email", emailRestPass);

  var data = undefined;

  await axios
    .post(base_url + rest_pass_user, formDataRestPass, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        // Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addPersonalInformation = async (
  firstName,
  lastName,
  MobileNumber,
  PhoneNumber,
  intersted,
  jobTitle,
  message,
  company_name,
  employees_no,
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  if (firstName) formData.append("first_name", firstName);
  if (lastName) formData.append("last_name", lastName);
  if (MobileNumber) formData.append("mobile", MobileNumber);
  if (PhoneNumber) formData.append("phone_number", PhoneNumber);
  if (jobTitle) formData.append("job_title", jobTitle);
  if (intersted) formData.append("intersted", intersted);
  if (message) formData.append("message", message);
  if (company_name) formData.append("company_name", company_name);
  if (employees_no) formData.append("employees_no", employees_no);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_personal_information, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getPersonalInformation = async (user_id) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + get_personal_information;

  await axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
      // params: {
      //   user_id: user_id
      // }
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getUsersDemoLists = async (nextLink, pageSize = 50) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + get_users_demo;
  if (nextLink && nextLink !== "") url = nextLink;
  await axios
    .get(url, {
      withCredentials: true,
      params: {
        page_size: pageSize,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const sendUsersDemo = async () => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + send_req_user_demo;
  await axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const updateUsersDemo = async (
  username,
  request_demo,
  accepted_demo
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";
  let formData = new FormData();
  console.log("Update: ",  username, request_demo, accepted_demo);

  formData.append("username", username);
  formData.append("request_demo", request_demo);
  formData.append("demo_accepted", accepted_demo);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + update_user_demo , formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

   
    .then(function (response) {
      if (response.data !== undefined) data = response.data;
    })
    
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};


export const changeExpiryUserDemo = async (
  username,
  targetDate_text
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  if (username) formData.append("username", username);
  if (targetDate_text) formData.append("targetDate", targetDate_text);
 
  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + changeExpiry_user_demo, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getUsersTaskList = async (nextLink, pageSize = 50) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + user_task_list;
  if (nextLink && nextLink !== "") url = nextLink;
  await axios
    .get(url, {
      withCredentials: true,
      params: {
        page_size: pageSize,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};