import { useEffect, useState, useContext } from "react";
import { LanguageContext } from "../../../App";

function MostEffectiveUsersMenuHeaderIN({ onSelectedOption }) {
  const { language } = useContext(LanguageContext);

  const [isOpenMostEffectiveUsers, setIsOpenMostEffectiveUsers] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setSelectedOption(language === "ar" ? "مجموع الإعجابات" : "Total Like");
  }, [language]);

  const toggleMenuMostEffectiveUsers = () => {
    setIsOpenMostEffectiveUsers(!isOpenMostEffectiveUsers);
  };

  useEffect(() => {
    const handleClickOutsideMostEffectiveUsers = (event) => {
      if (
        isOpenMostEffectiveUsers &&
        !event.target.closest(".menu_button_most_effective_users")
      ) {
        setIsOpenMostEffectiveUsers(false);
      }
    };

    document.addEventListener("click", handleClickOutsideMostEffectiveUsers);

    return () => {
      document.removeEventListener(
        "click",
        handleClickOutsideMostEffectiveUsers
      );
    };
  }, [isOpenMostEffectiveUsers]);

  const handleOptionClickMostEffectiveUsers = (option) => {
    setSelectedOption(option);
    setIsOpenMostEffectiveUsers(false);
    onSelectedOption(option);
  };

  return (
    <div className="most_effective_users_menu_header">
      <div
        className={`menu_button_most_effective_users ${
          language === "en" ? "menu_button_most_effective_users_en" : ""
        }`}
        onClick={toggleMenuMostEffectiveUsers}
      >
        {selectedOption}
        <span
          className={`arrow_filter_result_search ${
            isOpenMostEffectiveUsers ? "up" : "down"
          } ${
            language === "ar"
              ? "arrow_filter_result_search_arabic"
              : "arrow_filter_result_search_en"
          }`}
        >
          ⮛
        </span>
      </div>
      {isOpenMostEffectiveUsers && (
        <div className="options_container_menu_button_most_effective_users">
          {language === "ar" ? (
            <>
              <div
                className="option_menu_button_most_effective_users"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("مجموع الإعجابات")
                }
              >
                مجموع الإعجابات
              </div>
              <div
                className="option_menu_button_most_effective_users"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("مجموع وسائط")
                }
              >
                مجموع وسائط
              </div>
              <div
                className="option_menu_button_most_effective_users"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("مجموع الردود")
                }
              >
                مجموع الردود
              </div>
              <div
                className="option_menu_button_most_effective_users"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("نسبة التفاعلات إلى المحتوى")
                }
              >
                نسبة التفاعلات إلى المحتوى
              </div>
              <div
                className="option_menu_button_most_effective_users"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("نسبة المتابعين إلى التفاعلات على المحتوى")
                }
              >
                نسبة المتابعين إلى التفاعلات على المحتوى
              </div>
            </>
          ) : (
            <>
              <div
                className="option_menu_button_most_effective_users_en"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("Total Like")
                }
              >
                Total Like
              </div>
              <div
                className="option_menu_button_most_effective_users_en"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("Total shares")
                }
              >
                Total Media
              </div>
              <div
                className="option_menu_button_most_effective_users_en"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("Total Replies")
                }
              >
                Total Replies
              </div>
              <div
                className="option_menu_button_most_effective_users_en"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("Engagement to Content Ratio")
                }
              >
                Engagement to Content Ratio
              </div>
              <div
                className="option_menu_button_most_effective_users_en"
                onClick={() =>
                  handleOptionClickMostEffectiveUsers("Followers to Engagement Ratio on Content")
                }
              >
                Followers to Engagement Ratio on Content
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default MostEffectiveUsersMenuHeaderIN;
