import React, { useEffect, useRef, useState, useContext } from "react";
import * as echarts from "echarts/lib/echarts";
import "echarts-wordcloud";
import Select from "react-select";
import { words } from "../CloudHashtagPlatformsTab/WordsFilter";
import { LanguageContext } from "../../../App";

const CloudPlatformsExistWordCloudIN = ({ instagramPosts, onWordClick }) => {
  const chartRef = useRef(null);
  const [wordCount, setWordCount] = useState(-1); // تنظیم مقدار پیش‌فرض برای نمایش کلمات
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (
      instagramPosts &&
      instagramPosts.entity_chart_action &&
      instagramPosts.entity_chart_action.happy &&
      instagramPosts.entity_chart_action.sad &&
      instagramPosts.entity_chart_action.indifferent
    ) {
      const myChart = echarts.init(chartRef.current);

      // پردازش داده‌ها و اضافه کردن رنگ
      const processData = (data, color) => {
        return Object.keys(data).map((key) => ({
          name: key,
          value: data[key],
          textStyle: {
            color: color,
          },
        }));
      };

      const happyData = processData(instagramPosts.entity_chart_action.happy, "#4caf50");
      const sadData = processData(instagramPosts.entity_chart_action.sad, "#a7bcb9");
      const indifferentData = processData(instagramPosts.entity_chart_action.indifferent, "#E80505");

      const combinedData = [...happyData, ...sadData, ...indifferentData];

      const filteredWordCloudData = combinedData.filter(
        (wordItem) => !words.includes(wordItem.name)
      );

      if (filteredWordCloudData.length > 0) {
        const option = {
          tooltip: {},
          series: [
            {
              type: "wordCloud",
              gridSize: 10,
              sizeRange: [15, 40],
              rotationRange: [0, 20],
              shape: "rect",
              textStyle: {
                fontFamily: "sans-serif",
                fontWeight: "bold",
              },
              emphasis: {
                focus: "self",
                textStyle: {
                  textShadowBlur: 10,
                  textShadowColor: "#333",
                },
              },
              data: filteredWordCloudData.slice(0, wordCount),
            },
          ],
        };

        myChart.setOption(option);

        myChart.on("click", ({ data }) => {
          if (data && data.name) {
            onWordClick(data.name);
          }
        });

        window.addEventListener("resize", () => {
          myChart.resize();
        });

        return () => {
          myChart.dispose();
        };
      } else {
        myChart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text: language === "ar" ? "ليس هنالك معلومات" : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      }
    } else {
      const myChart = echarts.init(chartRef.current);
      myChart.setOption({
        graphic: {
          elements: [
            {
              type: "group",
              left: "center",
              top: "middle",
              children: [
                {
                  type: "rect",
                  left: "center",
                  top: "middle",
                  shape: {
                    width: 300,
                    height: 45,
                    r: 12,
                  },
                  style: {
                    fill: "#f4f7fe",
                    stroke: "#458bdf",
                    lineWidth: 2,
                  },
                },
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  style: {
                    text: language === "ar" ? "ليس هنالك معلومات" : "No data available",
                    font: 'bold 16px "Segoe UI", sans-serif',
                    fill: "#000",
                  },
                },
              ],
            },
          ],
        },
      });
    }
  }, [wordCount, instagramPosts, onWordClick, language]);

  const wordCountOptions = [
    {
      value: -1,
      label: language === "ar" ? "عرض جميع الكلمات" : "Show All Words",
    },
  ];

  for (
    let i = 5;
    i <=
    (instagramPosts.entity_chart_action
      ? Object.keys(instagramPosts.entity_chart_action.happy || {}).length +
        Object.keys(instagramPosts.entity_chart_action.sad || {}).length +
        Object.keys(instagramPosts.entity_chart_action.indifferent || {}).length
      : 0);
    i += 5
  ) {
    wordCountOptions.push({
      value: i,
      label: language === "ar" ? `${i} كلمة` : `${i} Words`,
    });
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: language === "ar" ? "right" : "left",
    }),
    control: (provided) => ({
      ...provided,
      direction: language === "ar" ? "rtl" : "ltr",
    }),
    singleValue: (provided) => ({
      ...provided,
      direction: language === "ar" ? "rtl" : "ltr",
    }),
  };

  return (
    <div className="CloudHashtagPlatformsWordTW">
      <div className="select_CloudHashtagPlatformsWordTW">
        <Select
          options={wordCountOptions}
          value={wordCountOptions.find((option) => option.value === wordCount)}
          onChange={(selectedOption) => setWordCount(selectedOption.value)}
          styles={customStyles}
        />
      </div>
      <div ref={chartRef} style={{ width: "90%", height: "500px" }} />
    </div>
  );
};

export default CloudPlatformsExistWordCloudIN;
