import React, { useState, useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ThemeContext, LanguageContext } from "../../../App";
import clear_icon from "../../../images/broom_icon.png";
import facebook_icon from "../../../images/facebook_icon_figma.png";
import insta_icon from "../../../images/instagram_icon_figma.png";
import x_icon from "../../../images/x_icon_figma.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

function SimpleSearchNew({
  selectedMenuItem,
  setSearchWord,
  setIsTwitterActive,
  setIsFacebookActive,
  isTwitterActive,
  isFacebookActive,
  setStartingDate,
  setEndingDate,
  setIsInstagramActive,
  isInstagramActive,
  setIsWebSiteActive,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  const [buttonLists, setButtonLists] = useState([
    [
      {
        text: <img src={x_icon} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
      {
        text: <img src={insta_icon} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
      {
        text: <img src={facebook_icon} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
    ],
  ]);

  const handleButtonClick = (menuIndex, buttonIndex) => {
    const newButtonLists = [...buttonLists];
    newButtonLists[menuIndex][buttonIndex].background =
      newButtonLists[menuIndex][buttonIndex].background === "#fff"
        ? "rgba(0, 0, 0, 0.08)"
        : "#fff";

    setButtonLists(newButtonLists);

    if (buttonIndex === 0) {
      setIsTwitterActive(!isTwitterActive);
    } else if (buttonIndex === 2) {
      setIsFacebookActive(!isFacebookActive);
    } else if (buttonIndex === 1) {
      setIsInstagramActive(!isInstagramActive);
    }
  };

  useEffect(() => {
    updateActiveButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem]);

  const updateActiveButtons = () => {
    switch (selectedMenuItem) {
      case 0:
        setIsTwitterActive(true);
        setIsFacebookActive(true);
        setIsInstagramActive(true);
        setIsWebSiteActive(true);
        break;
      case 1:
        setIsTwitterActive(true);
        setIsFacebookActive(true);
        setIsInstagramActive(true);
        setIsWebSiteActive(false);
        break;
      case 2:
        setIsTwitterActive(false);
        setIsFacebookActive(false);
        setIsInstagramActive(false);
        setIsWebSiteActive(true);
        break;
      default:
        break;
    }
  };

  // استیت انتخاب بازه زمانی

  const [selectedRadio, setSelectedRadio] = useState("quick");
  const [showPopupDate, setShowPopupDate] = useState(false);
  const [tempSelectedRadio, setTempSelectedRadio] = useState(null);

  const handleOpenPopupDate = () => {
    setTempSelectedRadio(selectedRadio);
    setShowPopupDate(true);
  };

  const handleClosePopupDate = (applyChanges) => {
    if (!applyChanges) {
      setSelectedRadio(tempSelectedRadio);
    }
    setShowPopupDate(false);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
    if (e.target.value === "quick" && selectedRadio !== "quick") {
      clearDateInputs();
    } else if (e.target.value === "custom" && selectedRadio !== "custom") {
      clearDateInputs();
    }
  };

  const handleConfirm = () => {
    setShowPopupDate(false);
  };

  // اینپوت تاریخ

  const [selectedFromDateSearch, setSelectedFromDateSearch] = useState(null);
  const [selectedToDateSearch, setSelectedToDateSearch] = useState(null);

  const formatDate = (date, offset) => {
    if (!date) return "";
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    const year = newDate.getUTCFullYear();
    const month = newDate.getUTCMonth() + 1;
    const day = newDate.getUTCDate();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  const handleFromDateSearch = (date) => {
    if (!date) return;
    if (selectedToDateSearch && date > selectedToDateSearch) {
      if (language === "en") {
        toast.error("Start date cannot be greater than end date.");
      } else {
        toast.error("لا يمكن أن يكون تاريخ البدء أكبر من تاريخ الانتهاء.");
      }
      return;
    }
    setSelectedFromDateSearch(date);
    setStartingDate(formatDate(date, 1));
    setCurrentDate(date);
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
  };

  const handleToDateSearch = (date) => {
    if (!date) return;
    if (selectedFromDateSearch && date < selectedFromDateSearch) {
      if (language === "en") {
        toast.error("End date cannot be less than start date.");
      } else {
        toast.error("لا يمكن أن يكون تاريخ الانتهاء أقل من تاريخ البدء.");
      }
      return;
    }
    setSelectedToDateSearch(date);
    setEndingDate(formatDate(date, 1));
    setSelectedDate(date);
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
  };
  const clearDateInputs = () => {
    setStartingDate("");
    setEndingDate("");

    const today = new Date();
    setCurrentDate(today);
    setSelectedDate(today);

    setSelectedFromDateSearch(null);
    setSelectedToDateSearch(null);

    setIsButtonClicked(false);
    setSelectedButton(null);
  };

  const handleSearchWordChange = (e) => {
    setSearchWord(e.target.value);
    console.log("Search Word ", e.target.value);
  };

  // دکمه های انتخاب سریع

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClickDateTime = (dateOffset) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + dateOffset);
    console.log("handleButtonClickDateTime: ", newDate);
    setCurrentDate(newDate);
    setStartingDate(formatDate(newDate, 0));
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
    setSelectedButton(dateOffset);
  };

  return (
    <div className="simple_search">
      {language === "en" ? (
        <div className="simple_search_body">
          <div className="input_center_simple_search">
            <input
              className={`input_center_simple_search_input  ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              onChange={handleSearchWordChange}
              placeholder="Word search..."
            />
          </div>
          <div className="items_simple_search_new">
            <div id="serachin_simple_search">
              <span>Search in:</span>
              <div className="inputs_items_simple_search_new">
                {buttonLists[selectedMenuItem].map((button, index) => (
                  <button
                    className="btns_bastar"
                    key={index}
                    style={{
                      backgroundColor: button.background,
                      color: button.textColor,
                      border:
                        button.background !== "rgba(0, 0, 0, 0.08)"
                          ? "2px solid rgb(22, 139, 242)"
                          : "none",
                    }}
                    onClick={() => handleButtonClick(selectedMenuItem, index)}
                  >
                    {button.text}
                  </button>
                ))}
              </div>
            </div>
            <div className="inputs_items_simple_search_new">
              <button
                className="btn_show_date_sim_search"
                onClick={handleOpenPopupDate}
              >
                {isButtonClicked
                  ? `${currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} to ${selectedDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}`
                  : currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </button>

              <Dialog
                className="popup_mui_search"
                open={showPopupDate}
                onClose={() => handleClosePopupDate(false)}
              >
                <DialogTitle className="header_popup_detail_display_search">
                  <label>Choose Time Frame</label>
                  <button
                    onClick={() => handleClosePopupDate(false)}
                    className="btn_close_sim_detail_search"
                  >
                    <CloseIcon />
                  </button>
                </DialogTitle>
                <DialogContent className="body_popup_simple_search">
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search">
                      <input
                        type="radio"
                        value="quick"
                        checked={selectedRadio === "quick"}
                        onChange={handleRadioChange}
                      />
                      <label>Quick Selection</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={() => {
                          if (selectedRadio !== "custom") {
                            clearDateInputs();
                          }
                        }}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>

                    <div className="col_rowone_dateinput_simple_search">
                      <div className="col_rowone_dateinput_simple_search">
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === 0 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(0)}
                        >
                          Today
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -1 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-1)}
                        >
                          Yesterday
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -2 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-2)}
                        >
                          2 Days Ago
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -3 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-3)}
                        >
                          3 Days Ago
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -7 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-7)}
                        >
                          1 Week Ago
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -30 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-30)}
                        >
                          1 Month Ago
                        </button>
                      </div>
                    </div>
                    <div className="show_date_time_from_until_search_simple">
                      {" "}
                      {isButtonClicked
                        ? `${currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })} to ${selectedDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}`
                        : currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                    </div>
                  </div>
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search">
                      <input
                        type="radio"
                        value="custom"
                        checked={selectedRadio === "custom"}
                        onChange={handleRadioChange}
                      />
                      <label>Custom Period</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        // onClick={clearDateInputs}
                        onClick={() => {
                          if (selectedRadio !== "quick") {
                            clearDateInputs();
                          }
                        }}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>
                    <div className="col_rowtwo_dateinput_simple_search">
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedFromDateSearch}
                            onChange={handleFromDateSearch}
                            placeholderText="From Date"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                        <label>From</label>
                      </div>
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedToDateSearch}
                            onChange={handleToDateSearch}
                            placeholderText="Until Date"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                        <label>Until</label>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions className="btns_bottom_search_simple">
                  <button
                    className="okay_btn_search_simple"
                    onClick={handleConfirm}
                  >
                    Ok
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      ) : (
        <div className="simple_search_body">
          <div className="input_center_simple_search">
            <input
              className={`input_center_simple_search_input_ar  ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              onChange={handleSearchWordChange}
              placeholder="البحث عن كلمة..."
            />
          </div>
          <div className="items_simple_search_new">
            <div className="inputs_items_simple_search_new">
              <button
                className="btn_show_date_sim_search"
                onClick={handleOpenPopupDate}
              >
                {isButtonClicked
                  ? `${currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} حتی   ${selectedDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}`
                  : currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </button>

              <Dialog
                className="popup_mui_search"
                open={showPopupDate}
                onClose={() => handleClosePopupDate(false)}
              >
                <DialogTitle className="header_popup_detail_display_search">
                  <label>اختيار فترة زمنية</label>
                  <button
                    onClick={() => handleClosePopupDate(false)}
                    className="btn_close_sim_detail_search"
                  >
                    <CloseIcon />
                  </button>
                </DialogTitle>
                <DialogContent className="body_popup_simple_search">
                  <div className="rowone_dateinput_simple_search_ar">
                    <div className="col_rowone_dateinput_simple_search_ar">
                      <input
                        type="radio"
                        value="quick"
                        checked={selectedRadio === "quick"}
                        onChange={handleRadioChange}
                      />
                      <label>اختيار سريع</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        // onClick={clearDateInputs}
                        onClick={() => {
                          if (selectedRadio !== "custom") {
                            clearDateInputs();
                          }
                        }}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>

                    <div className="col_rowtwo_dateinput_simple_search_ar">
                      <div className="col_rowone_dateinput_simple_search_ar">
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === 0 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(0)}
                        >
                          اليوم
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -1 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-1)}
                        >
                          منذ الأمس
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -2 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-2)}
                        >
                          الأیام الثلاثة الماضیة
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -3 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-3)}
                        >
                          الأیام الأربعة الماضیة
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -7 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-7)}
                        >
                          الأسبوع الماضي
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -30 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-30)}
                        >
                          الشهر الماضي
                        </button>
                      </div>
                    </div>
                    <div className="show_date_time_from_until_search_simple">
                      {" "}
                      {isButtonClicked
                        ? `${currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })} حتی   ${selectedDate.toLocaleDateString(
                            undefined,
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}`
                        : currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                    </div>
                  </div>
                  <div className="rowone_dateinput_simple_search_ar">
                    <div className="col_rowone_dateinput_simple_search_ar">
                      <input
                        type="radio"
                        value="custom"
                        checked={selectedRadio === "custom"}
                        onChange={handleRadioChange}
                      />
                      <label>فترة محددة</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={() => {
                          if (selectedRadio !== "quick") {
                            clearDateInputs();
                          }
                        }}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>
                    <div className="col_rowtwo_dateinput_simple_search_ar">
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <label> : من</label>
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedFromDateSearch}
                            onChange={handleFromDateSearch}
                            placeholderText="تاريخ بداية البحث"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                      </div>
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <label> : إلی</label>
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedToDateSearch}
                            onChange={handleToDateSearch}
                            placeholderText="تاريخ نهاية البحث"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions className="btns_bottom_search_simple">
                  <button
                    className="okay_btn_search_simple"
                    onClick={handleConfirm}
                  >
                    موافق
                  </button>
                </DialogActions>
              </Dialog>
            </div>
            <div id="serachin_simple_search">
              <div className="inputs_items_simple_search_new">
                {buttonLists[selectedMenuItem].map((button, index) => (
                  <button
                    className="btns_bastar"
                    key={index}
                    style={{
                      backgroundColor: button.background,
                      color: button.textColor,
                      border:
                        button.background !== "rgba(0, 0, 0, 0.08)"
                          ? "2px solid rgb(22, 139, 242)"
                          : "none",
                    }}
                    onClick={() => handleButtonClick(selectedMenuItem, index)}
                  >
                    {button.text}
                  </button>
                ))}
              </div>

              <span>:ابحث فی</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SimpleSearchNew;
