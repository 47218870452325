import React, { useContext, useState } from "react";
import { LanguageContext } from "../../App";
import { Link } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import logo from "../../images/new_logo_menu.png";
import { sendUsersDemo } from "../../backend/Admin/UserManager/UserManager";
import { toast } from "react-toastify";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function DemoReq({ saveLoginStatus, setIsSubmitted }) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const [activeTab, setActiveTab] = useState("vip");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleConfirmation = async () => {
    try {
      await sendUsersDemo();
    } catch (error) {
      toast.warning(
        language === "ar"
          ? "حدثت مشكلة أثناء إرسال الطلب"
          : "There was a problem sending the request"
      );
    }
  };

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  return (
    <>
      <div className="body_massage_page">
        <div className="header_body_massage_page">
          <div className="language-toggle-menu">
            <div className="language-toggle-container">
              <div className="language-toggle-button" onClick={toggleMenu}>
                {language === "ar" ? "العربية" : "English (US)"}
                {islanguageMenuOpen ? (
                  <TiArrowSortedUp />
                ) : (
                  <TiArrowSortedDown />
                )}
              </div>
              {islanguageMenuOpen && (
                <div
                  className={`language-menu ${
                    islanguageMenuOpen ? "show" : ""
                  }`}
                  id="language_menu"
                >
                  <div
                    className={language === "ar" ? "active" : ""}
                    onClick={() => handleLanguageToggle("ar")}
                  >
                    العربية
                  </div>
                  <div
                    className={language === "en" ? "active" : ""}
                    onClick={() => handleLanguageToggle("en")}
                  >
                    English (US)
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="petition_page_btn_top_header_right">
            <img src={logo} alt="" />
          </div>
        </div>

        {language === "ar" ? (
          <div className="petition_page_card arabic">
            <div className="petition_page_btn_top_header">
              <button
                onClick={() => handleTabChange("demo")}
                className={activeTab === "demo" ? "activeButton" : ""}
              >
                النسخة التجربية
              </button>
              <button
                onClick={() => handleTabChange("vip")}
                className={activeTab === "vip" ? "activeButton" : ""}
              >
                المشاريع المخصصة
              </button>
            </div>
            {activeTab === "demo" && (
              <div className="petition_page_body_card">
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>تحليل المشاعر و الانطباعات</span>
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon red" />
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <span>قائمة النتائج و مقدار النشر</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>المواضيع و المجالات</span>
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon red" />
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <span>سحابة الكلمات و سحابة الرموز التعبيرية</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>المستخدمين الأكثر نشاطا و تأثيرا</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "vip" && (
              <div className="petition_page_body_card">
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>المستخدمين الأكثر نشاطًا و تأثيرًا</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <span>قائمة النتائج و مقدار النشر</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>المواضيع و المجالات</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <span>سحابة الكلمات و سحابة الرموز التعبيرية</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>انشاء استعلام</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <span>تحليل المشاعر و الانطباعات</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <span>صحف</span>
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon green" />
                  </div>
                </div>
              </div>
            )}
            <div className="petition_page_card_btn_footer arabic">
              {activeTab === "demo" ? (
                <Link to="/demomessage" onClick={handleConfirmation}>
                  تأکيد
                </Link>
              ) : (
                <Link to="/profile">تأکيد</Link>
              )}
            </div>
          </div>
        ) : (
          <div className="petition_page_card">
            <div className="petition_page_btn_top_header">
              <button
                onClick={() => handleTabChange("demo")}
                className={activeTab === "demo" ? "activeButton" : ""}
              >
                Demo Project
              </button>
              <button
                onClick={() => handleTabChange("vip")}
                className={activeTab === "vip" ? "activeButton" : ""}
              >
                Vip Project
              </button>
            </div>
            {activeTab === "demo" && (
              <div className="petition_page_body_card">
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon green" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon red" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon green" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon red" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "vip" && (
              <div className="petition_page_body_card">
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon green" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon red" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                </div>
                <div className="petition_page_body_card_rows">
                  <div className="petition_page_body_card_rows_items">
                    <HighlightOffIcon className="petition_page_body_card_rows_items_icon green" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                  <div className="petition_page_body_card_rows_items">
                    <IoMdCheckmarkCircleOutline className="petition_page_body_card_rows_items_icon red" />
                    <span>Lorem Ipsum is simply dummy text</span>
                  </div>
                </div>
              </div>
            )}
            <div className="petition_page_card_btn_footer">
              {activeTab === "demo" ? (
                <Link to="/demomessage">OK</Link>
              ) : (
                <Link to="/profile">OK</Link>
              )}
            </div>
          </div>
        )}

        <div className="logout_icon_steps_filed">
          <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
        </div>
      </div>
    </>
  );
}

export default DemoReq;
