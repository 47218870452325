import HomeMenu from "../../../pages/Public/HomeMenu";
import MenuTop from "../../../components/SearchAll/Public/MenuTop";
import { ThemeContext, LanguageContext } from "../../../App";
import React, { useState, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TempBlueBulletin from "./TempBlueBulletin";
import temp_blue from "../../../images/temp_blue.png";
import temp_orange from "../../../images/temp_orange.png";
import temp_Sky from "../../../images/temp_sky.png";
import TempOrangeBulletin from "./TempOrangeBulletin";
import TempSkyBulletin from "./TempSkyBulletin";
import AddIcon from "@mui/icons-material/Add";


function ListBulletin({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  setSelectedMenuItem,
  idUserName,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  //   loaded list
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedApi, setfetchedApi] = useState(false);
  const [apiData, setapiData] = useState([]);
  // const fetchDataRef = useRef(null);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         await handleStart();
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchData();
  //   }, [fetchedApi]);

  //   const handleStart = async () => {
  //     try {
  //       const result = await listFilterMaker();
  //       if (result.hasError) {
  //         if (result.isLoggedIn === false) {
  //           // setneedLogin(true);
  //         } else if (result.status === 500) {
  //           window.alert("Server Error");
  //         } else {
  //           window.alert("Connection Error");
  //         }
  //       } else {
  //         const data = result.data.data;
  //         console.log("🚀 filter maker result:", result);
  //         setapiData(data);
  //         setfetchedApi(true);
  //       }
  //     } catch (error) {
  //       toast.error("Something Went Wrong");
  //     }
  //   };

  //   useEffect(() => {
  //     const fetchDataRef = setInterval(() => {
  //       setfetchedApi(true);
  //     }, 5000);

  //     return () => {
  //       clearInterval(fetchDataRef);
  //     };
  //   }, []);

  //   show detail bulletin
  const [showTable, setShowTable] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const handleFlashClick = (filterId) => {
    const selected = apiData.find((filter) => filter.id === filterId);
    setSelectedFilter(selected);
    setShowTable(false);
  };

  const handleReturnClick = () => {
    setShowTable(true);
  };

  //   select templates
  const [selectedTemplate, setSelectedTemplate] = useState(1);
  const [dynamicContent, setDynamicContent] = useState("Soon...");

  const showTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const handleContentChange = (e) => {
    setDynamicContent(e.target.value);
  };

  // bulletin
  

  return (
    <div className="search_all">
      <div
        className={`search_all_page ${
          language === "ar" ? "search_all_page_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <HomeMenu
          saveLoginStatus={saveLoginStatus}
          setIsSubmitted={setIsSubmitted}
          isSuperUser={isSuperUser}
          isDefultDB={isSuperUser}
        />
        <div
          className={`body_search_all ${theme === "dark" ? "dark_mode" : ""}`}
        >
          <div className="header_search_all">
            <MenuTop
              onSelectMenuItem={setSelectedMenuItem}
              username={idUserName}
              saveLoginStatus={saveLoginStatus}
              setIsSubmitted={setIsSubmitted}
            />
          </div>
          <div className="header_filter_maker">
            {language === "ar" ? (
              <div className="header_filter_maker_body">
                <button id="btn_add_filter_header_filter_page"> <AddIcon /></button>
                <p>قائمة النقاط</p>
              </div>
            ) : (
              <div className="header_filter_maker_body">
                <p>Bulletins List</p>
                <button id="btn_add_filter_header_filter_page"> <AddIcon /></button>
              </div>
            )}
          </div>
          <div className="body_filter_maker">
            {showTable ? (
              <div className="container_table_body_filter_maker">
                {language === "ar" ? (
                  <ul className="responsive_table_filter_maker">
                    <li className="table_header_filter_maker arabic" id="table_header_filter_maker_arabic">
                      <div className="col col-1">التسلسل</div>
                      <div className="col col-2">الاسم</div>
                      <div className="col col-3">تاريخ الإنشاء</div>
                      <div className="col col-5">وضعیت</div>
                      {/* <div className="col col-5">الشبكات الإجتماعية</div> */}
                      <div className="col col-5">خيارات</div>
                    </li>
                    {/* {isLoading ? (
                    <div className="spinner_postbox_admin_page">
                      <div>
                        <ScaleLoader color="#36d7b7" size={120} />
                      </div>
                    </div>
                  ) : (
                    <> */}
                    {/* {apiData.map((filter, index) => ( */}
                    <li className="table_row_filter_maker arabic">
                      <div className="col col-1" data-label="ID">
                        1
                      </div>
                      <div className="col col-2" data-label="Name">
                      اختبار
                      </div>
                      <div className="col col-3" data-label="Create Date">
                        2/10/2024
                      </div>
                      <div className="col col-5" data-label="status">
                      يحتوي على محتوى
                      </div>
                      {/* <div className="col col-5" data-label="Socials">
                        <XIcon id="social_media_icon_filter_maker" />
                        <FacebookIcon id="social_media_icon_filter_maker" />
                        <InstagramIcon id="social_media_icon_filter_maker" />
                      </div> */}
                      <div className="col col-5" data-label="Options">
                        <CloseIcon id="options_btn_filter_maker_page" />
                        <ArrowBackIosIcon
                          id="options_btn_filter_maker_page"
                          onClick={() => handleFlashClick()}
                        />
                      </div>
                    </li>
                    {/* ))} */}
                    {/* </>
                  )} */}
                  </ul>
                ) : (
                  <ul className="responsive_table_filter_maker">
                    <li className="table_header_filter_maker" id="table_header_filter_maker_en">
                      <div className="col col-1">Row</div>
                      <div className="col col-2">Name</div>
                      <div className="col col-3">Create Date</div>
                      <div className="col col-5">Status</div>
                      {/* <div className="col col-5">Socials</div> */}
                      <div className="col col-6">Options</div>
                    </li>
                    {isLoading ? (
                      <div className="spinner_postbox_admin_page">
                        <div>
                        <HashLoader color="#458BDF" size={50} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* {apiData.map((filter, index) => ( */}
                        <li className="table_row_filter_maker">
                          <div className="col col-1" data-label="ID"></div>
                          <div className="col col-2" data-label="Name"></div>
                          <div className="col col-3" data-label="Create Date">
                            2/10/2024
                          </div>
                          <div className="col col-4" data-label="status">0</div>
                          {/* <div className="col col-5" data-label="Socials">
                            <XIcon id="social_media_icon_filter_maker" />
                            <FacebookIcon id="social_media_icon_filter_maker" />
                            <InstagramIcon id="social_media_icon_filter_maker" />
                          </div> */}
                          <div className="col col-6" data-label="Options">
                            <SearchIcon id="options_btn_filter_maker_page" />

                            <CloseIcon id="options_btn_filter_maker_page" />
                            <ArrowForwardIosIcon id="options_btn_filter_maker_page" />
                          </div>
                        </li>
                        {/* ))} */}
                      </>
                    )}
                  </ul>
                )}
              </div>
            ) : (
              <div className="result_body_filter_maker">
                <div className="btn_return_result_body_filter_maker">
                  <button>
                    <ArrowBackIosNewIcon onClick={handleReturnClick} />
                  </button>
                </div>
                <div className="body_templates_bulletin_page">
                  <div className="left_side_body_templates_bulletin_page">
                    <div className="items_left_side_body_templates_bulletin_page">
                      <div className="">
                        <img onClick={() => showTemplate(1)} src={temp_blue} alt="" />
                      </div>
                      <div className="">
                        <img onClick={() => showTemplate(2)}  src={temp_orange} alt="" />
                      </div>
                      <div className="">
                        <img onClick={() => showTemplate(3)} src={temp_Sky} alt="" />
                      </div>
                    </div>
                    <div
                      div
                      className="items_left_side_body_templates_bulletin_page"
                    >
                      select font
                    </div>
                  </div>
                  <div className="right_side_body_templates_bulletin_page">
                    <TemplateRenderer
                      template={selectedTemplate}
                      dynamicContent={dynamicContent}
                      onContentChange={handleContentChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TemplateRenderer({ template, dynamicContent, onContentChange }) {
  const selectTemplate = (templateId) => {
    switch (templateId) {
      case 1:
        return (
          <TempBlueBulletin>
            <h2>Blue Temp</h2>
            <p>{dynamicContent}</p>
          </TempBlueBulletin>
        );
      case 2:
        return (
          <TempOrangeBulletin>
            <h2>Orange Temp</h2>
            <p>{dynamicContent}</p>
          </TempOrangeBulletin>
        );
      case 3:
        return (
          <TempSkyBulletin>
            <h2>Sky Temp</h2>
            <p>{dynamicContent}</p>
          </TempSkyBulletin>
        );
      default:
        return null;
    }
  };

  return template ? selectTemplate(template) : null;
}

export default ListBulletin;
