import React, { useContext, useEffect, useState } from "react";
import pen_icon from "../../../svg/pen.svg";
import search_icon from "../../../svg/search_icon.svg";
import insta_icon from "../../../svg/insta_icon.svg";
import cal_icon from "../../../svg/cal_icon.svg";
import language_icon from "../../../svg/language_icon.svg";
// import detail_icon from "../../../svg/detail.svg";
// import filter_icon from "../../../svg/filter_icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ThemeContext, LanguageContext } from "../../../App";
import clear_icon from "../../../images/broom_icon.png";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { listFilterMaker } from "../../../backend/filterMaker/FilterMaker_backend";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";
import facebook_icon from "../../../images/facebook_icon_figma.png";
import insta_icon_figma from "../../../images/instagram_icon_figma.png";
import x_icon from "../../../images/x_icon_figma.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AdvancedSearch({
  selectedMenuItem,
  setSearchWord,
  setSearchOrWord,
  setSearchNotWord,
  setIsTwitterActive,
  setIsFacebookActive,
  isTwitterActive,
  isFacebookActive,
  setIsInstagramActive,
  isInstagramActive,
  setStartingDate,
  setEndingDate,
  isWebSiteActive,
  setIsWebSiteActive,
  setSearchfilter,
  setActiveTab,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  const [buttonLists, setButtonLists] = useState([
    [
      {
        text: <img src={x_icon} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
      {
        text: <img src={insta_icon_figma} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
      {
        text: <img src={facebook_icon} alt="" />,
        background: "#fff",
        textColor: "rgb(59, 63, 67)",
      },
    ],
  ]);

  const handleButtonClick = (menuIndex, buttonIndex) => {
    const newButtonLists = [...buttonLists];
    newButtonLists[menuIndex][buttonIndex].background =
      newButtonLists[menuIndex][buttonIndex].background === "#fff"
        ? "rgba(0, 0, 0, 0.08)"
        : "#fff";

    setButtonLists(newButtonLists);

    if (buttonIndex === 0) {
      setIsTwitterActive(!isTwitterActive);
    } else if (buttonIndex === 2) {
      setIsFacebookActive(!isFacebookActive);
    } else if (buttonIndex === 1) {
      setIsInstagramActive(!isInstagramActive);
    }
  };
  useEffect(() => {
    updateActiveButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem]);

  const updateActiveButtons = () => {
    switch (selectedMenuItem) {
      case 0:
        setIsTwitterActive(true);
        setIsFacebookActive(true);
        setIsInstagramActive(true);
        setIsWebSiteActive(true);
        break;
      case 1:
        setIsTwitterActive(true);
        setIsFacebookActive(true);
        setIsInstagramActive(true);
        setIsWebSiteActive(false);
        break;
      case 2:
        setIsTwitterActive(false);
        setIsFacebookActive(false);
        setIsInstagramActive(false);
        setIsWebSiteActive(true);
        break;
      default:
        break;
    }
  };

  const [selectedRadio, setSelectedRadio] = useState("quick");
  const [showPopupDate, setShowPopupDate] = useState(false);
  const [tempSelectedRadio, setTempSelectedRadio] = useState(null);

  const handleOpenPopupDate = () => {
    setTempSelectedRadio(selectedRadio);
    setShowPopupDate(true);
  };

  const handleClosePopupDate = (applyChanges) => {
    if (!applyChanges) {
      setSelectedRadio(tempSelectedRadio);
    }
    setShowPopupDate(false);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
    if (e.target.value === "quick" && selectedRadio !== "quick") {
      clearDateInputs();
    } else if (e.target.value === "custom" && selectedRadio !== "custom") {
      clearDateInputs();
    }
  };

  const handleConfirm = () => {
    setShowPopupDate(false);
  };

  // اینپوت تاریخ

  const [selectedFromDateSearch, setSelectedFromDateSearch] = useState(null);
  const [selectedToDateSearch, setSelectedToDateSearch] = useState(null);

  const formatDate = (date, offset) => {
    if (!date) return "";
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    const year = newDate.getUTCFullYear();
    const month = newDate.getUTCMonth() + 1;
    const day = newDate.getUTCDate();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  const handleFromDateSearch = (date) => {
    if (!date) return;
    if (selectedToDateSearch && date > selectedToDateSearch) {
      if (language === "en") {
        toast.error("Start date cannot be greater than end date.");
      } else {
        toast.error("لا يمكن أن يكون تاريخ البدء أكبر من تاريخ الانتهاء.");
      }
      return;
    }
    setSelectedFromDateSearch(date);
    setStartingDate(formatDate(date, 1));
    setCurrentDate(date);
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
  };

  const handleToDateSearch = (date) => {
    if (!date) return;
    if (selectedFromDateSearch && date < selectedFromDateSearch) {
      if (language === "en") {
        toast.error("End date cannot be less than start date.");
      } else {
        toast.error("لا يمكن أن يكون تاريخ الانتهاء أقل من تاريخ البدء.");
      }
      return;
    }
    setSelectedToDateSearch(date);
    setEndingDate(formatDate(date, 1));
    setSelectedDate(date);
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
  };
  const clearDateInputs = () => {
    setStartingDate("");
    setEndingDate("");

    const today = new Date();
    setCurrentDate(today);
    setSelectedDate(today);

    setSelectedFromDateSearch(null);
    setSelectedToDateSearch(null);

    setIsButtonClicked(false);
    setSelectedButton(null);
  };

  const handleSearchWordChange = (e) => {
    setSearchWord(e.target.value);
    console.log("Search Word ", e.target.value);
  };

  function handleSearchOrWordChange(e) {
    setSearchOrWord(e.target.value);
    console.log("Search Or Word ", e.target.value);
  }

  function handleSearchNotWordChange(e) {
    setSearchNotWord(e.target.value);
    console.log("Search NotWord ", e.target.value);
  }

  // دکمه های انتخاب سریع

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClickDateTime = (dateOffset) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + dateOffset);
    console.log("handleButtonClickDateTime: ", newDate);
    setCurrentDate(newDate);
    setStartingDate(formatDate(newDate, 0));
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    }
    setSelectedButton(dateOffset);
  };

  // language btn radio

  const [languageBtn, setLanguageBtn] = useState("arabic");

  const handleLanguageChange = (e) => {
    if (e.target.value === "english") {
      if (language === "ar") {
        Swal.fire({
          text: "لا توجد المنشورات لهذه اللغة بعد",
          icon: "warning",
        });
      } else {
        Swal.fire({
          text: "No posts available for this language yet",
          icon: "warning",
        });
      }
    } else {
      setLanguageBtn("arabic");
    }
  };

  // filter maker

  //   loaded list
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedApi, setfetchedApi] = useState(false);
  const [apiData, setapiData] = useState([]);
  // const fetchDataRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleStart();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedApi]);

  const handleStart = async () => {
    try {
      const result = await listFilterMaker();
      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        const data = result.data.data;
        console.log("🚀 filter maker result:", result);
        setapiData(data);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  useEffect(() => {
    const fetchDataRef = setInterval(() => {
      setfetchedApi(true);
    }, 5000);

    return () => {
      clearInterval(fetchDataRef);
    };
  }, []);

  // btn select name filter
  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterClick = (filterId, filterName) => {
    console.log(`Filter ID: ${filterId}, Filter Name: ${filterName}`);
    if (selectedFilter === filterId) {
      setSelectedFilter(null);
      setSearchfilter(null);
    } else {
      setSelectedFilter(filterId);
      setSearchfilter(filterId);
    }
  };

  return (
    <div className="advanced_search">
      {language === "en" ? (
        <div className="advanced_search_body">
          <div className="items_simple_search">
            <label>
              <img src={pen_icon} alt="" />
              All of These Words
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              placeholder="The search results include all of these words together"
              onChange={handleSearchWordChange}
            />
          </div>
          <div className="items_simple_search">
            <label>
              <img src={pen_icon} alt="" />
              One of These Words
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              placeholder="The search results must include at least one of these words"
              onChange={handleSearchOrWordChange}
            />
          </div>
          <div className="items_simple_search">
            <label>
              <img src={pen_icon} alt="" />
              Without These Words
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              placeholder="The search results do not include any of these words"
              onChange={handleSearchNotWordChange}
            />
          </div>
          <div className="items_simple_search">
            <label>
              <img src={insta_icon} alt="" />
              Platform
            </label>

            <div
              className={`inputs_items_simple_search ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              {buttonLists[selectedMenuItem].map((button, index) => (
                <button
                  className="btns_bastar"
                  key={index}
                  style={{
                    backgroundColor: button.background,
                    color: button.textColor,
                    border:
                      button.background !== "rgba(0, 0, 0, 0.08)"
                        ? "2px solid rgb(22, 139, 242)"
                        : "none",
                  }}
                  onClick={() => handleButtonClick(selectedMenuItem, index)}
                >
                  {button.text}
                </button>
              ))}
            </div>
          </div>
          <div className="items_simple_search">
            <label>
              <img src={cal_icon} alt="" />
              Date
            </label>
            <div
              className={`inputs_items_simple_search ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <button
                className="btn_show_date_sim_search"
                onClick={handleOpenPopupDate}
              >
                {isButtonClicked
                  ? `${currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} From ${selectedDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}`
                  : currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </button>

              <Dialog
                className="popup_mui_search"
                open={showPopupDate}
                onClose={() => handleClosePopupDate(false)}
              >
                <DialogTitle className="header_popup_detail_display_search">
                  <label>Choose Time Period</label>
                  <button
                    onClick={() => handleClosePopupDate(false)}
                    className="btn_close_sim_detail_search"
                  >
                    <CloseIcon />
                  </button>
                </DialogTitle>
                <DialogContent className="body_popup_simple_search">
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search">
                      <input
                        type="radio"
                        value="quick"
                        checked={selectedRadio === "quick"}
                        onChange={handleRadioChange}
                      />
                      <label>Quick Selection</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={clearDateInputs}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>

                    <div className="col_rowone_dateinput_simple_search">
                      <div className="col_rowone_dateinput_simple_search">
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === 0 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(0)}
                        >
                          Today
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -1 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-1)}
                        >
                          Yesterday
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -2 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-2)}
                        >
                          Last 2 Days
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -3 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-3)}
                        >
                          Last 3 Days
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -7 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-7)}
                        >
                          Last Week
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search ${
                            selectedButton === -30 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-30)}
                        >
                          Last Month
                        </button>
                      </div>
                    </div>
                    <div className="show_date_time_from_until_search_simple">
                      {" "}
                      {isButtonClicked
                        ? `${currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })} to ${selectedDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}`
                        : currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                    </div>
                  </div>
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search">
                      <input
                        type="radio"
                        value="custom"
                        checked={selectedRadio === "custom"}
                        onChange={handleRadioChange}
                      />
                      <label>Custom Period</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={clearDateInputs}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>
                    <div className="col_rowtwo_dateinput_simple_search">
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedFromDateSearch}
                            onChange={handleFromDateSearch}
                            placeholderText="From Date"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                        <label>From</label>
                      </div>
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedToDateSearch}
                            onChange={handleToDateSearch}
                            placeholderText="Until Date"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                        <label>Until</label>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions className="btns_bottom_search_simple">
                  <button
                    className="okay_btn_search_simple"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div className="items_simple_search">
            <label>
              <img src={language_icon} alt="" />
              Language
            </label>
            <div className={`inputs_items_simple_search`}>
              <label id="btn_radio_box_search_top">
                <input
                  type="radio"
                  value="english"
                  name="language"
                  checked={languageBtn === "english"}
                  onChange={handleLanguageChange}
                />
                English
              </label>
              <label id="btn_radio_box_search_top">
                <input
                  type="radio"
                  value="arabic"
                  name="language"
                  checked={languageBtn === "arabic"}
                  onChange={handleLanguageChange}
                />
                العربية
              </label>
            </div>
          </div>
          <div className="items_simple_search">
            <label>
              <img src={search_icon} alt="" />
              Search in
            </label>
            <div
              className={`inputs_items_simple_search_ar_searchin ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <div className="rows_advanced_search_filter_top en">
                <label id="btn_radio_box_search_top">
                  All
                  <input
                    type="radio"
                    value="all"
                    name="searchin"
                    defaultChecked
                  />
                </label>
                <label id="btn_radio_box_search_top">
                  Posts
                  <input type="radio" value="posts" name="searchin" />
                </label>
                <label id="btn_radio_box_search_top">
                  Comments
                  <input type="radio" value="comments" name="searchin" />
                </label>
              </div>
              <div className="rows_advanced_search_filter_bottom en">
                {isLoading ? (
                  <div className="spinner_postbox_admin_page">
                    <div>
                      <BarLoader color="#458BDF" size={10} />
                    </div>
                  </div>
                ) : (
                  <>
                    {apiData.length > 0 ? (
                      apiData.map((filter) => (
                        <button
                          key={filter.id}
                          onClick={() =>
                            handleFilterClick(filter.id, filter.name)
                          }
                          style={
                            selectedFilter === filter.id
                              ? {
                                  backgroundColor: "#ddeeff",
                                  border: "2px solid #168bf2",
                                }
                              : {
                                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                                  border: "none",
                                }
                          }
                          id="btn_select_filter_name_filter_search"
                        >
                          <div className="btn_select_filter_name_filter_search">
                            {filter.name}
                          </div>
                        </button>
                      ))
                    ) : (
                      <div className="not_selected_btn_request_massage">
                        <span>You haven't created any filters yet</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="advanced_search_body_ar">
          <div className="items_simple_search_ar">
            <label>
              جميع هذه الكلمات
              <img src={pen_icon} alt="" />
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              placeholder="نتائج البحث تتضمن جميع هذه الكلمات معا = استخدم علامة – بين الكلمات"
              onChange={handleSearchWordChange}
            />
          </div>
          <div className="items_simple_search_ar">
            <label >
              أي كلمة من هذه الكلمات
              <img src={pen_icon} alt="" />
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              placeholder="يجب أن تتضمن نتائج البحث على الأقل إحدى هذه الكلمات = استخدم علامة – بين الكلمات"
              type="text"
              onChange={handleSearchOrWordChange}
            />
          </div>
          <div className="items_simple_search_ar">
            <label>
              لا شيء من هذه الكلمات
              <img src={pen_icon} alt="" />
            </label>
            <input
              className={`items_simple_search_input_advance ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              type="text"
              placeholder="نتائج البحث لا تتضمن أي من هذه الكلمات = استخدم علامة – بين الكلمات"
              onChange={handleSearchNotWordChange}
            />
          </div>
          <div className="items_simple_search_ar">
            <label>
              المنصة
              <img src={insta_icon} alt="" />
            </label>
            <div
              className={`inputs_items_simple_search_ar ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              {buttonLists[selectedMenuItem].map((button, index) => (
                <button
                  className="btns_bastar"
                  key={index}
                  style={{
                    backgroundColor: button.background,
                    color: button.textColor,
                    border:
                      button.background !== "rgba(0, 0, 0, 0.08)"
                        ? "2px solid rgb(22, 139, 242)"
                        : "none",
                  }}
                  onClick={() => handleButtonClick(selectedMenuItem, index)}
                >
                  {button.text}
                </button>
              ))}
            </div>
          </div>
          <div className="items_simple_search_ar">
            <label>
              الفترة الزمنية
              <img src={cal_icon} alt="" />
            </label>
            <div
              className={`inputs_items_simple_search_ar ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <button
                className="btn_show_date_sim_search"
                onClick={handleOpenPopupDate}
              >
                {isButtonClicked
                  ? `${currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} حتی   ${selectedDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}`
                  : currentDate.toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </button>

              <Dialog
                className="popup_mui_search"
                open={showPopupDate}
                onClose={() => handleClosePopupDate(false)}
              >
                <DialogTitle className="header_popup_detail_display_search">
                  <label>اختيار فترة زمنية</label>
                  <button
                    onClick={() => handleClosePopupDate(false)}
                    className="btn_close_sim_detail_search"
                  >
                    <CloseIcon />
                  </button>
                </DialogTitle>
                <DialogContent className="body_popup_simple_search">
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search_ar">
                      <input
                        type="radio"
                        value="quick"
                        checked={selectedRadio === "quick"}
                        onChange={handleRadioChange}
                      />
                      <label>اختيار سريع</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={clearDateInputs}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>

                    <div className="col_rowone_dateinput_simple_search_ar">
                      <div className="col_rowone_dateinput_simple_search_ar">
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === 0 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(0)}
                        >
                          اليوم
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -1 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-1)}
                        >
                          منذ الأمس
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -2 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-2)}
                        >
                          الأیام الثلاثة الماضیة
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -3 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-3)}
                        >
                          الأیام الأربعة الماضیة
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -7 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-7)}
                        >
                          الأسبوع الماضي
                        </button>
                        <button
                          disabled={selectedRadio === "custom"}
                          className={`btn_btn_select_date_sim_search_ar ${
                            selectedButton === -30 ? "selected" : ""
                          }`}
                          onClick={() => handleButtonClickDateTime(-30)}
                        >
                          الشهر الماضي
                        </button>
                      </div>
                    </div>
                    <div className="show_date_time_from_until_search_simple">
                      {" "}
                      {isButtonClicked
                        ? `${currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })} حتی   ${selectedDate.toLocaleDateString(
                            undefined,
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}`
                        : currentDate.toLocaleDateString(undefined, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                    </div>
                  </div>
                  <div className="rowone_dateinput_simple_search">
                    <div className="col_rowone_dateinput_simple_search_ar">
                      <input
                        type="radio"
                        value="custom"
                        checked={selectedRadio === "custom"}
                        onChange={handleRadioChange}
                      />
                      <label>فترة محددة</label>
                      <img
                        src={clear_icon}
                        className="icon_clear_filter_date_home_page"
                        onClick={clearDateInputs}
                        alt="Clear Date Filter"
                        title="Clear Date Filter"
                      />
                    </div>
                    <div className="col_rowtwo_dateinput_simple_search_ar">
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <label>من</label>
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedFromDateSearch}
                            onChange={handleFromDateSearch}
                            placeholderText="تاريخ بداية البحث"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                      </div>
                      <div className="rows_one_col_rowtwo_dateinput_simple_search">
                        <label>إلی</label>
                        <div className="items_rowtwo_col_rowone_dateinput_simple_search">
                          <DatePicker
                            selected={selectedToDateSearch}
                            onChange={handleToDateSearch}
                            placeholderText="تاريخ نهاية البحث"
                            className="datetime_searchall"
                            disabled={selectedRadio === "quick"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions className="btns_bottom_search_simple">
                  <button
                    className="okay_btn_search_simple"
                    onClick={handleConfirm}
                  >
                    موافق
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div className="items_simple_search_ar">
            <label>
              اللغة
              <img src={language_icon} alt="" />
            </label>
            <div
              className={`inputs_items_simple_search_ar ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <label id="btn_radio_box_search_top">
                العربية
                <input
                  type="radio"
                  value="arabic"
                  name="language"
                  checked={languageBtn === "arabic"}
                  onChange={handleLanguageChange}
                />
              </label>
              <label id="btn_radio_box_search_top">
                English
                <input
                  type="radio"
                  value="english"
                  name="language"
                  checked={languageBtn === "english"}
                  onChange={handleLanguageChange}
                />
              </label>
            </div>
          </div>

          <div className="items_simple_search_ar">
            <label>
              البحث في
              <img src={search_icon} alt="" />
            </label>
            <div
              className={`inputs_items_simple_search_ar_searchin ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <div className="rows_advanced_search_filter_top">
                <label id="btn_radio_box_search_top">
                  الکل
                  <input
                    type="radio"
                    value="all"
                    name="searchin"
                    defaultChecked
                  />
                </label>
                <label id="btn_radio_box_search_top">
                  منشورات
                  <input type="radio" value="posts" name="searchin" />
                </label>
                <label id="btn_radio_box_search_top">
                  تعليقات
                  <input type="radio" value="comments" name="searchin" />
                </label>
              </div>
              <div className="rows_advanced_search_filter_bottom">
                {isLoading ? (
                  <div className="spinner_postbox_admin_page">
                    <div>
                      <BarLoader color="#458BDF" size={10} />
                    </div>
                  </div>
                ) : (
                  <>
                    {apiData.length > 0 ? (
                      apiData.map((filter) => (
                        <button
                          key={filter.id}
                          onClick={() =>
                            handleFilterClick(filter.id, filter.name)
                          }
                          style={
                            selectedFilter === filter.id
                              ? {
                                  backgroundColor: "#ddeeff",
                                  border: "2px solid #168bf2",
                                }
                              : {
                                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                                  border: "none",
                                }
                          }
                          id="btn_select_filter_name_filter_search"
                        >
                          <div className="btn_select_filter_name_filter_search">
                            {filter.name}
                          </div>
                        </button>
                      ))
                    ) : (
                      <div className="not_selected_btn_request_massage">
                        <span>لم تقم بإنشاء أي فلاتر بعد</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvancedSearch;
