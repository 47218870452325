import React, { useState, useEffect, useContext } from "react";
import twitter_icon from "../../../images/twitter_x_35.png";
import instagram_icon from "../../../images/instagram_35.png";
import facebook_icon from "../../../images/facebook.png";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudPlatformsExistChart from "./CloudPlatformsExistChart";
import CloudPlatformsExistWordCloud from "./CloudPlatformsExistWordCloud";
import { HashLoader } from "react-spinners";
import { ThemeContext, LanguageContext } from "../../../App";
import CloudPlatformsExistWordCloudIN from "./CloudPlatformsExistWordCloudIN";
import CloudPlatformsExistWordCloudTW from "./CloudPlatformsExistWordCloudTW";
import CloudPlatformsExistChartTW from "./CloudPlatformsExistChartTW";
import CloudPlatformsExistChartIN from "./CloudPlatformsExistChartIN";
import CloudPlatformsExistChartAll from "./CloudPlatformsExistChartAll";
import CloudPlatformsExistChartAllTW from "./CloudPlatformsExistChartAllTW";
import CloudPlatformsExistChartAllIN from "./CloudPlatformsExistChartAllIN";

function CloudPlatformsExist({
  selectedItem,
  isFacebookActive,
  isTwitterActive,
  facebookPosts,
  twitterPosts,
  loadingStateFB,
  loadingStateTW,
  visibleTWHashtagExist,
  visibleFBExistPlatforms,
  showAllBtnCloudExist,
  isInstagramActive,
  instagramPosts,
  visibleINHashtagPlatformsEntities,
  loadingStateIN,
  onWordClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { theme } = useContext(ThemeContext);

  const { language } = useContext(LanguageContext);

  // popup download
  const [hashtagPlatformsdl, setHashtagPlatformsDl] = React.useState(null);

  const handleClickHashtagPlatformsdl = (event) => {
    setHashtagPlatformsDl(event.currentTarget);
  };

  const handleCloseHashtagPlatformsdl = () => {
    setHashtagPlatformsDl(null);
  };

  const open = Boolean(hashtagPlatformsdl);
  const id = open ? "simple-popover" : undefined;

  // تعیین وضعیت اولیه لود دو کامپوننت

  const [currentSelectedItem, setCurrentSelectedItem] = useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedItem(selectedItem);
  }, [selectedItem]);

  return (
    <div
      className={`cloud_hashtag_platforms ${
        theme === "dark" ? "dark_mode" : ""
      }`}
    >
      <div className="cloud_hashtag_platforms_page">
        {showAllBtnCloudExist && (
          <div className="body_cloud_hashtag_platforms_page">
            {currentSelectedItem === "wordcloudplatform" && (
              <div className="body_cloud_hashtag_cloud_words">
                {isTwitterActive && (
                  <>
                    {loadingStateTW ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleTWHashtagExist && showTwitterSection && (
                          <>
                            {language === "ar" ? (
                              <div className="header_release_process_body">
                                <div className="items_header_release_process_body">
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    إكس
                                  </span>
                                  <img src={twitter_icon} alt="" />
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {twitterPosts && twitterPosts.word && (
                                      <span>{twitterPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        إضافة إلى النشرة
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            ) : (
                              <div className="header_release_process_body_en">
                                <div className="items_header_release_process_body">
                                  <img src={twitter_icon} alt="" />
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    X
                                  </span>
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {twitterPosts && twitterPosts.word && (
                                      <span>{twitterPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        Adding to the bulletin
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            )}
                            <div className="body_cloud_hashtag_platforms_page_Approach_tw">
                              <CloudPlatformsExistWordCloudTW
                                onWordClick={onWordClick}
                                twitterPosts={twitterPosts}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {isFacebookActive && (
                  <>
                    {loadingStateFB ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleFBExistPlatforms && showFacebookSection && (
                          <>
                            {language === "ar" ? (
                              <div className="header_release_process_body">
                                <div className="items_header_release_process_body">
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    فيسبوك
                                  </span>
                                  <img src={facebook_icon} alt="" />
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {facebookPosts && facebookPosts.word && (
                                      <span>{facebookPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        إضافة إلى النشرة
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            ) : (
                              <div className="header_release_process_body_en">
                                <div className="items_header_release_process_body">
                                  <img src={facebook_icon} alt="" />
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    Facebook
                                  </span>
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {facebookPosts && facebookPosts.word && (
                                      <span>{facebookPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        Adding to the bulletin
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            )}

                            <div className="body_cloud_hashtag_platforms_page_Approach_tw">
                              <CloudPlatformsExistWordCloud
                                facebookPosts={facebookPosts}
                                onWordClick={onWordClick}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {isInstagramActive && (
                  <>
                    {loadingStateIN ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleINHashtagPlatformsEntities &&
                          showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={instagram_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={instagram_icon} alt="" />
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              )}

                              <CloudPlatformsExistWordCloudIN
                                instagramPosts={instagramPosts}
                                onWordClick={onWordClick}
                              />
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {currentSelectedItem === "chartwordplatform" && (
              <div className="body_cloud_hashtag_cloud_words">
                {isTwitterActive && (
                  <>
                    {loadingStateTW ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleTWHashtagExist && showTwitterSection && (
                          <>
                            {language === "ar" ? (
                              <div className="header_release_process_body">
                                <div className="items_header_release_process_body">
                                  <span>إكس</span>
                                  <img src={twitter_icon} alt="" />
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {twitterPosts && twitterPosts.word && (
                                      <span>{twitterPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        تحميل
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            ) : (
                              <div className="header_release_process_body_en">
                                <div className="items_header_release_process_body">
                                  <img src={twitter_icon} alt="" />
                                  <span>X</span>
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {twitterPosts && twitterPosts.word && (
                                      <span>{twitterPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        Adding to the bulletin
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            )}

                            <div className="body_cloud_hashtag_platforms_page_Approach_tw">
                              <CloudPlatformsExistChartAllTW
                                twitterPosts={twitterPosts}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {isFacebookActive && (
                  <>
                    {loadingStateFB ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleFBExistPlatforms && showFacebookSection && (
                          <>
                            {language === "ar" ? (
                              <div className="header_release_process_body">
                                <div className="items_header_release_process_body">
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    فيسبوك
                                  </span>
                                  <img src={facebook_icon} alt="" />
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {facebookPosts && facebookPosts.word && (
                                      <span>{facebookPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        إضافة إلى النشرة
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            ) : (
                              <div className="header_release_process_body_en">
                                <div className="items_header_release_process_body">
                                  <img src={facebook_icon} alt="" />
                                  <span
                                    className={`items_header_release_process_body_span ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    Facebook
                                  </span>
                                </div>
                                <div className="items_header_release_process_body">
                                  <span id="search_word_items_header_release_process_body">
                                    {facebookPosts && facebookPosts.word && (
                                      <span>{facebookPosts.word}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="items_header_release_process_body_dlbtn">
                                  <Button
                                    className="btn_dl_items_header_release_process_body"
                                    onClick={handleClickHashtagPlatformsdl}
                                    startIcon={<MoreVertIcon />}
                                  />

                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={hashtagPlatformsdl}
                                    onClose={handleCloseHashtagPlatformsdl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuList>
                                      <MenuItem
                                        onClick={handleCloseHashtagPlatformsdl}
                                      >
                                        Adding to the bulletin
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              </div>
                            )}

                            <div className="body_cloud_hashtag_platforms_page_Approach_tw">
                              <CloudPlatformsExistChartAll
                                facebookPosts={facebookPosts}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {isInstagramActive && (
                  <>
                    {loadingStateIN ? (
                      <div className="spiner_postbox">
                        <HashLoader color="#458BDF" size={50} />
                      </div>
                    ) : (
                      <>
                        {visibleINHashtagPlatformsEntities &&
                          showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={instagram_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={instagram_icon} alt="" />
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              )}

                              <CloudPlatformsExistChartAllIN
                                instagramPosts={instagramPosts}
                              />
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CloudPlatformsExist;
