import React, { useEffect, useRef, useContext } from "react";
import * as echarts from "echarts";
import { LanguageContext } from "../../../App";

const AccentPieChartIN = ({ instagramPosts, onAxisClick }) => {
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (instagramPosts && instagramPosts.accent_chart) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = Object.keys(instagramPosts.accent_chart).map(
        (key) => ({
          name: key,
          value: instagramPosts.accent_chart[key],
        })
      );

      if (wordCloudData.length === 0) {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text:
                        language === "ar"
                          ? "ليس هنالك معلومات"
                          : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      } else {
        const options = {
          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: 14,
              fontFamily: "Segoe UI",
            },
            formatter: function (params) {
              const { name, percent } = params;
              return `${name} : (%${percent})`;
            },
          },
          legend: {
            orient: "horizontal",
            bottom: 0,
            right: 0,
            align: "right",
            padding:[30, 0 ,10 ,0],
            itemWidth: 30,
            itemHeight: 30,
            textStyle: {
              fontSize: 14,
              fontFamily: "Segoe UI",
            },
          },
          series: [
            {
              name: "Word Cloud",
              type: "pie",
              radius: ["20%", "80%"],
              avoidLabelOverlap: false,
              label: {
                show: true,
                position: "outside",
                formatter: "{b}: %{d}",
                fontFamily: "Segoe UI",
                fontSize: 14,
              },
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 1,
              },
              labelLine: {
                show: true,
              },
              data: wordCloudData,
              center: ["40%", "50%"],

            },
          ],
          animation: false,
        };
        chart.setOption(options);
        chart.on("click", (params) => {
          if (params.componentType === "series") {
            const selectedOption = params.name;
            onAxisClick(selectedOption);
          }
        });
        chart.resize();
      }

      return () => {
        chart.dispose();
      };
    }
  }, [instagramPosts, language, onAxisClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "60%",
        height: "550px",
        borderBottom: "2px solid #ccc",
        display: "flex",
        justifyContent: "center",
        // paddingRight: "10%",
      }}
    />
  );
};

export default AccentPieChartIN;