import { HashLoader } from "react-spinners";
import { ThemeContext, LanguageContext } from "../../../App";
import { useContext } from "react";
import facebook_img from "../../../images/facabook_color_figma.png";
import x_img from "../../../images/x_color_figma.png";
import insta_img from "../../../images/instagram_color_figma.png";
import like_img from "../../../images/like_info_figma.png";
import users_img from "../../../images/user_info_figma.png";
import comment_img from "../../../images/coment_info_figma.png";
import repost_img from "../../../images/repost_info_figma.png";

function InformationSearch(props) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  const {
    facebookPosts,
    twitterPosts,
    isFacebookActive,
    isTwitterActive,
    showTwitterSection,
    visibleTWInfo,
    visibleFBInfo,
    showAllBtnInfo,
    instagramPosts,
    isInstagramActive,
    visibleINInfo,
    loadingStateIN,
    loadingStateFB,
    loadingStateTW,
    showFacebookSection,
    showInstagramSection,
  } = props;

  // if (!props.visible) {
  //   return null;
  // }

  const facebookPostCount = facebookPosts ? facebookPosts.allCount : null;
  const facebookAllComment = facebookPosts ? facebookPosts.all_comments : null;
  const facebookAllLike = facebookPosts ? facebookPosts.all_likes : null;
  const facebookAllShare = facebookPosts ? facebookPosts.all_shares : null;
  const facebookAllUsers = facebookPosts ? facebookPosts.all_users_count : null;

  const twitterPostCount = twitterPosts ? twitterPosts.allCount : null;
  const twitterAllComment = twitterPosts ? twitterPosts.all_comments : null;
  const twitterAllLike = twitterPosts ? twitterPosts.all_likes : null;
  const twitterAllShare = twitterPosts ? twitterPosts.all_shares : null;
  const twitterAllUsers = twitterPosts ? twitterPosts.all_users_count : null;

  const instagramPostCount = instagramPosts ? instagramPosts.allCount : null;
  const instagramAllComment = instagramPosts
    ? instagramPosts.all_comments
    : null;
  const instagramAllLike = instagramPosts ? instagramPosts.all_likes : null;
  const instagramAllUsers = instagramPosts
    ? instagramPosts.all_users_count
    : null;

  function formatNumber(num) {
    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return (num / 1000000).toFixed(1) + "m";
    }
  }

  return (
    <div
      className={`information_search ${theme === "dark" ? "dark_mode" : ""}`}
    >
      {showAllBtnInfo && (
        <div
          className={`information_search_body ${
            language === "ar" ? "information_search_body_ar" : ""
          } ${theme === "dark" ? "dark_mode" : ""}`}
        >
          {isTwitterActive && (
            <>
              {loadingStateTW ? (
                <div className="spiner_info_search">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleTWInfo && showTwitterSection && (
                    <div
                      className={`twitter_information_search_body ${
                        language === "ar"
                          ? "twitter_information_search_body_ar"
                          : ""
                      }`}
                    >
                      {language === "ar" ? (
                        <div className="information_search_body_left">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_box twiiter">
                              <strong>
                                {formatNumber(
                                  twitterPostCount
                                ).toLocaleString()}
                              </strong>
                              <span>عدد المنشورات</span>
                            </div>
                            <div className="facebook_information_tab_box_header_img twiiter">
                              <img src={x_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items right">
                              <strong>
                                {formatNumber(twitterAllLike).toLocaleString()}
                              </strong>
                              <span>إعجاب</span>
                              <img src={like_img} alt="" />
                            </div>
                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(twitterAllShare).toLocaleString()}
                              </strong>
                              <span>إعادة نشر</span>
                              <img src={repost_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items right">
                              <strong>
                                {formatNumber(
                                  twitterAllComment
                                ).toLocaleString()}
                              </strong>

                              <span>رد</span>
                              <img src={comment_img} alt="" />
                            </div>
                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(twitterAllUsers).toLocaleString()}
                              </strong>
                              <span>عدد المستخدمين</span>
                              <img src={users_img} alt="" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="information_search_body_left_en">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_img en twiiter">
                              <img src={x_img} alt="" />
                            </div>
                            <div className="facebook_information_tab_box_header_box twiiter">
                              <span>All Posts</span>
                              <strong>
                                {formatNumber(
                                  twitterPostCount
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={like_img} alt="" />
                              <span>Likes</span>
                              <strong>
                                {formatNumber(twitterAllLike).toLocaleString()}
                              </strong>
                            </div>
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={comment_img} alt="" />
                              <span>Replies</span>
                              <strong>
                                {formatNumber(
                                  twitterAllComment
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={users_img} alt="" />
                              <span>Users</span>
                              <strong>
                                {formatNumber(twitterAllUsers).toLocaleString()}
                              </strong>
                            </div>
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={repost_img} alt="" />
                              <span>Shares</span>
                              <strong>
                                {formatNumber(twitterAllShare).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {isFacebookActive && (
            <>
              {loadingStateFB ? (
                <div className="spiner_info_search">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleFBInfo && showFacebookSection && (
                    <div
                      className={`facebook_information_search_body ${
                        language === "ar"
                          ? "facebook_information_search_body_ar"
                          : ""
                      }`}
                    >
                      {language === "ar" ? (
                        <div className="information_search_body_left">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_box">
                              <strong>
                                {formatNumber(
                                  facebookPostCount
                                ).toLocaleString()}
                              </strong>
                              <span>عدد المنشورات</span>
                            </div>
                            <div className="facebook_information_tab_box_header_img">
                              <img src={facebook_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items right">
                              <strong>
                                {formatNumber(facebookAllLike).toLocaleString()}
                              </strong>
                              <span>إعجاب</span>
                              <img src={like_img} alt="" />
                            </div>

                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(
                                  facebookAllShare
                                ).toLocaleString()}
                              </strong>
                              <span>إعادة نشر</span>
                              <img src={repost_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items right">
                              <strong>
                                {formatNumber(
                                  facebookAllComment
                                ).toLocaleString()}
                              </strong>
                              <span>رد</span>
                              <img src={comment_img} alt="" />
                            </div>
                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(
                                  facebookAllUsers
                                ).toLocaleString()}
                              </strong>
                              <span>عدد المستخدمين</span>
                              <img src={users_img} alt="" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="information_search_body_left_en">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_img en">
                              <img src={facebook_img} alt="" />
                            </div>
                            <div className="facebook_information_tab_box_header_box">
                              <span>All Posts</span>

                              <strong>
                                {formatNumber(facebookAllLike).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={like_img} alt="" />
                              <span>Likes</span>
                              <strong>
                                {formatNumber(facebookAllLike).toLocaleString()}
                              </strong>
                            </div>
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={comment_img} alt="" />
                              <span>Replies</span>
                              <strong>
                                {formatNumber(
                                  facebookAllComment
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={users_img} alt="" />
                              <span>Users</span>
                              <strong>
                                {formatNumber(
                                  facebookAllUsers
                                ).toLocaleString()}
                              </strong>
                            </div>
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={repost_img} alt="" />
                              <span>Shares</span>

                              <strong>
                                {formatNumber(
                                  facebookAllShare
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {isInstagramActive && (
            <>
              {loadingStateIN ? (
                <div className="spiner_info_search">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleINInfo && showInstagramSection && (
                    <div
                      className={`insta_information_search_body ${
                        language === "ar"
                          ? "insta_information_search_body_ar"
                          : ""
                      }`}
                    >
                      {language === "ar" ? (
                        <div className="information_search_body_left">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_box instagram">
                              <strong>
                                {formatNumber(
                                  instagramPostCount
                                ).toLocaleString()}
                              </strong>
                              <span>عدد المنشورات</span>
                            </div>
                            <div className="facebook_information_tab_box_header_img instagram">
                              <img src={insta_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(
                                  instagramAllLike
                                ).toLocaleString()}
                              </strong>
                              <span>إعجاب</span>
                              <img src={like_img} alt="" />
                            </div>

                            <div className="information_search_body_left_rowone_items right">
                              <strong>
                                {formatNumber(
                                  instagramAllComment
                                ).toLocaleString()}
                              </strong>
                              <span>رد</span>
                              <img src={comment_img} alt="" />
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items left">
                              <strong>
                                {formatNumber(
                                  instagramAllUsers
                                ).toLocaleString()}
                              </strong>
                              <span>عدد المستخدمين</span>
                              <img src={users_img} alt="" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="information_search_body_left_en">
                          <div className="facebook_information_tab_box_header">
                            <div className="facebook_information_tab_box_header_img en instagram">
                              <img src={insta_img} alt="" />
                            </div>
                            <div className="facebook_information_tab_box_header_box instagram">
                              <span>All Posts</span>
                              <strong>
                                {formatNumber(
                                  instagramPostCount
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={like_img} alt="" />
                              <span>Likes</span>
                              <strong>
                                {formatNumber(
                                  instagramAllLike
                                ).toLocaleString()}
                              </strong>
                            </div>
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={comment_img} alt="" />
                              <span>Replies</span>
                              <strong>
                                {formatNumber(
                                  instagramAllComment
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                          <div className="information_search_body_left_rowone insta en">
                            <div className="information_search_body_left_rowone_items_en">
                              <img src={users_img} alt="" />
                              <span>Users</span>
                              <strong>
                                {formatNumber(
                                  instagramAllUsers
                                ).toLocaleString()}
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default InformationSearch;
