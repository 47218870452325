import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 2) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible &&
    ReactDOM.createPortal(
      <button className="scroll_to_top_button" onClick={scrollToTop}>
        <ArrowUpwardIcon />
      </button>,
      document.body
    )
  );
}

export default ScrollToTopButton;
