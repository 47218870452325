import React, { useContext, useEffect, useState } from "react";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import facebook_icon from "../../../images/facebook.png";
import { HashLoader } from "react-spinners";
import { ThemeContext, LanguageContext } from "../../../App";
import twitter_icon from "../../../images/twitter_x_35.png";
import insta_icon from "../../../images/instagram_35.png";
import AxializationChartFB from "./AxializationChartFB";
import AxializationChartTW from "./AxializationChartTW";
import AxializationChartIN from "./AxializationChartIN";
import AxializationChartRadarFB from "./AxializationChartRadarFB";
import AxializationChartRadarTW from "./AxializationChartRadarTW";
import AxializationChartRadarIN from "./AxializationChartRadarIN";
// import DateTime from "react-datetime";
// import filter_icon from "../../../images/filter_icon.png";

function Axialization({
  showAllBtnAxialization,
  isFacebookActive,
  facebookPosts,
  visibleFBAxialization,
  loadingStateFB,
  visibleTWAxialization,
  loadingStateTW,
  isTwitterActive,
  twitterPosts,
  loadingStateIN,
  visibleINAxialization,
  isInstagramActive,
  instagramPosts,
  isDefultDB,
  selectedItem,
  onAxisClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // popup Adding to the bulletin
  const [hashtagPlatformsdl, setHashtagPlatformsDl] = React.useState(null);

  const handleClickHashtagPlatformsdl = (event) => {
    setHashtagPlatformsDl(event.currentTarget);
  };

  const handleCloseHashtagPlatformsdl = () => {
    setHashtagPlatformsDl(null);
  };

  const open = Boolean(hashtagPlatformsdl);
  const id = open ? "dropdl_approach" : undefined;

  const [currentSelectedAxis, setCurrentSelectedAxis] = useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedAxis(selectedItem);
  }, [selectedItem]);

  // filter date Facebook

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  // };

  // filter date Twitter

  // const [startDateTW, setStartDateTW] = useState(null);
  // const [endDateTW, setEndDateTW] = useState(null);

  // const handleStartDateChangeTW = (date) => {
  //   setStartDateTW(date);
  // };

  // const handleEndDateChangeTW = (date) => {
  //   setEndDateTW(date);
  // };

  // filter date Instagram

  // const [startDateIN, setStartDateIN] = useState(null);
  // const [endDateIN, setEndDateIN] = useState(null);

  // const handleStartDateChangeIN = (date) => {
  //   setStartDateIN(date);
  // };

  // const handleEndDateChangeIN = (date) => {
  //   setEndDateIN(date);
  // };

  // const [isFilterOpenFB, setIsFilterOpenFB] = useState(false);
  // const [isFilterOpenTW, setIsFilterOpenTW] = useState(false);
  // const [isFilterOpenIN, setIsFilterOpenIN] = useState(false);

  return (
    <div className={`axialization ${theme === "dark" ? "dark_mode" : ""}`}>
      {/* {isDefultDB === "default" || isDefultDB === null || isDefultDB === "demo" ? (
        <>
          {language === "ar" ? (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                يمنحك الاشتراك المميز الحصول على هذه الخدمة
              </p>
            </div>
          ) : (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                To View This Section, You Need to Purchase a Premium Account.
              </p>
            </div>
          )}
        </>
      ) : (
        <> */}
          {showAllBtnAxialization && (
            <div className="axialization_page">
              {currentSelectedAxis === "axis_radar" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <div className="facebook_approach_search">
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWAxialization && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إكس
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      X
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartRadarTW
                                  twitterPosts={twitterPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isFacebookActive && (
                    <div className="facebook_approach_search">
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBAxialization && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      فيسبوك
                                    </span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Facebook
                                    </span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartRadarFB
                                  facebookPosts={facebookPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isInstagramActive && (
                    <div className="facebook_approach_search">
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINAxialization && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartRadarIN
                                  instagramPosts={instagramPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {currentSelectedAxis === "axis_linear" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <div className="facebook_approach_search">
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWAxialization && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إكس
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      X
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartTW
                                  twitterPosts={twitterPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isFacebookActive && (
                    <div className="facebook_approach_search">
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBAxialization && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      فيسبوك
                                    </span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Facebook
                                    </span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartFB
                                  facebookPosts={facebookPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isInstagramActive && (
                    <div className="facebook_approach_search">
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINAxialization && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  {/* <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div> */}
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClickHashtagPlatformsdl}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={hashtagPlatformsdl}
                                      onClose={handleCloseHashtagPlatformsdl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={
                                            handleCloseHashtagPlatformsdl
                                          }
                                        >
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <AxializationChartIN
                                  instagramPosts={instagramPosts}
                                  onAxisClick={onAxisClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        {/* </>
      )} */}
    </div>
  );
}

export default Axialization;
