import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { ThemeContext, LanguageContext } from "../../App";
import "../../css/Facebook/CompetitorAnalysisFB.css";
import arrowpic from "../../images/arrow_down_figma.png";

function AnalysisMenuAxis({ data, setSelected, setPosts, index, post }) {
  // arabic
  if (typeof data !== "undefined") {
    data = data.results;
    data = data.map((x) => {
      return { Key: x.id, Value: x.name };
    });
  } else {
    data = [];
  }

  const { language } = useContext(LanguageContext);

  // dark mode
  const { theme } = useContext(ThemeContext);

  const DropMenuProjectSubject = {
    submenu: data,
  };

  const DropMenuProjectSubjectAr = {
    submenu: data,
  };

  //open onclick menu filter Choose Topic
  const [isOpenProjectSubject, setIsOpenProjectSubject] = useState(false);

  const handleToggleDropdownProjectSubject = () => {
    setIsOpenProjectSubject(!isOpenProjectSubject);
  };

  // dropdown filter Choose Topic
  const [selectedProjectSubject, setSelectedProjectSubject] = useState(
    typeof post.action.axis === "undefined" ? "Main Point" : post.action.axis
  );
  const [selectedProjectSubjectAr, setSelectedProjectSubjectAr] = useState(
    typeof post.action.axis === "undefined" ? "الانطباع" : post.action.axis
  );

  const handleSelectProjectSubjec = (value) => {
    const selectedProjectSubject = DropMenuProjectSubject.submenu.find(
      (option) => option.Key === value
    );
    if (selectedProjectSubject) {
      setSelectedProjectSubject(selectedProjectSubject.Value);
      setSelected(selectedProjectSubject.Value);
      setIsOpenProjectSubject(false);
      setPosts((x) => {
        let temp = x;
        temp.posts[index]["axis"] = selectedProjectSubject.Value;
        return temp;
      });
    } else {
      setSelectedProjectSubject("");
    }
  };

  const [isOpenProjectSubjectAr, setIsOpenProjectSubjectAr] = useState(false);

  const handleToggleDropdownProjectSubjectAr = () => {
    setIsOpenProjectSubjectAr(!isOpenProjectSubjectAr);
  };

  const handleSelectProjectSubjectAR = (value) => {
    const selectedProjectSubjectAr = DropMenuProjectSubjectAr.submenu.find(
      (option) => option.Key === value
    );
    if (selectedProjectSubjectAr) {
      setSelectedProjectSubjectAr(selectedProjectSubjectAr.Value);
      setSelected(selectedProjectSubjectAr.Value);
      setPosts((x) => {
        let temp = x;
        console.log(
          "🚀 ~ file: 77 ~ handleSelectProjectSubjectAR ~ x:",
          x.posts[index]
        );
        temp.posts[index]["axis"] = selectedProjectSubjectAr.Value;
        return temp;
      });
      setIsOpenProjectSubjectAr(false);
    } else {
      setSelectedProjectSubjectAr("");
    }
  };

  const parentRef = useRef(null);

  const handleDocumentClick = (e) => {
    if (parentRef.current && !parentRef.current.contains(e.target)) {
      setIsOpenProjectSubject(false);
      setIsOpenProjectSubjectAr(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="menu_btn_header_main_topic">
      {language === "en" ? (
        <div ref={parentRef} id="dropdown_menu_header_chart">
          <div className="choose_drop_header_chart">
            <div
              className={`dropdown_label_header_main_topic ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              onClick={handleToggleDropdownProjectSubject}
            >
              {selectedProjectSubject}
              <span
                className={`arrow_filter_header_main_topic ${
                  isOpenProjectSubject ? "up" : "down"
                }`}
              >
                &#11163;
              </span>
            </div>
            {isOpenProjectSubject && (
              <ul
                className={`dropdown_menu_header_main_topic ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                {DropMenuProjectSubject.submenu &&
                  DropMenuProjectSubject.submenu.map((e, key) => (
                    <li
                      className={`dropdown_menu_header_main_topic_li ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                      key={key}
                      onClick={() => handleSelectProjectSubjec(e.Key)}
                    >
                      {e.Value}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      ) : (
        <div ref={parentRef} id="dropdown_menu_header_chart">
          <div className="choose_drop_header_chart">
            <div
              className={`dropdown_label_header_main_topic_ar ${
                theme === "dark" ? "dark_mode" : ""
              }`}
              onClick={handleToggleDropdownProjectSubjectAr}
            >
              {selectedProjectSubjectAr}
              <span
                className={`arrow_filter_header_main_topic_ar ${
                  isOpenProjectSubjectAr ? "up" : "down"
                }`}
              >
                &#9660;
              </span>
            </div>
            {isOpenProjectSubjectAr && (
              <ul
                className={`dropdown_menu_header_main_topic_ar ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                {DropMenuProjectSubjectAr.submenu &&
                  DropMenuProjectSubjectAr.submenu.map((e, key) => (
                    <li
                      className={`dropdown_menu_header_main_topic_li_ar ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                      key={key}
                      onClick={() => handleSelectProjectSubjectAR(e.Key)}
                    >
                      {e.Value}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalysisMenuAxis;
