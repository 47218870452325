export const words = [
    "فى", "و", "التي", "في", "عن", "لم", "لن", "له", "من", "هو", "هي", "قوة", "كما", "لها", "منذ", "وقد", "ولا", "نفسه", "لقاء", "مقابل", "هناك",
    "وقال", "وكان", "نهاية", "وقالت", "وكانت", "للامم", "فيه", "كلم", "لكن", "وفي", "وقف", "ولم", "ومن", "وهو", "وهي", "يوم", "فيها", "منها", "مليار",
    "لوكالة", "يكون", "يمكن", "مليون", "حيث", "اكد", "الا", "اما", "امس", "السابق", "التى", "التي", "اكثر", "ايار", "ايضا", "ثلاثة", "الذاتي", "الاخيرة",
    "الثاني", "الثانية", "الذى", "الذي", "الان", "امام", "ايام", "خلال", "حوالى", "الذين", "الاول", "الاولى", "بين", "ذلك", "دون", "حول", "حين", "الف",
    "الى", "انه", "اول", "ضمن", "انها", "جميع", "الماضي", "الوقت", "المقبل", "اليوم", "ـ", "ف", "و", "و6", "قد", "لا", "ما", "مع", "مساء", "هذا", "واحد",
    "واضاف", "واضافت", "فان", "قبل", "قال", "كان", "لدى", "نحو", "هذه", "وان", "واكد", "كانت", "واوضح", "مايو", "ب", "ا", "أ", "،", "عشر", "عدد", "عدة",
    "عشرة", "عدم", "عام", "عاما", "عن", "عند", "عندما", "على", "عليه", "عليها", "زيارة", "سنة", "سنوات", "تم", "ضد", "بعد", "بعض", "اعادة", "اعلنت",
    "بسبب", "حتى", "اذا", "احد", "اثر", "برس", "باسم", "غدا", "شخصا", "ل", "ه", "ب", "صباح", "اطار", "اربعة", "اخرى", "بان", "اجل", "غير", "بشكل", "حاليا", "بن", "به",
    "ثم", "اف", "ان", "او", "اي", "بها", "صفر","الله",
    "في", "و", "من", "أن", "إلى", "عن", "مع", "لو", "هذا", "هذه", "هذان", "هؤلاء", "ذلك", "تلك", "ذلكم", "أولئك",
    "هناك", "هنالك", "كل", "كلما", "لكل", "كلا", "كلاهما", "الذي", "التي", "الذين", "هو", "هي", "هم", "هما",
    "هن", "هنا", "هؤلاء", "هاتان", "هاته", "هاتي", "هاتين", "هاتيه", "أنت", "أنتم", "أنتما", "أنتن", "أنا",
    "نحن", "إن", "أن", "كأن", "ليت", "لعل", "كي", "أي", "كل", "كما", "لما", "إذ", "0", "إذما", "إذن",
    "لذلك", "لهذا", "لم", "لن", "لو", "لولا", "لوما", "ما", "ماذا", "متى", "من", "منذ", "مهما", "هكذا",
    "وهو", "وهي", "وهم", "وكان", "وكانت", "ويكون", "وتكون", "أين", "أينما", "أيان", "حيث", "حيثما", "حين",
    "بعد", "قبل", "أمام", "خلف", "فوق", "تحت", "ضمن", "بين", "إلى", "من", "عن", "على", "في", "مع", "حتى",
    "ثم", "رغم", "على", "أن", "إلى", "خلا", "عدا", "حاشا", "سوى", "لولا", "لوما", "مع", "عن", "عند", "حول",
    "أثناء", "بسبب", "بدلا", "جراء", "حسب", "خلال", "طبقا", "قصد", "مذ", "مع", "مقابل", "منذ", "نظرا", "وفق",
    "قبل", "قد", "من", "يكون", "قال", "كان", "كانت", "كنت", "كنتم", "كنتما", "كنتن", "كانوا", "كنت", "كانتا", "كانتا", "كانت", "كانتا", "كانتم", "كانتما", "كانتن", "يكون",
    "تكون", "تكونين", "تكونان", "تكونون", "أكون", "نكون", "أكون", "تكون", "يكون", "يكونون", "يكونان", "يكونوا", "تكونوا", "تكونون", "تكونان", "تكونين", "تكون", "أكون",
    "نكون", "نكونون", "أكون", "أكونوا", "أكونون", "أكونان", "أكونين", "أكون", "علي", "العالم", "RCDFORUM2024",
    "in", "and", "that", "on", "about", "did", "will", "has", "from", "he", "she", "power", "as", "has", "since", "and", "nor", "himself", "meeting", "versus", "there",
    "said", "was", "end", "said", "were", "to", "there", "word", "but", "in", "stopped", "nor", "and", "and", "day", "in", "of", "billion",
    "for", "agency", "be", "can", "million", "where", "asserted", "not", "but", "yesterday", "previous", "which", "that", "more", "May", "also", "three", "itself", "last",
    "second", "second", "who", "that", "now", "in front of", "days", "during", "about", "those", "first", "first", "between", "that", "without", "around", "when", "the",
    "to", "it", "first", "within", "it", "all", "past", "time", "next", "today", "-", "to", "and", "and", "6", "may", "no", "what", "with", "evening", "this", "one",
    "added", "added", "then", "before", "said", "was", "had", "have", "towards", "this", "when", "confirmed", "that", "clarified", "May", "to", "a", "a", ",", "ten", "number", "several",
    "ten", "none", "year", "year", "about", "a", "on", "at", "visit", "year", "years", "was", "against", "after", "some", "return", "announced",
    "because", "until", "if", "someone", "because", "under the name of", "tomorrow", "person","to", "his", "by", "morning", "framework", "four", "other", "that", "later", "instead", "currently", "with", "been", "with",
    "then", "either", "that", "or", "any", "therein", "zero",
    "in", "and", "from", "that", "to", "about", "with", "if", "this", "these", "these", "those", "those", "that", "that", "those", "those",
    "there", "there", "every", "whenever", "each", "all", "whenever", "every", "all", "who", "which", "who", "he", "she", "they", "they",
    "they", "there", "here", "these", "these", "these", "these", "you", "you", "you", "you", "I",
    "we", "if", "that", "to be like", "would that", "would that", "how", "which", "every", "as", "whatever", "when", "0", "whenever", "so",
    "thus", "for this", "for this", "not", "not", "not", "unless", "unless", "with", "about", "about", "about", "during", "due to", "because of", "instead of", "due to", "because of", "due to", "during",
    "according to", "due to", "before", "may", "from", "be", "said", "was", "was", "was", "were", "were", "were", "was", "was", "were", "were", "was", "was", "were", "be",
    "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be", "be",
    "to", "the", "world","Click","expand",
];