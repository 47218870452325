import { ThemeContext, LanguageContext } from "../../App";
import React, { useState, useContext, useEffect } from "react";
import HomeMenu from "./HomeMenu";
// import MenuTop from "../../components/SearchAll/Public/MenuTop";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { getNewsPaper } from "../../backend/NewPaperRequest";
import { toast } from "react-toastify";
import { HashLoader } from "react-spinners";
import MenuTop from "../../components/SearchAll/Public/MenuTop";

function NewsPaper({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  setSelectedMenuItem,
  idUserName,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  //   show big size img
  const [isModalOpen, setIsModalOpen] = useState([]);

  // Function to handle opening a modal
  const openModal = (index) => {
    const newModalOpenStates = [...isModalOpen];
    newModalOpenStates[index] = true;
    setIsModalOpen(newModalOpenStates);
  };

  // Function to handle closing a modal
  const closeModal = () => {
    setIsModalOpen(new Array(apiData?.data?.newletters.length).fill(false));
  };

  // request news paper
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const handleDateChange = (amount) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + amount);

    if (newDate > new Date()) {
      if (language === "ar") {
        toast.warning("لم تتم اضافة صحف اليوم");
      } else {
        toast.warning("Tomorrow has not arrived yet");
      }
      return;
    }

    setCurrentDate(newDate);
    fetchNewsData(newDate);
  };

  const fetchNewsData = async (newDate) => {
    try {
      setIsLoading(true);
      const formattedDate = newDate.toISOString().split("T")[0];

      const result = await getNewsPaper(formattedDate);
      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        const data = result.data;
        console.log("🚀 newsPaper Result:", data);
        setApiData(data);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsData(currentDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <div className="search_all">
      <div
        className={`search_all_page ${
          language === "ar" ? "search_all_page_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <HomeMenu
          saveLoginStatus={saveLoginStatus}
          setIsSubmitted={setIsSubmitted}
          isSuperUser={isSuperUser}
          isDefultDB={isSuperUser}
        />
        <div
          className={`body_search_all ${theme === "dark" ? "dark_mode" : ""}`}
        >
          <div className="header_search_all">
            <MenuTop
              onSelectMenuItem={setSelectedMenuItem}
              username={idUserName}
              saveLoginStatus={saveLoginStatus}
              setIsSubmitted={setIsSubmitted}
            />
          </div>
          <div className="newspaper_page_body_new">
            <div className="header_news_paper_page">
              <div className="header_news_paper_page_body">
                <div className="show_date_news_paper_page_borders">
                  <button onClick={() => handleDateChange(-1)}>
                    <ArrowBackIosNewIcon id="arrow_btn_header_news_paper" />
                  </button>
                </div>
                <div className="show_date_news_paper_page_borders">
                  <div className="show_date_news_paper_page">
                    {currentDate.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </div>
                </div>
                <div className="show_date_news_paper_page_borders">
                  <button onClick={() => handleDateChange(1)}>
                    <ArrowForwardIosIcon id="arrow_btn_header_news_paper" />
                  </button>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="spiner_info_search">
                <HashLoader color="#458BDF" size={50} />
              </div>
            ) : (
              <div className="body_new_paper_page">
                {apiData &&
                apiData.data &&
                apiData.data.newletters &&
                apiData.data.newletters.length > 0 ? (
                  apiData.data.newletters.map((newspaper, index) => (
                    <div className="newspaper_show_new_paper_page" key={index}>
                      <div className="items_body_new_paper_page">
                        <img
                          src={newspaper.image_url}
                          alt=""
                          className="normal_size_image"
                          onClick={() => openModal(index)}
                        />
                        <div className="foter_items_body_new_paper_page">
                          <a href={newspaper.pdf_url}>
                            <DownloadingIcon id="foter_items_body_new_paper_page_btns" />
                          </a>
                          <ZoomOutMapIcon
                            onClick={() => openModal(index)}
                            id="foter_items_body_new_paper_page_btns"
                          />
                        </div>
                      </div>
                      <Modal
                        isOpen={isModalOpen[index]}
                        onRequestClose={closeModal}
                        contentLabel="Big Size Image Modal"
                        className="modal"
                        overlayClassName="overlay"
                      >
                        <HighlightOffIcon
                          onClick={closeModal}
                          id="foter_items_body_new_paper_page_btns"
                        />
                        <img
                          src={newspaper.image_url}
                          alt=""
                          className="big_size_image"
                        />
                      </Modal>
                    </div>
                  ))
                ) : (
                  <>
                    {language === "ar" ? (
                      <div className="massage_disable_tabs_searchall">
                        <p>لم تتم اضافة صحف اليوم</p>
                      </div>
                    ) : (
                      <div className="massage_disable_tabs_searchall">
                        <p>

                        There is no newspaper for today
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsPaper;
