import MostEffectiveUsersTableLikeFB from "./MostEffectiveUsersTableLikeFB";
import MostEffectiveUsersTableCommentFB from "./MostEffectiveUsersTableCommentFB";
import MostEffectiveUsersTableShareFB from "./MostEffectiveUsersTableShareFB";
import FollowerstoEngagementRatioonContentFB from "./FollowerstoEngagementRatioonContentFB";
import EngagementtoContentRatioFB from "./EngagementtoContentRatioFB";

function MostEffectiveUsersTable({
  selectedOption,
  loadingStateFB,
  facebookPosts,
  onUsernameClick,
}) {
  let displayedComponent;

  if (selectedOption === "مجموع الردود" || selectedOption === "Total Replies") {
    displayedComponent = (
      <MostEffectiveUsersTableCommentFB
        facebookPosts={facebookPosts}
        loadingStateFB={loadingStateFB}
      />
    );
  } else if (
    selectedOption === "مجموع مشاركة" ||
    selectedOption === "Total Share"
  ) {
    displayedComponent = (
      <MostEffectiveUsersTableShareFB
        facebookPosts={facebookPosts}
        loadingStateFB={loadingStateFB}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة المتابعين إلى التفاعلات على المحتوى" ||
    selectedOption === "Followers to Engagement Ratio on Content"
  ) {
    displayedComponent = (
      <FollowerstoEngagementRatioonContentFB
        facebookPosts={facebookPosts}
        loadingStateFB={loadingStateFB}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة التفاعلات إلى المحتوى" ||
    selectedOption === "Engagement to Content Ratio"
  ) {
    displayedComponent = (
      <EngagementtoContentRatioFB
        facebookPosts={facebookPosts}
        loadingStateFB={loadingStateFB}
        onUsernameClick={onUsernameClick}
      />
    );
  } else {
    displayedComponent = (
      <MostEffectiveUsersTableLikeFB
        facebookPosts={facebookPosts}
        loadingStateFB={loadingStateFB}
        onUsernameClick={onUsernameClick}
      />
    );
  }

  return (
    <div className="top_users_table">
      <div className="top_users_table_page">{displayedComponent}</div>
    </div>
  );
}

export default MostEffectiveUsersTable;
