import axios from "axios";
import {
  base_url,
  edit_user_url,
  add_user_to_project_url,
  tokenName,
} from "../../config_backend";
import { getToken } from "../../Login_backend";

export const addUserAnalyzer = async ({ projectName, users_ids }) => {
  console.log(
    "🚀 ~ file: Analyzer.js:11 ~ addUserAnalyzer ~ NumberProject:",
    projectName,
    users_ids
  );
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("projectName", projectName);
  // users_ids.map((user_id) => {
  //   formData.append("users", user_id);
  // });

  if (users_ids && Array.isArray(users_ids)) {
    users_ids.forEach((user_id) => {
      formData.append("analyzer", user_id);
    });
  }

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + edit_user_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addUsersToProjects = async ({ projectName, users_ids }) => {
  console.log(
    "🚀 ~ file: Users.js:11 ~ addUsersToProjects ~ NumberProject:",
    projectName,
    users_ids
  );
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("projectName", projectName);
  // users_ids.map((user_id) => {
  //   formData.append("users", user_id);
  // });

  if (users_ids && Array.isArray(users_ids)) {
    users_ids.forEach((user_id) => {
      formData.append("users", user_id);
    });
  }

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_user_to_project_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
