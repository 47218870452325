import React, { useContext } from "react";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import facebook_icon from "../../../images/facebook.png";
import { HashLoader } from "react-spinners";
import { ThemeContext, LanguageContext } from "../../../App";
import twitter_icon from "../../../images/twitter_x_35.png";
import insta_icon from "../../../images/instagram_35.png";
import AccentPieChartFB from "./AccentPieChartFB";
import AccentPieChartTW from "./AccentPieChartTW";
import AccentPieChartIN from "./AccentPieChartIN";
import AccentChartRadarFB from "./AccentChartRadarFB";
import AccentChartRadarIN from "./AccentChartRadarIN";
import AccentChartRadarTW from "./AccentChartRadarTW";

function Accent({
  showAllBtnAccent,
  isFacebookActive,
  facebookPosts,
  visibleFBAccent,
  loadingStateFB,
  visibleTWAccent,
  loadingStateTW,
  isTwitterActive,
  twitterPosts,
  loadingStateIN,
  visibleINAccent,
  isInstagramActive,
  instagramPosts,
  isDefultDB,
  selectedItem,
  onAxisClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // popup Adding to the bulletin
  const [hashtagPlatformsdl, setHashtagPlatformsDl] = React.useState(null);

  const handleClickHashtagPlatformsdl = (event) => {
    setHashtagPlatformsDl(event.currentTarget);
  };

  const handleCloseHashtagPlatformsdl = () => {
    setHashtagPlatformsDl(null);
  };

  const open = Boolean(hashtagPlatformsdl);
  const id = open ? "dropdl_approach" : undefined;

  return (
    <div className={`axialization ${theme === "dark" ? "dark_mode" : ""}`}>
      {showAllBtnAccent && (
        <div className="axialization_page">
          <div className="body_cloud_hashtag_cloud_words">
            {isTwitterActive && (
              <div className="facebook_approach_search">
                {loadingStateTW ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={50} />
                  </div>
                ) : (
                  <>
                    {visibleTWAccent && showTwitterSection && (
                      <>
                        {language === "ar" ? (
                          <div className="header_release_process_body">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                إكس
                              </span>
                              <img src={twitter_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {twitterPosts && twitterPosts.word && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    إضافة إلى النشرة
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        ) : (
                          <div className="header_release_process_body_en">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                X
                              </span>
                              <img src={twitter_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {twitterPosts && twitterPosts.word && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    Adding to the bulletin
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        )}

                        <div className="body_cloud_hashtag_platforms_page">
                          <AccentPieChartTW
                            twitterPosts={twitterPosts}
                          />
                          <AccentChartRadarTW
                            twitterPosts={twitterPosts}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {isFacebookActive && (
              <div className="facebook_approach_search">
                {loadingStateFB ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={50} />
                  </div>
                ) : (
                  <>
                    {visibleFBAccent && showFacebookSection && (
                      <>
                        {language === "ar" ? (
                          <div className="header_release_process_body">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                فيسبوك
                              </span>
                              <img src={facebook_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {facebookPosts && facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    إضافة إلى النشرة
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        ) : (
                          <div className="header_release_process_body_en">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                Facebook
                              </span>
                              <img src={facebook_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {facebookPosts && facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    Adding to the bulletin
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        )}

                        <div className="body_cloud_hashtag_platforms_page">
                          <AccentPieChartFB
                            facebookPosts={facebookPosts}
                          />
                          <AccentChartRadarFB
                            facebookPosts={facebookPosts}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {isInstagramActive && (
              <div className="facebook_approach_search">
                {loadingStateIN ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={50} />
                  </div>
                ) : (
                  <>
                    {visibleINAccent && showInstagramSection && (
                      <>
                        {language === "ar" ? (
                          <div className="header_release_process_body">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                إنستغرام
                              </span>
                              <img src={insta_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {instagramPosts && instagramPosts.word && (
                                  <span>{instagramPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    إضافة إلى النشرة
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        ) : (
                          <div className="header_release_process_body_en">
                            <div className="items_header_release_process_body">
                              <span
                                className={`items_header_release_process_body_span ${
                                  theme === "dark" ? "dark_mode" : ""
                                }`}
                              >
                                Instagram
                              </span>
                              <img src={insta_icon} alt="" />
                            </div>
                            <div className="items_header_release_process_body">
                              <span id="search_word_items_header_release_process_body">
                                {instagramPosts && instagramPosts.word && (
                                  <span>{instagramPosts.word}</span>
                                )}
                              </span>
                            </div>
                            <div className="items_header_release_process_body_dlbtn">
                              <Button
                                className="btn_dl_items_header_release_process_body"
                                onClick={handleClickHashtagPlatformsdl}
                                startIcon={<MoreVertIcon />}
                              />

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={hashtagPlatformsdl}
                                onClose={handleCloseHashtagPlatformsdl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuList>
                                  <MenuItem
                                    onClick={handleCloseHashtagPlatformsdl}
                                  >
                                    Adding to the bulletin
                                  </MenuItem>
                                </MenuList>
                              </Popover>
                            </div>
                          </div>
                        )}

                        <div className="body_cloud_hashtag_platforms_page">
                          <AccentPieChartIN
                            instagramPosts={instagramPosts}
                          />
                          <AccentChartRadarIN
                            instagramPosts={instagramPosts}
                          />
                          
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Accent;
