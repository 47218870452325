import React, { useState, useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import user_avatar from "../../../svg/user_avatar.svg";
import retweet_icon from "../../../svg/retweet_icon.svg";
import view_icon from "../../../svg/view_icon.svg";
import like_icon from "../../../svg/like_icon.svg";
import comment_icon from "../../../svg/comment_icon.svg";
import cal_icon from "../../../svg/cal_icon.svg";
import twitter_icon from "../../../images/twitter_x_35.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ShareIcon from "@mui/icons-material/Share";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  twitter_profile_url,
  facebook_profile_url,
  instagram_profile_url,
} from "../../../backend/config_backend";
import FacebookIcon from "@mui/icons-material/Facebook";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { HashLoader } from "react-spinners";
import InstagramIcon from "@mui/icons-material/Instagram";
import instagram_logo from "../../../images/instagram_35.png";
import facebook_icon from "../../../images/fb_search_btn.png";
import posts_insta_image from "../../../images/Image_not_available.png";
import ExcelJS from "exceljs";
import Excel_green from "../../../images/excel_green.png";
import { ThemeContext, LanguageContext } from "../../../App";
import { getPostsByWord as getPostsByWordFb } from "../../../backend/Post_Search_backend";
import { getPostsByWord as getPostsByWordTW } from "../../../backend/twitter/Twitter_Post_Search_backend";
import { getPostsByWord as getPostsByWordIN } from "../../../backend/instagram/Instagram_Post_Search_backend";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import share_icon from "../../../images/share_20.png";
import { getCommentsList } from "../../../backend/Post_Action_backend";
import { getCommentsListTwitter } from "../../../backend/twitter/Twitter_Post_Action_backend";
import { getCommentsListInstagram } from "../../../backend/instagram/Instagram_Post_Action_backend";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RepeatIcon from "@mui/icons-material/Repeat";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

function ListResultsPostBox({
  twitterPosts,
  setTwitterPosts,
  facebookPosts,
  setFacebookPosts,
  isTwitterActive,
  isFacebookActive,
  loadingStateFB,
  loadingStateTW,
  visibleTWResult,
  visibleFBResult,
  instagramPosts,
  setInstagramPosts,
  isInstagramActive,
  loadingStateIN,
  visibleINResult,
  setIsCheckedFB,
  setIsCheckedTW,
  setIsCheckedIN,
  showTwitterSection,
  onUsernameClick,
  onDateClick,
  showFacebookSection,
  showInstagramSection,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  // const [needLogin, setneedLogin] = useState(false);

  const { language } = useContext(LanguageContext);

  // deatail posts

  const [showPopupDetail, setShowPopupDetail] = useState({});
  const [showPopupDetailTW, setShowPopupDetailTW] = useState({});
  const [showPopupDetailIN, setShowPopupDetailIN] = useState({});

  const [loadMoreStateFb, setLoadMoreStateFb] = useState(false);
  const [loadMoreStateTW, setLoadMoreStateTW] = useState(false);
  const [loadMoreStateIN, setLoadMoreStateIN] = useState(false);
  // const [loadMoreStateGO, setLoadMoreStateGO] = useState(false);

  const [apiDataComment, setApiDataComment] = useState(false);
  const [apiDataCommentTW, setApiDataCommentTW] = useState(false);
  const [apiDataCommentIN, setApiDataCommentIN] = useState(false);

  const handleOpenPopupDetail = async (postId) => {
    setShowPopupDetail({ ...showPopupDetail, [postId]: true });

    try {
      const response = await getCommentsList(postId);
      const data = response.data;
      setApiDataComment(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopupDate = (postId) => {
    setShowPopupDetail({ ...showPopupDetail, [postId]: false });
  };

  // get comments twitter

  const handleOpenPopupDetailTW = async (postId) => {
    setShowPopupDetailTW({ ...showPopupDetailTW, [postId]: true });

    try {
      const response = await getCommentsListTwitter(postId);
      const data = response.data;
      setApiDataCommentTW(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopupDateTW = (postId) => {
    setShowPopupDetailTW({ ...showPopupDetailTW, [postId]: false });
  };

  // get comments instagram

  const handleOpenPopupDetailIN = async (postId) => {
    setShowPopupDetailIN({ ...showPopupDetailIN, [postId]: true });

    try {
      const response = await getCommentsListInstagram(postId);
      const data = response.data;
      setApiDataCommentIN(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopupDateIN = (postId) => {
    setShowPopupDetailIN({ ...showPopupDetailIN, [postId]: false });
  };

  // پاپ آپ نمایش متن کامل پست توییتر

  const [openAllTextTW, setOpenAllTextTW] = useState(false);
  const [selectedPostTW, setSelectedPostTW] = useState(null);

  const handleOpenAllTextTW = (post) => {
    setSelectedPostTW(post);
    setOpenAllTextTW(true);
  };

  const handleCloseAllTextTW = () => {
    setSelectedPostTW(null);
    setOpenAllTextTW(false);
  };

  // پاپ آپ نمایش متن کامل پست فیسبوک

  const [openAllTextFB, setOpenAllTextFB] = useState(false);
  const [selectedPostFB, setSelectedPostFB] = useState(null);

  const handleOpenAllTextFB = (post) => {
    setSelectedPostFB(post);
    setOpenAllTextFB(true);
  };

  const handleCloseAllTextFB = () => {
    setSelectedPostFB(null);
    setOpenAllTextFB(false);
  };

  // پاپ آپ نمایش متن کامل پست اینستاگرام

  const [openAllTextIN, setOpenAllTextIN] = useState(false);
  const [selectedPostIN, setSelectedPostIN] = useState(null);

  const handleOpenAllTextIN = (post) => {
    setSelectedPostIN(post);
    setOpenAllTextIN(true);
  };

  const handleCloseAllTextIN = () => {
    setSelectedPostIN(null);
    setOpenAllTextIN(false);
  };

  // پاپ آپ نمایش متن کامل پست گوگل

  // const [openAllTextGO, setOpenAllTextGO] = useState(false);
  // const [selectedPostGO, setSelectedPostGO] = useState(null);

  // const handleOpenAllTextGO = (post) => {
  //   setSelectedPostGO(post);
  //   setOpenAllTextGO(true);
  // };

  // const handleCloseAllTextGO = () => {
  //   setSelectedPostGO(null);
  //   setOpenAllTextGO(false);
  // };

  // pagination - twitter

  const postsPerPageTwitter = 6;
  const [currentPageTwitter, setCurrentPageTwitter] = useState(1);

  const indexOfLastPostTwitter = currentPageTwitter * postsPerPageTwitter;
  const indexOfFirstPostTwitter = indexOfLastPostTwitter - postsPerPageTwitter;

  const currentTwitterPosts = twitterPosts
    ? twitterPosts.posts.slice(indexOfFirstPostTwitter, indexOfLastPostTwitter)
    : [];

  const totalPagesTwitter = twitterPosts
    ? Math.ceil(twitterPosts.posts.length / postsPerPageTwitter)
    : 0;

  const handleLoadMoreClickedTW = async () => {
    console.log("Handle Load more clicked next link x", twitterPosts.next_link);
    if (loadMoreStateTW === true || loadMoreStateTW === "") {
      return;
    }
    if (twitterPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateTW(true);
    try {
      var postsByWord = await getPostsByWordTW(twitterPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...twitterPosts.posts, ...data.posts];
        setTwitterPosts(data);
        setLoadMoreStateTW(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageTwitter = async () => {
    if (currentPageTwitter < totalPagesTwitter) {
      setCurrentPageTwitter(currentPageTwitter + 1);
    }
    if (currentPageTwitter === totalPagesTwitter - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedTW();
    }
  };

  const prevPageTwitter = () => {
    if (currentPageTwitter > 1) {
      setCurrentPageTwitter(currentPageTwitter - 1);
    }
  };

  // Pagination - Facebook
  const postsPerPageFacebook = 6;
  const [currentPageFacebook, setCurrentPageFacebook] = useState(1);

  const indexOfLastPostFacebook = currentPageFacebook * postsPerPageFacebook;
  const indexOfFirstPostFacebook =
    indexOfLastPostFacebook - postsPerPageFacebook;

  const currentFacebookPosts = facebookPosts
    ? facebookPosts.posts.slice(
        indexOfFirstPostFacebook,
        indexOfLastPostFacebook
      )
    : [];

  const totalPagesFacebook = facebookPosts
    ? Math.ceil(facebookPosts.posts.length / postsPerPageFacebook)
    : 0;

  const handleLoadMoreClickedFb = async () => {
    console.log(
      "Handle Load more clicked next link facebook",
      facebookPosts.next_link
    );
    if (loadMoreStateFb === true || loadMoreStateFb === "") {
      return;
    }
    if (facebookPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateFb(true);
    try {
      var postsByWord = await getPostsByWordFb(facebookPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...facebookPosts.posts, ...data.posts];
        setFacebookPosts(data);
        setLoadMoreStateFb(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageFacebook = async () => {
    if (currentPageFacebook < totalPagesFacebook) {
      setCurrentPageFacebook(currentPageFacebook + 1);
    }
    if (currentPageFacebook === totalPagesFacebook - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedFb();
    }
  };

  const prevPageFacebook = () => {
    if (currentPageFacebook > 1) {
      setCurrentPageFacebook(currentPageFacebook - 1);
    }
  };

  // Pagination - Instagram
  const postsPerPageInstagram = 6;
  const [currentPageInstagram, setCurrentPageInstagram] = useState(1);

  const indexOfLastPostInstagram = currentPageInstagram * postsPerPageInstagram;
  const indexOfFirstPostInstagram =
    indexOfLastPostInstagram - postsPerPageInstagram;

  const currentInstagramPosts = instagramPosts
    ? instagramPosts.posts.slice(
        indexOfFirstPostInstagram,
        indexOfLastPostInstagram
      )
    : [];

  const totalPagesInstagram = instagramPosts
    ? Math.ceil(instagramPosts.posts.length / postsPerPageInstagram)
    : 0;

  const handleLoadMoreClickedIN = async () => {
    console.log(
      "Handle Load more clicked next link instagram",
      instagramPosts.next_link
    );
    if (loadMoreStateIN === true || loadMoreStateIN === "") {
      return;
    }
    if (instagramPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateIN(true);
    try {
      var postsByWord = await getPostsByWordIN(instagramPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...instagramPosts.posts, ...data.posts];
        setInstagramPosts(data);
        setLoadMoreStateIN(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageInstagram = async () => {
    if (currentPageInstagram < totalPagesInstagram) {
      setCurrentPageInstagram(currentPageInstagram + 1);
    }
    if (currentPageInstagram === totalPagesInstagram - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedIN();
    }
  };

  const prevPageInstagram = () => {
    if (currentPageInstagram > 1) {
      setCurrentPageInstagram(currentPageInstagram - 1);
    }
  };

  // sort notif massage

  const showMessage = (type, language, isActive) => {
    let alertMessage = "";
    if (isActive) {
      alertMessage =
        language === "ar"
          ? type === "asc"
            ? "ترتیب صعودی اول ۱۰۰ منشورات"
            : "ترتیب نزولی اول ۱۰۰ منشورات"
          : type === "asc"
          ? "The first 100 chapters in descending order"
          : "The first 100 chapters in ascending order";
    } else {
      alertMessage =
        language === "ar" ? "تعطيل الترتيب" : "Sorting has been deactivated";
    }
    toast.warning(alertMessage);
  };

  // sort retweet
  const [sortByRetweet, setSortByRetweet] = useState(null);
  const [sortByLikeTW, setSortByLikeTW] = useState(null);
  const [sortByViewTW, setSortByViewTW] = useState(null);
  const [sortByDateTW, setSortByDateTW] = useState(null);
  const [originalPosts, setOriginalPosts] = useState(null);

  const sortByRetweetsTW = (type) => {
    let isActive = true;
    if (sortByRetweet === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByRetweet(null);
      isActive = false;
    } else {
      setSortByRetweet(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.retweet_count - b.retweet_count;
        } else {
          return b.retweet_count - a.retweet_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort Like tw

  const sortByLikesTW = (type) => {
    let isActive = true;
    if (sortByLikeTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByLikeTW(null);
      isActive = false;
    } else {
      setSortByLikeTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.like_count - b.like_count;
        } else {
          return b.like_count - a.like_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views tw

  const sortByViewsTW = (type) => {
    let isActive = true;
    if (sortByViewTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByViewTW(null);
      isActive = false;
    } else {
      setSortByViewTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.view_count - b.view_count;
        } else {
          return b.view_count - a.view_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date tw

  const sortByCreateTW = (type) => {
    let isActive = true;
    if (sortByDateTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByDateTW(null);
      isActive = false;
    } else {
      setSortByDateTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.date_tweet.split("at")[0]);
        const dateB = new Date(b.date_tweet.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sorts facebook
  // sort Like fb
  const [sortByLikeFB, setSortByLikeFB] = useState(null);
  const [originalFacebookPosts, setOriginalFacebookPosts] = useState(null);

  const sortByLikesFB = (type) => {
    let isActive = true;
    if (sortByLikeFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByLikeFB(null);
      isActive = false;
    } else {
      setSortByLikeFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.likes - b.likes;
        } else {
          return b.likes - a.likes;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views fb
  const [sortByReplyFB, setSortByReplyFB] = useState(null);

  const sortReplyFB = (type) => {
    let isActive = true;
    if (sortByReplyFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByReplyFB(null);
      isActive = false;
    } else {
      setSortByReplyFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.comments - b.comments;
        } else {
          return b.comments - a.comments;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date fb
  const [sortByDateFB, setSortByDateFB] = useState(null);

  const sortByCreateFB = (type) => {
    let isActive = true;
    if (sortByDateFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByDateFB(null);
      isActive = false;
    } else {
      setSortByDateFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.creates.split("at")[0]);
        const dateB = new Date(b.creates.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sorts Instagram
  // sort Like IN
  const [sortByLikeIN, setSortByLikeIN] = useState(null);
  const [originalInstagramPosts, setOriginalInstagramPosts] = useState(null);

  const sortLikesIN = (type) => {
    let isActive = true;
    if (sortByLikeIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByLikeIN(null);
      isActive = false;
    } else {
      setSortByLikeIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.like_count - b.like_count;
        } else {
          return b.like_count - a.like_count;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views IN
  const [sortByReplyIN, setSortByReplyIN] = useState(null);

  const sortReplyIN = (type) => {
    let isActive = true;
    if (sortByReplyIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByReplyIN(null);
      isActive = false;
    } else {
      setSortByReplyIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.comment_count - b.comment_count;
        } else {
          return b.comment_count - a.comment_count;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date IN
  const [sortByDateIN, setSortByDateIN] = useState(null);

  const sortByCreateIN = (type) => {
    let isActive = true;
    if (sortByDateIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByDateIN(null);
      isActive = false;
    } else {
      setSortByDateIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.create_date.split("at")[0]);
        const dateB = new Date(b.create_date.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  function handleCheckboxChangeTW(event) {
    setIsCheckedTW(event.target.checked);
  }

  function handleCheckboxChangeFB(event) {
    setIsCheckedFB(event.target.checked);
  }

  function handleCheckboxChangeIN(event) {
    setIsCheckedIN(event.target.checked);
  }

  // Excel btn Select front twitter

  function handleExportToExcelTwitter() {
    const checkedCheckboxes = document.querySelectorAll(
      "#cyberpunk_checkbox_front:checked"
    );

    const checkedPosts = [];

    checkedCheckboxes.forEach((checkbox) => {
      const postElement = checkbox.closest(".posts_body_result_list");
      const name = postElement.querySelector(
        "#name_span_results_posts"
      ).textContent;
      const username = postElement.querySelector(
        "#username_span_results_posts"
      ).textContent;

      const postText = postElement.querySelector(
        ".text_posts_body_result_list_twitter"
      ).textContent;
      const postDate = postElement.querySelector(
        ".date_results_posts_exel"
      ).textContent;
      const postLikes = postElement.querySelector(
        "#like_count_results_posts"
      ).textContent;
      const postComments = postElement.querySelector(
        "#comments_count_results_posts"
      ).textContent;
      const link_tweet = postElement
        .querySelector(".btn_link_bottom_body_popup_detail_display_post")
        .getAttribute("href");

      const post = {
        name: name,
        username: username,
        text: postText,
        date: postDate,
        likes: postLikes,
        comments: postComments,
        link: link_tweet,
      };

      checkedPosts.push(post);
    });

    // Export to Excel

    if (checkedPosts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Post X");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 60 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
        { header: "Link", key: "link", width: 100 },
      ];

      checkedPosts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "post x.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const exelIcon = document.querySelector("#exel_icon");
        if (checkedPosts.length > 0) {
          exelIcon.src = Excel_green;
        } else {
          exelIcon.src = null;
        }
      });
    } else {
      if (language === "en") {
        Swal.fire({
          icon: "warning",
          title: "Oops...!",
          text: "You have not selected a post yet",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "أوه",
          text: "لم تقم بعد باختيار أي منشور",
        });
      }
    }
  }

  // Excel btn Select front facebook

  function handleExportToExcelFacebook() {
    const checkedCheckboxes = document.querySelectorAll(
      "#cyberpunk_checkbox_front_facebook:checked"
    );

    const checkedPosts = [];

    checkedCheckboxes.forEach((checkbox) => {
      const postElement = checkbox.closest("#posts_body_result_list_facebook");
      const name = postElement.querySelector(
        "#excel_tag_home_facebook_name"
      ).textContent;
      const username = postElement.querySelector(
        "#excel_tag_home_facebook_username"
      ).textContent;

      const postText = postElement.querySelector(
        "#excel_tag_home_facebook_text"
      ).textContent;
      const postDate = postElement.querySelector(
        "#date_results_posts_exel_facebook"
      ).textContent;
      const postLikes = postElement.querySelector(
        "#like_count_results_posts_facebook"
      ).textContent;
      const postComments = postElement.querySelector(
        "#comments_count_results_posts_facebook"
      ).textContent;
      const postid = postElement
        .querySelector(".btn_link_bottom_body_popup_detail_display_post")
        .getAttribute("href");

      const post = {
        name: name,
        username: username,
        text: postText,
        date: postDate,
        likes: postLikes,
        comments: postComments,
        link: postid,
      };

      checkedPosts.push(post);
    });

    // Export to Excel

    if (checkedPosts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Post Facebook");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 60 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
        { header: "Link", key: "link", width: 100 },
      ];

      checkedPosts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Post Facebook.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const exelIcon = document.querySelector("#exel_icon_facebook");
        if (checkedPosts.length > 0) {
          exelIcon.src = Excel_green;
        } else {
          exelIcon.src = null;
        }
      });
    } else {
      if (language === "en") {
        Swal.fire({
          icon: "warning",
          title: "Oops...!",
          text: "You have not selected a post yet",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "أوه",
          text: "لم تقم بعد باختيار أي منشور",
        });
      }
    }
  }

  // Excel btn Select front instagram

  function handleExportToExcelInstagram() {
    const checkedCheckboxes = document.querySelectorAll(
      "#cyberpunk_checkbox_front_instagram:checked"
    );

    const checkedPosts = [];

    checkedCheckboxes.forEach((checkbox) => {
      const postElement = checkbox.closest("#posts_body_result_list_instagram");

      const username = postElement.querySelector(
        "#excel_tag_home_instagram_username"
      ).textContent;

      const postText = postElement.querySelector(
        ".text_posts_body_result_list_instagram"
      ).textContent;
      const postDate = postElement.querySelector(
        "#date_results_posts_exel_instagram"
      ).textContent;
      const postLikes = postElement.querySelector(
        "#like_count_results_posts_instagram"
      ).textContent;
      const postComments = postElement.querySelector(
        "#comments_count_results_posts_instagram"
      ).textContent;
      const post_url = postElement
        .querySelector(".btn_link_bottom_body_popup_detail_display_post")
        .getAttribute("href");

      const post = {
        username: username,
        text: postText,
        date: postDate,
        likes: postLikes,
        comments: postComments,
        link: post_url,
      };

      checkedPosts.push(post);
    });

    // Export to Excel

    if (checkedPosts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Post Instagram");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Text", key: "text", width: 60 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
        { header: "Link", key: "link", width: 100 },
      ];

      checkedPosts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Post Instagram.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const exelIcon = document.querySelector("#exel_icon_instagram");
        if (checkedPosts.length > 0) {
          exelIcon.src = Excel_green;
        } else {
          exelIcon.src = null;
        }
      });
    } else {
      if (language === "en") {
        Swal.fire({
          icon: "warning",
          title: "Oops...!",
          text: "You have not selected a post yet",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "أوه",
          text: "لم تقم بعد باختيار أي منشور",
        });
      }
    }
  }

  // change icon excel with checkbox twiiter

  function handleCheckboxChangeTwitter(event) {
    const checkedCount = document.querySelectorAll(
      "#cyberpunk_checkbox_front:checked"
    ).length;
    const exelIcon = document.querySelector("#exel_icon");
    // const checkIcons = document.querySelectorAll("#checkboc_icon");

    exelIcon.style.display = checkedCount > 0 ? "inline" : "none";

    // if (checkedCount > 0) {
    //   exelIcon.src = Excel_green;
    // checkIcons.forEach((checkIcon) => {
    //   checkIcon.src = checkbox_green;
    // });
    // } else {
    //   exelIcon.src = Excel;
    // checkIcons.forEach((checkIcon) => {
    //   checkIcon.src = checkbox_gray;
    // });
    // }
  }

  // change icon excel with checkbox facebook

  function handleCheckboxChangeFacebook(event) {
    const checkedCount = document.querySelectorAll(
      "#cyberpunk_checkbox_front_facebook:checked"
    ).length;
    const exelIcon = document.querySelector("#exel_icon_facebook");

    exelIcon.style.display = checkedCount > 0 ? "inline" : "none";
  }

  // change icon excel with checkbox instagram

  function handleCheckboxChangeInstagram(event) {
    const checkedCount = document.querySelectorAll(
      "#cyberpunk_checkbox_front_instagram:checked"
    ).length;
    const exelIcon = document.querySelector("#exel_icon_instagram");
    exelIcon.style.display = checkedCount > 0 ? "inline" : "none";
  }

  // formater emoji

  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(
        parseInt(unicode.replace("U+", ""), 16)
      );
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  const renderPostTextWithStickers = (post) => {
    const parts = post.text_twitt.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderCommentTextWithStickers = (item) => {
    const parts = item.text.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderPostTextWithStickersFB = (posts) => {
    const parts = posts.texts.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderPostTextWithStickersIN = (posts) => {
    const parts = posts.media_caption.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ")
      .trim();
    return textWithStickers;
  };

  // format date onclick filter

  function formatDate(dateString) {
    const date = new Date(dateString.split("at")[0]);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="result_list">
      <div className="result_list_body">
        {isTwitterActive && (
          <div className="twitter_result_list_body">
            {/* {!isTwitterActive ? (
              <>
                {language === "ar" ? (
                  <div className="not_selected_btn_request_massage">
                    <p>لم تقم بتحديد خيار العرض على إكس</p>
                  </div>
                ) : (
                  <div className="not_selected_btn_request_massage">
                    <p>You haven't selected the X display option.</p>
                  </div>
                )}
              </>
            ) : (
              <> */}
            {loadingStateTW ? (
              <div className="spiner_postbox">
                <HashLoader color="#458BDF" size={50} />
              </div>
            ) : (
              <>
                {showTwitterSection && visibleTWResult && (
                  <>
                    {language === "ar" ? (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">تاريخ النشر</span>
                            </span>

                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <RepeatIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">إعادة نشر</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByRetweet === "desc" ? "active" : ""
                                }
                                onClick={() => sortByRetweetsTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByRetweet === "asc" ? "active" : ""
                                }
                                onClick={() => sortByRetweetsTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">اعجاب</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByLikesTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByLikesTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <VisibilityIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">مشاهدة</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByViewTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByViewsTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByViewTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByViewsTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="right_header_result_list">
                          <img
                            src={Excel_green}
                            id="exel_icon"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelTwitter}
                          />
                          <div className="checkbox_header_result_border_excel">
                            <label className="cyberpunk_checkbox_label">
                              <span>إضافة النتائج إلي الإكسل</span>
                              <input
                                onChange={handleCheckboxChangeTW}
                                type="checkbox"
                                className="cyberpunk_checkbox"
                              />
                            </label>
                          </div>
                          <img src={twitter_icon} alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="right_header_result_list">
                          <img src={twitter_icon} alt="" />
                          <div className="checkbox_header_result_border_excel en">
                            <label className="cyberpunk_checkbox_label">
                              <input
                                type="checkbox"
                                className="cyberpunk_checkbox"
                                onChange={handleCheckboxChangeTW}
                              />
                            </label>
                            <span>Adding the results to Excel</span>
                          </div>
                          <img
                            src={Excel_green}
                            id="exel_icon"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelTwitter}
                          />
                        </div>
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">
                                Publication Date
                              </span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByRetweet === "desc" ? "active" : ""
                                }
                                onClick={() => sortByRetweetsTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByRetweet === "asc" ? "active" : ""
                                }
                                onClick={() => sortByRetweetsTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <RepeatIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Retweets</span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByLikesTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByLikesTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Likes</span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByViewTW === "desc" ? "active" : ""
                                }
                                onClick={() => sortByViewsTW("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByViewTW === "asc" ? "active" : ""
                                }
                                onClick={() => sortByViewsTW("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <VisibilityIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Views</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="body_result_list">
                      {currentTwitterPosts.map((post) => {
                        const shouldTruncate = post.text_twitt.length > 120;

                        return (
                          <div
                            className={`posts_body_result_list ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            key={post.id}
                          >
                            <div className="header_posts_body_result_list">
                              <div className="left_header_posts_body_result_list">
                                <label className="cyberpunk_checkbox_label">
                                  <input
                                    type="checkbox"
                                    className="cyberpunk_checkbox"
                                    id="cyberpunk_checkbox_front"
                                    onChange={handleCheckboxChangeTwitter}
                                  />
                                </label>
                              </div>
                              <div className="right_header_posts_body_result_list">
                                <div className="username_right_header_posts_body_result_list">
                                  <span id="name_span_results_posts">
                                    {post.name}
                                  </span>
                                  <span
                                    onClick={() =>
                                      onUsernameClick(post.username)
                                    }
                                    id="username_span_results_posts"
                                  >
                                    {post.username && `@${post.username}`}
                                  </span>
                                </div>
                                <img
                                  src={
                                    post.username
                                      ? twitter_profile_url +
                                        post.username +
                                        ".png"
                                      : user_avatar
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = user_avatar;
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                            {language === "ar" ? (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  {post.retweet_count}
                                  <span className="tooltip">
                                    <img src={retweet_icon} alt="" />
                                    <span className="tooltip_text">
                                      إعادة نشر
                                    </span>
                                  </span>
                                </label>
                                <label>
                                  {post.view_count}
                                  <span className="tooltip">
                                    <img src={view_icon} alt="" />
                                    <span className="tooltip_text">مشاهدة</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="like_count_results_posts">
                                    {post.like_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">اعجاب</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="comments_count_results_posts">
                                    {post.replies}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="الرد" />
                                    <span className="tooltip_text">الرد</span>
                                  </span>
                                </label>
                                <label>
                                  <div
                                    className="date_results_posts_exel"
                                    onClick={() =>
                                      onDateClick(formatDate(post.date_tweet))
                                    }
                                  >
                                    {new Date(
                                      post.date_tweet.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </div>
                                  <span className="tooltip">
                                    <img src={cal_icon} alt="تاریخ انتشار" />
                                    <span className="tooltip_text">
                                      تاريخ النشر
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  منشور
                                  <img src={twitter_icon} alt="" />
                                </label>
                              </div>
                            ) : (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  {post.retweet_count}
                                  <span className="tooltip">
                                    <img src={retweet_icon} alt="" />
                                    <span className="tooltip_text">
                                      Retweets
                                    </span>
                                  </span>
                                </label>
                                <label>
                                  {post.view_count}
                                  <span className="tooltip">
                                    <img src={view_icon} alt="" />
                                    <span className="tooltip_text">Views</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="like_count_results_posts">
                                    {post.like_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">Likes</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="comments_count_results_posts">
                                    {post.replies}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="Comment" />
                                    <span className="tooltip_text">
                                      Comments
                                    </span>
                                  </span>
                                </label>
                                <label>
                                  <div
                                    className="date_results_posts_exel"
                                    onClick={() =>
                                      onDateClick(formatDate(post.date_tweet))
                                    }
                                  >
                                    {new Date(
                                      post.date_tweet.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </div>
                                  <span className="tooltip">
                                    <img
                                      src={cal_icon}
                                      alt="Publication Date"
                                    />
                                    <span className="tooltip_text">
                                      Publication Date
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  Post
                                  <img src={twitter_icon} alt="" />
                                </label>
                              </div>
                            )}
                            <div className="text_posts_body_result_list_twitter">
                              {shouldTruncate ? (
                                <React.Fragment>
                                  {renderPostTextWithStickers(post).slice(
                                    0,
                                    120
                                  )}
                                  <span> ... </span>
                                  {language === "ar" ? (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextTW(post)}
                                    >
                                      عرض المزيد
                                    </button>
                                  ) : (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextTW(post)}
                                    >
                                      Show More
                                    </button>
                                  )}
                                </React.Fragment>
                              ) : (
                                <div>{renderPostTextWithStickers(post)}</div>
                              )}
                              <Dialog
                                open={openAllTextTW}
                                onClose={handleCloseAllTextTW}
                              >
                                <DialogTitle className="header_popup_text_posts_body_result_list">
                                  <Button onClick={handleCloseAllTextTW}>
                                    <CloseIcon />
                                  </Button>
                                </DialogTitle>
                                <DialogContent className="textall_popup_text_posts_body_result_list">
                                  {selectedPostTW &&
                                    renderPostTextWithStickers(selectedPostTW)}
                                </DialogContent>
                              </Dialog>
                            </div>
                            <div className="footer_posts_body_result_list">
                              {/* <div className="right_footer_posts_body_result_list">
                            {(() => {
                              switch (post.action.action_state) {
                                case 1:
                                  return <img src={plus_green} alt="" />;
                                case 2:
                                  return <img src={minus_red} alt="" />;
                                case 3:
                                  return <img src={zero_gray} alt="" />;
                                default:
                                  return null;
                              }
                            })()}
                          </div> */}
                              <div className="left_footer_posts_body_result_list">
                                <button
                                  onClick={() =>
                                    handleOpenPopupDetailTW(post.id)
                                  }
                                >
                                  {language === "ar" ? (
                                    <label>
                                      <ArrowBackIosNewIcon id="arrow_detail_post_body_result_list_ar" />
                                      التفاصيل
                                    </label>
                                  ) : (
                                    <label>
                                      Details
                                      <ArrowForwardIosIcon id="arrow_detail_post_body_result_list" />
                                    </label>
                                  )}
                                </button>
                                <Dialog
                                  className="popup_mui_search_posts"
                                  open={showPopupDetailTW[post.id] || false}
                                  onClose={() =>
                                    handleClosePopupDateTW(post.id)
                                  }
                                >
                                  <DialogTitle className="header_popup_detail_display_post">
                                    <button
                                      onClick={() =>
                                        handleClosePopupDateTW(post.id)
                                      }
                                      className="btn_close_sim_detail_search"
                                    >
                                      <CloseIcon />
                                    </button>
                                    <img src={twitter_icon} alt="" />
                                  </DialogTitle>
                                  <DialogContent className="body_popup_detail_display_post">
                                    {language === "ar" ? (
                                      <div className="header_body_popup_detail_display_post">
                                        {/* <strong>تاريخ الوصول الأول</strong>
                                      <span></span>
                                      <strong>آخر موعد للوصول</strong>
                                      <span></span> */}
                                      </div>
                                    ) : (
                                      <div className="header_body_popup_detail_display_post_en">
                                        {/* <strong>First Access Date</strong>
                                      <span></span>
                                      <strong>Last Access Date</strong>
                                      <span></span> */}
                                      </div>
                                    )}

                                    <div className="tweet_body_popup_detail_display_post">
                                      <div className="sender_tweet_body_popup_detail_display_post">
                                        <div className="right_tweet_body_popup_detail_display_post">
                                          <img
                                            src={
                                              post.username
                                                ? twitter_profile_url +
                                                  post.username +
                                                  ".png"
                                                : user_avatar
                                            }
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = user_avatar;
                                            }}
                                            alt=""
                                          />
                                          <span className="border_sender_tweet_body_popup_detail_display_post"></span>
                                        </div>
                                        <div className="left_tweet_body_popup_detail_display_post">
                                          <div className="username_right_header_posts_body_result_list">
                                            <span> {post.name} </span>
                                            <span> @ {post.username} </span>
                                          </div>
                                          <div className="tweet_content_body_popup_detail_display_post">
                                            <p className="text_posts_body_result_list">
                                              {renderPostTextWithStickers(post)}
                                            </p>
                                          </div>
                                          {language === "ar" ? (
                                            <div className="bottom_body_popup_detail_display_post">
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>هوية المنشور : </span>
                                                <span> {post.id} </span>
                                              </div>
                                              <a
                                                href={
                                                  "https://twitter.com" +
                                                  post.link_tweet
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                رابط المنشور
                                              </a>
                                            </div>
                                          ) : (
                                            <div className="bottom_body_popup_detail_display_post">
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>Post ID: </span>
                                                <span>{post.id}</span>
                                              </div>
                                              <a
                                                href={
                                                  "https://twitter.com" +
                                                  post.link_tweet
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                Post Link
                                              </a>
                                            </div>
                                          )}
                                          {language === "ar" ? (
                                            <div className="footer_body_popup_detail_display_post">
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>تاریخ : </span>
                                                <span>
                                                  {" "}
                                                  {new Date(
                                                    post.date_tweet.split(
                                                      "at"
                                                    )[0]
                                                  ).toLocaleDateString(
                                                    undefined,
                                                    {
                                                      day: "numeric",
                                                      month: "long",
                                                      year: "numeric",
                                                    }
                                                  )}
                                                </span>
                                              </div>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                التعليقات{" "}
                                                <span> {post.replies} </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                إعادة التغريدات{" "}
                                                <span>
                                                  {" "}
                                                  {post.retweet_count}{" "}
                                                </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                الإعجابات
                                                <span> {post.like_count} </span>
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="footer_body_popup_detail_display_post">
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Replies{" "}
                                                <span>{post.replies}</span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Retweets{" "}
                                                <span>
                                                  {post.retweet_count}
                                                </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Likes{" "}
                                                <span>{post.like_count}</span>
                                              </button>
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>Date: </span>
                                                <span>
                                                  {new Date(
                                                    post.date_tweet.split(
                                                      "at"
                                                    )[0]
                                                  ).toLocaleDateString(
                                                    undefined,
                                                    {
                                                      day: "numeric",
                                                      month: "long",
                                                      year: "numeric",
                                                    }
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {apiDataCommentTW &&
                                        apiDataCommentTW.comments &&
                                        apiDataCommentTW.comments.length > 0 &&
                                        apiDataCommentTW.comments.map(
                                          (item) => (
                                            <div
                                              className="replay_body_popup_detail_display_post"
                                              key={item.id}
                                            >
                                              <div className="right_tweet_body_popup_detail_display_post">
                                                <img
                                                  src={
                                                    item.username
                                                      ? twitter_profile_url +
                                                      item.username +
                                                        ".png"
                                                      : user_avatar
                                                  }
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src =
                                                      user_avatar;
                                                  }}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="left_tweet_body_popup_detail_display_post">
                                                <div className="username_right_header_posts_body_result_list">
                                                  <span>{item.name}</span>
                                                  <span>{item.username}</span>
                                                </div>
                                                <div className="tweet_content_body_popup_detail_display_post">
                                                  <p className="text_posts_body_result_list">
                                                   {renderCommentTextWithStickers(item)} 
                                                  </p>
                                                </div>
                                                {language === "ar" ? (
                                                  <div className="bottom_body_popup_detail_display_post">
                                                    <div className="spans_bottom_body_popup_detail_display_post">
                                                      <span>
                                                        هوية المنشور :{" "}
                                                      </span>
                                                      <span>{item.id}</span>
                                                    </div>
                                                    <button className="btn_link_bottom_body_popup_detail_display_post">
                                                      <a
                                                        href={"https://twitter.com" + item.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="btn_link_bottom_body_popup_detail_display_post"
                                                      >
                                                        رابط المنشور
                                                      </a>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className="bottom_body_popup_detail_display_post">
                                                    <div className="spans_bottom_body_popup_detail_display_post">
                                                      <span>Post ID: </span>
                                                      <span>{item.id}</span>
                                                    </div>
                                                    <a
                                                     
                                                      href={"https://twitter.com" + item.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="btn_link_bottom_body_popup_detail_display_post"
                                                    >
                                                      Post Link
                                                    </a>
                                                  </div>
                                                )}
                                                {language === "ar" ? (
                                                  <div className="footer_body_popup_detail_display_post">
                                                    <div>
                                                      <span>
                                                        {new Date(
                                                          item.creates.split(
                                                            "at"
                                                          )[0]
                                                        ).toLocaleDateString(
                                                          undefined,
                                                          {
                                                            day: "numeric",
                                                            month: "long",
                                                            year: "numeric",
                                                          }
                                                        )}
                                                      </span>
                                                      <span> : تاریخ</span>
                                                    </div>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      الإعجابات{" "}
                                                      <span>
                                                        {item.likes_count}
                                                      </span>
                                                    </button>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      التعليقات{" "}
                                                      <span>
                                                        {item.replies_count}
                                                      </span>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className="footer_body_popup_detail_display_post">
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      Likes <span>0</span>
                                                    </button>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      Replies <span>0</span>
                                                    </button>

                                                    <div>
                                                      <span>Date: </span>
                                                      <span>
                                                        {new Date(
                                                          item.creates.split(
                                                            "at"
                                                          )[0]
                                                        ).toLocaleDateString(
                                                          undefined,
                                                          {
                                                            day: "numeric",
                                                            month: "long",
                                                            year: "numeric",
                                                          }
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>

                                    {/* <div className="chart_tweet_body_popup_detail_display_post">
                                  <ChartPopupDetailPosts />
                                </div> */}
                                  </DialogContent>
                                </Dialog>
                                {language === "ar" ? (
                                  <a
                                    href={
                                      "https://twitter.com" + post.link_tweet
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_ar" />
                                    الرابط
                                  </a>
                                ) : (
                                  <a
                                    href={
                                      "https://twitter.com" + post.link_tweet
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    Link
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_en" />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {loadMoreStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pagination_posts_body_result_list">
                        <button
                          onClick={prevPageTwitter}
                          disabled={currentPageTwitter === 1}
                        >
                          <KeyboardDoubleArrowLeftIcon />
                        </button>
                        <span className="pagination_posts_body_result_list_span">
                          {currentPageTwitter}
                        </span>
                        <button
                          onClick={nextPageTwitter}
                          disabled={currentPageTwitter === totalPagesTwitter}
                        >
                          <KeyboardDoubleArrowRightIcon />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {/* </>
            )} */}
          </div>
        )}

        {isFacebookActive && (
          <div className="facebook_result_list_body">
            {loadingStateFB ? (
              <div className="spiner_postbox">
                <HashLoader color="#458BDF" size={50} />
              </div>
            ) : (
              <>
                {showFacebookSection && visibleFBResult && (
                  <>
                    {language === "ar" ? (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">تاريخ النشر</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">التعليقات</span>
                            </span>

                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">اعجاب</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortByLikesFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortByLikesFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="right_header_result_list">
                          <img
                            src={Excel_green}
                            id="exel_icon_facebook"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelFacebook}
                          />

                          <div className="checkbox_header_result_border_excel">
                            <label className="cyberpunk_checkbox_label">
                              <span>إضافة النتائج إلي الإكسل</span>
                              <input
                                onChange={handleCheckboxChangeFB}
                                type="checkbox"
                                className="cyberpunk_checkbox"
                              />
                            </label>
                          </div>
                          <img src={facebook_icon} alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="right_header_result_list">
                          <img src={facebook_icon} alt="" />

                          <div className="checkbox_header_result_border_excel en">
                            <label className="cyberpunk_checkbox_label">
                              <input
                                type="checkbox"
                                className="cyberpunk_checkbox"
                                onChange={handleCheckboxChangeFB}
                              />
                            </label>
                            <span>Adding the results to Excel</span>
                          </div>
                          <img
                            src={Excel_green}
                            id="exel_icon_facebook"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelFacebook}
                          />
                        </div>
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">
                                Publication Date
                              </span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Replies</span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeFB === "desc" ? "active" : ""
                                }
                                onClick={() => sortByLikesFB("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeFB === "asc" ? "active" : ""
                                }
                                onClick={() => sortByLikesFB("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Likes</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="body_result_list">
                      {currentFacebookPosts.map((posts) => {
                        const shouldTruncateFB = posts.texts.length > 120;

                        return (
                          <div
                            className={`posts_body_result_list ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            id="posts_body_result_list_facebook"
                            key={posts.id}
                          >
                            <div className="header_posts_body_result_list">
                              <div className="left_header_posts_body_result_list">
                                <label className="cyberpunk_checkbox_label">
                                  <input
                                    type="checkbox"
                                    className="cyberpunk_checkbox"
                                    id="cyberpunk_checkbox_front_facebook"
                                    onChange={handleCheckboxChangeFacebook}
                                  />
                                </label>
                              </div>
                              <div className="right_header_posts_body_result_list">
                                <div className="username_right_header_posts_body_result_list">
                                  <span id="excel_tag_home_facebook_name">
                                    {posts.name}
                                  </span>
                                  <span
                                    onClick={() =>
                                      onUsernameClick(posts.username)
                                    }
                                    id="excel_tag_home_facebook_username"
                                  >
                                    {posts.username && `@${posts.username}`}
                                  </span>
                                </div>
                                <img
                                  src={
                                    posts.username
                                      ? facebook_profile_url +
                                        posts.username +
                                        ".png"
                                      : user_avatar
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = user_avatar;
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                            {language === "ar" ? (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  {posts.shares}
                                  <span className="tooltip">
                                    <img src={share_icon} alt="" />
                                    <span className="tooltip_text">مشارکة</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="like_count_results_posts_facebook">
                                    {posts.likes}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">اعجاب</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="comments_count_results_posts_facebook">
                                    {posts.comments}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="الرد" />
                                    <span className="tooltip_text">الرد</span>
                                  </span>
                                </label>
                                <label>
                                  <span
                                    id="date_results_posts_exel_facebook"
                                    onClick={() =>
                                      onDateClick(formatDate(posts.creates))
                                    }
                                  >
                                    {new Date(
                                      posts.creates.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>
                                  <span className="tooltip">
                                    <img src={cal_icon} alt="" />
                                    <span className="tooltip_text">
                                      تاريخ النشر
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  منشور&nbsp;
                                  <FacebookIcon />
                                </label>
                              </div>
                            ) : (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  {posts.shares}
                                  <span className="tooltip">
                                    <img src={share_icon} alt="" />
                                    <span className="tooltip_text">Shares</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="like_count_results_posts_facebook">
                                    {posts.likes}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">Likes</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="comments_count_results_posts_facebook">
                                    {posts.comments}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="Comment" />
                                    <span className="tooltip_text">
                                      Comments
                                    </span>
                                  </span>
                                </label>
                                <label>
                                  <span
                                    onClick={() =>
                                      onDateClick(formatDate(posts.creates))
                                    }
                                    id="date_results_posts_exel_facebook"
                                  >
                                    {new Date(
                                      posts.creates.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>
                                  <span className="tooltip">
                                    <img src={cal_icon} alt="" />
                                    <span className="tooltip_text">
                                      Publication Date
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  Post
                                  <img src={facebook_icon} alt="" />
                                </label>
                              </div>
                            )}
                            <div className="text_posts_body_result_list_facebook">
                              {shouldTruncateFB ? (
                                <React.Fragment>
                                  {renderPostTextWithStickersFB(posts).slice(
                                    0,
                                    120
                                  )}
                                  <span> ... </span>
                                  {language === "ar" ? (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextFB(posts)}
                                    >
                                      عرض المزيد
                                    </button>
                                  ) : (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextFB(posts)}
                                    >
                                      Show More
                                    </button>
                                  )}
                                </React.Fragment>
                              ) : (
                                <span id="excel_tag_home_facebook_text">
                                  {/* {posts.texts} */}
                                  {renderPostTextWithStickersFB(posts)}
                                </span>
                              )}
                              <Dialog
                                open={openAllTextFB}
                                onClose={handleCloseAllTextFB}
                              >
                                <DialogTitle className="header_popup_text_posts_body_result_list">
                                  <Button onClick={handleCloseAllTextFB}>
                                    <CloseIcon />
                                  </Button>
                                </DialogTitle>
                                <DialogContent className="textall_popup_text_posts_body_result_list">
                                  {selectedPostFB &&
                                    renderPostTextWithStickersFB(
                                      selectedPostFB
                                    )}
                                </DialogContent>
                              </Dialog>
                            </div>

                            <div className="footer_posts_body_result_list">
                              <div className="left_footer_posts_body_result_list">
                                <button
                                  onClick={() =>
                                    handleOpenPopupDetail(posts.id)
                                  }
                                >
                                  {language === "ar" ? (
                                    <label>
                                      <ArrowBackIosNewIcon id="arrow_detail_post_body_result_list_ar" />
                                      التفاصيل
                                    </label>
                                  ) : (
                                    <label>
                                      Details
                                      <ArrowForwardIosIcon id="arrow_detail_post_body_result_list" />
                                    </label>
                                  )}
                                </button>
                                <Dialog
                                  className="popup_mui_search_posts"
                                  open={showPopupDetail[posts.id] || false}
                                  onClose={() => handleClosePopupDate(posts.id)}
                                >
                                  <DialogTitle className="header_popup_detail_display_post">
                                    <button
                                      onClick={() =>
                                        handleClosePopupDate(posts.id)
                                      }
                                      className="btn_close_sim_detail_search"
                                    >
                                      <CloseIcon />
                                    </button>
                                    <FacebookIcon className="fb_icon_popup_posts_result_search" />
                                  </DialogTitle>
                                  <DialogContent className="body_popup_detail_display_post">
                                    {language === "ar" ? (
                                      <div className="header_body_popup_detail_display_post">
                                        <strong> : تاريخ الوصول الأول</strong>
                                        <span></span>
                                        <strong> : آخر موعد للوصول</strong>
                                        <span></span>
                                      </div>
                                    ) : (
                                      <div className="header_body_popup_detail_display_post_en">
                                        <strong>First Access Date</strong>
                                        <span></span>
                                        <strong>Last Access Date</strong>
                                        <span></span>
                                      </div>
                                    )}
                                    <div className="tweet_body_popup_detail_display_post">
                                      <div className="sender_tweet_body_popup_detail_display_post">
                                        <div className="right_tweet_body_popup_detail_display_post">
                                          <img
                                            src={
                                              posts.username
                                                ? facebook_profile_url +
                                                  posts.username +
                                                  ".png"
                                                : user_avatar
                                            }
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = user_avatar;
                                            }}
                                            alt=""
                                          />
                                          <span className="border_sender_tweet_body_popup_detail_display_post"></span>
                                        </div>
                                        <div className="left_tweet_body_popup_detail_display_post">
                                          <div className="username_right_header_posts_body_result_list">
                                            <span> {posts.name} </span>
                                            <span> {posts.username} </span>
                                          </div>
                                          <div className="tweet_content_body_popup_detail_display_post">
                                            <p className="text_posts_body_result_list">
                                              {/* {posts.texts} */}
                                              {renderPostTextWithStickersFB(
                                                posts
                                              )}
                                            </p>
                                          </div>
                                          {language === "ar" ? (
                                            <div className="bottom_body_popup_detail_display_post">
                                              {/* <div className="emoji_bottom_body_popup_detail_display_post">
                              {(() => {
                                switch (posts.action.action_state) {
                                  case 1:
                                    return (
                                      <img
                                        src={plus_green}
                                        alt=""
                                      />
                                    );
                                  case 2:
                                    return (
                                      <img src={minus_red} alt="" />
                                    );
                                  case 3:
                                    return (
                                      <img src={zero_gray} alt="" />
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </div> */}
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>هوية المنشور : </span>
                                                <span> {posts.id} </span>
                                              </div>
                                              <a
                                                href={posts.postid}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                رابط المنشور
                                              </a>
                                            </div>
                                          ) : (
                                            <div className="bottom_body_popup_detail_display_post">
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>Post ID: </span>
                                                <span>{posts.id}</span>
                                              </div>
                                              <a
                                                href={posts.postid}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                Post Link
                                              </a>
                                            </div>
                                          )}
                                          {language === "ar" ? (
                                            <div className="footer_body_popup_detail_display_post">
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>تاریخ : </span>
                                                <span>
                                                  {new Date(
                                                    posts.creates.split("at")[0]
                                                  ).toLocaleDateString(
                                                    undefined,
                                                    {
                                                      day: "numeric",
                                                      month: "long",
                                                      year: "numeric",
                                                    }
                                                  )}
                                                </span>
                                              </div>

                                              <button className="btns_footer_body_popup_detail_display_post">
                                                التعليقات{" "}
                                                <span> {posts.comments} </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                الإعجابات{" "}
                                                <span> {posts.likes} </span>
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="footer_body_popup_detail_display_post">
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Replies{" "}
                                                <span>{posts.comments}</span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Likes <span>{posts.likes}</span>
                                              </button>
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>Date: </span>
                                                <span id="date_results_posts_exel_facebook">
                                                  {new Date(
                                                    posts.creates.split("at")[0]
                                                  ).toLocaleDateString(
                                                    undefined,
                                                    {
                                                      day: "numeric",
                                                      month: "long",
                                                      year: "numeric",
                                                    }
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {apiDataComment &&
                                        apiDataComment.comments &&
                                        apiDataComment.comments.length > 0 &&
                                        apiDataComment.comments.map((item) => (
                                          <div
                                            className="replay_body_popup_detail_display_post"
                                            key={item.id}
                                          >
                                            <div className="right_tweet_body_popup_detail_display_post">
                                              <img
                                                src={
                                                  posts.username
                                                    ? facebook_profile_url +
                                                      posts.username +
                                                      ".png"
                                                    : user_avatar
                                                }
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =
                                                    user_avatar;
                                                }}
                                                alt=""
                                              />
                                            </div>
                                            <div className="left_tweet_body_popup_detail_display_post">
                                              <div className="username_right_header_posts_body_result_list">
                                                <span>{item.name}</span>
                                                <span>{item.username}</span>
                                              </div>
                                              <div className="tweet_content_body_popup_detail_display_post">
                                                <p className="text_posts_body_result_list">
                                                  {item.text}
                                                </p>
                                              </div>
                                              {language === "ar" ? (
                                                <div className="bottom_body_popup_detail_display_post">
                                                  <div className="spans_bottom_body_popup_detail_display_post">
                                                    <span>هوية المنشور : </span>
                                                    <span>{item.userid}</span>
                                                  </div>
                                                  <button className="btn_link_bottom_body_popup_detail_display_post">
                                                    <a
                                                      href={posts.postid}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="btn_link_bottom_body_popup_detail_display_post"
                                                    >
                                                      رابط المنشور
                                                    </a>
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="bottom_body_popup_detail_display_post">
                                                  <div className="spans_bottom_body_popup_detail_display_post">
                                                    <span>Post ID: </span>
                                                    <span>{posts.id}</span>
                                                  </div>
                                                  <a
                                                    href={posts.postid}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="btn_link_bottom_body_popup_detail_display_post"
                                                  >
                                                    Post Link
                                                  </a>
                                                </div>
                                              )}
                                              {language === "ar" ? (
                                                <div className="footer_body_popup_detail_display_post">
                                                  <div>
                                                    <span>
                                                      {new Date(
                                                        item.creates.split(
                                                          "at"
                                                        )[0]
                                                      ).toLocaleDateString(
                                                        undefined,
                                                        {
                                                          day: "numeric",
                                                          month: "long",
                                                          year: "numeric",
                                                        }
                                                      )}
                                                    </span>
                                                    <span> : تاریخ</span>
                                                  </div>
                                                  <button className="btns_footer_body_popup_detail_display_post">
                                                    الإعجابات{" "}
                                                    <span>
                                                      {item.likes_count}
                                                    </span>
                                                  </button>
                                                  <button className="btns_footer_body_popup_detail_display_post">
                                                    التعليقات{" "}
                                                    <span>
                                                      {item.comments_count}
                                                    </span>
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="footer_body_popup_detail_display_post">
                                                  <button className="btns_footer_body_popup_detail_display_post">
                                                    Likes{" "}
                                                    <span>
                                                      {item.likes_count}
                                                    </span>
                                                  </button>
                                                  <button className="btns_footer_body_popup_detail_display_post">
                                                    Replies{" "}
                                                    <span>
                                                      {item.comments_count}
                                                    </span>
                                                  </button>

                                                  <div>
                                                    <span>Date: </span>
                                                    <span>
                                                      {new Date(
                                                        item.creates.split(
                                                          "at"
                                                        )[0]
                                                      ).toLocaleDateString(
                                                        undefined,
                                                        {
                                                          day: "numeric",
                                                          month: "long",
                                                          year: "numeric",
                                                        }
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                    {/* <div className="chart_tweet_body_popup_detail_display_post">
                                    <ChartPopupDetailPosts />
                                  </div> */}
                                  </DialogContent>
                                </Dialog>

                                {language === "ar" ? (
                                  <a
                                    href={posts.postid}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_ar" />
                                    الرابط
                                  </a>
                                ) : (
                                  <a
                                    href={posts.postid}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    Link
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_en" />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {loadMoreStateFb ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pagination_posts_body_result_list">
                        <button
                          onClick={prevPageFacebook}
                          disabled={currentPageFacebook === 1}
                        >
                          <KeyboardDoubleArrowLeftIcon />
                        </button>
                        <span className="pagination_posts_body_result_list_span">
                          {currentPageFacebook}
                        </span>
                        <button
                          onClick={nextPageFacebook}
                          disabled={currentPageFacebook === totalPagesFacebook}
                        >
                          <KeyboardDoubleArrowRightIcon />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {isInstagramActive && (
          <div className="instagram_result_list_body">
            {loadingStateIN ? (
              <div className="spiner_postbox">
                <HashLoader color="#458BDF" size={50} />
              </div>
            ) : (
              <>
                {showInstagramSection && visibleINResult && (
                  <>
                    {language === "ar" ? (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">تاريخ النشر</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">التعليقات</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_left_header_result_list">
                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">اعجاب</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="right_header_result_list">
                          <img
                            src={Excel_green}
                            id="exel_icon_instagram"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelInstagram}
                          />

                          <div className="checkbox_header_result_border_excel">
                            <label className="cyberpunk_checkbox_label">
                              <span>إضافة النتائج إلي الإكسل</span>
                              <input
                                onChange={handleCheckboxChangeIN}
                                type="checkbox"
                                className="cyberpunk_checkbox"
                              />
                            </label>
                          </div>
                          <img src={instagram_logo} alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`header_result_list ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <div className="right_header_result_list">
                          <img src={instagram_logo} alt="" />

                          <div className="checkbox_header_result_border_excel en">
                            <label className="cyberpunk_checkbox_label">
                              <input
                                type="checkbox"
                                className="cyberpunk_checkbox"
                                onChange={handleCheckboxChangeIN}
                              />
                            </label>
                            <span>Adding the results to Excel</span>
                          </div>
                          <img
                            src={Excel_green}
                            id="exel_icon_instagram"
                            alt=""
                            className="excel_icon_header_home_result_posts"
                            onClick={handleExportToExcelInstagram}
                          />
                        </div>
                        <div className="left_header_result_list">
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByDateIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortByCreateIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">
                                Publication Date
                              </span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Replies</span>
                            </span>
                          </div>
                          <div className="items_left_header_result_list">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByLikeIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>

                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Likes</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="body_result_list">
                      {currentInstagramPosts.map((posts) => {
                        const shouldTruncateIN =
                          posts.media_caption.length > 120;
                        return (
                          <div
                            className={`posts_body_result_list_insta ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            id="posts_body_result_list_instagram"
                            key={posts.id}
                          >
                            <div className="header_posts_body_result_list">
                              <div className="left_header_posts_body_result_list">
                                <label className="cyberpunk_checkbox_label">
                                  <input
                                    type="checkbox"
                                    className="cyberpunk_checkbox"
                                    id="cyberpunk_checkbox_front_instagram"
                                    onChange={handleCheckboxChangeInstagram}
                                  />
                                </label>
                              </div>
                              <div className="right_header_posts_body_result_list">
                                <div className="username_right_header_posts_body_result_list">
                                  <span
                                    onClick={() =>
                                      onUsernameClick(posts.username)
                                    }
                                    id="excel_tag_home_instagram_username"
                                  >
                                    @{posts.username}
                                  </span>
                                </div>
                                <img
                                  src={
                                    posts.username
                                      ? instagram_profile_url +
                                        posts.username +
                                        ".png"
                                      : user_avatar
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = user_avatar;
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                            {language === "ar" ? (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  <span id="comments_count_results_posts_instagram">
                                    {posts.comment_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="" />
                                    <span className="tooltip_text">الرد</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="like_count_results_posts_instagram">
                                    {posts.like_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">اعجاب</span>
                                  </span>
                                </label>
                                <label>
                                  <span
                                    onClick={() =>
                                      onDateClick(formatDate(posts.create_date))
                                    }
                                    id="date_results_posts_exel_instagram"
                                  >
                                    {posts.create_date &&
                                    posts.create_date.split("at")[0]
                                      ? new Date(
                                          posts.create_date.split("at")[0]
                                        ).toLocaleDateString(undefined, {
                                          day: "numeric",
                                          month: "short",
                                          year: "numeric",
                                        })
                                      : ""}
                                  </span>
                                  <span className="tooltip">
                                    <img src={cal_icon} alt="" />
                                    <span className="tooltip_text">
                                      تاريخ النشر
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  منشور&nbsp;
                                  <InstagramIcon id="type_posts_label_result_search_insta" />
                                </label>
                              </div>
                            ) : (
                              <div className="statics_posts_body_result_list">
                                <label>
                                  <span id="like_count_results_posts_instagram">
                                    {posts.like_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={like_icon} alt="" />
                                    <span className="tooltip_text">Likes</span>
                                  </span>
                                </label>
                                <label>
                                  <span id="comments_count_results_posts_instagram">
                                    {posts.comment_count}
                                  </span>
                                  <span className="tooltip">
                                    <img src={comment_icon} alt="" />
                                    <span className="tooltip_text">
                                      Comment
                                    </span>
                                  </span>
                                </label>
                                <label>
                                  <span
                                    onClick={() =>
                                      onDateClick(formatDate(posts.create_date))
                                    }
                                    id="date_results_posts_exel_instagram"
                                  >
                                    {posts.create_date &&
                                    posts.create_date.split("at")[0]
                                      ? new Date(
                                          posts.create_date.split("at")[0]
                                        ).toLocaleDateString(undefined, {
                                          day: "numeric",
                                          month: "short",
                                          year: "numeric",
                                        })
                                      : ""}
                                  </span>
                                  <span className="tooltip">
                                    <img src={cal_icon} alt="" />
                                    <span className="tooltip_text">
                                      Publication Date
                                    </span>
                                  </span>
                                </label>
                                <label id="type_posts_label_result_search">
                                  Post&nbsp;
                                  <InstagramIcon id="type_posts_label_result_search_insta" />
                                </label>
                              </div>
                            )}
                            <div className="image_post_insta_body_result_list">
                              <img src={posts_insta_image} alt="" />
                            </div>

                            <div className="text_posts_body_result_list_instagram">
                              {shouldTruncateIN ? (
                                <React.Fragment>
                                  {/* {posts.media_caption.slice(0, 120)} */}
                                  {renderPostTextWithStickersIN(posts).slice(
                                    0,
                                    120
                                  )}
                                  <span> ... </span>
                                  {language === "ar" ? (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextIN(posts)}
                                    >
                                      عرض المزيد
                                    </button>
                                  ) : (
                                    <button
                                      className="btn_text_posts_body_result_list"
                                      onClick={() => handleOpenAllTextIN(posts)}
                                    >
                                      Show More
                                    </button>
                                  )}
                                </React.Fragment>
                              ) : (
                                <span id="excel_tag_home_instagram_text_result">
                                  {/* {posts.media_caption} */}
                                  {renderPostTextWithStickersIN(posts)}
                                </span>
                              )}
                              <Dialog
                                open={openAllTextIN}
                                onClose={handleCloseAllTextIN}
                              >
                                <DialogTitle className="header_popup_text_posts_body_result_list">
                                  <Button onClick={handleCloseAllTextIN}>
                                    <CloseIcon />
                                  </Button>
                                </DialogTitle>
                                <DialogContent className="textall_popup_text_posts_body_result_list">
                                  {selectedPostIN &&
                                    renderPostTextWithStickersIN(
                                      selectedPostIN
                                    )}
                                </DialogContent>
                              </Dialog>
                            </div>

                            <div className="footer_posts_body_result_list">
                              <div className="left_footer_posts_body_result_list">
                                <button
                                  onClick={() =>
                                    handleOpenPopupDetailIN(posts.id)
                                  }
                                >
                                  {language === "ar" ? (
                                    <label>
                                      <ArrowBackIosNewIcon id="arrow_detail_post_body_result_list_ar" />
                                      التفاصيل
                                    </label>
                                  ) : (
                                    <label>
                                      Details
                                      <ArrowForwardIosIcon id="arrow_detail_post_body_result_list" />
                                    </label>
                                  )}
                                </button>

                                <Dialog
                                  className="popup_mui_search_posts"
                                  open={showPopupDetailIN[posts.id] || false}
                                  onClose={() =>
                                    handleClosePopupDateIN(posts.id)
                                  }
                                >
                                  <DialogTitle className="header_popup_detail_display_post">
                                    <button
                                      onClick={() =>
                                        handleClosePopupDateIN(posts.id)
                                      }
                                      className="btn_close_sim_detail_search"
                                    >
                                      <CloseIcon />
                                    </button>
                                    <InstagramIcon id="type_posts_label_result_search_insta" />
                                  </DialogTitle>
                                  <DialogContent className="body_popup_detail_display_post">
                                    {language === "ar" ? (
                                      <div className="header_body_popup_detail_display_post">
                                        <strong> : تاريخ الوصول الأول</strong>
                                        <span></span>
                                        <strong> : آخر موعد للوصول</strong>
                                        <span></span>
                                      </div>
                                    ) : (
                                      <div className="header_body_popup_detail_display_post_en">
                                        <strong>First Access Date</strong>
                                        <span></span>
                                        <strong>Last Access Date</strong>
                                        <span></span>
                                      </div>
                                    )}
                                    <div className="tweet_body_popup_detail_display_post">
                                      <div className="sender_tweet_body_popup_detail_display_post">
                                        <div className="right_tweet_body_popup_detail_display_post">
                                          <img
                                            src={
                                              posts.username
                                                ? instagram_profile_url +
                                                  posts.username +
                                                  ".png"
                                                : user_avatar
                                            }
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = user_avatar;
                                            }}
                                            alt=""
                                          />
                                          <span className="border_sender_tweet_body_popup_detail_display_post"></span>
                                        </div>
                                        <div className="left_tweet_body_popup_detail_display_post">
                                          <div className="username_right_header_posts_body_result_list">
                                            {/* <span> {posts.name} </span> */}
                                            <span> {posts.username} </span>
                                          </div>
                                          <div className="tweet_content_body_popup_detail_display_post">
                                            <p className="text_posts_body_result_list">
                                              {/* {posts.media_caption} */}
                                              {renderPostTextWithStickersIN(
                                                posts
                                              )}
                                            </p>
                                          </div>
                                          {language === "ar" ? (
                                            <div className="bottom_body_popup_detail_display_post">
                                              {/* <div className="emoji_bottom_body_popup_detail_display_post">
                                            {(() => {
                                              switch (posts.action.action_state) {
                                                case 1:
                                                  return (
                                                    <img
                                                      src={plus_green}
                                                      alt=""
                                                    />
                                                  );
                                                case 2:
                                                  return (
                                                    <img src={minus_red} alt="" />
                                                  );
                                                case 3:
                                                  return (
                                                    <img src={zero_gray} alt="" />
                                                  );
                                                default:
                                                  return null;
                                              }
                                            })()}
                                          </div> */}
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>هوية المنشور : </span>
                                                <span> {posts.id} </span>
                                              </div>
                                              <a
                                                href={posts.postid}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                رابط المنشور
                                              </a>
                                            </div>
                                          ) : (
                                            <div className="bottom_body_popup_detail_display_post">
                                              {/* <div className="emoji_bottom_body_popup_detail_display_post">
                                            {(() => {
                                              switch (posts.action.action_state) {
                                                case 1:
                                                  return (
                                                    <img
                                                      src={plus_green}
                                                      alt=""
                                                    />
                                                  );
                                                case 2:
                                                  return (
                                                    <img src={minus_red} alt="" />
                                                  );
                                                case 3:
                                                  return (
                                                    <img src={zero_gray} alt="" />
                                                  );
                                                default:
                                                  return null;
                                              }
                                            })()}
                                          </div> */}
                                              <div className="spans_bottom_body_popup_detail_display_post">
                                                <span>Post ID: </span>
                                                <span>{posts.id}</span>
                                              </div>
                                              <a
                                                href={posts.post_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn_link_bottom_body_popup_detail_display_post"
                                              >
                                                Post Link
                                              </a>
                                            </div>
                                          )}
                                          {language === "ar" ? (
                                            <div className="footer_body_popup_detail_display_post">
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>تاریخ : </span>
                                                <span>
                                                  {posts.create_date &&
                                                  posts.create_date.split(
                                                    "at"
                                                  )[0]
                                                    ? new Date(
                                                        posts.create_date.split(
                                                          "at"
                                                        )[0]
                                                      ).toLocaleDateString(
                                                        undefined,
                                                        {
                                                          day: "numeric",
                                                          month: "short",
                                                          year: "numeric",
                                                        }
                                                      )
                                                    : ""}
                                                </span>
                                              </div>

                                              <button className="btns_footer_body_popup_detail_display_post">
                                                التعليقات
                                                <span>
                                                  {" "}
                                                  {posts.comment_count}{" "}
                                                </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                الإعجابات{" "}
                                                <span>
                                                  {" "}
                                                  {posts.like_count}{" "}
                                                </span>
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="footer_body_popup_detail_display_post">
                                              <div className="date_footer_body_popup_detail_display_post">
                                                <span>Date: </span>
                                                <span>
                                                  {posts.create_date &&
                                                  posts.create_date.split(
                                                    "at"
                                                  )[0]
                                                    ? new Date(
                                                        posts.create_date.split(
                                                          "at"
                                                        )[0]
                                                      ).toLocaleDateString(
                                                        undefined,
                                                        {
                                                          day: "numeric",
                                                          month: "short",
                                                          year: "numeric",
                                                        }
                                                      )
                                                    : ""}
                                                </span>
                                              </div>

                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Replies
                                                <span>
                                                  {" "}
                                                  {posts.comment_count}{" "}
                                                </span>
                                              </button>
                                              <button className="btns_footer_body_popup_detail_display_post">
                                                Likes
                                                <span>
                                                  {" "}
                                                  {posts.like_count}{" "}
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      {apiDataCommentIN &&
                                        apiDataCommentIN.comments &&
                                        apiDataCommentIN.comments.length > 0 &&
                                        apiDataCommentIN.comments.map(
                                          (item) => (
                                            <div
                                              className="replay_body_popup_detail_display_post"
                                              key={item.id}
                                            >
                                              <div className="right_tweet_body_popup_detail_display_post">
                                                <img
                                                  src={
                                                    posts.username
                                                      ? instagram_profile_url +
                                                        posts.username +
                                                        ".png"
                                                      : user_avatar
                                                  }
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      user_avatar;
                                                  }}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="left_tweet_body_popup_detail_display_post">
                                                <div className="username_right_header_posts_body_result_list">
                                                  <span>{item.name}</span>
                                                  <span>{item.username}</span>
                                                </div>
                                                <div className="tweet_content_body_popup_detail_display_post">
                                                  <p className="text_posts_body_result_list">
                                                    {item.text}
                                                  </p>
                                                </div>
                                                {language === "ar" ? (
                                                  <div className="bottom_body_popup_detail_display_post">
                                                    <div className="spans_bottom_body_popup_detail_display_post">
                                                      <span>
                                                        هوية المنشور :{" "}
                                                      </span>
                                                      <span>{item.id}</span>
                                                    </div>
                                                    <button className="btn_link_bottom_body_popup_detail_display_post">
                                                      <a
                                                        href={item.postid}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="btn_link_bottom_body_popup_detail_display_post"
                                                      >
                                                        رابط المنشور
                                                      </a>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className="bottom_body_popup_detail_display_post">
                                                    <div className="spans_bottom_body_popup_detail_display_post">
                                                      <span>Post ID: </span>
                                                      <span>{item.id}</span>
                                                    </div>
                                                    <a
                                                      href={item.postid}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="btn_link_bottom_body_popup_detail_display_post"
                                                    >
                                                      Post Link
                                                    </a>
                                                  </div>
                                                )}
                                                {language === "ar" ? (
                                                  <div className="footer_body_popup_detail_display_post">
                                                    <div>
                                                      <span>
                                                        {new Date(
                                                          item.creates.split(
                                                            "at"
                                                          )[0]
                                                        ).toLocaleDateString(
                                                          undefined,
                                                          {
                                                            day: "numeric",
                                                            month: "long",
                                                            year: "numeric",
                                                          }
                                                        )}
                                                      </span>
                                                      <span> : تاریخ</span>
                                                    </div>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      الإعجابات{" "}
                                                      <span>
                                                        {item.likes_count}
                                                      </span>
                                                    </button>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      التعليقات{" "}
                                                      <span>
                                                        {item.comments_count}
                                                      </span>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className="footer_body_popup_detail_display_post">
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      Likes{" "}
                                                      <span>
                                                        {item.likes_count}
                                                      </span>
                                                    </button>
                                                    <button className="btns_footer_body_popup_detail_display_post">
                                                      Replies{" "}
                                                      <span>
                                                        {item.comments_count}
                                                      </span>
                                                    </button>

                                                    <div>
                                                      <span>Date: </span>
                                                      <span>
                                                        {new Date(
                                                          item.creates.split(
                                                            "at"
                                                          )[0]
                                                        ).toLocaleDateString(
                                                          undefined,
                                                          {
                                                            day: "numeric",
                                                            month: "long",
                                                            year: "numeric",
                                                          }
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                    {/* <div className="chart_tweet_body_popup_detail_display_post">
                                  <ChartPopupDetailPosts />
                                </div> */}
                                  </DialogContent>
                                </Dialog>

                                {language === "ar" ? (
                                  <a
                                    href={posts.post_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_ar" />
                                    الرابط
                                  </a>
                                ) : (
                                  <a
                                    href={posts.post_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn_link_bottom_body_popup_detail_display_post"
                                  >
                                    Link
                                    <ShareIcon id="arrow_detail_post_body_result_list_link_en" />
                                  </a>
                                )}
                              </div>
                              {/* <div className="right_footer_posts_body_result_list">
                            {(() => {
                              switch (posts.action.action_state) {
                                case 1:
                                  return <img src={plus_green} alt="" />;
                                case 2:
                                  return <img src={minus_red} alt="" />;
                                case 3:
                                  return <img src={zero_gray} alt="" />;
                                default:
                                  return null;
                              }
                            })()}
                          </div> */}
                            </div>
                          </div>
                        );
                      })}
                      {loadMoreStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pagination_posts_body_result_list">
                        <button
                          onClick={prevPageInstagram}
                          disabled={currentPageInstagram === 1}
                        >
                          <KeyboardDoubleArrowLeftIcon />
                        </button>
                        <span className="pagination_posts_body_result_list_span">
                          {currentPageInstagram}
                        </span>
                        <button
                          onClick={nextPageInstagram}
                          disabled={
                            currentPageInstagram === totalPagesInstagram
                          }
                        >
                          <KeyboardDoubleArrowRightIcon />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ListResultsPostBox;
