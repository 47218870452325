import "../../index.css";
import ChromeDinoGame from "react-chrome-dino";
import CloseIcon from "@mui/icons-material/Close";
import ReactDOM from "react-dom";
import { useContext } from "react";
import { LanguageContext } from "../../App";
import { LuArrowBigUp } from "react-icons/lu";

function InternetStatus({ onClose }) {
  const { language } = useContext(LanguageContext);

  return ReactDOM.createPortal(
    <div className="game_dino_no_internet">
      <div className="game_dino_no_internet_body">
        <div className="game_dino_no_internet_body_header">
          <button onClick={onClose}>
            <CloseIcon id="game_dino_no_internet_body_header_btn" />
          </button>
        </div>
        <div className="game_dino_no_internet_body_game_p">
          {language === "en" ? (
            <div className="game_dino_no_internet_body_game_text_all">
              <h1 className="game_dino_no_internet_body_game_text">
                <span className="game_dino_no_internet_body_game_text_word">
                  Your internet connection
                  <span className="game_dino_no_internet_body_game_text_superscript">
                    has
                  </span>
                </span>
                <span className="game_dino_no_internet_body_game_text_word">
                  been disconnected
                </span>
              </h1>
              <p id="game_dino_no_internet_body_game_text_p">
                To start the flash game{" "}
                <LuArrowBigUp id="game_dino_no_internet_body_game_text_p_icon" />{" "}
                press the up arrow on the keyboard
              </p>
            </div>
          ) : (
            <div className="game_dino_no_internet_body_game_text_all">
              <h1 className="game_dino_no_internet_body_game_text">
                <span className="game_dino_no_internet_body_game_text_word">
                  انقطع الاتصال
                  <span className="game_dino_no_internet_body_game_text_superscript">
                    بالإنترنت{" "}
                  </span>{" "}
                </span>
                <span className="game_dino_no_internet_body_game_text_word">
                  الخاص بك
                </span>
              </h1>
              <p id="game_dino_no_internet_body_game_text_p">
                لبدء لعبة الفلاش
                <LuArrowBigUp id="game_dino_no_internet_body_game_text_p_icon" />
                اضغط على مفتاح الصعود على لوحة المفاتيح
              </p>
            </div>
          )}
        </div>
        <div className="game_dino_no_internet_body_game">
          <ChromeDinoGame />
        </div>
      </div>
    </div>,
    document.body
  );
}

export default InternetStatus;
