import React, { useEffect, useRef, useState, useContext } from "react";
import * as echarts from "echarts/lib/echarts";
import "echarts-wordcloud";
import Select from "react-select";
import {words} from './WordsFilter'
import { LanguageContext } from "../../../App";


const CloudHashtagPlatformsWord = ({ facebookPosts,onWordClick }) => {
  const chartRef = useRef(null);
  const [wordCount, setWordCount] = useState(facebookPosts.word_chart.length);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (facebookPosts && facebookPosts.word_chart && facebookPosts.word_chart.length > 0) {

      const myChart = echarts.init(chartRef.current);

      const wordCloudData = facebookPosts.word_chart.map((wordItem) => ({
        name: wordItem[0],
        value: wordItem[1],
      }));

      const filteredWordCloudData = wordCloudData.filter(wordItem => !words.includes(wordItem.name));

      
      const option = {
        tooltip: {},
        series: [
          {
            type: "wordCloud",
            gridSize: 10,
            sizeRange: [15, 40],
            rotationRange: [0, 0],
            shape: "rect",
            textStyle: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              color: function () {
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                  ].join(",") +
                  ")"
                );
              },
            },
            emphasis: {
              focus: "self",
              textStyle: {
                textShadowBlur: 10,
                textShadowColor: "#333",
              },
            },
            // data: wordCloudData.slice(0, wordCount),
            data: filteredWordCloudData.slice(0, wordCount),
          },
        ],
      };

      myChart.setOption(option);

      myChart.on('click', ({ data }) => {
        if (data && data.name) {
          onWordClick(data.name);
        }
      });

      window.addEventListener("resize", () => {
        myChart.resize();
      });

      return () => {
        myChart.dispose();
      };
    }
  }, [wordCount, facebookPosts,onWordClick]);

  const wordCountOptions = [{ value: -1, label:language === 'ar' ? "عرض جميع الكلمات" : "Show All Words" }];

  for (
    let i = 5;
    i <= (facebookPosts.word_chart ? facebookPosts.word_chart.length : 0);
    i += 5
  ) {
    wordCountOptions.push({ value: i, label: language === 'ar' ? `${i} كلمة` : `${i} Words` });
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: language === 'ar' ? 'right' : 'left',
    }),
    control: (provided) => ({
      ...provided,
      direction: language === 'ar' ? 'rtl' : 'ltr',
    }),
    singleValue: (provided) => ({
      ...provided,
      direction: language === 'ar' ? 'rtl' : 'ltr',
    }),
  };

  return (
    <div className="CloudHashtagPlatformsWordTW">
      <div className="select_CloudHashtagPlatformsWordTW">
        <Select
          options={wordCountOptions}
          value={wordCountOptions.find((option) => option.value === wordCount)}
          onChange={(selectedOption) => setWordCount(selectedOption.value)}
          styles={customStyles}
        />
      </div>
      <div ref={chartRef} style={{ width: "90%", height: "500px" }} />
    </div>
  );
};

export default CloudHashtagPlatformsWord;
