import HomeMenu from "../../../pages/Public/HomeMenu";
import MenuTop from "../../../components/SearchAll/Public/MenuTop";
import { ThemeContext, LanguageContext } from "../../../App";
import React, { useState, useContext, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";

function TemplatesBulletin({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  setSelectedMenuItem,
  idUserName,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  return (
    <div className="search_all">
      <div
        className={`search_all_page ${
          language === "ar" ? "search_all_page_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <HomeMenu
          saveLoginStatus={saveLoginStatus}
          setIsSubmitted={setIsSubmitted}
          isSuperUser={isSuperUser}
          isDefultDB={isSuperUser}
        />
        <div
          className={`body_search_all ${theme === "dark" ? "dark_mode" : ""}`}
        >
          <div className="header_search_all">
          <MenuTop
              onSelectMenuItem={setSelectedMenuItem}
              username={idUserName}
              saveLoginStatus={saveLoginStatus}
              setIsSubmitted={setIsSubmitted}
            />
          </div>
          <div className="header_filter_maker">
            <div className="header_filter_maker_body">
            <button> <AddIcon /></button>
                <p>قائمة النقاط</p>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  );
}

export default TemplatesBulletin;
