import axios from 'axios'
import { base_url, fetch_word_filter_url, edit_word_filter_url, delete_word_filter_url, tokenName } from './config_backend';
import { getToken } from "./Login_backend"

export const deleteWordFilter = async (projectName, word) => {



  var hasError = false;
  var isLoggedIn = true;
  var status = 200

  var errorText = "";
  var data = undefined

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false
    status = 401
    hasError = true
    return { hasError, errorText, data, status, isLoggedIn }
  }

  var url = base_url + delete_word_filter_url



  await axios.get(url, {
    params: {
      projectName,
      word
    },
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Authorization": "Bearer " + String(getToken().access)
    },
  })

    .then(function (response) {
      if (response !== undefined) data = response
    }).catch(function (error) {
      var response = error.response
      errorText = response.request.response

      hasError = true
      status = response.status
      if (response.status === 500) {
        errorText = "Server Error ... "
      }
      else if (errorText.includes("Authentication") || errorText.includes("credentials")) {
        isLoggedIn = false
        localStorage.setItem("isLogged", "false");

      }
    }

    )


  return { hasError, errorText, data, status, isLoggedIn }
}

export const editWordFilter = async (projectName, word) => {



  var hasError = false;
  var isLoggedIn = true;
  var status = 200

  var errorText = "";
  var data = undefined

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false
    status = 401
    hasError = true
    return { hasError, errorText, data, status, isLoggedIn }
  }

  var url = base_url + edit_word_filter_url



  await axios.post(url, {
    projectName, word
  }, {

    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Authorization": "Bearer " + String(getToken().access)
    },
  })

    .then(function (response) {
      if (response !== undefined) data = response
    }).catch(function (error) {
      var response = error.response
      errorText = response.request.response

      hasError = true
      status = response.status
      if (response.status === 500) {
        errorText = "Server Error ... "
      }
      else if (errorText.includes("Authentication") || errorText.includes("credentials")) {
        isLoggedIn = false
        localStorage.setItem("isLogged", "false");

      }
    }

    )


  return { hasError, errorText, data, status, isLoggedIn }
}


export const fetchWordFilter = async (projectName) => {



  var hasError = false;
  var isLoggedIn = true;
  var status = 200

  var errorText = "";
  var data = undefined

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false
    status = 401
    hasError = true
    return { hasError, errorText, data, status, isLoggedIn }
  }

  var url = base_url + fetch_word_filter_url



  await axios.get(url, {
    params: {
      projectName
    },

    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Authorization": "Bearer " + String(getToken().access)
    },
  })

    .then(function (response) {
      if (response !== undefined) data = response
    }).catch(function (error) {
      var response = error.response
      errorText = response.request.response

      hasError = true
      status = response.status
      if (response.status === 500) {
        errorText = "Server Error ... "
      }
      else if (errorText.includes("Authentication") || errorText.includes("credentials")) {
        isLoggedIn = false
        localStorage.setItem("isLogged", "false");

      }
    }

    )


  return { hasError, errorText, data, status, isLoggedIn }
}