import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../App";

function ReleaseProcessHeaderMenuTw({
  language,
  setSelectedDateRange,
  twitterPosts,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  const [all_posts_chart_monthly, setall_posts_chart_monthly] = useState([]);

  useEffect(() => {
    if (twitterPosts && twitterPosts.all_posts_chart_monthly) {
      setall_posts_chart_monthly(twitterPosts.all_posts_chart_monthly);
    }
  }, [twitterPosts]);

  const determineMenu = useCallback(
    (count) => {
      if (count > 4) {
        return {
          submenu: [
            { Key: 1, Value: language === "ar" ? "أسبوعي" : "weekly" },
            { Key: 2, Value: language === "ar" ? "شهريا" : "monthly" },
          ],
        };
      } else if (count >= 2 && count <= 4) {
        return {
          submenu: [{ Key: 1, Value: language === "ar" ? "أسبوعي" : "weekly" }],
        };
      } else {
        return {
          submenu: [{ Key: 0, Value: language === "ar" ? "يوميًا" : "daily" }],
        };
      }
    },
    [language]
  );

  const [menu, setMenu] = useState(
    determineMenu(all_posts_chart_monthly.length)
  );
  const [selectedInfo, setSelectedInfo] = useState(menu.submenu[0].Key);
  const [isOpenInfo, setIsOpenInfo] = useState(false);

  useEffect(() => {
    const newMenu = determineMenu(all_posts_chart_monthly.length);
    setMenu(newMenu);
    setSelectedInfo(newMenu.submenu[0].Key);
    setSelectedDateRange(newMenu.submenu[0].Key);
  }, [all_posts_chart_monthly, language, setSelectedDateRange, determineMenu]);

  const handleToggleDropdownInfo = () => {
    setIsOpenInfo(!isOpenInfo);
  };

  const handleSelectInfo = (value) => {
    const selected = menu.submenu.find((option) => option.Key === value);
    if (selected) {
      setSelectedInfo(selected.Key);
      setIsOpenInfo(false);
      setSelectedDateRange(selected.Key);
    } else {
      setSelectedInfo(menu.submenu[0].Key);
      setSelectedDateRange(menu.submenu[0].Key);
    }
  };

  useEffect(() => {
    const closeMenuOnClickOutside = (e) => {
      if (
        isOpenInfo &&
        e.target &&
        !e.target.closest(".choose_drop_result_search")
      ) {
        setIsOpenInfo(false);
      }
    };

    document.addEventListener("click", closeMenuOnClickOutside);

    return () => {
      document.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, [isOpenInfo]);

  return (
    <div className="release_header_drop_result_search">
      <div id="dropdown_menu_result_search">
        <div className="choose_drop_result_search">
          <div
            className={`dropdown_label_result_search ${
              theme === "dark" ? "dark_mode" : ""
            }`}
            onClick={handleToggleDropdownInfo}
          >
            {menu.submenu.find((option) => option.Key === selectedInfo)?.Value}
            <span
              className={`arrow_filter_result_search_new ${
                isOpenInfo ? "up" : "down"
              } ${
                language === "ar"
                  ? "arrow_filter_result_search_arabic"
                  : "arrow_filter_result_search_en"
              }`}
            >
              ⮛
            </span>
          </div>
          {isOpenInfo && (
            <ul
              className={`dropdown_menu_result_search release ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              {menu.submenu.map((e, key) => (
                <li
                  className={`dropdown_menu_result_search_li ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                  key={key}
                  onClick={() => handleSelectInfo(e.Key)}
                >
                  {e.Value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReleaseProcessHeaderMenuTw;
