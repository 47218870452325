import axios from 'axios'
import { base_url, login_url , refresh_login_url , tokenName} from './config_backend';



export const logout = ()=>{
    localStorage.removeItem(tokenName)
}

export const getToken = ()=>{
    return JSON.parse(localStorage.getItem(tokenName))
}

export const refreshToken = async () => {


    var hasError = false;
    var errorText = "";

    await axios.post(base_url + refresh_login_url,{
        refresh: getToken().refresh,
    }, {
        withCredentials: true,
        headers: { 
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Headers' : '*',
        },
      })
  
      .then(function (response) {
        //console.log("Refreshing..." , response.data)
        localStorage.setItem(tokenName , JSON.stringify(response.data))
      }).catch(function (error) {
        var response = error.response
        var status = response.request.status
        errorText = JSON.parse(response.request.response)
        if(errorText.hasOwnProperty("password")){
            errorText = " password is required"
        }
        else if (errorText.hasOwnProperty("username")){
            errorText = " username is required"

        }
        else if (errorText.hasOwnProperty("non_field_errors")){
            errorText = "Invalid Username/Password"
        }
        hasError = true

        }

      )

      
      return {hasError , errorText }
}

export const authenticate = async (username , password) => {


    var hasError = false;
    var errorText = "";

    await axios.post(base_url + login_url,{
        username: username,
        password: password
    }, {
        withCredentials: true,
        headers: { 
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Headers' : '*'},
      })
  
      .then(function (response) {
        if(response === undefined) return
        localStorage.setItem(tokenName , JSON.stringify(response.data))
      }).catch(function (error) {
        var response = error.response
        var status = response.request.status
        errorText = JSON.parse(response.request.response)
        if(errorText.hasOwnProperty("password")){
            errorText = " password is required"
        }
        else if (errorText.hasOwnProperty("username")){
            errorText = " username is required"

        }
        else if (errorText.hasOwnProperty("non_field_errors")){
            errorText = "Invalid Username/Password"
        }
        hasError = true

        }

      )

      
      return {hasError , errorText }
}
