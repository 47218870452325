import React, { useEffect, useRef, useState, useContext } from "react";
import * as echarts from "echarts/lib/echarts";
import "echarts-wordcloud";
import Select from "react-select";
import { LanguageContext } from "../../../App";

const CloudEmojiCloud = ({ facebookPosts }) => {
  const chartRef = useRef(null);
  const [wordCount, setWordCount] = useState(facebookPosts.emojie_chart.length);
  const { language } = useContext(LanguageContext);

  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(parseInt(unicode.replace("U+", ""), 16));
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  useEffect(() => {
    if (
      facebookPosts &&
      facebookPosts.emojie_chart &&
      facebookPosts.emojie_chart.length > 0
    ) {
      const myChart = echarts.init(chartRef.current);

      const wordCloudData = facebookPosts.emojie_chart.map((wordItem) => {
        const emoji = convertUnicodeToEmoji(wordItem[0]);
        if (emoji) {
          return {
            name: emoji,
            value: wordItem[1],
          };
        } else {
          return null;
        }
      }).filter(item => item !== null);

      console.log("wordData:", wordCloudData);

      const option = {
        tooltip: {},
        series: [
          {
            type: "wordCloud",
            gridSize: 10,
            sizeRange: [12, 50],
            rotationRange: [0, 0],
            shape: "diamond",
            textStyle: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              color: function () {
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                  ].join(",") +
                  ")"
                );
              },
            },
            data: wordCloudData.slice(0, wordCount),
          },
        ],
      };

      myChart.setOption(option);

      window.addEventListener("resize", () => {
        myChart.resize();
      });

      return () => {
        myChart.dispose();
      };
    }
  }, [wordCount, facebookPosts]);

  const wordCountOptions = [
    { value: -1, label: language === 'ar' ? "عرض جميع ایموجي" : "Show All Emojis" }
  ];

  for (
    let i = 5;
    i <= (facebookPosts.emojie_chart ? facebookPosts.emojie_chart.length : 0);
    i += 5
  ) {
    wordCountOptions.push({ value: i, label: language === 'ar' ? `${i} ایموجي` : `${i} Emojis` });
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: language === 'ar' ? 'right' : 'left',
    }),
    control: (provided) => ({
      ...provided,
      direction: language === 'ar' ? 'rtl' : 'ltr',
    }),
    singleValue: (provided) => ({
      ...provided,
      direction: language === 'ar' ? 'rtl' : 'ltr',
    }),
  };

  return (
    <div className="CloudHashtagPlatformsWordTW">
      <div className="select_CloudHashtagPlatformsWordTW">
        <Select
          options={wordCountOptions}
          value={wordCountOptions.find((option) => option.value === wordCount)}
          onChange={(selectedOption) => setWordCount(selectedOption.value)}
          styles={customStyles}
        />
      </div>
      <div ref={chartRef} style={{ width: "80%", height: "400px" }} />
    </div>
  );
};

export default CloudEmojiCloud;
