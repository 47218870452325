import axios from "axios";
import {
  base_url,
  create_projects_url,
  tokenName,
  projects_list_url,
  delete_project_url,
  add_new_project,
  file_excel_confirm_list,
  file_excel_confirm_accept,
  file_excel_confirm_delete,
  projects_price_list,
  projects_price_add,
  projects_price_edit,
} from "../../config_backend";
import { getToken } from "../../Login_backend";

export const addProjectLists = async (
  NumberProject,
  ProjectCreator,
  NameProject,
  ProjectApplicant
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("numberproject", NumberProject);
  formData.append("projectcreator", ProjectCreator);
  formData.append("nameproject", NameProject);
  formData.append("projectapplicant", ProjectApplicant);
  // formData.append("projectgroup", ProjectGroup);
  formData.append("checkbox_public", 0);
  formData.append("checkbox_private", 0);
  // formData.append("projectdate", "d");
  // formData.append("task_date", "d");
  // formData.append("task_time", "d");

  // formData.append("ProjectAccessUsers", ProjectAccessUsers);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + create_projects_url, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getProjectLists = async (nextLink) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + projects_list_url;
  if (nextLink && nextLink !== "") url = nextLink;
  console.log("req api next", nextLink);
  await axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const deleteProjectLists = async (nameproject) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .delete(base_url + delete_project_url + nameproject, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addProjectListsNew = async (
  NameProject,
  start_date,
  end_date,
  Duration,
  checkbox_google,
  checkbox_x,
  checkbox_instagram,
  checkbox_facebook,
  selectedUsers
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("nameproject", NameProject);
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  formData.append("duration", Duration);
  formData.append("google", checkbox_google ? 1 : 0);
  formData.append("twitter", checkbox_x ? 1 : 0);
  formData.append("instagram", checkbox_instagram ? 1 : 0);
  formData.append("facebook", checkbox_facebook ? 1 : 0);

  const selectedUserIds = selectedUsers.map((user) => user.id);
  formData.append("id", selectedUserIds);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_new_project, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getProjectListsExcel = async (nextLink, userid) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + file_excel_confirm_list;
  if (nextLink && nextLink !== "") url = nextLink;
  await axios
    .get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
      params: {
        userid: userid,
      }
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const AcceptWordListExcel = async (id_list) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (!localStorage.getItem(tokenName)) {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(
      base_url + file_excel_confirm_accept,
      { id_list },
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          Authorization: "Bearer " + String(getToken().access),
        },
      }
    )
    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const DeleteWordListExcel = async (id_list) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (!localStorage.getItem(tokenName)) {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(
      base_url + file_excel_confirm_delete,
      { id_list },
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          Authorization: "Bearer " + String(getToken().access),
        },
      }
    )
    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const addPriceProject = async (
  ProjectId,
  PriceFacebook,
  PriceX,
  PriceInstagram,
  PriceGoogle
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("project_name", ProjectId);
  formData.append("price_facebook", PriceFacebook);
  formData.append("price_x", PriceX);
  formData.append("price_instagram", PriceInstagram);
  formData.append("price_google", PriceGoogle);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + projects_price_add, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const getProjectPriceLists = async (nextLink) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + projects_price_list;
  if (nextLink && nextLink !== "") url = nextLink;
  console.log("req api next", nextLink);
  await axios
    .get(url, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const editPriceProject = async (
  PriceFacebookEdit,
  PriceXEdit,
  PriceInstagramEdit,
  PriceGoogleEdit,
  billId
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  let formData = new FormData();
  formData.append("id", billId);
  formData.append("price_facebook", PriceFacebookEdit);
  formData.append("price_x", PriceXEdit);
  formData.append("price_instagram", PriceInstagramEdit);
  formData.append("price_google", PriceGoogleEdit);

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .put(base_url + projects_price_edit + billId + "/" , formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};

export const CreateProjectVIP = async (
  NameProject,
  start_date,
  end_date,
  Duration,
  checkbox_google,
  checkbox_x,
  checkbox_instagram,
  checkbox_facebook,
  selectedUserIds 
) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;
  var errorText = "";

  let formData = new FormData();
  formData.append("nameproject", NameProject);
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  formData.append("duration", Duration);
  formData.append("google", checkbox_google ? 1 : 0);
  formData.append("twitter", checkbox_x ? 1 : 0);
  formData.append("instagram", checkbox_instagram ? 1 : 0);
  formData.append("facebook", checkbox_facebook ? 1 : 0);

  selectedUserIds.forEach((id, index) => {
    formData.append(`id[${index}]`, id);
  });

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  await axios
    .post(base_url + add_new_project, formData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })
    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
