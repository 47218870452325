import React, { useState, useEffect, useContext } from "react";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import facebook_icon from "../../../images/facebook.png";
import x_icon from "../../../images/twitter_x_35.png";
import instagram_icon from "../../../images/instagram_35.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import EmotionalAnalysisHeaderMenu from "./EmotionalAnalysisHeaderMenu";
import EmotionalAnalysisChart from "./EmotionalAnalysisChart";
import EmotionalAnalysisRadarChart from "./EmotionalAnalysisRadarChart";
import { ThemeContext, LanguageContext } from "../../../App";
import { HashLoader } from "react-spinners";
import EmotionalAnalysisRadarChartTW from "./EmotionalAnalysisRadarChartTW";
import EmotionalAnalysisChartTW from "./EmotionalAnalysisChartTW";
import EmotionalAnalysisRadarChartIN from "./EmotionalAnalysisRadarChartIN";
import EmotionalAnalysisChartIN from "./EmotionalAnalysisChartIN";
// import DateTime from "react-datetime";
// import filter_icon from "../../../images/filter_icon.png";

function EmotionalAnalysis({
  selectedItem,
  facebookPosts,
  isFacebookActive,
  showAllBtnEmotional,
  visibleFBEmotional,
  loadingStateFB,
  isTwitterActive,
  visibleTWEmotional,
  twitterPosts,
  loadingStateTW,
  loadingStateIN,
  visibleINEmotional,
  instagramPosts,
  isInstagramActive,
  isDefultDB,
  onEmotionClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // تعیین وضعیت اولیه لود دو کامپوننت

  const [currentSelectedMention, setCurrentSelectedMention] =
    useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedMention(selectedItem);
  }, [selectedItem]);

  // filter date Facebook

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  // };

  // filter date Twitter

  // const [startDateTW, setStartDateTW] = useState(null);
  // const [endDateTW, setEndDateTW] = useState(null);

  // const handleStartDateChangeTW = (date) => {
  //   setStartDateTW(date);
  // };

  // const handleEndDateChangeTW = (date) => {
  //   setEndDateTW(date);
  // };

  // filter date Instagram

  // const [startDateIN, setStartDateIN] = useState(null);
  // const [endDateIN, setEndDateIN] = useState(null);

  // const handleStartDateChangeIN = (date) => {
  //   setStartDateIN(date);
  // };

  // const handleEndDateChangeIN = (date) => {
  //   setEndDateIN(date);
  // };

  // const [isFilterOpenFB, setIsFilterOpenFB] = useState(false);
  // const [isFilterOpenTW, setIsFilterOpenTW] = useState(false);
  // const [isFilterOpenIN, setIsFilterOpenIN] = useState(false);

  return (
    <div
      className={`emotional_analysis ${theme === "dark" ? "dark_mode" : ""}`}
    >
      {/* {isDefultDB === "default" || isDefultDB === "demo" ? (
        <>
          {language === "ar" ? (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                يمنحك الاشتراك المميز الحصول على هذه الخدمة
              </p>
            </div>
          ) : (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                To View This Section, You Need to Purchase a Premium Account.
              </p>
            </div>
          )}
        </>
      ) : (
        <> */}
          {showAllBtnEmotional && (
            <div className="emotional_analysis_page">
              {currentSelectedMention === "emotional_radar" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <>
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWEmotional && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>إكس</span>
                                    <img src={x_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          تحميل
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={x_icon} alt="" />
                                    <span>X</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Download
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <EmotionalAnalysisHeaderMenu />
                        </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisRadarChartTW
                                    twitterPosts={twitterPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isFacebookActive && (
                    <>
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBEmotional && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>فيسبوك</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={facebook_icon} alt="" />
                                    <span>Facebook</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <EmotionalAnalysisHeaderMenu />
                        </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisRadarChart
                                    facebookPosts={facebookPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isInstagramActive && (
                    <>
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINEmotional && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>إنستغرام</span>
                                    <img src={instagram_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={instagram_icon} alt="" />
                                    <span>Instagram</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <EmotionalAnalysisHeaderMenu />
                        </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisRadarChartIN
                                    instagramPosts={instagramPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {currentSelectedMention === "emotional_linear" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <>
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWEmotional && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>إكس</span>
                                    <img src={x_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={x_icon} alt="" />
                                    <span>X</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                        <EmotionalAnalysisHeaderMenu />
                      </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisChartTW
                                    twitterPosts={twitterPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isFacebookActive && (
                    <>
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBEmotional && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>فيسبوك</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          تحميل
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={facebook_icon} alt="" />
                                    <span>Facebook</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Download
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                        <EmotionalAnalysisHeaderMenu />
                      </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisChart
                                    facebookPosts={facebookPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isInstagramActive && (
                    <>
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINEmotional && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>إنستغرام</span>
                                    <img src={instagram_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          تحميل
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <img src={instagram_icon} alt="" />
                                    <span>Post</span>
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Download
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                {/* <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <EmotionalAnalysisHeaderMenu />
                        </div> */}
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <EmotionalAnalysisChartIN
                                    instagramPosts={instagramPosts}
                                    onEmotionClick={onEmotionClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        {/* </>
      )} */}
    </div>
  );
}

export default EmotionalAnalysis;
