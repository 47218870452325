/* eslint-disable array-callback-return */
import "../../css/Facebook/CompetitorAnalysisFB.css";
import Excel from "../../images/excel.png";
import Star from "../../images/Star.png";
import Like from "../../images/like.png";
import Comment from "../../images/comment.png";
import filter_icon from "../../images/Filter4.png";
import filter_green from "../../images/filter_green.png";
import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { getMyShow } from "../../backend/Dashboard_backend";
import { getPostsByWord } from "../../backend/Post_Search_backend";
import { setPostAction } from "../../backend/Senior_Post_Action_backend";
import { setPostAction as twitterSetPostAction } from "../../backend/twitter/Senior_Post_Action_backend";
import { setPostAction as instagramSetPostAction } from "../../backend/instagram/Senior_Post_Action_backend";
import { setPostAction as googleSetPostAction } from "../../backend/searchGoogle/Senior_Post_Action_backend";
import {
  addSubTopic,
  addTopic,
  deleteSubTopic,
  deleteTopic,
} from "../../backend/Admin/TopicManager/TopicManager";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  base_url,
  senior_post_search_url,
  // fetch_word_filter_url,
  // edit_word_filter_url,
  facebook_profile_url,
  senior_twitter_post_search_url,
  senior_instagram_post_search_url,
  senior_web_search_post_search_url,
} from "../../backend/config_backend";
import axios from "axios";
import { getToken } from "../../backend/Login_backend";
import { HashLoader } from "react-spinners";
import { ThemeContext, LanguageContext } from "../../App";
import ExcelJS from "exceljs";
import Star_gold from "../../images/star_gold.png";
import Excel_green from "../../images/excel_green.png";
import broom_icon from "../../images/broom_icon.png";
import avatar_user from "../../images/avatar_user.png";
import checkbox_green from "../../images/checkbox_green.png";
import checkbox_gray from "../../images/checkbox_gray.png";
import DateTime from "react-datetime";
import Swal from "sweetalert2";
import Sad from "../../images/minus_gray.png";
import Sad_yellow from "../../images/minus_red.png";
import Happy from "../../images/plus_gray.png";
import Happy_yellow from "../../images/plus_green.png";
import indifferent from "../../images/zero_gray_light.png";
import indifferent_yellow from "../../images/zero_gray.png";
import tick_gray from "../../images/tick_gray_25.png";
import tick_green from "../../images/tick_green_25.png";
import sticker_square from "../../images/sticker_square.png";
import sticker_square_gray from "../../images/sticker_square_gray.png";
import broom_new from "../../images/broom_new.png";
import AnalysisMenuProjectSubject from "../../components/Menu/AnalysisMenuProjectSubject";
import AnalysisMenuMainTopic from "../../components/Menu/AnalysisMenuMainTopic";
import AnalysisMenuSubtopic from "../../components/Menu/AnalysisMenuSubtopic";
import AnalysisMenuEmotion from "../../components/Menu/AnalysisMenuEmotion";
import AnalysisMenuAxis from "../../components/Menu/AnalysisMenuAxis";
import { getEmotionLists } from "../../backend/Admin/EmotionManager/EmotionManager";
import { getAxisLists } from "../../backend/Admin/AxisManager/AxisManager";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import base_icon from "../../images/show_site_menu.png";
import logout_icon from "../../images/logout_menu.png";
import show_site_menu from "../../images/base_menu.png";
import admin_menu_icon from "../../images/admin_menu_icon.png";
import senior_menu_icon from "../../images/tools_menu_analysis_senior_35.png";
import logo from "../../images/new_logo_menu.png";
import { logout } from "../../backend/Login_backend";
import multiple_red from "../../images/multiple_red.png";
import AddchartIcon from "@mui/icons-material/Addchart";
import arrow_down from "../../images/arrow_down.png";
import edit_icon from "../../images/pencil.png";
import delete_icon from "../../images/multiple_red.png";
import { deletePost } from "../../backend/Post_Delete_backend";
import { deletePost as twitterDeletePost } from "../../backend/twitter/Twitter_Post_Delete_backend";
import { deletePost as instagramDeletePost } from "../../backend/instagram/Instagram_Post_Delete_backend";
import { deletePost as googleDeletePost } from "../../backend/searchGoogle/Google_Post_Delete_backend";
import url_icon from "../../images/url_icon_40.png";
import { editPost } from "../../backend/Post_Edit_backend";
import { editPost as twitterEditPost } from "../../backend/twitter/Twitter_Post_Edit_backend";
import { editPost as instagramEditPost } from "../../backend/instagram/Instagram_Post_Edit_backend";
import { editPost as googleEditPost } from "../../backend/searchGoogle/Google_Post_Edit_backend";
import eye_green from "../../images/eye_green_22.png";
import eye_gray from "../../images/eye_gray_22.png";
import {
  editFakeChart,
  fetchFakeChart,
} from "../../backend/Fake_Chart_Edit_backend";
import {
  deleteWordFilter,
  editWordFilter,
  fetchWordFilter,
} from "../../backend/Word_Filter_Edit_backend";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Approver from "../../images/Approvert_img_analysis_20.png";
import tager from "../../images/tager_img_analysis_20.png";
import AnalysisMenuAccent from "../../components/Menu/AnalysisMenuAccent";
import { getAccentList } from "../../backend/Admin/AccentManager/AccentManager";

function SeniorConfirmPage({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  isSeniorAnalyzer,
}) {
  //   // dark mode
  const { theme, toggleTheme } = useContext(ThemeContext);

  //   // arabic

  const { language, toggleLanguage } = useContext(LanguageContext);

  const handleLanguageToggle = () => {
    toggleLanguage();
  };

  // logout

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();

    saveLoginStatus(false);
    setIsSubmitted(false);
    navigate("/");
  };

  const [subject_projects, setSubject_projects] = useState([]);

  const [emotionsData, setEmotionsData] = useState([]);
  const [axisData, setAxisData] = useState([]);
  const [accentData, setAccentData] = useState([]);

  const [fetchedSubjects, setFetchedSubjects] = useState([]);
  const [fetchedApi, setfetchedApi] = useState(false);
  const [postByWordsData, setpostByWordsData] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");

  const [selectedSubProject, setSelectedSubProject] = useState("");
  const [selectedSubSubProject, setSelectedSubSubProject] = useState("");
  const [selectedEmotion, setSelectedEmotion] = useState("");
  const [selectedAxis, setSelectedAxis] = useState("");
  const [selectedAccent, setSelectedAccent] = useState("");

  const [needLogin, setneedLogin] = useState(false);

  const [loadingState, setLoadingState] = useState(true);
  const [loadingMoreState, setLoadingMoreState] = useState(false);

  const [loadedPostsNumber, setLoadedPostsNumber] = useState(100);

  const [fakeChartData, setFakeChartData] = useState({});

  const handleOpenStatisticsImpression = async (openStatus) => {
    setIsOpenStatisticsImpression(!openStatus);
    if (!openStatus) {
      var chartData = await handleFetchFakeChart("action_chart");
      chartData =
        chartData && chartData.data.data && chartData.data.data.chartText
          ? chartData.data.data
          : false;
      console.log("handleFetchFakeChart", chartData);
      setFakeChartData({ ...fakeChartData, action_chart: chartData });
      console.log("---handleOpenStatisticsImpression", fakeChartData);
      setIsCheckedImpression(chartData ? chartData.isActive : false);
    }
  };

  const handleFetchFakeChart = async (chartName) => {
    var socialNetwork = selectedOptionSocial.label.toLowerCase();
    var projectName = selectedProjectName;
    var postsByWord = await fetchFakeChart(
      chartName,
      projectName,
      socialNetwork
    );
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      return postsByWord;
    }
  };

  const [impressionFakeChartPlus, setImpressionFakeChartPlus] = useState(0);
  const [impressionFakeChartMinus, setImpressionFakeChartMinus] = useState(0);
  const [impressionFakeChartZero, setImpressionFakeChartZero] = useState(0);

  const handleCheckedImpression = (checked) => {
    console.log("handleCheckedImpression", checked);
    if (checked == false) {
      handleImpressionFakeChartSend(false);
      if (fakeChartData && fakeChartData.action_chart) {
        let temp = fakeChartData.action_chart;
        temp.isActive = false;
        setFakeChartData({ ...fakeChartData, action_chart: temp });
      }
    }
    setIsCheckedImpression(checked);
    // if (checked)
  };

  const handleOpenStatisticsAxis = async (openStatus) => {
    setIsOpenStatisticsAxis(!openStatus);
    if (!openStatus) {
      var chartData = await handleFetchFakeChart("axis_chart");
      chartData =
        chartData && chartData.data.data && chartData.data.data.chartText
          ? chartData.data.data
          : false;
      console.log("handleFetchFakeChart", chartData);
      setFakeChartData({ ...fakeChartData, axis_chart: chartData });
      console.log("---handleOpenStatisticsAxis", fakeChartData);
      setIsCheckedAxis(chartData ? chartData.isActive : false);
    }
  };

  const handleCheckedAxis = (checked) => {
    console.log("handleCheckedAxis", checked);
    if (checked == false) {
      handleAxisFakeChartSend(false);
      if (fakeChartData && fakeChartData.axis_chart) {
        let temp = fakeChartData.axis_chart;
        temp.isActive = false;
        setFakeChartData({ ...fakeChartData, axis_chart: temp });
      }
    }
    setIsCheckedAxis(checked);
    // if (checked)
  };

  const handleImpressionFakeChartSend = async (isActive) => {
    var chartData = {
      happy: impressionFakeChartPlus,
      sad: impressionFakeChartMinus,
      indifferent: impressionFakeChartZero,
    };
    var chartName = "action_chart";
    var projectName = selectedProjectName;
    var socialNetwork = selectedOptionSocial.label.toLowerCase();
    var postsByWord = await editFakeChart(
      chartName,
      chartData,
      isActive,
      projectName,
      socialNetwork
    );
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Chart Edited");
      } else {
        toast.success("تم تحرير الرسم البياني");
      }
    }
  };

  const [axisFakeChartInputs, setAxisFakeChartInputs] = useState({});

  const handleAxisFakeChartInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var temp = { ...axisFakeChartInputs };
    temp[name] = value;
    setAxisFakeChartInputs(temp);
  };

  const handleAxisFakeChartSend = async (isActive) => {
    var chartData = {};
    Object.entries(axisFakeChartInputs).forEach(([name, value]) => {
      chartData[name] = value;
    });

    // console.log("handleAxisFakeChartSend" , chartData)

    var chartName = "axis_chart";
    var projectName = selectedProjectName;
    var socialNetwork = selectedOptionSocial.label.toLowerCase();

    var postsByWord = await editFakeChart(
      chartName,
      chartData,
      isActive,
      projectName,
      socialNetwork
    );
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Chart Edited");
      } else {
        toast.success("تم تحرير الرسم البياني");
      }
    }
  };

  const handleOpenStatisticsEmotion = async (openStatus) => {
    setIsOpenStatisticsEmotions(!openStatus);
    if (!openStatus) {
      var chartData = await handleFetchFakeChart("emotion_chart");
      chartData =
        chartData && chartData.data.data && chartData.data.data.chartText
          ? chartData.data.data
          : false;
      console.log("handleFetchFakeChart", chartData);
      setFakeChartData({ ...fakeChartData, emotion_chart: chartData });
      console.log("---handleOpenStatisticsEmotion", fakeChartData);
      setIsCheckedEmotions(chartData ? chartData.isActive : false);
    }
  };

  const handleCheckedEmotion = (checked) => {
    console.log("handleCheckedEmotion", checked);
    if (checked === false) {
      handleEmotionFakeChartSend(false);
      if (fakeChartData && fakeChartData.emotion_chart) {
        let temp = fakeChartData.emotion_chart;
        temp.isActive = false;
        setFakeChartData({ ...fakeChartData, emotion_chart: temp });
      }
    }
    setIsCheckedEmotions(checked);
    // if (checked)
  };

  const [emotionFakeChartInputs, setEmotionFakeChartInputs] = useState({});

  const handleEmotionFakeChartInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var temp = { ...emotionFakeChartInputs };
    temp[name] = value;
    setEmotionFakeChartInputs(temp);
  };

  const handleEmotionFakeChartSend = async (isActive) => {
    var chartData = {};
    Object.entries(emotionFakeChartInputs).forEach(([name, value]) => {
      chartData[name] = value;
    });

    // console.log("handleAxisFakeChartSend" , chartData)

    var chartName = "emotion_chart";
    var projectName = selectedProjectName;
    var socialNetwork = selectedOptionSocial.label.toLowerCase();

    var postsByWord = await editFakeChart(
      chartName,
      chartData,
      isActive,
      projectName,
      socialNetwork
    );
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Chart Edited");
      } else {
        toast.success("تم تحرير الرسم البياني");
      }
    }
  };

  const handleOpenStatisticsTopic = async (openStatus) => {
    setIsOpenStatisticsTopicSubTopic(!openStatus);
    if (!openStatus) {
      var chartData = await handleFetchFakeChart("topic_chart");
      chartData =
        chartData && chartData.data.data && chartData.data.data.chartText
          ? chartData.data.data
          : false;
      console.log("handleFetchFakeChart", chartData);
      setFakeChartData({ ...fakeChartData, topic_chart: chartData });
      console.log("---handleOpenStatisticsTopic", fakeChartData);
      setIsCheckedTopicSubTopic(chartData ? chartData.isActive : false);
    }
  };

  const handleCheckedTopic = (checked) => {
    console.log("handleCheckedTopic", checked);
    if (checked === false) {
      handleTopicFakeChartSend(false);
      if (fakeChartData && fakeChartData.topic_chart) {
        let temp = fakeChartData.topic_chart;
        temp.isActive = false;
        setFakeChartData({ ...fakeChartData, topic_chart: temp });
      }
    }
    setIsCheckedTopicSubTopic(checked);
    // if (checked)
  };

  const [topicFakeChartInputs, setTopicFakeChartInputs] = useState({});

  const handleTopicFakeChartInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var temp = { ...topicFakeChartInputs };
    temp[name] = value;
    console.log("handleTopicFakeChartInputChange: ", name, value, temp);
    setTopicFakeChartInputs(temp);
  };

  const handleTopicFakeChartSend = async (isActive) => {
    var chartData = {};
    Object.entries(topicFakeChartInputs).forEach(([name, value]) => {
      var select = "happy";
      if (name in topicFakeChartSelects) select = topicFakeChartSelects[name];
      name = name.split("^");
      var topic = name[0];
      var subTopic = name[1];
      if (!(topic in chartData)) chartData[topic] = {};
      chartData[topic][subTopic] = { number: value, select: select };
    });

    console.log("handleTopicFakeChartSend", chartData);
    var chartName = "topic_chart";
    var projectName = selectedProjectName;
    var socialNetwork = selectedOptionSocial.label.toLowerCase();

    var postsByWord = await editFakeChart(
      chartName,
      chartData,
      isActive,
      projectName,
      socialNetwork
    );
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Chart Edited");
      } else {
        toast.success("تم تحرير الرسم البياني");
      }
    }
  };

  const [topicFakeChartSelects, setTopicFakeChartSelects] = useState({});

  const handleSelectChangeTopic = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var temp = { ...topicFakeChartSelects };
    temp[name] = value;
    console.log("handleSelectChangeTopic: ", name, value, temp);
    setTopicFakeChartSelects(temp);
  };

  // const handleLoadedPostsNumberChange = (e) => {
  //   setLoadedPostsNumber(e.target.value);
  // };

  //   const handlePostCommentKeyDown = (event, postId) => {
  //     if (event.key === "Enter") {
  //       updatePostAction(postId, "cross", event.target.value);
  //     }
  //   };

  const formatEmojiToNumber = (emoji) => {
    let dict = {
      happy: 1,
      sad: 2,
      indifferent: 3,
    };

    return dict[emoji];
  };

  const formatNumberToEmoji = (number) => {
    let dict = ["happy", "sad", "indifferent"];

    if (number > 0) return dict[number - 1];
    else return undefined;
  };

  const formatStickerToNumber = (sticker) => {
    let dict = {
      tick: 1,
      cross: 2,
    };

    return dict[sticker];
  };

  const formatNumberToSticker = (number) => {
    let dict = {
      1: "tick",
      2: "cross",
    };

    return dict[number];
  };

  // menu header select social

  const [isOpenSelectSocial, setIsOpenSelectSocial] = useState(false);
  const [selectedOptionSocial, setSelectedOptionSocial] = useState({
    label: "Facebook",
  });

  const handleOptionClickSelectSocial = (option) => {
    setSelectedOptionSocial(option);
    setIsOpenSelectSocial(false);
  };

  const toggleMenuSelectSocial = () => {
    setIsOpenSelectSocial(!isOpenSelectSocial);
  };

  // menu header Statistics and figures

  // eslint-disable-next-line no-unused-vars
  const [isOpenStatistics, setIsOpenStatistics] = useState(false);
  const [selectedStatistics, setSelectedStatistics] = useState("AllPosts");
  const [selectedStatisticsBackend, setSelectedStatisticsBackend] =
    useState("default");

  const handleOptionClickStatistics = (option, backend) => {
    setSelectedStatistics(option);
    setSelectedStatisticsBackend(backend);
    setIsOpenStatistics(false);
  };

  const handleFetchActions = (posts) => {
    var emojies = {};
    var stickersTick = {};
    var stickersSelected = [];

    if (posts)
      posts.forEach((element, index) => {
        // let comment = "";
        if (element.action.id) {
          let action = element.action;
          let emoji = formatNumberToEmoji(action.action_state);
          let sticker = formatNumberToSticker(action.agreement_state);
          let confirmed = action.confirmed;
          emojies[element.id] = emoji;
          if (sticker && confirmed) {
            stickersTick[element.id] = true;
            stickersSelected.push(element.id);
          }
        }
      });
    setSelectedEmojis(emojies);
    setIsTickSelected(stickersTick);
    setSelectedTickPosts(stickersSelected);
    // console.log(emojies , stickersTick , stickersSelected)
  };
  const updatePostAction = async (
    postid,
    sticker,
    index,
    comment,
    selectedProject
  ) => {
    let action_state = selectedEmojis[postid];
    action_state = formatEmojiToNumber(action_state);
    let agreement_state = formatStickerToNumber(sticker);
    console.log(
      "🚀 ~ file: SeniorConfirmPage.js:142 ~ updatePostAction ~ postByWordsData:",
      postByWordsData.posts,
      index
    );
    let main_subject = postByWordsData.posts[index].main_subject;
    let sub_subject = postByWordsData.posts[index].sub_subject;
    let emotion = postByWordsData.posts[index].emotion;
    let axis = postByWordsData.posts[index].axis;
    let accent = postByWordsData.posts[index].accent;
    console.log("Hell is Here", selectedStuff);
    if (typeof main_subject === "undefined") {
      main_subject = selectedStuff[postid].main_subject
        ? selectedStuff[postid].main_subject
        : "default";
    }
    if (typeof sub_subject === "undefined") {
      sub_subject = selectedStuff[postid].sub_subject
        ? selectedStuff[postid].sub_subject
        : "default";
    }
    if (typeof emotion === "undefined") {
      emotion = selectedStuff[postid].emotion
        ? selectedStuff[postid].emotion
        : "default";
    }
    if (typeof axis === "undefined") {
      axis = selectedStuff[postid].axis
        ? selectedStuff[postid].axis
        : "default";
    }
    if (typeof accent === "undefined") {
      accent = selectedStuff[postid].accent
        ? selectedStuff[postid].accent
        : "default";
    }
    let dbName = selectedProject;
    console.log(agreement_state, sticker);
    comment = comment ? comment : "";
    if (selectedOptionSocial.label === "Facebook") {
      var postsByWord = await setPostAction(
        postid,
        action_state,
        agreement_state,
        comment,
        main_subject,
        sub_subject,
        emotion,
        axis,
        accent,
        dbName
      );
    } else if (selectedOptionSocial.label === "Twitter") {
      var postsByWord = await twitterSetPostAction(
        postid,
        action_state,
        agreement_state,
        comment,
        main_subject,
        sub_subject,
        emotion,
        axis,
        accent,
        dbName
      );
    } else if (selectedOptionSocial.label === "Instagram") {
      var postsByWord = await instagramSetPostAction(
        postid,
        action_state,
        agreement_state,
        comment,
        main_subject,
        sub_subject,
        emotion,
        axis,
        accent,
        dbName
      );
    } else if (selectedOptionSocial.label === "Google") {
      var postsByWord = await googleSetPostAction(
        postid,
        action_state,
        agreement_state,
        comment,
        main_subject,
        sub_subject,
        emotion,
        axis,
        accent,
        dbName
      );
    }

    //TODO post sub and subsub and emotions
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
    }
  };

  const handleFetchWordFilter = async () => {
    var projectName = selectedProjectName;
    var postsByWord = await fetchWordFilter(projectName);
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      console.log("handleFetchWordFilter", postsByWord);
      setWordFilters(postsByWord.data.data);
    }
  };

  useEffect(() => {
    if (!fetchedApi) return;

    setSelectedSubProject("");
    setSelectedSubSubProject("");
    setSelectedEmotion("");
    setSelectedAxis("");
    setSelectedAccent("");

    // setLoadingState(true)
    handleSearchProject();
    if (subject_projects.length > 0 && selectedProject !== "") {
      console.log(subject_projects, selectedProject);
      setFetchedSubjects(
        subject_projects.find((x) => x.id === selectedProject).subjects
      );
      handleFetchWordFilter();
    }

    // setLoadingState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProject,
    fetchedApi,
    selectedOptionSocial,
    selectedStatisticsBackend,
  ]);

  useEffect(() => {
    const handleStartAxis = async () => {
      var result = await getAxisLists();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        setAxisData(data);

        // setapiData(data);
      }
    };

    const handleStartEmotion = async () => {
      var result = await getEmotionLists();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        setEmotionsData(data);

        // setapiData(data);
      }
    };

    const handleStartAccent = async () => {
      var result = await getAccentList();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        setAccentData(data);

        // setapiData(data);
      }
    };

    const handleStartMyShow = async () => {
      var result = await getMyShow();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // var data = result.data.data;
        setSubject_projects(result.data.data.subject_projects);

        // setapiData(data);
      }
    };

    const fetchData = async () => {
      if (fetchedApi) {
        return;
      }
      console.log("fetchData handleStartMyShow");
      try {
        // await handleStart();
        await handleStartMyShow();
        await handleStartEmotion();
        await handleStartAxis();
        await handleStartAccent();

        setfetchedApi(true);
      } catch (error) {}
    };

    // Run fetchData initially
    try {
      // handleStartMyShow();
      fetchData();
      // handleFirstPostFetch();
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  }, [fetchedApi, language]);

  const handleSearchProject = async () => {
    console.log("going to handleSearchProject");
    try {
      setLoadingState(true);

      var start = "1900-06-01";
      var end = "2040-06-01";
      var word = "";
      var hashtag = "";
      var page_size = 100;
      let projectName = "default";
      let mode = selectedStatisticsBackend;
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      } else if (subject_projects.length > 0) {
        setSelectedProject(subject_projects[0].id);
        setSelectedProjectName(subject_projects[0].name);
        projectName = subject_projects[0].name;
      }
      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + senior_post_search_url;
      if (selectedOptionSocial.label === "Facebook") {
        url = base_url + senior_post_search_url;
      } else if (selectedOptionSocial.label === "Twitter") {
        url = base_url + senior_twitter_post_search_url;
      } else if (selectedOptionSocial.label === "Instagram") {
        url = base_url + senior_instagram_post_search_url;
      } else if (selectedOptionSocial.label === "Google") {
        url = base_url + senior_web_search_post_search_url;
      }
      await axios
        .get(url, {
          params: {
            word,
            startDate: start,
            endDate: end,
            hashtag,
            page_size,
            projectName,
            mode,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
          console.log("🚀 ~ file: AnalysisSearchFB.js:303 ~ data:", data);
          var stuf = {};

          data.data.posts.map((post) => {
            stuf = { ...stuf, [post.id]: post.action };
          });
          setSelectedStuff(stuf);
          setpostByWordsData(data.data);
          handleFetchActions(data.data.posts);
        })
        .catch(function (error) {
          console.log(error);
          var response = error.response;
          errorText = response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        // setpostByWordsData(data);
        setLoadingState(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

 


  const handleLoadMoreClicked = async (e) => {
    if (loadingState === true || loadingState === "") {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    console.log(bottom , loadingState , loadingMoreState)
    if (!bottom) {
      return;
    }
    if (bottom ==true && loadingMoreState== true){
        return;
    }
    if (!postByWordsData.next_link) {
      return;
    }
    // console.log("Loading...");
    setLoadingMoreState(true);
    try {
      var postsByWord = await getPostsByWord(postByWordsData.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...postByWordsData.posts, ...data.posts];
        var stuf = {};
        data.posts.map((post) => {
          stuf = { ...stuf, [post.id]: post.action };
        });
        setSelectedStuff(stuf);
        setpostByWordsData(data);
        handleFetchActions(data.posts);
        setLoadingMoreState(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  // show more text post

  const [expandedStates, setExpandedStates] = useState([]);

  const formatFileName = (name) => {
    return name
      .replaceAll(".", "")
      .replaceAll("%", "")
      .replaceAll("?", "")
      .replaceAll("=", "")
      .replaceAll("/", "")
      .substring(0, 30)
      .toLowerCase();
  };

  // Excel btn All Record Backend
  async function handleAllRecordsExport(recordsNumber) {
    try {
      console.log("handleAllRecordsExport recordsNumber", recordsNumber);
      toast.success(
        language === "en"
          ? "Please wait. It may take a moment"
          : "الرجاء الانتظار. قد يستغرق الأمر لحظة",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
          style: {
            fontSize: "16px",
            width: "350px",
            right: "25px",
          },
        }
      );
      var start = "1900-06-01";
      var end = "2040-06-01";
      var word = "";
      var hashtag = "";
      let projectName = "default";
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      } else if (subject_projects.length > 0) {
        setSelectedProject(subject_projects[0].id);
        setSelectedProjectName(subject_projects[0].name);
        projectName = subject_projects[0].name;
      }

      // var orderBy =
      //   selectedRankingParameter.length > 0
      //     ? selectedRankingParameter
      //     : "delta_time";
      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + senior_post_search_url;
      if (selectedOptionSocial.label === "Facebook") {
        url = base_url + senior_post_search_url;
      } else if (selectedOptionSocial.label === "Twitter") {
        url = base_url + senior_twitter_post_search_url;
      } else if (selectedOptionSocial.label === "Instagram") {
        url = base_url + senior_instagram_post_search_url;
      } else if (selectedOptionSocial.label === "Google") {
        url = base_url + senior_web_search_post_search_url;
      }
      await axios
        .get(url, {
          params: {
            word,
            startDate: start,
            endDate: end,
            hashtag,
            // orderBy,
            projectName,
            page_size: 1000 * 1000 * 1000,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
        })
        .catch(function (error) {
          // console.log(error);
          var response = error.response;
          errorText = response.request.response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error On Exporting Excel");
          } else {
            toast.error("خطأ في الخادم أثناء تصدير الإكسل");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error On Exporting Excel");
          } else {
            toast.error("هذا المحور موجود حاليا في القائمة أثناء تصدير الإكسل");
          }
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        const checkedPosts = [];
        data.posts.forEach((element) => {
          const post = {
            username: element.username,
            name: element.name,
            text: element.texts,
            date: element.creates,
            likes: element.likes,
            comments: element.comments,
          };
          checkedPosts.push(post);
        });
        if (checkedPosts.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Posts");

          worksheet.columns = [
            { header: "Username", key: "username", width: 20 },
            { header: "Name", key: "name", width: 20 },
            { header: "Text", key: "text", width: 60 },
            { header: "Date", key: "date", width: 20 },
            { header: "Likes", key: "likes", width: 10 },
            { header: "Comments", key: "comments", width: 10 },
          ];

          checkedPosts.forEach((post) => {
            worksheet.addRow(post);
          });

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "posts.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  }

  // Excel btn Record Backend

  async function handleRecordExportToExcel(recordsNumber) {
    try {
      // console.log("recordsNumber", recordsNumber);

      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + senior_post_search_url;
      if (selectedOptionSocial.label === "Facebook") {
        url = base_url + senior_post_search_url;
      } else if (selectedOptionSocial.label === "Twitter") {
        url = base_url + senior_twitter_post_search_url;
      } else if (selectedOptionSocial.label === "Instagram") {
        url = base_url + senior_instagram_post_search_url;
      } else if (selectedOptionSocial.label === "Google") {
        url = base_url + senior_web_search_post_search_url;
      }
      await axios
        .get(url, {
          params: {
            page_size: recordsNumber,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
        })
        .catch(function (error) {
          // console.log(error);
          var response = error.response;
          errorText = response.request.response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          toast.error("Server Error On Exporting Excel");
        } else {
          toast.error("Connection Error On Exporting Excel");
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        const checkedPosts = [];
        data.posts.forEach((element) => {
          const post = {
            username: element.username,
            name: element.name,
            text: element.texts,
            date: element.creates ? element.creates : "-",
            likes: element.likes,
            comments: element.comments,
          };
          checkedPosts.push(post);
        });
        if (checkedPosts.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Posts");

          worksheet.columns = [
            { header: "Username", key: "username", width: 20 },
            { header: "Name", key: "name", width: 20 },
            { header: "Text", key: "text", width: 60 },
            { header: "Date", key: "date", width: 20 },
            { header: "Likes", key: "likes", width: 10 },
            { header: "Comments", key: "comments", width: 10 },
          ];

          checkedPosts.forEach((post) => {
            worksheet.addRow(post);
          });

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "posts.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  }

  // Excel btn Select front

  function handleExportToExcel() {
    const checkedCheckboxes = document.querySelectorAll(
      "#checkboc_icon:checked"
    );

    const checkedPosts = [];

    checkedCheckboxes.forEach((checkbox) => {
      const postElement = checkbox.closest(".items_posts_fb");
      const name = postElement.querySelector(
        ".tooltip_text_posts_box_top"
      ).textContent;
      const username = postElement
        .querySelector(".tooltip_text_posts_box_bottom")
        .textContent.substring(1);

      const postText = postElement.querySelector(
        ".text_post_fb_analysis_confirm span"
      ).textContent;
      const postDate = postElement.querySelector(
        ".items_left_posts_fb_analysis_one_confirm"
      ).textContent;
      // const postLikes = postElement.querySelector(
      //   ".items_left_posts_fb_analysis_two span"
      // ).textContent;
      // const postComments = postElement.querySelector(
      //   ".items_left_posts_fb_analysis_three span"
      // ).textContent;

      const post = {
        username: username,
        name: name,
        text: postText,
        date: postDate,
        // likes: postLikes,
        // comments: postComments,
      };

      checkedPosts.push(post);
    });

    // Export to Excel

    if (checkedPosts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Posts");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 60 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
      ];

      checkedPosts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "posts.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const exelIcon = document.querySelector("#exel-icon");
        if (checkedPosts.length > 0) {
          exelIcon.src = Excel_green;
        } else {
          exelIcon.src = Excel;
        }
      });
    } else {
      if (language === "en") {
        Swal.fire({
          icon: "warning",
          title: "Oops...!",
          text: "You have not selected a post yet",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "أوه",
          text: "لم تقم بعد باختيار أي منشور.",
        });
      }
    }
  }

  // Excel btn current front

  function handleExportCurrentToExcel() {
    // const currentPosts = document.querySelectorAll(".items_posts_fb");

    const posts = [];

    postByWordsData.posts.forEach((postElement) => {
      const name = postElement.name ? postElement.name : "-";
      const username = postElement.username ? postElement.username : "-";

      const postText = postElement.texts ? postElement.texts : "-";
      const postDate = postElement.creates ? postElement.creates : "-";
      const postLikes = postElement.likes ? postElement.likes : "0";

      const postComments = postElement.comments ? postElement.comments : "0";
      const post = {
        username: username,
        name: name,
        text: postText,
        date: postDate,
        likes: postLikes,
        comments: postComments,
      };

      posts.push(post);
    });

    // currentPosts.forEach((postElement) => {
    //   const name = postElement.querySelector(
    //     ".tooltip_text_posts_box_top"
    //   ).textContent;
    //   const username = postElement
    //     .querySelector(".tooltip_text_posts_box_bottom")
    //     .textContent.substring(1);

    //   const postText = postElement.querySelector(
    //     ".text_post_fb_analysis_confirm span"
    //   ).textContent;
    //   const postDate = postElement.querySelector(
    //     ".items_left_posts_fb_analysis_one_confirm"
    //   ).textContent;
    // const postLikesElement = postElement.querySelector(".items_left_posts_fb_analysis_two span");
    // const postLikes = postLikesElement ? postLikesElement.textContent : '';

    // const postCommentsElement = postElement.querySelector(".items_left_posts_fb_analysis_three span");
    // const postComments = postCommentsElement ? postCommentsElement.textContent : '';

    //   const post = {
    //     username: username,
    //     name: name,
    //     text: postText,
    //     date: postDate,
    //     // likes: postLikes,
    //     // comments: postComments,
    //   };

    //   posts.push(post);
    // });

    // Export to Excel

    if (posts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("CurrentPosts");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 100 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
      ];

      posts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "current_posts.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Swal.fire({
          icon: "success",
          title:
            language === "ar"
              ? "عدد المنشورات:"
              : "Number of posts:" + posts.length,
          showConfirmButton: false,
          timer: 3000,
        });
      });
    }
  }

  // change icon excel with checkbox

  function handleCheckboxChange(event) {
    // const checkbox = event.target;
    const checkedCount = document.querySelectorAll(
      ".checkbox_input:checked"
    ).length;
    const exelIcon = document.querySelector("#exel-icon");
    const checkIcon = document.querySelector("#checkboc_icon");

    if (checkedCount > 0) {
      exelIcon.src = Excel_green;
      checkIcon.src = checkbox_green;
    } else {
      exelIcon.src = Excel;
      checkIcon.src = checkbox_gray;
    }
  }

  // clear checkbox

  function handleClearAll() {
    const checkedCheckboxes = document.querySelectorAll(
      ".checkbox_input:checked"
    );
    checkedCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    const exelIcon = document.querySelector("#exel-icon");
    const checkIcon = document.querySelector("#checkboc_icon");

    exelIcon.src = Excel;
    checkIcon.src = checkbox_gray;
  }

  // star icon

  const [selectedPosts, setSelectedPosts] = useState([]);
  const [showOnlyStarredPosts, setShowOnlyStarredPosts] = useState(false);

  const handlePostStarClick = (postId) => {
    const isSelected = selectedPosts.includes(postId);
    if (isSelected) {
      setSelectedPosts(selectedPosts.filter((id) => id !== postId));
    } else {
      setSelectedPosts([...selectedPosts, postId]);
    }
  };

  // ستاره ی طلایی هدر پست ها

  const [isGold, setIsGold] = useState(false);

  const handleHeaderStarClick = () => {
    setShowOnlyStarredPosts(!showOnlyStarredPosts);
    setIsGold(!isGold);
  };

  // show k and m in like and comment

  function formatNumber(num) {
    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return (num / 1000000).toFixed(1) + "m";
    }
  }

  // clear filters posts

  const handleClearFiltersUser = () => {
    setUsernameFilter("");
    setNameFilter("");
    setCurrentFilterUsersImage(filter_icon);
  };

  const handleClearFiltersTextPost = () => {
    setTextFilter("");
    setExactTextFilter("");
    setNotTextFilter("");
    setNotExactTextFilter("");
    setStartParagraphFilter("");
    setEndParagraphFilter("");
    setCurrentFilterTextImage(filter_icon);
  };

  const handleClearFiltersLikes = () => {
    setMinLikes("");
    setMaxLikes("");
    setCurrentFilterLikeImage(filter_icon);
  };

  const handleClearFiltersComments = () => {
    setMinComments("");
    setMaxComments("");
    setCurrentFilterCommentImage(filter_icon);
  };

  const handleClearFiltersDates = () => {
    setFromDate("");
    setUntilDate("");
    setCurrentFilterDateImage(filter_icon);
  };

  // filter posts

  const [usernameFilter, setUsernameFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const [textFilter, setTextFilter] = useState("");
  const [exactTextFilter, setExactTextFilter] = useState("");

  const [notTextFilter, setNotTextFilter] = useState("");
  const [notExactTextFilter, setNotExactTextFilter] = useState("");

  const [startParagraphFilter, setStartParagraphFilter] = useState("");
  const [endParagraphFilter, setEndParagraphFilter] = useState("");

  const [minLikes, setMinLikes] = useState("");
  const [maxLikes, setMaxLikes] = useState("");

  const [minComments, setMinComments] = useState("");
  const [maxComments, setMaxComments] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [untilDate, setUntilDate] = useState("");

  const fromDateObject = new Date(fromDate);
  const untilDateObject = new Date(untilDate);

  // change icon filter

  // user inputs

  const [currentFilterUsersImage, setCurrentFilterUsersImage] =
    useState(filter_icon);

  const handleNameFilterChange = (e) => {
    const value = e.target.value;
    setNameFilter(value);
    if (value === "" && usernameFilter === "") {
      setCurrentFilterUsersImage(filter_icon);
    } else {
      setCurrentFilterUsersImage(filter_green);
    }
  };

  const handleUsernameFilterChange = (e) => {
    const value = e.target.value;
    setUsernameFilter(value);
    if (value === "" && nameFilter === "") {
      setCurrentFilterUsersImage(filter_icon);
    } else {
      setCurrentFilterUsersImage(filter_green);
    }
  };

  // text inputs

  const [currentFilterTextImage, setCurrentFilterTextImage] =
    useState(filter_icon);

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleExactTextFilterChange = (e) => {
    const value = e.target.value;
    setExactTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleNotTextFilterChange = (e) => {
    const value = e.target.value;
    setNotTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleNotExactTextFilterChange = (e) => {
    const value = e.target.value;
    setNotExactTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleStartParagraphFilterChange = (e) => {
    const value = e.target.value;
    setStartParagraphFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleEndParagraphFilterChange = (e) => {
    const value = e.target.value;
    setEndParagraphFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  // Date inputs

  const [currentFilterDateImage, setCurrentFilterDateImage] =
    useState(filter_icon);

  const handleFromDateFilterChange = () => {
    if (fromDate !== "") {
      setCurrentFilterDateImage(filter_icon);
    } else {
      setCurrentFilterDateImage(filter_green);
    }
  };

  const handleUntilDateFilterChange = () => {
    if (untilDate !== "") {
      setCurrentFilterDateImage(filter_icon);
    } else {
      setCurrentFilterDateImage(filter_green);
    }
  };

  // Like inputs

  const [currentFilterLikeImage, setCurrentFilterLikeImage] =
    useState(filter_icon);

  const handleMinLikeFilterChange = (e) => {
    const value = e.target.value;
    setMinLikes(value);
    if (value === "" && maxLikes === "") {
      setCurrentFilterLikeImage(filter_icon);
    } else {
      setCurrentFilterLikeImage(filter_green);
    }
  };

  const handleMaxLikeFilterChange = (e) => {
    const value = e.target.value;
    setMaxLikes(value);
    if (value === "" && minLikes === "") {
      setCurrentFilterLikeImage(filter_icon);
    } else {
      setCurrentFilterLikeImage(filter_green);
    }
  };

  // Comment inputs

  const [currentFilterCommentImage, setCurrentFilterCommentImage] =
    useState(filter_icon);

  const handleMinCommentFilterChange = (e) => {
    const value = e.target.value;
    setMinComments(value);
    if (value === "" && maxComments === "") {
      setCurrentFilterCommentImage(filter_icon);
    } else {
      setCurrentFilterCommentImage(filter_green);
    }
  };

  const handleMaxCommentFilterChange = (e) => {
    const value = e.target.value;
    setMaxComments(value);
    if (value === "" && minComments === "") {
      setCurrentFilterCommentImage(filter_icon);
    } else {
      setCurrentFilterCommentImage(filter_green);
    }
  };
  // open menu filters

  // menu user

  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [isOpenLike, setIsOpenLike] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);

  const [alertShown, setAlertShown] = useState({
    user: false,
    text: false,
    date: false,
    like: false,
    comment: false,
  });

  const closeAllMenus = () => {
    setIsOpenUser(false);
    setIsOpenText(false);
    setIsOpenDate(false);
    setIsOpenLike(false);
    setIsOpenComment(false);
  };

  const showInfoAlert = (key) => {
    if (!alertShown[key]) {
      const alertText =
        language === "en"
          ? "The filter is only applied to the loaded posts."
          : "تم تطبيق المرشح فقط على المشاركات المحملة.";

      Swal.fire({
        icon: "info",
        title: language === "en" ? "Attention" : "تنبيه",
        confirmButtonText: language === "en" ? "Ok" : "حسناً",
        cancelButtonText: language === "en" ? "Cancel" : "إلغاء",
        text: alertText,
      });
      setAlertShown({ ...alertShown, [key]: true });
    }
  };

  const toggleMenu = (isOpenState, setIsOpenState, key) => {
    if (!isOpenState) {
      closeAllMenus();
      showInfoAlert(key);
    }
    setIsOpenState(!isOpenState);
  };

  const toggleMenuUser = () => {
    toggleMenu(isOpenUser, setIsOpenUser, "user");
  };

  const toggleMenuText = () => {
    toggleMenu(isOpenText, setIsOpenText, "text");
  };

  const toggleMenuDate = () => {
    toggleMenu(isOpenDate, setIsOpenDate, "date");
  };

  // const toggleMenuLike = () => {
  //   toggleMenu(isOpenLike, setIsOpenLike, "like");
  // };

  // const toggleMenuComment = () => {
  //   toggleMenu(isOpenComment, setIsOpenComment, "comment");
  // };

  // highlight users

  const highlightFilterUsers = (text) => {
    const filterKeywords = [nameFilter, usernameFilter];

    const escapeRegExp = (string) => {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    };

    let highlightedText = text;

    filterKeywords.forEach((keyword) => {
      if (keyword && keyword !== "") {
        const regex = new RegExp(escapeRegExp(keyword), "gi");
        highlightedText = highlightedText.replace(
          regex,
          (match) => `<mark>${match}</mark>`
        );
      }
    });

    return { __html: highlightedText };
  };

  // highlight text

  const highlightFilterKeywords = (text) => {
    const filterKeywords = [
      textFilter,
      exactTextFilter,
      startParagraphFilter,
      endParagraphFilter,
    ];

    const escapeRegExp = (string) => {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    };

    let highlightedText = text;

    filterKeywords.forEach((keyword) => {
      if (keyword && keyword !== "") {
        const regex = new RegExp(escapeRegExp(keyword), "gi");
        highlightedText = highlightedText.replace(
          regex,
          (match) => `<mark>${match}</mark>`
        );
      }
    });

    return { __html: highlightedText };
  };

  // highlight likes

  // const highlightFilterLikes = (text) => {
  //   const filterKeywords = [minLikes, maxLikes];

  //   let highlightedText = text;

  //   filterKeywords.forEach((keyword) => {
  //     if (keyword && keyword !== "") {
  //       const regex = new RegExp(`\\d+`, "g");
  //       highlightedText = highlightedText.replace(regex, (match) => {
  //         const num = parseInt(match);
  //         if (
  //           (minLikes === "" ||
  //             minLikes === null ||
  //             num >= parseInt(minLikes)) &&
  //           (maxLikes === "" || maxLikes === null || num <= parseInt(maxLikes))
  //         ) {
  //           return `<mark>${match}</mark>`;
  //         }
  //         return match;
  //       });
  //     }
  //   });

  //   return { __html: highlightedText };
  // };

  // highlight comment

  // const highlightFilterComment = (text) => {
  //   const filterKeywords = [minComments, maxComments];

  //   let highlightedText = text;

  //   filterKeywords.forEach((keyword) => {
  //     if (keyword && keyword !== "") {
  //       const regex = new RegExp(`\\d+`, "g");
  //       highlightedText = highlightedText.replace(regex, (match) => {
  //         const num = parseInt(match);
  //         if (
  //           (minComments === "" ||
  //             minComments === null ||
  //             num >= parseInt(minComments)) &&
  //           (maxComments === "" ||
  //             maxComments === null ||
  //             num <= parseInt(maxComments))
  //         ) {
  //           return `<mark>${match}</mark>`;
  //         }
  //         return match;
  //       });
  //     }
  //   });

  //   return { __html: highlightedText };
  // };

  // select emoji posts

  const [selectedEmojis, setSelectedEmojis] = useState({});
  const [selectedStuff, setSelectedStuff] = useState({});

  const [isTickSelected, setIsTickSelected] = useState({});

  const handleEmojiClick = (postId, emojiId, needUpdate) => {
    // console.log("handleEmojiClick", postId, emojiId, isTickSelected[postId]);
    // if (!isTickSelected[postId]) {
    setSelectedEmojis((prevState) => ({
      ...prevState,
      [postId]: emojiId,
    }));
    // }
  };

  // selected tick posts
  const [selectedTickPosts, setSelectedTickPosts] = useState([]);
  const [selectedCrossPosts, setSelectedCrossPosts] = useState([]);
  const [showOnlyTickPosts, setShowOnlyTickPosts] = useState(false);

  const [isCrossSelected, setIsCrossSelected] = useState({});

  const handleStickerClick = (postId, emoji, needUpdate, index) => {
    if (!selectedEmojis[postId]) {
      return;
    }

    const isPostSelected = isTickSelected[postId] || isCrossSelected[postId];

    if (needUpdate)
      updatePostAction(postId, "tick", index, "-", selectedProjectName);
    toast.success(language === "en" ? "Request Send" : "تم إرسال الطلب", {
      position: toast.POSITION.TOP_CENTER,
    });
    if (!isPostSelected) {
      if (emoji === "tick") {
        setIsTickSelected({ ...isTickSelected, [postId]: true });
        setIsCrossSelected({ ...isCrossSelected, [postId]: false });
        setSelectedTickPosts([...selectedTickPosts, postId]);
      } else if (emoji === "cross") {
        setIsTickSelected({ ...isTickSelected, [postId]: false });
        setIsCrossSelected({ ...isCrossSelected, [postId]: true });
        setSelectedCrossPosts([...selectedCrossPosts, postId]);
        setIsTickSelected((prevState) => ({
          ...prevState,
          [postId]: true,
        }));
      }
    }
  };

  // emoji header post

  const [selectedEmojiHeader, setSelectedEmojiHeader] = useState(null);
  const [showOnlyHappyPosts, setShowOnlyHappyPosts] = useState(false);
  const [showOnlySadPosts, setShowOnlySadPosts] = useState(false);
  const [showOnlyIndifferentPosts, setShowOnlyIndifferentPosts] =
    useState(false);

  const handleEmojiHeaderClick = (emojiHeaderId) => {
    if (emojiHeaderId === "happy") {
      setSelectedEmojiHeader("happy");
      setShowOnlyHappyPosts(true);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(false);
    } else if (emojiHeaderId === "sad") {
      setSelectedEmojiHeader("sad");
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(true);
      setShowOnlyIndifferentPosts(false);
    } else if (emojiHeaderId === "indifferent") {
      setSelectedEmojiHeader("indifferent");
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(true);
    } else {
      setSelectedEmojiHeader(null);
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(false);
    }
    const filterOptions = {
      happy: { happy: true, sad: false, indifferent: false },
      sad: { happy: false, sad: true, indifferent: false },
      indifferent: { happy: false, sad: false, indifferent: true },
      clear: { happy: false, sad: false, indifferent: false },
    };

    const selectedOptions = filterOptions[emojiHeaderId] || filterOptions.clear;

    setSelectedEmojiHeader(emojiHeaderId);
    setShowOnlyHappyPosts(selectedOptions.happy);
    setShowOnlySadPosts(selectedOptions.sad);
    setShowOnlyIndifferentPosts(selectedOptions.indifferent);
  };

  const handleClearFiltersClick = () => {
    setSelectedEmojiHeader(null);
    setShowOnlyHappyPosts(false);
    setShowOnlySadPosts(false);
    setShowOnlyIndifferentPosts(false);
  };

  const [isOpenStickerHeader, setIsOpenStickerHeader] = useState(false);

  const toggleMenuStickerHeader = () => {
    if (!isOpenStickerHeader) {
      closeAllMenus();
    }
    setIsOpenStickerHeader(!isOpenStickerHeader);
  };

  // tick header post filter

  const [selectedTickHeaderFilter, setSelectedTickHeaderFilter] =
    useState(null);

  const handleTickHeaderClickFilter = (tickHeaderId) => {
    if (selectedTickHeaderFilter === tickHeaderId) {
      setSelectedTickHeaderFilter(null);
      setShowOnlyTickPosts(false);
    } else {
      setSelectedTickHeaderFilter(tickHeaderId);
      setShowOnlyTickPosts(true);
    }
  };

  // tick header post

  // const [selectedTickHeader, setSelectedTickHeader] = useState(null);

  const handleTickHeaderClick = async () => {
    await postByWordsData.posts.forEach(async (element, index) => {
      if (Object.keys(selectedEmojis).includes(String(element.id))) {
        if (!selectedTickPosts.includes(element.id))
          await updatePostAction(
            element.id,
            "tick",
            index,
            "-",
            selectedProjectName
          );
      }
    });
    // Object.keys(selectedEmojis).forEach((element ,index) => {
    //   if (!(selectedTickPosts.includes(parseInt(element))))
    // })

    toast.success(language === "en" ? "Request send" : "تم إرسال الطلب", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // const toggleMenuStatistics = (option) => {
  //   setIsOpenStatistics(!isOpenStatistics);
  // };

  const handleStartMyShow = async () => {
    var result = await getMyShow();

    if (result.hasError) {
      if (result.isLoggedIn === false) {
        setneedLogin(true);
      } else if (result.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      // var data = result.data.data;
      setSubject_projects(result.data.data.subject_projects);

      // setapiData(data);
    }
  };

  // show popup add topic and subtopic

  const handleAddTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await addTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        // setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleDeleteTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await deleteTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleDeleteSubTopic = async (
    nameProject,
    newMainTopic,
    newSubTubic
  ) => {
    try {
      var result = await deleteSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleAddSubTopic = async (nameProject, newMainTopic, newSubTubic) => {
    try {
      var result = await addSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const [showPopupAddTopic, setShowPopupAddTopic] = useState(false);
  const [showPopupFilterWordCloud, setShowPopupFilterWordCloud] =
    useState(false);

  const [topics, setTopics] = useState([]);
  const [newMainTopic, setNewMainTopic] = useState("");
  const [newWordFilter, setNewWordFilter] = useState("");
  const [wordFilters, setWordFilters] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [apiData, setapiData] = useState(undefined);
  const [newSubTopic, setNewSubTopic] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    console.log(
      "🚀 ~ file: AddSubjects.js:157 ~ AddSubjects ~ fetchedApi:",
      apiData
    );
  }, [apiData]);

  // const handleOpenPopup = (indexProject, nameOfPopUp) => {
  //   setapiData(
  //     apiData.map((ele, index) => {
  //       if (index === indexProject) {
  //         return { ...ele, [nameOfPopUp]: true };
  //       }
  //       return ele;
  //     })
  //   );

  //   setShowPopupAddTopic(true);
  // };

  // const handleClosePopup = (indexProject, nameOfPopUp) => {
  //   setapiData(
  //     apiData.map((ele, index) => {
  //       if (index === indexProject) {
  //         return { ...ele, [nameOfPopUp]: false };
  //       }
  //       return ele;
  //     })
  //   );

  //   setShowPopupAddTopic(false);
  // };

  const handleClosePopupAddTopic = async () => {
    try {
      await handleStartMyShow();
      setShowPopupAddTopic(false);
    } catch (error) {
      console.log("error in handle close popup add topic", error);
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
    // setfetchedApi(false);
  };

  const handleOpenPopupAddTopic = () => {
    setShowPopupAddTopic(true);
  };

  const handleOpenPopupFilterWordCloud = () => {
    setShowPopupFilterWordCloud(true);
  };

  const handleClosePopupFilterWordCloud = async () => {
    try {
      await handleStartMyShow();
      setShowPopupFilterWordCloud(false);
    } catch (error) {
      console.log("error in handle close popup filter word", error);
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
    // setfetchedApi(false);
  };

  const handleRemoveWordFilter = async (newWordFilter) => {
    var projectName = selectedProjectName;
    var word = newWordFilter;

    var postsByWord = await deleteWordFilter(projectName, word);
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      var temp = [...wordFilters]; // make a separate copy of the array
      var index = temp.indexOf(newWordFilter);
      if (index !== -1) {
        temp.splice(index, 1);
        setWordFilters(temp);
      }

      if (language === "en") {
        toast.success("Word Deleted");
      } else {
        toast.error("تم حذف الكلمة.");
      }
    }
  };

  const handleAddWordFilter = async (newWordFilter) => {
    var projectName = selectedProjectName;
    var word = newWordFilter;

    var postsByWord = await editWordFilter(projectName, word);
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Word Added");
      } else {
        toast.error("تمت إضافة الكلمة.");
      }
    }
  };

  const addWordFilter = async (nameProject) => {
    if (newWordFilter.trim() !== "") {
      const isDuplicate = wordFilters.find((word) => {
        if (word === newWordFilter) {
          return true;
        }
      });
      if (!isDuplicate) {
        await handleAddWordFilter(newWordFilter);

        setWordFilters([...wordFilters, newWordFilter]);
        setNewWordFilter("");
        // await updateSubjects()
      } else {
        if (language === "en") {
          Swal.fire({
            icon: "warning",
            title: "Oops...!",
            text: "This Word is already in the list.",
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "أوه",
            text: "هذه الكلمة موجودة بالفعل في القائمة.",
          });
        }
      }
    }
  };

  // const updateSubjects = () => {
  //   let temp = subject_projects
  //   if (temp.length > 0 && selectedProject !== ""){
  //     console.log("updateSubjects: " , temp , fetchedSubjects)
  //     temp = temp.map((element) => {
  //       if (element.id === selectedProject){
  //         element.subjects = fetchedSubjects
  //         return element
  //       }else{
  //         return element
  //       }
  //     })
  //     console.log("updateSubjects2: " , temp )

  //     setSubject_projects(temp)
  //   }
  // }

  const addMainTopic = async (nameProject) => {
    if (newMainTopic.trim() !== "") {
      const isDuplicate = subject_projects.find((project) => {
        if (
          project.name === nameProject &&
          project.subjects.find((ele) => ele.subject_id.name === newMainTopic)
        ) {
          return true;
        }
      });
      if (!isDuplicate) {
        await handleAddTopic(nameProject, newMainTopic);

        setFetchedSubjects([
          ...fetchedSubjects,
          {
            subject_id: {
              name: newMainTopic,
              subSubjects: [],
            },
          },
        ]);
        setNewMainTopic("");
        // await updateSubjects()
      } else {
        if (language === "en") {
          Swal.fire({
            icon: "warning",
            title: "Oops...!",
            text: "This Main topic is already in the list.",
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "أوه",
            text: "هذا الموضوع الرئيسي موجود في القائمة.",
          });
        }
      }
    }
  };

  const addSubtopic = async (topicChoosed, nameProject) => {
    const topic = topicChoosed;
    await handleAddSubTopic(nameProject, topic.subject_id.name, newSubTopic);
    if (newSubTopic.trim() !== "") {
      if (topic) {
        // console.log("addSubtopic" , topic)
        const isDuplicate =
          topic.subject_id.subSubjects !== undefined
            ? topic.subject_id.subSubjects.includes(newSubTopic)
            : false;
        if (!isDuplicate) {
          topic.subject_id.subSubjects.push({ name: newSubTopic });
          setNewSubTopic("");
          setSelectedTopic({ ...topic });
          var temp = fetchedSubjects;
          temp = temp.map((element) => {
            if (element.subject_id.name === topic.name) {
              return topic;
            } else {
              return element;
            }
          });
          setFetchedSubjects(temp);
        } else {
          if (language === "en") {
            Swal.fire({
              icon: "warning",
              title: "Oops...!",
              text: "This subtopic is already in the list for the selected main topic",
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "أوه",
              text: "هذا الموضوع الفرعي موجود حاليا في قائمة الموضوع الرئيسي المحدد.",
            });
          }
        }
      }
    }
  };

  const handleRemoveMainTopic = async (nameProject, indexOfSubject) => {
    const updatedTopics = [...topics];
    updatedTopics.splice(indexOfSubject, 1);
    setTopics(updatedTopics);

    const temp = [...fetchedSubjects];
    console.log(
      "🚀 ~ file: AddSubjects.js:335 ~ apiData.map ~ temp:",
      temp[indexOfSubject].subject_id.name
    );
    await handleDeleteTopic(nameProject, temp[indexOfSubject].subject_id.name);
    temp.splice(indexOfSubject, 1);
    setFetchedSubjects(temp);
  };

  const handleRemoveSubtopic = async (nameProject, subIndex, topicChoosed) => {
    const topic = { ...topicChoosed };
    await handleDeleteSubTopic(
      nameProject,
      topic.subject_id.name,
      topic.subject_id.subSubjects[subIndex].name
    );
    console.log("handleRemoveSubtopic", topic);
    topic.subject_id.subSubjects.splice(subIndex, 1);
    var temp = fetchedSubjects;
    temp = temp.map((element) => {
      if (element.subject_id.name === topic.name) {
        return topic;
      } else {
        return element;
      }
    });
    setFetchedSubjects(temp);
  };

  // پاپ آپ نمایش جزئیات نمودارها و دیتا

  const [openChartPopUp, setOpenChartPopUp] = useState(false);

  const handleOpenChartPopUp = () => {
    setOpenChartPopUp(true);
  };

  const handleCloseChartPopUp = () => {
    setOpenChartPopUp(false);
  };

  const [isCheckedImpression, setIsCheckedImpression] = useState(false);
  const [isCheckedTopicSubTopic, setIsCheckedTopicSubTopic] = useState(false);
  const [isCheckedAxis, setIsCheckedAxis] = useState(false);
  const [isCheckedEmotions, setIsCheckedEmotions] = useState(false);

  const [topicsSubTopic, setTopicsSubTopic] = useState({});

  const toggleSubTopics = (mainTopicId) => {
    setTopicsSubTopic((prevTopics) => ({
      ...prevTopics,
      [mainTopicId]: !prevTopics[mainTopicId],
    }));
  };

  // topic and subtopic chart

  const [mainTopicChart, setMainTopicChart] = useState("");
  const [subTopicChart, setSubTopicChart] = useState({ text: "", number: "" });
  const [topicsChart, setTopicsChart] = useState([]);
  const [activeMainTopicIndex, setActiveMainTopicIndex] = useState(null);

  const handleAddMainTopicChart = () => {
    if (mainTopicChart.trim() !== "") {
      setTopicsChart([
        ...topicsChart,
        { mainTopic: mainTopicChart, subTopics: [] },
      ]);
      setMainTopicChart("");
    }
  };

  const handleAddSubTopicChart = (index) => {
    if (subTopicChart.text.trim() !== "" && subTopicChart.number !== "") {
      const updatedTopics = [...topicsChart];
      updatedTopics[index].subTopics.push(subTopicChart);
      setTopicsChart(updatedTopics);
      setSubTopicChart({ text: "", number: "" });
    }
  };

  const toggleSubTopicInput = (index) => {
    setActiveMainTopicIndex(index === activeMainTopicIndex ? null : index);
  };

  // tab charts items

  const [isOpenStatisticsImpression, setIsOpenStatisticsImpression] =
    useState(false);
  const [isOpenStatisticsTopicSubTopic, setIsOpenStatisticsTopicSubTopic] =
    useState(false);
  const [isOpenStatisticsAxis, setIsOpenStatisticsAxis] = useState(false);
  const [isOpenStatisticsEmotions, setIsOpenStatisticsEmotions] =
    useState(false);

  // tab axis popup chart

  const [axisChart, setAxisChart] = useState("");
  const [numberAxisChart, setNumberAxisChart] = useState("");
  const [axisListChart, setAxisListChart] = useState([]);

  const handleAddClickAxisChart = () => {
    if (axisChart && numberAxisChart) {
      setAxisListChart([
        ...axisListChart,
        { axis: axisChart, numberAxis: numberAxisChart },
      ]);
      setAxisChart("");
      setNumberAxisChart("");
    }
  };

  // tab Emotions popup chart

  const [EmotionsChart, setEmotionsChart] = useState("");
  const [numberEmotionsChart, setNumberEmotionsChart] = useState("");
  const [EmotionsListChart, setEmotionsListChart] = useState([]);

  const handleAddClickEmotions = () => {
    if (EmotionsChart && numberEmotionsChart) {
      setEmotionsListChart([
        ...EmotionsListChart,
        { Emotions: EmotionsChart, numberEmotions: numberEmotionsChart },
      ]);
      setEmotionsChart("");
      setNumberEmotionsChart("");
    }
  };

  // edit posts popup

  const [showPopupEditPosts, setShowPopupEditPosts] = useState(false);

  const [selectedTextEdit, setSelectedTextEdit] = useState("");
  const [selectedDateEdit, setSelectedDateEdit] = useState("");

  const handleClosePopupEditPosts = () => {
    setSelectedTextEdit("");
    setSelectedDateEdit("");
    setShowPopupEditPosts(false);
  };

  const handleOpenPopupEditPosts = (date, text) => {
    setSelectedDateEdit(date);
    setSelectedTextEdit(text);
    setShowPopupEditPosts(true);
  };

  const handlePostEdit = async (postid, index) => {
    var postsByWord = await editPost(
      postid,
      selectedProjectName,
      selectedTextEdit,
      selectedDateEdit
    );
    if (selectedOptionSocial.label === "Facebook") {
      var postsByWord = await editPost(
        postid,
        selectedProjectName,
        selectedTextEdit,
        selectedDateEdit
      );
    } else if (selectedOptionSocial.label === "Twitter") {
      var postsByWord = await twitterEditPost(
        postid,
        selectedProjectName,
        selectedTextEdit,
        selectedDateEdit
      );
    } else if (selectedOptionSocial.label === "Instagram") {
      var postsByWord = await instagramEditPost(
        postid,
        selectedProjectName,
        selectedTextEdit,
        selectedDateEdit
      );
    } else if (selectedOptionSocial.label === "Google") {
      var postsByWord = await googleEditPost(
        postid,
        selectedProjectName,
        selectedTextEdit,
        selectedDateEdit
      );
    }
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
      if (language === "en") {
        toast.success("Post Edited");
      } else {
        toast.success("تم تحرير المنشور");
      }
      var temp = { ...postByWordsData };
      temp.posts[index].texts = selectedTextEdit;
      temp.posts[index].creates = selectedDateEdit;
      setpostByWordsData(temp);
      handleClosePopupEditPosts();
    }
  };

  const handlePostDelete = async (postid, index) => {
    Swal.fire({
      title: language === "ar" ? "هل أنت متأكد؟" : "Are you sure?",
      text:
        language === "ar"
          ? "بالضغط على نعم، سيتم حذف المنشور نهائياً"
          : "By pressing 'Yes,' the post will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: language === "ar" ? "نعم، احذفها" : "Yes, delete it!",
      cancelButtonText: language === "ar" ? "لا، الغاء" : "No, Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let postsByWord;
        if (selectedOptionSocial.label === "Facebook") {
          postsByWord = await deletePost(postid, selectedProjectName);
        } else if (selectedOptionSocial.label === "Twitter") {
          postsByWord = await twitterDeletePost(postid, selectedProjectName);
        } else if (selectedOptionSocial.label === "Instagram") {
          postsByWord = await instagramDeletePost(postid, selectedProjectName);
        } else if (selectedOptionSocial.label === "Google") {
          postsByWord = await googleDeletePost(postid, selectedProjectName);
        }

        if (postsByWord.hasError) {
          if (postsByWord.isLoggedIn === false) {
            setneedLogin(true);
          } else if (postsByWord.status === 500) {
            if (language === "en") {
              toast.error("Server Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          } else {
            if (language === "en") {
              toast.error("Connection Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          }
        } else {
          if (language === "en") {
            toast.success("Post Deleted");
          } else {
            toast.success("المنشور تم حذفه");
          }
          var temp = { ...postByWordsData };
          temp.posts.splice(index, 1);
          setpostByWordsData(temp);
        }
      }
    });
  };

  // sort weight post

  const [sortWeightPosts, setSortWeightPosts] = useState("asc");

  const sortByWeightPosts = (type) => {
    setSortWeightPosts(type);
    postByWordsData.posts.sort((a, b) => {
      if (type === "asc") {
        return a.weight_number - b.weight_number;
      } else {
        return b.weight_number - a.weight_number;
      }
    });
  };

  // emoji convert

  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(
        parseInt(unicode.replace("U+", ""), 16)
      );
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  const renderPostTextWithStickers = (post) => {
    const parts = post.texts.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderSeniorConfirmPage = (
    <>
      <div
        className={`senior_analyzer_page_left_menu ${
          language === "en" ? "left" : "right"
        }`}
      >
        <div
          className={`left_menu_dashboard ${
            theme === "dark" ? "dark_mode" : ""
          }`}
        >
          <img className="logo_dashboard" src={logo} alt="logo" />

          <div id="menu_submenu">
            <div className="contact_menu">
              <Link to="/base">
                <img src={base_icon} alt="" />
              </Link>
            </div>

            {isSeniorAnalyzer || isSuperUser ? (
              <section
                className={`sidebar_${language === "en" ? "left" : "right"}`}
              >
                <div id="active_bg">
                  <img src={senior_menu_icon} alt="" />
                  <div
                    className={`dropdown_${
                      language === "en" ? "left" : "right"
                    }`}
                  >
                    <Link to="/seniorpage">
                      <span>
                        {language === "en"
                          ? "Project Settings"
                          : "إعدادات المشروع"}
                      </span>
                    </Link>
                    <Link to="/seniorconfirm">
                      <span>
                        {" "}
                        {language === "en"
                          ? "Post Approval"
                          : "تأكيد المنشورات"}
                      </span>
                    </Link>
                  </div>
                </div>
              </section>
            ) : null}

            {isSuperUser ? (
              <div className="contact_menu">
                <Link to="/home">
                  <img src={show_site_menu} alt="" />
                </Link>
              </div>
            ) : null}

            {isSuperUser ? (
              <div className="contact_menu">
                <Link to="/admin">
                  <img src={admin_menu_icon} alt="" />
                </Link>
              </div>
            ) : null}

            <div className="contact_menu">
              <img src={logout_icon} alt="" onClick={handleLogout} />
            </div>
          </div>

          <div className="btn_container_toggle_language_base">
            <label className="switch btn-color-mode-switch-base">
              <input
                value="1"
                id="color_mode"
                name="color_mode"
                type="checkbox"
                onClick={handleLanguageToggle}
              />
              <label
                className="btn-color-mode-switch-inner-base"
                data-off="EN"
                data-on="AR"
                htmlFor="color_mode"
              ></label>
            </label>
          </div>
        </div>
      </div>
      <div
        className={`senior_confirm_page_body ${
          language === "ar" ? "senior_confirm_page_body_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <div className="body_facebook_page_analysis">
          <div
            className={`header_posts_fb ${
              language === "ar" ? "header_posts_fb_ar" : ""
            } ${theme === "dark" ? "dark_mode" : ""}`}
          >
            {language === "en" ? (
              <span
                className={`header_posts_fb_span ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                Posts
              </span>
            ) : (
              <span
                className={`header_posts_fb_span_ar ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                المنشورات
              </span>
            )}

            <div className="dropdown_found_post_number">
              <button
                className={`dropbtn_found_post_number ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                {selectedStatistics}
              </button>
              {language === "ar" ? (
                <div
                  className={`dropdown_content_found_post_number ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics(
                          "جميع مشاركات المشروع",
                          "default"
                        )
                      }
                    >
                      جميع مشاركات المشروع:{" "}
                      <span>{postByWordsData.allCount}</span>{" "}
                    </label>
                  ) : (
                    <label>مشاركات الموجودة: 0</label>
                  )}
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics(
                          "المشاركات بالوسوم في انتظار الموافقة",
                          "tag"
                        )
                      }
                    >
                      المشاركات بالوسوم في انتظار الموافقة:{" "}
                      <span>{postByWordsData.tagged_count}</span>
                    </label>
                  ) : (
                    <label>المشاركات بالوسوم في انتظار الموافقة: 0</label>
                  )}
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics(
                          "المشاركات بالوسوم الموافق عليها",
                          "confirmed"
                        )
                      }
                    >
                      المشاركات بالوسوم الموافق عليها:{" "}
                      <span>{postByWordsData.confirmed_count}</span>{" "}
                    </label>
                  ) : (
                    <label>المشاركات بالوسوم الموافق عليها: 0</label>
                  )}
                </div>
              ) : (
                <div
                  className={`dropdown_content_found_post_number_en ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics("All Posts", "default")
                      }
                    >
                      All Project Posts: <span>{postByWordsData.allCount}</span>{" "}
                    </label>
                  ) : (
                    <label>Posts Found: 0</label>
                  )}
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics(
                          "Tagged Posts Awaiting Approval",
                          "tag"
                        )
                      }
                    >
                      Tagged Posts Awaiting Approval:{" "}
                      <span>{postByWordsData.tagged_count}</span>
                    </label>
                  ) : (
                    <label>Tagged Posts Awaiting Approval: 0</label>
                  )}
                  {!loadingState && postByWordsData ? (
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOptionClickStatistics(
                          "Confirmed Tagged Posts",
                          "confirmed"
                        )
                      }
                    >
                      Confirmed Tagged Posts:{" "}
                      <span>{postByWordsData.confirmed_count}</span>{" "}
                    </label>
                  ) : (
                    <label>Confirmed Tagged Posts: 0</label>
                  )}
                </div>
              )}
            </div>

            <div
              className={`menu_header_select_social_senior ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              <button
                className={`menu_header_select_social_senior_btn ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
                onClick={toggleMenuSelectSocial}
              >
                {selectedOptionSocial ? selectedOptionSocial.label : "Social"}
                <span
                  className={`arrow_filter_header_project_subject ${
                    isOpenSelectSocial ? "up" : "down"
                  }`}
                >
                  &#11163;
                </span>
              </button>
              {isOpenSelectSocial && (
                <ul
                  className={`menu_header_select_social_senior_menu ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  <li
                    onClick={() =>
                      handleOptionClickSelectSocial({ label: "Facebook" })
                    }
                  >
                    Facebook
                  </li>
                  <li
                    onClick={() =>
                      handleOptionClickSelectSocial({ label: "Twitter" })
                    }
                  >
                    Twitter
                  </li>
                  <li
                    onClick={() =>
                      handleOptionClickSelectSocial({ label: "Instagram" })
                    }
                  >
                    Instagram
                  </li>
                  <li
                    onClick={() =>
                      handleOptionClickSelectSocial({ label: "Google" })
                    }
                  >
                    Google Search
                  </li>
                </ul>
              )}
            </div>

            <div className="analysis_menu_header_project_subject">
              <AnalysisMenuProjectSubject
                data={subject_projects.map((x) => ({
                  Key: x.id,
                  Value: x.name,
                }))}
                setSelectedProject={setSelectedProject}
                setSelectedProjectName={setSelectedProjectName}
              />
            </div>
            <div
              onClick={handleOpenPopupFilterWordCloud}
              className="btn_add_subjects_analysis"
            >
              <FilterAltOffIcon id="btn_add_subjects_analysis_icon" />
              <span className="btn_add_subjects_analysis_tooltip">
                Filter Word Cloud
              </span>
            </div>

            <Dialog
              className="popup_add_subject"
              open={showPopupFilterWordCloud}
              onClose={() => handleClosePopupFilterWordCloud(false)}
            >
              <DialogTitle className="header_popup_right_header_add_admin_page">
                <label>Filter Word Cloud</label>
                <button
                  onClick={() => handleClosePopupFilterWordCloud(false)}
                  className="btn_close_header_popup_right_header_add_admin_page"
                >
                  <CloseIcon />
                </button>
              </DialogTitle>
              <DialogContent className="body_popup_add_subject">
                {selectedProjectName ? (
                  <div className="rows_body_popup_add_subject_one">
                    <input
                      type="text"
                      placeholder="Enter Word To Filter"
                      value={newWordFilter}
                      onChange={(e) => setNewWordFilter(e.target.value)}
                    />
                    <button onClick={() => addWordFilter(selectedProjectName)}>
                      ADD
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="rows_body_popup_add_subject">
                  <ul>
                    {wordFilters
                      ? wordFilters.map((word, index) => (
                          <li
                            key={index}
                            className="li_topic_body_popup_add_subject"
                            style={{
                              cursor: "pointer",
                              background: "#fafafa",
                            }}
                          >
                            {word}
                            <button
                              onClick={() => {
                                handleRemoveWordFilter(word);
                              }}
                              id="btn_remove_items_li_ul_add_topic"
                            >
                              <img src={multiple_red} alt="" />
                            </button>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </DialogContent>
              <DialogActions className="btns_bottom_search_simple">
                <button
                  className="okay_btn_search_simple"
                  onClick={() => handleClosePopupFilterWordCloud(false)}
                >
                  OK
                </button>
                <button
                  className="deny_btn_search_simple"
                  onClick={() => handleClosePopupFilterWordCloud(false)}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>

            <div
              onClick={handleOpenPopupAddTopic}
              className="btn_add_subjects_analysis"
            >
              <AddCircleOutlineIcon id="btn_add_subjects_analysis_icon" />
              <span className="btn_add_subjects_analysis_tooltip">
                Add Topic & SubTopic
              </span>
            </div>
            <Dialog
              className="popup_add_subject"
              open={showPopupAddTopic}
              onClose={() => handleClosePopupAddTopic(false)}
            >
              <DialogTitle className="header_popup_right_header_add_admin_page">
                <label>Add Topic and SubTopic</label>
                <button
                  onClick={() => handleClosePopupAddTopic(false)}
                  className="btn_close_header_popup_right_header_add_admin_page"
                >
                  <CloseIcon />
                </button>
              </DialogTitle>
              <DialogContent className="body_popup_add_subject">
                {selectedProjectName ? (
                  <div className="rows_body_popup_add_subject_one">
                    <input
                      type="text"
                      placeholder="Enter Topic"
                      value={newMainTopic}
                      onChange={(e) => setNewMainTopic(e.target.value)}
                    />
                    <button onClick={() => addMainTopic(selectedProjectName)}>
                      ADD
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="rows_body_popup_add_subject">
                  <ul>
                    {fetchedSubjects
                      ? fetchedSubjects.map((topic, index) => (
                          <li
                            key={index}
                            className="li_topic_body_popup_add_subject"
                            style={{
                              cursor: "pointer",
                              background:
                                selectedTopic === topic ? "#fafafa" : "#fff",
                            }}
                            onClick={() => setSelectedTopic(topic)}
                          >
                            {topic.name}
                            <button
                              onClick={() => {
                                handleRemoveMainTopic(
                                  selectedProjectName,
                                  index
                                );
                              }}
                              id="btn_remove_items_li_ul_add_topic"
                            >
                              <img src={multiple_red} alt="" />
                            </button>
                            <ul className="ul_topic_body_popup_add_subject">
                              {topic.subject_id.name}
                              {topic.subject_id.subSubjects.map(
                                (subtopic, subIndex) => (
                                  <li key={subIndex}>
                                    {subtopic.name}
                                    <button
                                      onClick={() =>
                                        handleRemoveSubtopic(
                                          selectedProjectName,
                                          subIndex,
                                          topic
                                        )
                                      }
                                      id="btn_remove_items_li_ul_add_topic"
                                    >
                                      <img src={multiple_red} alt="" />
                                    </button>
                                  </li>
                                )
                              )}
                            </ul>
                            {selectedTopic === topic && (
                              <div className="ul_topic_body_popup_add_subject_input">
                                <input
                                  type="text"
                                  placeholder="Enter SubTopic"
                                  value={newSubTopic}
                                  onChange={(e) =>
                                    setNewSubTopic(e.target.value)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    addSubtopic(topic, selectedProjectName)
                                  }
                                >
                                  ADD SubTopic
                                </button>
                              </div>
                            )}
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </DialogContent>
              <DialogActions className="btns_bottom_search_simple">
                <button
                  className="okay_btn_search_simple"
                  onClick={() => handleClosePopupAddTopic(false)}
                >
                  OK
                </button>
                <button
                  className="deny_btn_search_simple"
                  onClick={() => handleClosePopupAddTopic(false)}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>

            <div
              onClick={handleOpenChartPopUp}
              className="btn_add_subjects_analysis"
            >
              <AddchartIcon id="btn_add_subjects_analysis_icon" />
              <span className="btn_add_subjects_analysis_tooltip">
                Charts Statistics
              </span>
            </div>
            <Dialog
              className="popup_charts_statistics"
              open={openChartPopUp}
              onClose={() => handleCloseChartPopUp(false)}
            >
              <DialogTitle className="header_popup_right_header_add_admin_page">
                <label>Display Chart Statistics</label>
                <button
                  onClick={() => handleCloseChartPopUp(false)}
                  className="btn_close_header_popup_right_header_add_admin_page"
                >
                  <CloseIcon />
                </button>
              </DialogTitle>
              <DialogContent className="body_popup_charts_statistics">
                <div className="items_body_popup_charts_statistics">
                  <div
                    className="header_items_body_popup_charts_statistics"
                    onClick={() =>
                      handleOpenStatisticsImpression(isOpenStatisticsImpression)
                    }
                  >
                    <span>Impression Chart</span>
                    <div
                      className={`img_header_filter_fb ${
                        language === "ar" ? "img_header_filter_fb_ar" : ""
                      } ${
                        isOpenStatisticsImpression
                          ? "rotate_up_arrow_analysis"
                          : ""
                      }`}
                    >
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                  {postByWordsData && postByWordsData.action_chart ? (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsImpression ? "flex" : "none",
                      }}
                    >
                      <span>
                        Plus :{" "}
                        <span>{postByWordsData.action_chart.happy ?? 0}</span>
                      </span>
                      <span>
                        Minus :{" "}
                        <span>{postByWordsData.action_chart.sad ?? 0}</span>
                      </span>
                      <span>
                        Zero :{" "}
                        <span>
                          {postByWordsData.action_chart.indifferent ?? 0}
                        </span>
                      </span>
                      <label className="cyberpunk_body_items_body_popup_charts_statistics">
                        <input
                          type="checkbox"
                          checked={
                            fakeChartData &&
                            fakeChartData.action_chart &&
                            fakeChartData.action_chart.isActive
                              ? true
                              : isCheckedImpression
                          }
                          onChange={(e) =>
                            handleCheckedImpression(e.target.checked)
                          }
                          className="checkbox_body_items_body_popup_charts_statistics"
                        />
                        Manual Chart
                      </label>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}

                  {(isCheckedImpression ||
                    (fakeChartData &&
                      fakeChartData.action_chart &&
                      fakeChartData.action_chart.isActive)) && (
                    <div
                      className="inputs_body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsImpression ? "flex" : "none",
                      }}
                    >
                      <div className="coolinput_body_items_body_popup_charts_statistics">
                        <label htmlFor="input_popup_charts_statistics">
                          Plus :
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Number Plus"
                          name="input_popup_charts_statistics"
                          className="input_body_items_body_popup_charts_statistics"
                          onChange={(e) =>
                            setImpressionFakeChartPlus(e.target.valueAsNumber)
                          }
                          value={
                            impressionFakeChartPlus
                              ? impressionFakeChartPlus
                              : fakeChartData &&
                                fakeChartData.action_chart &&
                                fakeChartData.action_chart.isActive
                              ? fakeChartData.action_chart.chartText.happy
                              : 0
                          }
                        />
                      </div>
                      <div className="coolinput_body_items_body_popup_charts_statistics">
                        <label htmlFor="input_popup_charts_statistics">
                          Minus :
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Number Minus"
                          name="input_popup_charts_statistics"
                          className="input_body_items_body_popup_charts_statistics"
                          onChange={(e) =>
                            setImpressionFakeChartMinus(e.target.valueAsNumber)
                          }
                          value={
                            impressionFakeChartMinus
                              ? impressionFakeChartMinus
                              : fakeChartData &&
                                fakeChartData.action_chart &&
                                fakeChartData.action_chart.isActive
                              ? fakeChartData.action_chart.chartText.sad
                              : 0
                          }
                        />
                      </div>
                      <div className="coolinput_body_items_body_popup_charts_statistics">
                        <label htmlFor="input_popup_charts_statistics">
                          Zero :
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Number Zero"
                          name="input_popup_charts_statistics"
                          className="input_body_items_body_popup_charts_statistics"
                          onChange={(e) =>
                            setImpressionFakeChartZero(e.target.valueAsNumber)
                          }
                          value={
                            impressionFakeChartZero
                              ? impressionFakeChartZero
                              : fakeChartData &&
                                fakeChartData.action_chart &&
                                fakeChartData.action_chart.isActive
                              ? fakeChartData.action_chart.chartText.indifferent
                              : 0
                          }
                        />
                      </div>
                      <div className="coolinput_body_items_body_popup_charts_statistics">
                        <button
                          onClick={() => handleImpressionFakeChartSend(true)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="items_body_popup_charts_statistics">
                  <div
                    className="header_items_body_popup_charts_statistics"
                    onClick={() =>
                      handleOpenStatisticsTopic(isOpenStatisticsTopicSubTopic)
                    }
                  >
                    <span>Topic And SubTopic Chart</span>
                    <div
                      className={`img_header_filter_fb ${
                        language === "ar" ? "img_header_filter_fb_ar" : ""
                      } ${
                        isOpenStatisticsTopicSubTopic
                          ? "rotate_up_arrow_analysis"
                          : ""
                      }`}
                    >
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                  {postByWordsData && postByWordsData.topic_chart ? (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsTopicSubTopic
                          ? "flex"
                          : "none",
                      }}
                    >
                      <div className="tree_view_body_items_body_popup_charts_statistics">
                        {Object.keys(postByWordsData.topic_chart).map(
                          (mainTopic) => (
                            <div
                              key={mainTopic}
                              className="main_topic_body_items_body_popup_charts_statistics"
                            >
                              <div
                                onClick={() => toggleSubTopics(mainTopic)}
                                className="toggle_icon_body_items_body_popup_charts_statistics"
                              >
                                <span>+ {mainTopic}</span>
                              </div>
                              {topicsSubTopic[mainTopic] && (
                                <div className="sub_topics_body_items_body_popup_charts_statistics">
                                  {Object.entries(
                                    postByWordsData.topic_chart[mainTopic]
                                      .childs
                                  ).map(([subTopic, value]) => (
                                    <div
                                      key={subTopic}
                                      className="sub_topic_body_items_body_popup_charts_statistics"
                                    >
                                      <div
                                        onClick={() =>
                                          toggleSubTopics(subTopic)
                                        }
                                        className="toggle_icon_body_items_body_popup_charts_statistics_sub"
                                      >
                                        {topicsSubTopic[subTopic] ? (
                                          <span>
                                            {subTopic} : {value}
                                          </span>
                                        ) : (
                                          <span>
                                            {subTopic} : {value}
                                          </span>
                                        )}
                                      </div>
                                      {topicsSubTopic[subTopic] && (
                                        <div className="sub_topics_body_items_body_popup_charts_statistics">
                                          {Object.entries(value).map(
                                            ([subSubTopic, subSubValue]) => (
                                              <div
                                                key={subSubTopic}
                                                className="sub_topic_body_items_body_popup_charts_statistics"
                                              >
                                                - {subSubTopic} ({subSubValue})
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>

                      <label className="cyberpunk_body_items_body_popup_charts_statistics">
                        <input
                          type="checkbox"
                          checked={
                            fakeChartData &&
                            fakeChartData.topic_chart &&
                            fakeChartData.topic_chart.isActive
                              ? true
                              : isCheckedTopicSubTopic
                          }
                          onChange={(e) => handleCheckedTopic(e.target.checked)}
                          className="checkbox_body_items_body_popup_charts_statistics"
                        />
                        Manual Chart
                      </label>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}

                  {isCheckedTopicSubTopic && (
                    <div
                      className="topicsandsubtopic_chart_manual"
                      style={{
                        display: isOpenStatisticsTopicSubTopic
                          ? "flex"
                          : "none",
                      }}
                    >
                      <div className="topicsandsubtopic_chart_manual_input_main">
                        {/* <input
                          type="text"
                          placeholder="Enter Main Topic"
                          className="input_body_items_body_popup_charts_statistics"
                          value={mainTopicChart}
                          onChange={(e) => setMainTopicChart(e.target.value)}
                        /> */}
                        {/* </div> */}
                        <ul className="main_topics_topicsandsubtopic_chart_manual">
                          {Object.keys(postByWordsData.topic_chart).map(
                            (mainTopic) => (
                              <li key={mainTopic}>
                                <div
                                  className="main_topic_header_main_topics_topicsandsubtopic_chart_manual"
                                  // onClick={() => toggleSubTopicInput(index)}
                                >
                                  {mainTopic}
                                </div>

                                <ul className="sub_topics_main_topics_topicsandsubtopic_chart_manual">
                                  {Object.entries(
                                    postByWordsData.topic_chart[mainTopic]
                                      .childs
                                  ).map(([subTopic, value]) => (
                                    <li key={subTopic}>
                                      {subTopic}
                                      <br></br>
                                      <br></br>
                                      <select
                                        onChange={(e) =>
                                          handleSelectChangeTopic(e)
                                        }
                                        name={mainTopic + "^" + subTopic}
                                        value={
                                          topicFakeChartSelects[
                                            mainTopic + "^" + subTopic
                                          ]
                                            ? topicFakeChartSelects[
                                                mainTopic + "^" + subTopic
                                              ]
                                            : fakeChartData &&
                                              fakeChartData.topic_chart &&
                                              fakeChartData.topic_chart.isActive
                                            ? fakeChartData.topic_chart
                                                .chartText[mainTopic][subTopic]
                                                .select
                                            : "happy"
                                        }
                                      >
                                        <option value="happy">Plus</option>

                                        <option value="sad">Minus</option>

                                        <option value="indifferent">
                                          Zero
                                        </option>
                                      </select>
                                      <br></br>
                                      <br></br>
                                      <input
                                        type="number"
                                        placeholder="Enter Number"
                                        className="input_body_items_body_popup_charts_statistics"
                                        value={
                                          topicFakeChartInputs[
                                            mainTopic + "^" + subTopic
                                          ]
                                            ? topicFakeChartInputs[
                                                mainTopic + "^" + subTopic
                                              ]
                                            : fakeChartData &&
                                              fakeChartData.topic_chart &&
                                              fakeChartData.topic_chart.isActive
                                            ? fakeChartData.topic_chart
                                                .chartText[mainTopic][subTopic]
                                                .number
                                            : 0
                                        }
                                        onChange={
                                          handleTopicFakeChartInputChange
                                        }
                                        name={mainTopic + "^" + subTopic}
                                      />
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <button onClick={() => handleTopicFakeChartSend(true)}>
                        Send
                      </button>
                    </div>
                  )}
                </div>
                <div className="items_body_popup_charts_statistics">
                  <div
                    className="header_items_body_popup_charts_statistics"
                    onClick={() =>
                      handleOpenStatisticsAxis(isOpenStatisticsAxis)
                    }
                  >
                    <span>Axis Chart</span>
                    <div
                      className={`img_header_filter_fb ${
                        language === "ar" ? "img_header_filter_fb_ar" : ""
                      } ${
                        isOpenStatisticsAxis ? "rotate_up_arrow_analysis" : ""
                      }`}
                    >
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                  {postByWordsData && postByWordsData.axis_chart ? (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsAxis ? "flex" : "none",
                      }}
                    >
                      {Object.entries(postByWordsData.axis_chart).map(
                        ([name, value]) => (
                          <div key={name}>
                            <span>{name}</span>: <span>{value}</span>
                          </div>
                        )
                      )}
                      <label className="cyberpunk_body_items_body_popup_charts_statistics">
                        <input
                          type="checkbox"
                          checked={
                            fakeChartData &&
                            fakeChartData.axis_chart &&
                            fakeChartData.axis_chart.isActive
                              ? true
                              : isCheckedAxis
                          }
                          onChange={(e) => handleCheckedAxis(e.target.checked)}
                          className="checkbox_body_items_body_popup_charts_statistics"
                        />
                        Manual Chart
                      </label>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}

                  {(isCheckedAxis ||
                    (fakeChartData &&
                      fakeChartData.axis_chart &&
                      fakeChartData.axis_chart.isActive)) && (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsAxis ? "flex" : "none",
                      }}
                    >
                      <div className="body_items_body_popup_charts_statistics_axis">
                        <div className="top_body_items_body_popup_charts_statistics_axis">
                          {Object.entries(postByWordsData.axis_chart).map(
                            ([name, value]) => (
                              <div key={name}>
                                <span>{name}</span>:
                                <input
                                  type="number"
                                  placeholder="Enter Number Axis"
                                  className="input_body_items_body_popup_charts_statistics"
                                  value={
                                    axisFakeChartInputs[name]
                                      ? axisFakeChartInputs[name]
                                      : fakeChartData &&
                                        fakeChartData.axis_chart &&
                                        fakeChartData.axis_chart.isActive
                                      ? fakeChartData.axis_chart.chartText[name]
                                      : 0
                                  }
                                  onChange={handleAxisFakeChartInputChange}
                                  name={name}
                                />
                              </div>
                            )
                          )}
                          {/* <input
                            type="text"
                            placeholder="Enter Axis"
                            className="input_body_items_body_popup_charts_statistics"
                            value={axisChart}
                            onChange={(e) => setAxisChart(e.target.value)}
                          />
                          <input
                            type="number"
                            placeholder="Enter Number Axis"
                            className="input_body_items_body_popup_charts_statistics"
                            value={numberAxisChart}
                            onChange={(e) => setNumberAxisChart(e.target.value)}
                          /> */}
                          <button onClick={() => handleAxisFakeChartSend(true)}>
                            Send
                          </button>
                        </div>
                        {/* <ul className="ul_body_items_body_popup_charts_statistics_axis">
                          {axisListChart.map((item, index) => (
                            <li key={index}>
                              {`Axis: ${item.axis} - Number Axis: ${item.numberAxis}`}
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="items_body_popup_charts_statistics">
                  <div
                    className="header_items_body_popup_charts_statistics"
                    onClick={() =>
                      setIsOpenStatisticsEmotions(!isOpenStatisticsEmotions)
                    }
                  >
                    <span>Emotions Chart</span>
                    <div
                      className={`img_header_filter_fb ${
                        language === "ar" ? "img_header_filter_fb_ar" : ""
                      } ${
                        isOpenStatisticsEmotions
                          ? "rotate_up_arrow_analysis"
                          : ""
                      }`}
                    >
                      <img src={arrow_down} alt="" />
                    </div>
                  </div>
                  {postByWordsData && postByWordsData.emotion_chart ? (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsEmotions ? "flex" : "none",
                      }}
                    >
                      {Object.entries(postByWordsData.emotion_chart).map(
                        ([name, value]) => (
                          <div key={name}>
                            <span>{name}</span>: <span>{value}</span>
                          </div>
                        )
                      )}
                      <label className="cyberpunk_body_items_body_popup_charts_statistics">
                        <input
                          type="checkbox"
                          checked={
                            fakeChartData &&
                            fakeChartData.emotion_chart &&
                            fakeChartData.emotion_chart.isActive
                              ? true
                              : isCheckedEmotions
                          }
                          onChange={(e) =>
                            handleCheckedEmotion(e.target.checked)
                          }
                          className="checkbox_body_items_body_popup_charts_statistics"
                        />
                        Manual Chart
                      </label>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}
                  {(isCheckedEmotions ||
                    (fakeChartData &&
                      fakeChartData.emotion_chart &&
                      fakeChartData.emotion_chart.isActive)) && (
                    <div
                      className="body_items_body_popup_charts_statistics"
                      style={{
                        display: isOpenStatisticsEmotions ? "flex" : "none",
                      }}
                    >
                      <div className="body_items_body_popup_charts_statistics_axis">
                        <div className="top_body_items_body_popup_charts_statistics_axis">
                          {Object.entries(postByWordsData.emotion_chart).map(
                            ([name, value]) => (
                              <div key={name}>
                                <span>{name}</span>:
                                <input
                                  type="number"
                                  placeholder="Enter Number Axis"
                                  className="input_body_items_body_popup_charts_statistics"
                                  value={
                                    emotionFakeChartInputs[name]
                                      ? emotionFakeChartInputs[name]
                                      : fakeChartData &&
                                        fakeChartData.emotion_chart &&
                                        fakeChartData.emotion_chart.isActive
                                      ? fakeChartData.emotion_chart.chartText[
                                          name
                                        ]
                                      : 0
                                  }
                                  onChange={handleEmotionFakeChartInputChange}
                                  name={name}
                                />
                              </div>
                            )
                          )}
                          {/* <input
                            type="text"
                            placeholder="Enter Emotions"
                            className="input_body_items_body_popup_charts_statistics"
                            value={EmotionsChart}
                            onChange={(e) => setEmotionsChart(e.target.value)}
                          />
                          <input
                            type="number"
                            placeholder="Enter Number Emotions"
                            className="input_body_items_body_popup_charts_statistics"
                            value={numberEmotionsChart}
                            onChange={(e) =>
                              setNumberEmotionsChart(e.target.value)
                            }
                          /> */}
                          <button
                            onClick={() => handleEmotionFakeChartSend(true)}
                          >
                            Send
                          </button>
                        </div>
                        <ul className="ul_body_items_body_popup_charts_statistics_axis">
                          {EmotionsListChart.map((item, index) => (
                            <li key={index}>
                              {`Emotions: ${item.Emotions} - Number Emotions: ${item.numberEmotions}`}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions className="btns_bottom_search_simple">
                <button
                  className="okay_btn_search_simple"
                  onClick={() => handleCloseChartPopUp(false)}
                >
                  OK
                </button>
                <button
                  className="deny_btn_search_simple"
                  onClick={() => handleCloseChartPopUp(false)}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>

            {language === "en" ? (
              <label className="dropdown_exel_btn_fb">
                <img src={Excel} id="exel-icon" alt="" />
                <input type="checkbox" className="ch_input_exel_btn_fb" />

                <ul
                  className={`submenu_exel_btn_fb ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  <li onClick={() => handleRecordExportToExcel(10)}>
                    10 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(50)}>
                    50 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(100)}>
                    100 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(1000)}>
                    1000 Record
                  </li>
                  <li className="divider_exel_btn"></li>
                  <li onClick={handleExportToExcel}>Selected records</li>
                  <li onClick={handleExportCurrentToExcel}>Current records</li>
                  <li onClick={() => handleAllRecordsExport(1000)}>
                    All records
                  </li>
                </ul>
              </label>
            ) : (
              <label className="dropdown_exel_btn_fb_ar">
                <img src={Excel} id="exel-icon" alt="" />
                <input type="checkbox" className="ch_input_exel_btn_fb_ar" />

                <ul
                  className={`submenu_exel_btn_fb_ar ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  <li onClick={() => handleRecordExportToExcel(10)}>
                    10 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(50)}>
                    50 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(100)}>
                    100 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(1000)}>
                    1000 Record
                  </li>
                  <li className="divider_exel_btn"></li>
                  <li onClick={handleExportToExcel}>Selected records</li>
                  <li onClick={handleExportCurrentToExcel}>Current records</li>
                  <li onClick={() => handleAllRecordsExport(1000)}>
                    All records
                  </li>
                </ul>
              </label>
            )}
          </div>

          <div
            className={`header_bottom_posts_fb_analysis ${
              language === "ar" ? "header_bottom_posts_fb_analysis_ar" : ""
            } ${theme === "dark" ? "dark_mode" : ""}`}
          >
            <div
              className={`user_avatar_fb_post_analysis_header ${
                language === "ar"
                  ? "user_avatar_fb_post_analysis_header_ar"
                  : "user_avatar_fb_post_analysis_header_en"
              } ${
                selectedOptionSocial.label === "Google"
                  ? "user_avatar_fb_post_analysis_header_google"
                  : ""
              }`}
            >
              {language === "en" ? (
                <div className="profile_body_facebook_page_analysis">
                  <img
                    src={broom_icon}
                    alt=""
                    title="Clear All Filter Users"
                    onClick={handleClearFiltersUser}
                  />

                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterUsersImage}
                      alt=""
                      onClick={toggleMenuUser}
                    />
                    {isOpenUser && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Name"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By UserName"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Users</span> */}
                </div>
              ) : (
                <div className="profile_body_facebook_page_analysis">
                  {/* <span>المستخدمون</span> */}
                  <div className="dropdown_filter_items_posts_fb_ar">
                    <img
                      src={currentFilterUsersImage}
                      alt=""
                      onClick={toggleMenuUser}
                    />
                    {isOpenUser && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="تصفية حسب الاسم"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="التصفية حسب اسم المستخدم"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt=""
                    title="مسح كافة تصفية المستخدمين"
                    onClick={handleClearFiltersUser}
                  />
                </div>
              )}
            </div>
            <div
              className={`text_body_user_fb_post_header ${
                language === "ar"
                  ? "text_body_user_fb_post_header_ar"
                  : "text_body_user_fb_post_header_en"
              }`}
            >
              {language === "en" ? (
                <div className="text_post_body_facebook_page_analysis">
                  <img
                    src={broom_icon}
                    alt=""
                    title="Clear All Filter Text"
                    onClick={handleClearFiltersTextPost}
                  />
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterTextImage}
                      alt=""
                      onClick={toggleMenuText}
                    />

                    {isOpenText && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Likes"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={textFilter}
                            // onChange={(e) => setTextFilter(e.target.value)}
                            onChange={handleTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Exact"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={exactTextFilter}
                            onChange={handleExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Lack"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notTextFilter}
                            onChange={handleNotTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Exact Word Negation"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notExactTextFilter}
                            onChange={handleNotExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By First Word"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={startParagraphFilter}
                            onChange={handleStartParagraphFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Last Word"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={endParagraphFilter}
                            onChange={handleEndParagraphFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Posts Text</span> */}
                </div>
              ) : (
                <div className="text_post_body_facebook_page_analysis">
                  {/* <span>نص المنشورات</span> */}
                  <div className="dropdown_filter_items_posts_fb_ar">
                    <img
                      src={currentFilterTextImage}
                      alt=""
                      onClick={toggleMenuText}
                    />

                    {isOpenText && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الإعجابات"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={textFilter}
                            // onChange={(e) => setTextFilter(e.target.value)}
                            onChange={handleTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على كلمة محددة"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={exactTextFilter}
                            onChange={handleExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على النقص"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notTextFilter}
                            onChange={handleNotTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الكلمة الأولى"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notExactTextFilter}
                            onChange={handleNotExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="تصفية حسب الكلمة الأولى"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={startParagraphFilter}
                            onChange={handleStartParagraphFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الكلمة الأخيرة"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={endParagraphFilter}
                            onChange={handleEndParagraphFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt=""
                    title="مسح كافة تصفية النص"
                    onClick={handleClearFiltersTextPost}
                  />
                </div>
              )}
            </div>
            <div
              className={`items_left_posts_fb_analysis_one_header_senior ${
                language === "en"
                  ? "items_left_posts_fb_analysis_one_header_senior_en"
                  : ""
              }`}
            >
              {language === "en" ? (
                <div className="items_left_posts_fb_analysis_header_rows">
                  <img
                    src={broom_icon}
                    alt="Clear Date Filter"
                    title="Clear Date Filter"
                    onClick={handleClearFiltersDates}
                  />
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterDateImage}
                      alt=""
                      onClick={toggleMenuDate}
                    />

                    {isOpenDate && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "From Date" }}
                            value={fromDate}
                            // onChange={(date) => setFromDate(date)}
                            onChange={(date) => {
                              setFromDate(date);
                              handleFromDateFilterChange();
                            }}
                          />
                        </li>
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "Until Date" }}
                            value={untilDate}
                            // onChange={(date) => setUntilDate(date)}
                            onChange={(date) => {
                              setUntilDate(date);
                              handleUntilDateFilterChange();
                            }}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Date</span> */}
                </div>
              ) : (
                <div className="items_left_posts_fb_analysis_header_rows">
                  {/* <span>تاریخ</span> */}
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterDateImage}
                      alt=""
                      onClick={toggleMenuDate}
                    />

                    {isOpenDate && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "من التاريخ" }}
                            value={fromDate}
                            onChange={(date) => {
                              setFromDate(date);
                              handleFromDateFilterChange();
                            }}
                          />
                        </li>
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "تا التاريخ" }}
                            value={untilDate}
                            onChange={(date) => {
                              setUntilDate(date);
                              handleUntilDateFilterChange();
                            }}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt="مسح مرشح التاريخ"
                    title="مسح مرشح التاريخ"
                    onClick={handleClearFiltersDates}
                  />
                </div>
              )}
            </div>
            {selectedOptionSocial.label === "Google" ? null : (
              <div className="items_left_posts_fb_analysis_one_header_sort">
                <div className="arrow_items_header_result_list_analysis">
                  <button
                    className={
                      sortWeightPosts === "desc" ? "active" : "inactive"
                    }
                    onClick={() => sortByWeightPosts("desc")}
                  >
                    <ArrowDropUpIcon className="arrow_items_header_result_list_analysis_icon" />
                  </button>
                  <button
                    className={
                      sortWeightPosts === "asc" ? "active" : "inactive"
                    }
                    onClick={() => sortByWeightPosts("asc")}
                  >
                    <ArrowDropDownIcon className="arrow_items_header_result_list_analysis_icon" />
                  </button>
                </div>
              </div>
            )}
            <div
              className={`items_left_posts_fb_analysis_five_header_senior ${
                language === "en"
                  ? "items_left_posts_fb_analysis_five_header_senior_en"
                  : ""
              }`}
            >
              <img
                src={checkbox_gray}
                alt=""
                className={`checkbox_header ${
                  language === "ar"
                    ? "checkbox_header_ar"
                    : "checkbox_header_en"
                }`}
                id="checkboc_icon"
                onClick={handleClearAll}
              />
            </div>
            <div className="items_left_posts_fb_analysis_four_header">
              <img
                onClick={handleHeaderStarClick}
                src={isGold ? Star_gold : Star}
                alt=""
              />
            </div>
            <div
              className={`items_left_posts_fb_analysis_six_header ${
                language === "ar"
                  ? "items_left_posts_fb_analysis_six_header_ar"
                  : "items_left_posts_fb_analysis_six_header_en"
              }`}
            >
              <div className="emoji_icon_header">
                <div className="dropdown_filter_items_posts_fb">
                  <img
                    src={
                      selectedEmojiHeader === "happy" ||
                      selectedEmojiHeader === "sad" ||
                      selectedEmojiHeader === "indifferent"
                        ? sticker_square
                        : sticker_square_gray
                    }
                    alt=""
                    onClick={toggleMenuStickerHeader}
                  />
                  {isOpenStickerHeader && (
                    <ul
                      className={`submenu_filter_btn_fb show ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    >
                      <li>
                        <img
                          src={
                            selectedEmojiHeader === "happy"
                              ? Happy_yellow
                              : Happy
                          }
                          alt=""
                          onClick={() => handleEmojiHeaderClick("happy")}
                        />
                      </li>
                      <li>
                        <img
                          src={selectedEmojiHeader === "sad" ? Sad_yellow : Sad}
                          alt=""
                          onClick={() => handleEmojiHeaderClick("sad")}
                        />
                      </li>
                      <li>
                        <img
                          src={
                            selectedEmojiHeader === "indifferent"
                              ? indifferent_yellow
                              : indifferent
                          }
                          alt=""
                          onClick={() => handleEmojiHeaderClick("indifferent")}
                        />
                      </li>
                      <li>
                        <img
                          src={broom_new}
                          alt=""
                          onClick={handleClearFiltersClick}
                        />
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`items_left_posts_fb_analysis_seven_header ${
                language === "ar"
                  ? "items_left_posts_fb_analysis_seven_header_ar"
                  : "items_left_posts_fb_analysis_seven_header_en"
              }`}
            >
              <div className="tick_icon_header">
                {/* <img
                  src={
                    selectedTickHeader === "tick_green" ? tick_green : tick_gray
                  }
                  alt=""
                  onClick={() => handleTickHeaderClick()}
                /> */}
                <img
                  src={tick_green}
                  alt=""
                  onClick={() => handleTickHeaderClick("")}
                />
              </div>
            </div>
            <div
              className={`items_left_posts_fb_analysis_seven_header_last ${
                language === "ar"
                  ? "items_left_posts_fb_analysis_seven_header_last_ar"
                  : "items_left_posts_fb_analysis_seven_header_last_en"
              }`}
            >
              <div className="tick_icon_header">
                <img
                  src={
                    selectedTickHeaderFilter === "tick_green"
                      ? eye_green
                      : eye_gray
                  }
                  alt=""
                  onClick={() => handleTickHeaderClickFilter("tick_green")}
                />
              </div>
            </div>
          </div>
          <div
            className={`display_posts_fb_analysis_search ${
              theme === "dark" ? "dark_mode" : ""
            }`}
            onScroll={handleLoadMoreClicked}
          >
            {!loadingState && postByWordsData ? (
              postByWordsData.posts
                .filter(
                  (post) =>
                    (!usernameFilter ||
                      post.username.includes(usernameFilter)) &&
                    (!nameFilter || post.name.includes(nameFilter)) &&
                    (!textFilter || post.texts.includes(textFilter)) &&
                    (!exactTextFilter ||
                      post.texts.split(" ").includes(exactTextFilter)) &&
                    (!notTextFilter || !post.texts.includes(notTextFilter)) &&
                    (!notExactTextFilter ||
                      post.texts.split(" ").includes(notExactTextFilter)) &&
                    (!startParagraphFilter ||
                      post.texts.startsWith(startParagraphFilter)) &&
                    (!endParagraphFilter ||
                      post.texts.endsWith(endParagraphFilter)) &&
                    (minLikes === "" ||
                      minLikes === null ||
                      post.likes >= parseInt(minLikes)) &&
                    (maxLikes === "" ||
                      maxLikes === null ||
                      post.likes <= parseInt(maxLikes)) &&
                    (minComments === "" ||
                      minComments === null ||
                      post.comments >= parseInt(minComments)) &&
                    (maxComments === "" ||
                      maxComments === null ||
                      post.comments <= parseInt(maxComments)) &&
                    (fromDate === "" ||
                      new Date(post.creates.split("at")[0]) >=
                        fromDateObject) &&
                    (untilDate === "" ||
                      new Date(post.creates.split("at")[0]) <= untilDateObject)
                )
                .map((element, index) => {
                  const isExpanded = expandedStates[index] || false;

                  const handleToggleExpand = () => {
                    const newExpandedStates = [...expandedStates];
                    newExpandedStates[index] = !isExpanded;
                    setExpandedStates(newExpandedStates);
                  };

                  const maxCharacters = 100;

                  if (
                    (selectedPosts.includes(element.id) ||
                      !showOnlyStarredPosts) &&
                    (selectedTickPosts.includes(element.id) ||
                      !showOnlyTickPosts)
                  ) {
                    if (
                      showOnlyHappyPosts &&
                      selectedEmojis[element.id] !== "happy"
                    ) {
                      return null;
                    }

                    if (
                      showOnlySadPosts &&
                      selectedEmojis[element.id] !== "sad"
                    ) {
                      return null;
                    }

                    if (
                      showOnlyIndifferentPosts &&
                      selectedEmojis[element.id] !== "indifferent"
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className={`items_posts_fb ${
                          language === "ar" ? "items_posts_fb_ar" : ""
                        }`}
                        key={element.id}
                      >
                        <div
                          className={`body_posts_fb ${
                            theme === "dark" ? "dark_mode" : ""
                          }`}
                        >
                          <div
                            className={`user_fb_post ${
                              language === "ar" ? "user_fb_post_ar" : ""
                            }`}
                          >
                            {selectedOptionSocial.label === "Google" ? (
                              <div
                                className={`user_avatar_fb_post_analysis_confirm_google ${
                                  language === "ar"
                                    ? "user_avatar_fb_post_analysis_confirm_google_ar"
                                    : ""
                                }`}
                              >
                                <a
                                  href={element.Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={url_icon} alt="" />
                                </a>
                                <div
                                  className={`spans_user_posts ${
                                    language === "ar"
                                      ? "spans_user_posts_ar"
                                      : ""
                                  }`}
                                >
                                  <span
                                    className={`name_user_fb ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    <span className="tooltip_posts_box_top">
                                      {element.name.length > 30 ? (
                                        element.name.slice(0, 30) + "..."
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={highlightFilterUsers(
                                            element.name
                                          )}
                                        />
                                      )}
                                      <span className="tooltip_text_posts_box_top">
                                        {element.name}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`user_avatar_fb_post_analysis_confirm ${
                                  language === "ar"
                                    ? "user_avatar_fb_post_analysis_confirm_ar"
                                    : ""
                                }`}
                              >
                                <a
                                  href={element.links}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={
                                      element.username
                                        ? facebook_profile_url +
                                          formatFileName(element.username) +
                                          ".png"
                                        : avatar_user
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = avatar_user;
                                    }}
                                    alt=""
                                    className={`post_image ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  />
                                </a>
                                <div
                                  className={`spans_user_posts ${
                                    language === "ar"
                                      ? "spans_user_posts_ar"
                                      : ""
                                  }`}
                                >
                                  <span
                                    className={`name_user_fb ${
                                      theme === "dark" ? "dark_mode" : ""
                                    }`}
                                  >
                                    <span className="tooltip_posts_box_top">
                                      {element.name && element.name.length > 17
                                        ? element.name.slice(0, 17) + "..."
                                        : element.name && (
                                            <span
                                              dangerouslySetInnerHTML={highlightFilterUsers(
                                                element.name
                                              )}
                                            />
                                          )}
                                      <span className="tooltip_text_posts_box_top">
                                        {element.name}
                                      </span>
                                    </span>
                                  </span>

                                  <span className="tooltip_posts_box_bottom">
                                    @
                                    {element.username.length > 17 ? (
                                      element.username.slice(0, 17) + "..."
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={highlightFilterUsers(
                                          element.username
                                        )}
                                      />
                                    )}
                                    <span className="tooltip_text_posts_box_bottom">
                                      {element.username}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )}

                            <div className="text_body_user_fb_post">
                              <p className="text_post_fb_analysis_confirm">
                                {isExpanded ||
                                element.texts.length <= maxCharacters ? (
                                  <span
                                    dangerouslySetInnerHTML={highlightFilterKeywords(
                                      renderPostTextWithStickers(element)
                                    )}
                                  />
                                ) : (
                                  <span>
                                    <span
                                      dangerouslySetInnerHTML={highlightFilterKeywords(
                                        renderPostTextWithStickers(
                                          element
                                        ).slice(0, maxCharacters)
                                      )}
                                    />
                                    ...
                                  </span>
                                )}
                                {/* {element.texts.length > maxCharacters && ( */}
                                <button
                                  className="more_text_btn_fb"
                                  onClick={handleToggleExpand}
                                >
                                  {isExpanded ? "Less" : "More..."}
                                </button>
                                {/* )} */}
                              </p>
                              {isExpanded && (
                                <div className="rowtwo_post_text_body_user_fb_post">
                                  {selectedOptionSocial.label ===
                                  "Google" ? null : (
                                    <div className="items_left_posts_fb_analysis_two">
                                      <img src={Like} alt="" />

                                      {element.likes}
                                    </div>
                                  )}
                                  {selectedOptionSocial.label ===
                                  "Google" ? null : (
                                    <div className="items_left_posts_fb_analysis_three">
                                      <img src={Comment} alt="" />
                                      {element.comments}
                                    </div>
                                  )}
                                  <div className="items_left_posts_fb_analysis_five">
                                    <span className="tooltip">
                                      <img
                                        src={edit_icon}
                                        alt=""
                                        onClick={() =>
                                          handleOpenPopupEditPosts(
                                            element.creates,
                                            element.texts
                                          )
                                        }
                                      />
                                      <span className="tooltip_text">
                                        Edit Post
                                      </span>
                                    </span>
                                    <Dialog
                                      className="popup_add_subject"
                                      open={showPopupEditPosts}
                                      onClose={() =>
                                        handleClosePopupEditPosts(false)
                                      }
                                    >
                                      <DialogTitle className="header_popup_right_header_add_admin_page">
                                        <label>Edit Title Post</label>
                                        <button
                                          onClick={() =>
                                            handleClosePopupEditPosts(false)
                                          }
                                          className="btn_close_header_popup_right_header_add_admin_page"
                                        >
                                          <CloseIcon />
                                        </button>
                                      </DialogTitle>
                                      <DialogContent className="body_popup_edit_title">
                                        <textarea
                                          placeholder="Enter Title"
                                          value={selectedTextEdit}
                                          onChange={(e) =>
                                            setSelectedTextEdit(e.target.value)
                                          }
                                        />
                                        <input
                                          type="date"
                                          value={selectedDateEdit}
                                          onChange={(e) =>
                                            setSelectedDateEdit(e.target.value)
                                          }
                                        />
                                      </DialogContent>
                                      <DialogActions className="btns_bottom_search_simple">
                                        <button
                                          className="okay_btn_search_simple"
                                          onClick={() =>
                                            handlePostEdit(element.id, index)
                                          }
                                        >
                                          OK
                                        </button>
                                        <button
                                          className="deny_btn_search_simple"
                                          onClick={() =>
                                            handleClosePopupEditPosts(false)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                                  <div className="items_left_posts_fb_analysis_five">
                                    <span className="tooltip">
                                      <img
                                        src={delete_icon}
                                        alt="Delete Post"
                                        onClick={() =>
                                          handlePostDelete(element.id, index)
                                        }
                                      />
                                      <span className="tooltip_text">
                                        Delete Post
                                      </span>
                                    </span>
                                  </div>
                                  <div className="items_left_posts_fb_analysis_four">
                                    <input
                                      type="checkbox"
                                      className={`checkbox_input ${
                                        language === "ar"
                                          ? "checkbox_input_ar"
                                          : ""
                                      }`}
                                      id="checkboc_icon"
                                      onChange={handleCheckboxChange}
                                    />
                                  </div>
                                  <div className="items_left_posts_fb_analysis_five">
                                    <div
                                      onClick={() =>
                                        handlePostStarClick(element.id)
                                      }
                                    >
                                      <img
                                        src={Star}
                                        alt=""
                                        className={`star-icon ${
                                          selectedPosts.includes(element.id)
                                            ? "star-selected"
                                            : ""
                                        }`}
                                        style={{
                                          display: selectedPosts.includes(
                                            element.id
                                          )
                                            ? "none"
                                            : "block",
                                        }}
                                      />
                                      <img
                                        src={Star_gold}
                                        alt=""
                                        className={`star-icon ${
                                          selectedPosts.includes(element.id)
                                            ? "star-selected"
                                            : ""
                                        }`}
                                        style={{
                                          display: selectedPosts.includes(
                                            element.id
                                          )
                                            ? "block"
                                            : "none",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="items_left_posts_fb_analysis_one_parent">
                              <div className="items_left_posts_fb_analysis_one_confirm">
                                {element.creates
                                  ? new Date(
                                      element.creates.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })
                                  : ""}
                              </div>
                            </div>

                            {selectedOptionSocial.label === "Google" ? null : (
                              <div className="items_left_posts_fb_analysis_one_parent">
                                <div className="items_left_posts_fb_analysis_one_confirm">
                                  {element.weight_number}
                                </div>
                              </div>
                            )}

                            <div className="items_left_posts_fb_analysis_one_parent">
                              <div className="approver_tager_item_analysis_page">
                                <div className="approver_tager_item_analysis_page_div">
                                  <img src={tager} alt="tager" />
                                  <span>
                                    {element.action
                                      ? element.action.username
                                        ? element.action.username
                                        : "-"
                                      : "-"}
                                  </span>
                                </div>
                                <div className="approver_tager_item_analysis_page_div">
                                  <img src={Approver} alt="tager" />
                                  <span>
                                    {element.action
                                      ? element.action.last_edit_username
                                        ? element.action.last_edit_username
                                        : "-"
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="top_left_bottom_posts_btn_fb_confirm">
                              <img
                                src={
                                  selectedEmojis[element.id] === "happy"
                                    ? Happy_yellow
                                    : Happy
                                }
                                alt=""
                                onClick={() =>
                                  handleEmojiClick(element.id, "happy", true)
                                }
                              />

                              <img
                                src={
                                  selectedEmojis[element.id] === "indifferent"
                                    ? indifferent_yellow
                                    : indifferent
                                }
                                alt=""
                                onClick={() =>
                                  handleEmojiClick(
                                    element.id,
                                    "indifferent",
                                    true
                                  )
                                }
                              />

                              <img
                                src={
                                  selectedEmojis[element.id] === "sad"
                                    ? Sad_yellow
                                    : Sad
                                }
                                alt=""
                                onClick={() =>
                                  handleEmojiClick(element.id, "sad", true)
                                }
                              />
                            </div>
                            <div className="analysis_menu_bottom_fb_analysis_search_parent">
                              <div className="analysis_menu_bottom_fb_analysis_search_new">
                                <AnalysisMenuAxis
                                  data={axisData}
                                  setSelected={setSelectedAxis}
                                  setPosts={setpostByWordsData}
                                  post={element}
                                  index={index}
                                />
                              </div>
                              <div className="analysis_menu_bottom_fb_analysis_search_new">
                                <AnalysisMenuEmotion
                                  data={emotionsData}
                                  setSelected={setSelectedEmotion}
                                  setPosts={setpostByWordsData}
                                  post={element}
                                  index={index}
                                />
                              </div>
                              <div className="analysis_menu_bottom_fb_analysis_search_new">
                                <AnalysisMenuAccent
                                  data={accentData}
                                  setSelected={setSelectedAccent}
                                  setPosts={setpostByWordsData}
                                  post={element}
                                  index={index}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="bottom_body_user_fb_post_analysis">
                            <div className="menu_bottom_posts_analysis_search">
                              <div className="bottom_left_bottom_posts_btn_fb">
                                <img
                                  src={
                                    selectedTickPosts.includes(element.id)
                                      ? tick_green
                                      : tick_gray
                                  }
                                  alt=""
                                  onClick={() =>
                                    handleStickerClick(
                                      element.id,
                                      "tick",
                                      true,
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="analysis_menu_bottom_fb_analysis_search">
                                <AnalysisMenuSubtopic
                                  data={subject_projects.find(
                                    (x) => x.id === selectedProject
                                  )}
                                  setSelected={setSelectedSubSubProject}
                                  subject={selectedSubProject}
                                  setPosts={setpostByWordsData}
                                  post={element}
                                  index={index}
                                />
                              </div>
                              <div className="analysis_menu_bottom_fb_analysis_search">
                                <AnalysisMenuMainTopic
                                  data={subject_projects.find(
                                    (x) => x.id === selectedProject
                                  )}
                                  setSelected={setSelectedSubProject}
                                  setPosts={setpostByWordsData}
                                  index={index}
                                  post={element}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                })
            ) : (
              <HashLoader color="#458BDF" size={30} />
            )}

            {loadingMoreState ? <HashLoader color="#458BDF" size={30} /> : ""}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div
      className={`senior_confirm_page ${
        language === "ar" ? "senior_confirm_page_ar" : ""
      } ${theme === "dark" ? "dark_mode" : ""}`}
    >
      {needLogin ? <Navigate to="/" /> : null}
      {fetchedApi ? (
        renderSeniorConfirmPage
      ) : (
        <div className="loading-spinner">
          <HashLoader color="#458BDF" size={50} />
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
}

export default SeniorConfirmPage;
