import React, { useEffect, useRef, useState, useContext } from "react";
import * as echarts from "echarts";
import Select from "react-select";
import { LanguageContext } from "../../../App";

const EmotionalAnalysisChartIN = ({ instagramPosts }) => {
  const [selectedEmotion, setSelectedEmotion] = useState("");
  const [chartData, setChartData] = useState(null);
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (instagramPosts && instagramPosts.emotion_chart_new) {
      const emotions = Object.keys(instagramPosts.emotion_chart_new);
      if (emotions.length > 0) {
        setChartData(
          emotions.reduce((acc, emotion) => {
            const data = instagramPosts.emotion_chart_new[emotion];
            Object.keys(data).forEach((date) => {
              if (!acc[date]) {
                acc[date] = {};
              }
              acc[date][emotion] = data[date];
            });
            return acc;
          }, {})
        );
        setSelectedEmotion(emotions[0]);
      } else {
        setChartData(null);
      }
    } else {
      setChartData(null);
    }
  }, [instagramPosts]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      if (selectedEmotion && chartData) {
        const dates = Object.keys(chartData).sort();
        const values = dates.map((date) => chartData[date][selectedEmotion]);

        const options = {
          tooltip: {
            trigger: "axis",
            formatter: "{b}: {c}",
          },
          xAxis: {
            type: "category",
            data: dates,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              type: "line",
              data: values,
            },
          ],
        };

        chart.setOption(options);
      } else {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text:
                        language === "ar"
                          ? "ليس هنالك معلومات"
                          : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      }

      chart.resize();

      return () => {
        chart.dispose();
      };
    }
  }, [selectedEmotion, chartData, language]);

  const handleEmotionChange = (selectedOption) => {
    setSelectedEmotion(selectedOption.value);
  };

  const emotionOptions = Object.keys(
    instagramPosts?.emotion_chart_new || {}
  ).map((emotion) => ({
    value: emotion,
    label: emotion,
  }));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "end",
        flexDirection: "column",
      }}
    >
      <Select
        options={emotionOptions}
        value={emotionOptions.find(
          (option) => option.value === selectedEmotion
        )}
        onChange={handleEmotionChange}
        className="select_menu_emotional_analysis_chart_second"
      />
      <div
        ref={chartRef}
        style={{
          width: "100%",
          height: "400px",
          borderBottom: "2px solid #ccc",
          paddingBottom: "30px",
          marginBottom: "30px",
        }}
      />
    </div>
  );
};

export default EmotionalAnalysisChartIN;
