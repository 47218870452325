import React, { useEffect, useContext } from "react";
import * as echarts from "echarts";
import { LanguageContext } from "../../../App";

const ApproachSearchChartGaugeIN = ({ instagramPosts, onActionClick }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const chart = echarts.init(
      document.getElementById("chart_Approach_fb_guage_in")
    );

    const data = [
      { value: instagramPosts.action_chart.happy, name: "إيجابي" },
      { value: instagramPosts.action_chart.indifferent, name: "محايد" },
      { value: instagramPosts.action_chart.sad, name: "سلبي" },
    ];

    const getItemColor = (name) => {
      const colorMap = {
        إيجابي: "#4caf50",
        محايد: "#a7bcb9",
        سلبي: "#E80505",
      };
      return colorMap[name];
    };
    const validData = data.filter((item) => !isNaN(item.value));
    if (validData.length > 0) {
      const total = validData.reduce((acc, item) => acc + item.value, 0);

      const gaugeData = validData.map((item, index) => ({
        value: item.value,
        name: item.name,
        title: {
          offsetCenter: [`${(index - 1) * 50}%`, "80%"],
        },
        detail: {
          offsetCenter: [`${(index - 1) * 50}%`, "100%"],
        },
        percentage: parseFloat(((item.value / total) * 100).toFixed(2)),
        itemStyle: {
          color: getItemColor(item.name),
        },
      }));

      const options = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const { name } = params;
            const percentage = gaugeData.find(
              (item) => item.name === name
            )?.percentage;
            return `${name} : (${percentage}%)`;
          },
        },

        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            data: gaugeData.map((item) => ({
              value: item.value,
              name: item.name,
              title: item.title,
              itemStyle: item.itemStyle,
            })),
            label: {
              fontFamily: "Segoe UI",
              fontSize: 18,
              fontWeight: "bold",
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                color: function (params) {
                  var colorListlabel = [];
                  return colorListlabel[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      chart.setOption(options);
      chart.on("click", function (params) {
        const clickedSectionName = params.name;
        let englishText = "";
        switch (clickedSectionName) {
          case "إيجابي":
            englishText = "Happy";
            break;
          case "محايد":
            englishText = "Indifferent";
            break;
          case "سلبي":
            englishText = "Sad";
            break;
          default:
            englishText = "";
        }
        onActionClick(englishText);
      });
    } else {
      chart.setOption({
        graphic: {
          elements: [
            {
              type: "group",
              left: "center",
              top: "middle",
              children: [
                {
                  type: "rect",
                  left: "center",
                  top: "middle",
                  shape: {
                    width: 300,
                    height: 45,
                    r: 12,
                  },
                  style: {
                    fill: "#f4f7fe",
                    stroke: "#458bdf",
                    lineWidth: 2,
                  },
                },
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  style: {
                    text:
                      language === "ar"
                        ? "ليس هنالك معلومات"
                        : "No data available",
                    font: 'bold 16px "Segoe UI", sans-serif',
                    fill: "#000",
                  },
                },
              ],
            },
          ],
        },
      });
    }
    return () => {
      chart.dispose();
    };
  }, [instagramPosts, onActionClick, language]);

  return (
    <div
      id="chart_Approach_fb_guage_in"
      style={{ width: "40%", height: "400px" }}
    ></div>
  );
};
export default ApproachSearchChartGaugeIN;
