import React, { useEffect, useRef, useContext } from "react";
import * as echarts from "echarts";
import { LanguageContext } from "../../../App";

const AccentChartRadarFB = ({ facebookPosts, onAxisClick }) => {
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (facebookPosts && facebookPosts.accent_chart) {
      const chart = echarts.init(chartRef.current);

      const radarData = Object.keys(facebookPosts.accent_chart).map((key) => ({
        name: key,
        value: facebookPosts.accent_chart[key],
      }));

      if (radarData.length === 0) {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text:
                        language === "ar"
                          ? "ليس هنالك معلومات"
                          : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      } else {
        const totalValue = radarData.reduce((acc, item) => acc + item.value, 0);
        const indicators = radarData.map((item) => ({
          name: item.name,
          max: Math.max(...radarData.map((i) => i.value)) * 1.2,
        }));

        const options = {
          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: 14,
              fontFamily: "Segoe UI",
            },
            formatter: function (params) {
              const valueIndex = params.dataIndex;
              const value = radarData[valueIndex].value;
              const name = radarData[valueIndex].name;
              const percent = ((value / totalValue) * 100).toFixed(2);
              return `${name} : %${percent}`;
            },
          },
          legend: {
            show: false,
          },
          radar: {
            indicator: indicators,
            shape: "circle",
            radius: "70%",
            name: {
              textStyle: {
                fontSize: 14,
                color: "#000",
                fontFamily: "Segoe UI",
              },
            },
          },
          series: [
            {
              name: "Accent Chart",
              type: "radar",
              data: [
                {
                  value: radarData.map((item) => item.value),
                  name: "Accent Chart",
                },
              ],
              label: {
                show: true,
                fontFamily: "Segoe UI",
                fontSize: 16,
                formatter: function (params) {
                  const percent = ((params.value / totalValue) * 100).toFixed(2);
                  return `${percent}%`;
                },
              },
              itemStyle: {
                borderColor: "#458bdf",
                borderWidth: 2,
              },
              areaStyle: {
                color: "rgba(69, 139, 223, 0.3)",
              },
            },
          ],
          animation: false,
        };
        chart.setOption(options);
        chart.on("click", (params) => {
          if (params.componentType === "series") {
            const selectedOption = params.name;
            onAxisClick(selectedOption);
          }
        });
        chart.resize();
      }

      return () => {
        chart.dispose();
      };
    }
  }, [facebookPosts, language, onAxisClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "40%",
        height: "550px",
        borderBottom: "2px solid #ccc",
        display: "flex",
        justifyContent: "center",
      }}
    />
  );
};

export default AccentChartRadarFB;
