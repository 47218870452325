import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../../App";
import * as echarts from "echarts";

function SubjectsChartPieTopicIN({ instagramPosts, onMainSubjectClick }) {
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    const chart = echarts.init(
      document.getElementById("chart_subjects_circle_topic_in")
    );

    chart.hideLoading();

    const data = [];
    const topics = {
      happy: Object.keys(instagramPosts.topic_chart?.happy || {}),
      sad: Object.keys(instagramPosts.topic_chart?.sad || {}),
      indifferent: Object.keys(instagramPosts.topic_chart?.indifferent || {}),
    };

    Object.keys(topics).forEach((parentKey, parentIndex) => {
      const parentTopics = topics[parentKey];
      parentTopics.forEach((key, index) => {
        const post = instagramPosts.topic_chart?.[parentKey]?.[key];
        if (post) {
          const childrenValuesSum = Object.values(post.childs).reduce(
            (acc, value) => acc + value,
            0
          );

          const item = {
            name: childrenValuesSum > 0 ? key : "N/A",
            value: childrenValuesSum,
            originalName: key,
            itemStyle: {
              color: getColorByParent(parentKey, index),
            },
          };

          data.push(item);
        }
      });
    });

    function getColorByParent(parentKey, index) {
      const colors = {
        happy: "#7dd87d",
        sad: "#e84a5f",
        indifferent: "#cbcbcb",
      };

      const parentColor = colors[parentKey];
      return parentColor;
    }

    if (data.length === 0) {
      chart.setOption({
        graphic: {
          elements: [
            {
              type: "group",
              left: "center",
              top: "middle",
              children: [
                {
                  type: "rect",
                  left: "center",
                  top: "middle",
                  shape: {
                    width: 300,
                    height: 45,
                    r: 12,
                  },
                  style: {
                    fill: "#f4f7fe",
                    stroke: "#458bdf",
                    lineWidth: 2,
                  },
                },
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  style: {
                    text:
                      language === "ar"
                        ? "ليس هنالك معلومات"
                        : "No data available",
                    font: 'bold 16px "Segoe UI", sans-serif',
                    fill: "#000",
                  },
                },
              ],
            },
          ],
        },
      });
    } else {
      const options = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          formatter: "{b} : ({d}%)",
        },
        series: [
          {
            name: "الموضوعات",
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            data: data,
            label: {
              color: "#000",
              fontFamily: "Segoe UI",
              fontSize: "15",
              padding: [10, 30],
              formatter: "{b} \n ({d}%)",
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                fontFamily: "Segoe UI",
                color: "#6495ED",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            smooth: 0.5,
            length: 30,
            length2: 20,
          },
        ],
      };
      chart.setOption(options);
      chart.on("click", (params) => {
        const clickedItem = params.data;
        // Send the original name of the clicked item to onMainSubjectClick function
        onMainSubjectClick(clickedItem.originalName);
      });
    }

    return () => {
      chart.dispose();
    };
  }, [instagramPosts, language, onMainSubjectClick]);

  return (
    <div
      id="chart_subjects_circle_topic_in"
      style={{ width: "100%", height: "500px", borderBottom: "1px solid #ccc" }}
    ></div>
  );
}

export default SubjectsChartPieTopicIN;
