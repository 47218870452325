import React from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers';
import App from './App';
// import App from './pages/Ai/App';
import  {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

const store = createStore(rootReducer);

const rootElement = document.getElementById('root');
Modal.setAppElement(rootElement);

createRoot(rootElement).render(
  <Provider store={store}>
     <ToastContainer />
    <App />
  </Provider>
);