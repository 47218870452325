import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import defaultavatar from "../../../images/user_avatar_50.png";
import { twitter_profile_url } from "../../../backend/config_backend";

const TopUsersChartTw = ({ twitterPosts, onUsernameClick }) => {
  const chartRef = useRef(null);

  // const formatFileName = (name) => {
  //   const formattedName =
  //     name
  //       .replaceAll(".", "")
  //       .replaceAll("%", "")
  //       .replaceAll("?", "")
  //       .replaceAll("=", "")
  //       .replaceAll("/", "")
  //       .substring(0, 30)
  //       .toLowerCase();
  //   return formattedName;
  // };

  useEffect(() => {
    if (twitterPosts && twitterPosts.user_stats) {
      const chart = echarts.init(chartRef.current);

      const userArray = Object.entries(twitterPosts.user_stats);
      userArray.sort(
        ([, a], [, b]) => (b ? b.numberOfPosts : 0) - (a ? a.numberOfPosts : 0)
      );

      const topUsers = userArray.slice(0, 10);

      const options = {
        dataset: {
          source: topUsers.map(([username, userData]) => ({
            username,
            numberOfPosts: userData ? userData.numberOfPosts : 0,
          })),
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            encode: {
              x: "username",
              y: "numberOfPosts",
            },
            barWidth: 50,
            barCategoryGap: "10%",
            sort: "none",
            itemStyle: {
              color: "#1DA1F2",
              borderRadius: [10, 10, 0, 0],
            },
          },
        ],
      };

      // Dispose the previous chart instance
      chart.dispose();

      // Create new chart instance
      const newChart = echarts.init(chartRef.current);
      newChart.setOption(options);

      topUsers.forEach(([username, userData], index) => {
        let avatarUrl = twitter_profile_url + username+ ".png";

        const img = new Image();
        img.onload = () => {
          // const barCoord = newChart.convertToPixel("grid", [
          //   username,
          //   userData ? userData.numberOfPosts : 0,
          // ]);
          if (newChart) {
            const barCoord = newChart.convertToPixel("grid", [
              username,
              userData ? userData.numberOfPosts : 0,
            ]);

          newChart.setOption({
            graphic: [
              {
                type: "image",
                id: username,
                right: "90%",
                left: barCoord[0] - 25,
                top: barCoord[1] - 55,
                z: 100,
                bounding: "raw",
                style: {
                  image: avatarUrl,
                  width: 50,
                  height: 50,
                },
                clipPath: createCircleClipPath(25),
                onclick: () => onUsernameClick(username),
              },
            ],
          });
        }
        };

        img.onerror = () => {
          const barCoord = newChart.convertToPixel("grid", [
            username,
            userData ? userData.numberOfPosts : 0,
          ]);

          newChart.setOption({
            graphic: [
              {
                type: "image",
                id: username,
                right: "90%",
                left: barCoord[0] - 25,
                top: barCoord[1] - 55,
                z: 100,
                bounding: "raw",
                style: {
                  image: defaultavatar,
                  width: 50,
                  height: 50,
                },
                clipPath: createCircleClipPath(25),
                onclick: () => onUsernameClick(username),
              },
            ],
          });
        };

        img.src = avatarUrl;
      });

      function createCircleClipPath(radius) {
        return {
          type: "circle",
          shape: {
            cx: radius, // center x
            cy: radius, // center y
            r: radius, // radius
          },
        };
      }
    }
  }, [twitterPosts, onUsernameClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "500px",
        borderBottom: "2px solid #ccc",
        paddingBottom: "30px",
        marginBottom: "30px",
      }}
    />
  );
};

export default TopUsersChartTw;
