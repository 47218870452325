import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";

const CloudEmojiChartIn = ({ instagramPosts }) => {
  const chartRef = useRef(null);
  const [showMore, setShowMore] = useState(false);

  // const [wordCount, setWordCount] = useState(instagramPosts.emojie_chart.length);
  const wordCount = instagramPosts.word_chart.length;
  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(parseInt(unicode.replace("U+", ""), 16));
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  const handleClickShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (instagramPosts && instagramPosts.emojie_chart) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = instagramPosts.emojie_chart.map((wordItem) => {
        const emoji = convertUnicodeToEmoji(wordItem[0]);
        if (emoji) {
          return {
            name: emoji,
            value: wordItem[1],
          };
        } else {
          
          return null;
        }
      }).filter(item => item !== null);

      console.log("wordData:", wordCloudData);

      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "value",
          position: "top",
        },
        yAxis: {
          type: "category",
          data: showMore
            ? wordCloudData.map((item) => item.name)
            : wordCloudData.slice(0, 5).map((item) => item.name),
          inverse: true,
        },
        series: [
          {
            type: "bar",
            data: showMore
              ? wordCloudData.map((item) => item.value)
              : wordCloudData.slice(0, 5).map((item) => item.value),
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#80D0C7",
                  },
                  {
                    offset: 1,
                    color: "#0093E9",
                  },
                ],
              },
            },
          },
        ],
      };

      chart.setOption(options);

      const resizeChart = () => {
        chart.resize();
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        window.removeEventListener("resize", resizeChart);
        chart.dispose();
      };
    }
  }, [wordCount, showMore, instagramPosts]);

  return (
    <div style={{ width: "95%" }}>
      <div ref={chartRef} style={{ height: showMore ? "900px" : "400px" }} />
      <div className="show_more_btn_cloud_hashtag_chart">
        {!showMore && <button onClick={handleClickShowMore}>عرض المزيد</button>}
        {showMore && <button onClick={handleClickShowMore}>عرض أقل</button>}
      </div>
    </div>
  );
};

export default CloudEmojiChartIn;