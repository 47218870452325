import React, { useState, useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import user_avatar from "../../../svg/user_avatar.svg";
import not_found_image from "../../../images/not_found_image.png";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  twitter_profile_url,
  facebook_profile_url,
  instagram_profile_url,
} from "../../../backend/config_backend";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { HashLoader } from "react-spinners";
import instagram_logo from "../../../images/instagram_35.png";
import facebook_icon from "../../../images/fb_search_btn.png";
import twitter_icon from "../../../images/twitter_x_35.png";
import { ThemeContext, LanguageContext } from "../../../App";
import { getPostsByWord as getPostsByWordFb } from "../../../backend/Post_Search_backend";
import { getPostsByWord as getPostsByWordTW } from "../../../backend/twitter/Twitter_Post_Search_backend";
import { getPostsByWord as getPostsByWordIN } from "../../../backend/instagram/Instagram_Post_Search_backend";
// import { getPostsByWord as getPostsByWordGO } from "../../../backend/searchGoogle/Google_Post_Search_backend";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareIcon from "@mui/icons-material/Share";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RepeatIcon from "@mui/icons-material/Repeat";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

function ListResultsPostList({
  twitterPosts,
  setTwitterPosts,
  facebookPosts,
  setFacebookPosts,
  isTwitterActive,
  isFacebookActive,
  loadingStateFB,
  loadingStateTW,
  visibleTWResult,
  visibleFBResult,
  instagramPosts,
  setInstagramPosts,
  isInstagramActive,
  loadingStateIN,
  visibleINResult,
  onUsernameClick,
  onDateClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  const [loadMoreStateFb, setLoadMoreStateFb] = useState(false);
  const [loadMoreStateTW, setLoadMoreStateTW] = useState(false);
  const [loadMoreStateIN, setLoadMoreStateIN] = useState(false);
  // const [loadMoreStateGO, setLoadMoreStateGO] = useState(false);
  // const [needLogin, setneedLogin] = useState(false);

  // pagination - twitter

  const postsPerPageTwitter = 6;
  const [currentPageTwitter, setCurrentPageTwitter] = useState(1);

  const indexOfLastPostTwitter = currentPageTwitter * postsPerPageTwitter;
  const indexOfFirstPostTwitter = indexOfLastPostTwitter - postsPerPageTwitter;

  const currentTwitterPosts = twitterPosts
    ? twitterPosts.posts.slice(indexOfFirstPostTwitter, indexOfLastPostTwitter)
    : [];

  const totalPagesTwitter = twitterPosts
    ? Math.ceil(twitterPosts.posts.length / postsPerPageTwitter)
    : 0;

  const handleLoadMoreClickedTW = async () => {
    console.log("Handle Load more clicked next link x", twitterPosts.next_link);
    if (loadMoreStateTW === true || loadMoreStateTW === "") {
      return;
    }
    if (twitterPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateTW(true);
    try {
      var postsByWord = await getPostsByWordTW(twitterPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...twitterPosts.posts, ...data.posts];
        setTwitterPosts(data);
        setLoadMoreStateTW(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageTwitter = async () => {
    if (currentPageTwitter < totalPagesTwitter) {
      setCurrentPageTwitter(currentPageTwitter + 1);
    }
    if (currentPageTwitter === totalPagesTwitter - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedTW();
    }
  };

  const prevPageTwitter = () => {
    if (currentPageTwitter > 1) {
      setCurrentPageTwitter(currentPageTwitter - 1);
    }
  };

  // Pagination - Facebook
  const postsPerPageFacebook = 6;
  const [currentPageFacebook, setCurrentPageFacebook] = useState(1);

  const indexOfLastPostFacebook = currentPageFacebook * postsPerPageFacebook;
  const indexOfFirstPostFacebook =
    indexOfLastPostFacebook - postsPerPageFacebook;

  const currentFacebookPosts = facebookPosts
    ? facebookPosts.posts.slice(
        indexOfFirstPostFacebook,
        indexOfLastPostFacebook
      )
    : [];

  const totalPagesFacebook = facebookPosts
    ? Math.ceil(facebookPosts.posts.length / postsPerPageFacebook)
    : 0;

  const handleLoadMoreClickedFb = async () => {
    console.log(
      "Handle Load more clicked next link facebook",
      facebookPosts.next_link
    );
    if (loadMoreStateFb === true || loadMoreStateFb === "") {
      return;
    }
    if (facebookPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateFb(true);
    try {
      var postsByWord = await getPostsByWordFb(facebookPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...facebookPosts.posts, ...data.posts];
        setFacebookPosts(data);
        setLoadMoreStateFb(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageFacebook = async () => {
    if (currentPageFacebook < totalPagesFacebook) {
      setCurrentPageFacebook(currentPageFacebook + 1);
    }
    if (currentPageFacebook === totalPagesFacebook - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedFb();
    }
  };

  const prevPageFacebook = () => {
    if (currentPageFacebook > 1) {
      setCurrentPageFacebook(currentPageFacebook - 1);
    }
  };

  // Pagination - Instagram
  const postsPerPageInstagram = 6;
  const [currentPageInstagram, setCurrentPageInstagram] = useState(1);

  const indexOfLastPostInstagram = currentPageInstagram * postsPerPageInstagram;
  const indexOfFirstPostInstagram =
    indexOfLastPostInstagram - postsPerPageInstagram;

  const currentInstagramPosts = instagramPosts
    ? instagramPosts.posts.slice(
        indexOfFirstPostInstagram,
        indexOfLastPostInstagram
      )
    : [];

  const totalPagesInstagram = instagramPosts
    ? Math.ceil(instagramPosts.posts.length / postsPerPageInstagram)
    : 0;

  const handleLoadMoreClickedIN = async () => {
    console.log(
      "Handle Load more clicked next link instagram",
      instagramPosts.next_link
    );
    if (loadMoreStateIN === true || loadMoreStateIN === "") {
      return;
    }
    if (instagramPosts.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreStateIN(true);
    try {
      var postsByWord = await getPostsByWordIN(instagramPosts.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...instagramPosts.posts, ...data.posts];
        setInstagramPosts(data);
        setLoadMoreStateIN(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const nextPageInstagram = async () => {
    if (currentPageInstagram < totalPagesInstagram) {
      setCurrentPageInstagram(currentPageInstagram + 1);
    }
    if (currentPageInstagram === totalPagesInstagram - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClickedIN();
    }
  };

  const prevPageInstagram = () => {
    if (currentPageInstagram > 1) {
      setCurrentPageInstagram(currentPageInstagram - 1);
    }
  };

  // Pagination - Google

  // const postsPerPageGoogle = 6;
  // const [currentPageGoogle, setCurrentPageGoogle] = useState(1);

  // const indexOfLastPostGoogle = currentPageGoogle * postsPerPageGoogle;
  // const indexOfFirstPostGoogle = indexOfLastPostGoogle - postsPerPageGoogle;

  // const currentGooglePosts = googlePosts
  //   ? googlePosts.posts.slice(indexOfFirstPostGoogle, indexOfLastPostGoogle)
  //   : [];

  // const totalPagesGoogle = googlePosts
  //   ? Math.ceil(googlePosts.posts.length / postsPerPageGoogle)
  //   : 0;

  // const handleLoadMoreClickedGO = async () => {
  //   console.log(
  //     "Handle Load more clicked next link Google",
  //     googlePosts.next_link
  //   );
  //   if (loadMoreStateGO === true || loadMoreStateGO === "") {
  //     return;
  //   }
  //   if (googlePosts.next_link === null) {
  //     return;
  //   }
  //   // console.log("Loading...");
  //   setLoadMoreStateGO(true);
  //   try {
  //     var postsByWord = await getPostsByWordGO(googlePosts.next_link);
  //     if (postsByWord.hasError) {
  //       if (postsByWord.isLoggedIn === false) {
  //         // setneedLogin(true);
  //       } else if (postsByWord.status === 500) {
  //         if (language === "en") {
  //           toast.error("Server Error");
  //         } else {
  //           toast.error("هناك خطأ في الاتصال");
  //         }
  //       } else {
  //         if (language === "en") {
  //           toast.error("Connection Error");
  //         } else {
  //           toast.error("هناك خطأ في الاتصال");
  //         }
  //       }
  //     } else {
  //       console.log(
  //         "Posts retrieved successfully:",
  //         postsByWord.data.data.posts
  //       );
  //       var data = postsByWord.data.data;
  //       data.posts = [...googlePosts.posts, ...data.posts];
  //       setGooglePostData(data);
  //       setLoadMoreStateGO(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //     if (language === "en") {
  //       toast.error("Something Went Wrong");
  //     } else {
  //       toast.error("لقد حدث خطأ ما");
  //     }
  //   }
  // };

  // const nextPageGoogle = async () => {
  //   if (currentPageGoogle < totalPagesGoogle) {
  //     setCurrentPageGoogle(currentPageGoogle + 1);
  //   }
  //   if (currentPageGoogle === totalPagesGoogle - 1) {
  //     console.log("Loading More ...");
  //     await handleLoadMoreClickedGO();
  //   }
  // };

  // const prevPageGoogle = () => {
  //   if (currentPageGoogle > 1) {
  //     setCurrentPageGoogle(currentPageGoogle - 1);
  //   }
  // };

  // پاپ آپ نمایش متن کامل پست توییتر

  const [openAllTextTW, setOpenAllTextTW] = useState(false);
  const [selectedPostTW, setSelectedPostTW] = useState(null);

  const handleOpenAllTextTW = (post) => {
    setSelectedPostTW(post);
    setOpenAllTextTW(true);
  };

  const handleCloseAllTextTW = () => {
    setSelectedPostTW(null);
    setOpenAllTextTW(false);
  };

  // پاپ آپ نمایش متن کامل پست فیسبوک

  const [openAllTextFB, setOpenAllTextFB] = useState(false);
  const [selectedPostFB, setSelectedPostFB] = useState(null);

  const handleOpenAllTextFB = (post) => {
    setSelectedPostFB(post);
    setOpenAllTextFB(true);
  };

  const handleCloseAllTextFB = () => {
    setSelectedPostFB(null);
    setOpenAllTextFB(false);
  };

  // پاپ آپ نمایش متن کامل پست اینستاگرام

  const [openAllTextIN, setOpenAllTextIN] = useState(false);
  const [selectedPostIN, setSelectedPostIN] = useState(null);

  const handleOpenAllTextIN = (post) => {
    setSelectedPostIN(post);
    setOpenAllTextIN(true);
  };

  const handleCloseAllTextIN = () => {
    setSelectedPostIN(null);
    setOpenAllTextIN(false);
  };

  // پاپ آپ نمایش متن کامل پست گوگل

  // const [openAllTextGO, setOpenAllTextGO] = useState(false);
  // const [selectedPostGO, setSelectedPostGO] = useState(null);

  // const handleOpenAllTextGO = (post) => {
  //   setSelectedPostGO(post);
  //   setOpenAllTextGO(true);
  // };

  // const handleCloseAllTextGO = () => {
  //   setSelectedPostGO(null);
  //   setOpenAllTextGO(false);
  // };

  // sort notif massage

  const showMessage = (type, language, isActive) => {
    let alertMessage = "";
    if (isActive) {
      alertMessage =
        language === "ar"
          ? type === "asc"
            ? "ترتیب صعودی اول ۱۰۰ منشورات"
            : "ترتیب نزولی اول ۱۰۰ منشورات"
          : type === "asc"
          ? "The first 100 chapters in descending order"
          : "The first 100 chapters in ascending order";
    } else {
      alertMessage =
        language === "ar" ? "تعطيل الترتيب" : "Sorting has been deactivated";
    }
    toast.warning(alertMessage);
  };

  const [sortByRetweet, setSortByRetweet] = useState(null);
  const [sortByLikeTW, setSortByLikeTW] = useState(null);
  const [sortByViewTW, setSortByViewTW] = useState(null);
  const [sortByDateTW, setSortByDateTW] = useState(null);
  const [originalPosts, setOriginalPosts] = useState(null);

  const sortByRetweetsTW = (type) => {
    let isActive = true;
    if (sortByRetweet === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByRetweet(null);
      isActive = false;
    } else {
      setSortByRetweet(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.retweet_count - b.retweet_count;
        } else {
          return b.retweet_count - a.retweet_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort Like tw

  const sortByLikesTW = (type) => {
    let isActive = true;
    if (sortByLikeTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByLikeTW(null);
      isActive = false;
    } else {
      setSortByLikeTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.like_count - b.like_count;
        } else {
          return b.like_count - a.like_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views tw

  const sortByViewsTW = (type) => {
    let isActive = true;
    if (sortByViewTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByViewTW(null);
      isActive = false;
    } else {
      setSortByViewTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.view_count - b.view_count;
        } else {
          return b.view_count - a.view_count;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date tw

  const sortByCreateTW = (type) => {
    let isActive = true;
    if (sortByDateTW === type) {
      setTwitterPosts({ ...twitterPosts, posts: originalPosts });
      setSortByDateTW(null);
      isActive = false;
    } else {
      setSortByDateTW(type);
      if (!originalPosts) {
        setOriginalPosts([...twitterPosts.posts]);
      }
      const sortedPosts = [...twitterPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.date_tweet.split("at")[0]);
        const dateB = new Date(b.date_tweet.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setTwitterPosts({ ...twitterPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };
  // sorts facebook
  // sort Like fb
  const [sortByLikeFB, setSortByLikeFB] = useState(null);
  const [originalFacebookPosts, setOriginalFacebookPosts] = useState(null);

  const sortByLikesFB = (type) => {
    let isActive = true;
    if (sortByLikeFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByLikeFB(null);
      isActive = false;
    } else {
      setSortByLikeFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.likes - b.likes;
        } else {
          return b.likes - a.likes;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views fb
  const [sortByReplyFB, setSortByReplyFB] = useState(null);

  const sortReplyFB = (type) => {
    let isActive = true;
    if (sortByReplyFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByReplyFB(null);
      isActive = false;
    } else {
      setSortByReplyFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.comments - b.comments;
        } else {
          return b.comments - a.comments;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort share fb
  const [sortByShareFB, setSortByShareFB] = useState(null);

  const sortSharesFB = (type) => {
    let isActive = true;
    if (sortByShareFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByShareFB(null);
      isActive = false;
    } else {
      setSortByShareFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.shares - b.shares;
        } else {
          return b.shares - a.shares;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date fb
  const [sortByDateFB, setSortByDateFB] = useState(null);

  const sortByCreateFB = (type) => {
    let isActive = true;
    if (sortByDateFB === type) {
      setFacebookPosts({ ...facebookPosts, posts: originalFacebookPosts });
      setSortByDateFB(null);
      isActive = false;
    } else {
      setSortByDateFB(type);
      if (!originalFacebookPosts) {
        setOriginalFacebookPosts([...facebookPosts.posts]);
      }
      const sortedPosts = [...facebookPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.creates.split("at")[0]);
        const dateB = new Date(b.creates.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setFacebookPosts({ ...facebookPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sorts Instagram
  // sort Like IN
  const [sortByLikeIN, setSortByLikeIN] = useState(null);
  const [originalInstagramPosts, setOriginalInstagramPosts] = useState(null);

  const sortLikesIN = (type) => {
    let isActive = true;
    if (sortByLikeIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByLikeIN(null);
      isActive = false;
    } else {
      setSortByLikeIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.like_count - b.like_count;
        } else {
          return b.like_count - a.like_count;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort views IN
  const [sortByReplyIN, setSortByReplyIN] = useState(null);

  const sortReplyIN = (type) => {
    let isActive = true;
    if (sortByReplyIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByReplyIN(null);
      isActive = false;
    } else {
      setSortByReplyIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        if (type === "asc") {
          return a.comment_count - b.comment_count;
        } else {
          return b.comment_count - a.comment_count;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date IN
  const [sortByDateIN, setSortByDateIN] = useState(null);

  const sortByCreateIN = (type) => {
    let isActive = true;
    if (sortByDateIN === type) {
      setInstagramPosts({ ...instagramPosts, posts: originalInstagramPosts });
      setSortByDateIN(null);
      isActive = false;
    } else {
      setSortByDateIN(type);
      if (!originalInstagramPosts) {
        setOriginalInstagramPosts([...instagramPosts.posts]);
      }
      const sortedPosts = [...instagramPosts.posts];
      sortedPosts.sort((a, b) => {
        const dateA = new Date(a.create_date.split("at")[0]);
        const dateB = new Date(b.create_date.split("at")[0]);
        if (type === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setInstagramPosts({ ...instagramPosts, posts: sortedPosts });
    }
    showMessage(type, language, isActive);
  };

  // sort date Google
  // const [sortByDateGO, setSortByDateGO] = useState("asc");

  // const sortByCreateGO = (type) => {
  //   setSortByDateGO(type);
  //   googlePosts.posts.sort((a, b) => {
  //     const dateA = new Date(a.date_publication.split("at")[0]);
  //     const dateB = new Date(b.date_publication.split("at")[0]);
  //     if (type === "asc") {
  //       return dateA - dateB;
  //     } else {
  //       return dateB - dateA;
  //     }
  //   });
  //   showMessage(type, language);
  // };

  // convert emoji

  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(
        parseInt(unicode.replace("U+", ""), 16)
      );
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  const renderPostTextWithStickers = (post) => {
    const parts = post.text_twitt.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderPostTextWithStickersFB = (posts) => {
    const parts = posts.texts.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const renderPostTextWithStickersIN = (posts) => {
    const parts = posts.media_caption.split(" ");
    const textWithStickers = parts
      .map((part, index) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ")
      .trim();
    return textWithStickers;
  };

  // format date onclick filter

  function formatDate(dateString) {
    const date = new Date(dateString.split("at")[0]);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="list_results_post_list">
      <div className="list_results_post_list_page">

        {isTwitterActive && (
          <>
              <div className="twitter_list_results_post_list_page">
                {loadingStateTW ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={50} />
                  </div>
                ) : (
                  <>
                    {visibleTWResult && showTwitterSection &&  (
                      <>
                        {language === "ar" ? (
                          <div
                            className={`header_list_results_post_list ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                          >
                            <img
                              src={twitter_icon}
                              alt=""
                              id="icon_social_results_post_list"
                            />
                            <div className="items_header_result_list onelr">
                              <span>المصدر</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>هوية</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>المحتوى</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span className="tooltip">
                                <CalendarMonthIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">
                                  تاريخ النشر
                                </span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByDateTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByDateTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <RepeatIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">إعادة نشر</span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByRetweet === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByRetweetsTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByRetweet === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByRetweetsTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">اعجاب</span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByLikeTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByLikeTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <VisibilityIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">مشاهدة</span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByViewTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByViewsTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByViewTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByViewsTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span>صورة المنشور</span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`header_list_results_post_list_en ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                          >
                            <img
                              src={twitter_icon}
                              alt=""
                              id="icon_social_results_post_list"
                            />
                            <div className="items_header_result_list onelr">
                              <span>Source</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>Identity</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>Content</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByDateTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByDateTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <CalendarMonthIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">
                                  Publication Date
                                </span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByRetweet === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByRetweetsTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByRetweet === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByRetweetsTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <RepeatIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Retweets</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByLikeTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByLikeTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Likes</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByViewTW === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByViewsTW("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByViewTW === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByViewsTW("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <VisibilityIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Views</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span>Post Image</span>
                            </div>
                          </div>
                        )}

                        <div>
                          {currentTwitterPosts.map((post) => {
                            const shouldTruncate = post.text_twitt.length > 50;
                            return (
                              <div
                                className={`body_list_results_post_list ${
                                  language === "ar"
                                    ? "body_list_results_post_list_ar"
                                    : ""
                                } ${theme === "dark" ? "dark_mode" : ""}`}
                                key={post.id}
                              >
                                {language === "ar" ? (
                                  <div className="items_body_list_results_post_list onelr pro_detail">
                                    <img
                                      src={
                                        post.username
                                          ? twitter_profile_url +
                                            post.username +
                                            ".png"
                                          : user_avatar
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = user_avatar;
                                      }}
                                      alt=""
                                      className="img_avatar_list_results_post_list"
                                    />
                                    <div className="username_items_body_list_results_post_list">
                                      <span className="tooltip">
                                        <span>
                                          {post.name.length > 15
                                            ? post.name.slice(0, 15) + "..."
                                            : post.name}
                                        </span>
                                        <span className="tooltip_text">
                                          {post.name}
                                        </span>
                                      </span>
                                      <span
                                        className="tooltip_bottom"
                                        onClick={() =>
                                          onUsernameClick(post.username)
                                        }
                                        id="username_span_results_posts"
                                      >
                                        {post.username.length > 15
                                          ? post.username.slice(0, 15) + "..."
                                          : post.username}
                                        <span className="tooltip_text_bottom">
                                          {post.username}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="items_body_list_results_post_list onelr pro_detail en">
                                    <div className="username_items_body_list_results_post_list_en">
                                      <span className="tooltip">
                                        <span>
                                          {post.name.length > 12
                                            ? post.name.slice(0, 12) + "..."
                                            : post.name}
                                        </span>
                                        <span className="tooltip_text">
                                          {post.name}
                                        </span>
                                      </span>
                                      <span className="tooltip">
                                        {post.username.length > 12
                                          ? post.username.slice(0, 12) + "..."
                                          : post.username}
                                        <span className="tooltip_text">
                                          {post.username}
                                        </span>
                                      </span>
                                    </div>
                                    <img
                                      src={
                                        post.username
                                          ? twitter_profile_url +
                                            post.username +
                                            ".png"
                                          : user_avatar
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = user_avatar;
                                      }}
                                      alt=""
                                      className="img_avatar_list_results_post_list"
                                    />
                                  </div>
                                )}
                                <div className="items_body_list_results_post_list onelr scr">
                                  <span>{post.id}</span>
                                </div>
                                <div className="items_body_list_results_post_list onelr col">
                                  {shouldTruncate ? (
                                    <React.Fragment>
                                      {/* {post.text_twitt.slice(0, 55)} ... */}
                                      {renderPostTextWithStickers(post).slice(
                                        0,
                                        55
                                      )}{" "}
                                      ...
                                      {language === "ar" ? (
                                        <button
                                          className="btn_text_posts_body_result_list"
                                          onClick={() =>
                                            handleOpenAllTextTW(post)
                                          }
                                        >
                                          عرض المزيد
                                        </button>
                                      ) : (
                                        <button
                                          className="btn_text_posts_body_result_list"
                                          onClick={() =>
                                            handleOpenAllTextTW(post)
                                          }
                                        >
                                          Show More
                                        </button>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <>{renderPostTextWithStickers(post)}</>
                                  )}
                                  <Dialog
                                    open={openAllTextTW}
                                    onClose={handleCloseAllTextTW}
                                  >
                                    <DialogTitle className="header_popup_text_posts_body_result_list">
                                      <Button onClick={handleCloseAllTextTW}>
                                        <CloseIcon />
                                      </Button>
                                    </DialogTitle>
                                    <DialogContent className="textall_popup_text_posts_body_result_list">
                                      {selectedPostTW &&
                                        renderPostTextWithStickers(
                                          selectedPostTW
                                        )}
                                    </DialogContent>
                                  </Dialog>
                                </div>
                                <div className="items_body_list_results_post_list onelr">
                                  <span
                                    onClick={() =>
                                      onDateClick(formatDate(post.date_tweet))
                                    }
                                  >
                                    {" "}
                                    {new Date(
                                      post.date_tweet.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{post.retweet_count}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{post.like_count}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{post.view_count}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <img src={not_found_image} alt="" />
                                </div>
                              </div>
                            );
                          })}
                          {loadMoreStateTW ? (
                            <div className="spiner_postbox">
                              <HashLoader color="#458BDF" size={50} />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="pagination_posts_body_result_list">
                            <button
                              onClick={prevPageTwitter}
                              disabled={currentPageTwitter === 1}
                            >
                              <KeyboardDoubleArrowLeftIcon />
                            </button>
                            <span className="pagination_posts_body_result_list_span">
                              {currentPageTwitter}
                            </span>
                            <button
                              onClick={nextPageTwitter}
                              disabled={
                                currentPageTwitter === totalPagesTwitter
                              }
                            >
                              <KeyboardDoubleArrowRightIcon />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
          
          </>
        )}

        {isFacebookActive && (
          <>
         
              <div className="facebook_list_results_post_list_page">
                {loadingStateFB ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={50} />
                  </div>
                ) : (
                  <>
                    {visibleFBResult && showFacebookSection && (
                      <>
                        {language === "ar" ? (
                          <div
                            className={`header_list_results_post_list ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                          >
                            <img
                              src={facebook_icon}
                              alt=""
                              id="icon_social_results_post_list"
                            />
                            <div className="items_header_result_list onelr">
                              <span>المصدر</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>هوية</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>المحتوى</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span className="tooltip">
                                <CalendarMonthIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">
                                  تاريخ النشر
                                </span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByDateFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByDateFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">التعليقات</span>
                              </span>

                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByReplyFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortReplyFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByReplyFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortReplyFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">اعجاب</span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByLikeFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByLikeFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span className="tooltip">
                                <ShareIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">مشارکة</span>
                              </span>
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByShareFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortSharesFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByShareFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortSharesFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
                            </div>

                            <div className="items_header_result_list twolr">
                              <span>صورة المنشور</span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`header_list_results_post_list_en ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                          >
                            <img
                              src={facebook_icon}
                              alt=""
                              id="icon_social_results_post_list"
                            />
                            <div className="items_header_result_list onelr">
                              <span>Source</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>ID</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <span>Content</span>
                            </div>
                            <div className="items_header_result_list onelr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByDateFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByDateFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <CalendarMonthIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">
                                  Publication Date
                                </span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByReplyFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortReplyFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByReplyFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortReplyFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Replies</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByLikeFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByLikeFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByLikesFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Likes</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByShareFB === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortSharesFB("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByShareFB === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortSharesFB("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>

                              <span className="tooltip">
                                <ShareIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">Shares</span>
                              </span>
                            </div>
                            <div className="items_header_result_list twolr">
                              <span>Post Image</span>
                            </div>
                          </div>
                        )}

                        <div>
                          {currentFacebookPosts.map((posts) => {
                            const shouldTruncateFB = posts.texts.length > 55;
                            return (
                              <div
                                className={`body_list_results_post_list ${
                                  language === "ar"
                                    ? "body_list_results_post_list_ar"
                                    : ""
                                } ${theme === "dark" ? "dark_mode" : ""}`}
                                key={posts.id}
                              >
                                {language === "ar" ? (
                                  <div className="items_body_list_results_post_list onelr pro_detail">
                                    <img
                                      src={
                                        posts.username
                                          ? facebook_profile_url +
                                            posts.username +
                                            ".png"
                                          : user_avatar
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = user_avatar;
                                      }}
                                      alt=""
                                      className="img_avatar_list_results_post_list"
                                    />
                                    <div className="username_items_body_list_results_post_list">
                                      <span className="tooltip">
                                        <span>
                                          {posts.name.length > 12
                                            ? posts.name.slice(0, 12) + "..."
                                            : posts.name}
                                        </span>
                                        <span className="tooltip_text">
                                          {posts.name}
                                        </span>
                                      </span>
                                      <span className="tooltip_bottom">
                                        {posts.username.length > 15
                                          ? posts.username.slice(0, 15) + "..."
                                          : posts.username}
                                        <span className="tooltip_text_bottom">
                                          {posts.username}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="items_body_list_results_post_list onelr pro_detail en">
                                    <div className="username_items_body_list_results_post_list_en">
                                      <span className="tooltip">
                                        <span>
                                          {posts.name.length > 12
                                            ? posts.name.slice(0, 12) + "..."
                                            : posts.name}
                                        </span>
                                        <span className="tooltip_text">
                                          {posts.name}
                                        </span>
                                      </span>
                                      <span
                                        className="tooltip_bottom"
                                        onClick={() =>
                                          onUsernameClick(posts.username)
                                        }
                                        id="username_span_results_posts"
                                      >
                                        {posts.username.length > 15
                                          ? posts.username.slice(0, 15) + "..."
                                          : posts.username}
                                        <span className="tooltip_text_bottom">
                                          {posts.username}
                                        </span>
                                      </span>
                                    </div>
                                    <img
                                      src={
                                        posts.username
                                          ? facebook_profile_url +
                                            posts.username +
                                            ".png"
                                          : user_avatar
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = user_avatar;
                                      }}
                                      alt=""
                                      className="img_avatar_list_results_post_list"
                                    />
                                  </div>
                                )}
                                <div className="items_body_list_results_post_list onelr scr">
                                  <span>{posts.id}</span>
                                </div>
                                <div className="items_body_list_results_post_list onelr col">
                                  {shouldTruncateFB ? (
                                    <React.Fragment>
                                      {/* {posts.texts.slice(0, 55)} ... */}
                                      {renderPostTextWithStickersFB(
                                        posts
                                      ).slice(0, 55)}{" "}
                                      ...
                                      {language === "ar" ? (
                                        <button
                                          className="btn_text_posts_body_result_list"
                                          onClick={() =>
                                            handleOpenAllTextFB(posts)
                                          }
                                        >
                                          عرض المزيد
                                        </button>
                                      ) : (
                                        <button
                                          className="btn_text_posts_body_result_list"
                                          onClick={() =>
                                            handleOpenAllTextFB(posts)
                                          }
                                        >
                                          Show More
                                        </button>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <>{renderPostTextWithStickersFB(posts)}</>
                                  )}
                                  <Dialog
                                    open={openAllTextFB}
                                    onClose={handleCloseAllTextFB}
                                  >
                                    <DialogTitle className="header_popup_text_posts_body_result_list">
                                      <Button onClick={handleCloseAllTextFB}>
                                        <CloseIcon />
                                      </Button>
                                    </DialogTitle>
                                    <DialogContent className="textall_popup_text_posts_body_result_list">
                                      {selectedPostFB &&
                                        renderPostTextWithStickersFB(
                                          selectedPostFB
                                        )}
                                    </DialogContent>
                                  </Dialog>
                                </div>
                                <div className="items_body_list_results_post_list onelr">
                                  <span
                                    onClick={() =>
                                      onDateClick(formatDate(posts.creates))
                                    }
                                  >
                                    {" "}
                                    {new Date(
                                      posts.creates.split("at")[0]
                                    ).toLocaleDateString(undefined, {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{posts.comments}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{posts.likes}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <span>{posts.shares}</span>
                                </div>
                                <div className="items_body_list_results_post_list twolr">
                                  <img src={not_found_image} alt="" />
                                </div>
                              </div>
                            );
                          })}
                          {loadMoreStateFb ? (
                            <div className="spiner_postbox">
                              <HashLoader color="#458BDF" size={50} />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="pagination_posts_body_result_list">
                            <button
                              onClick={prevPageFacebook}
                              disabled={currentPageFacebook === 1}
                            >
                              <KeyboardDoubleArrowLeftIcon />
                            </button>
                            <span className="pagination_posts_body_result_list_span">
                              {currentPageFacebook}
                            </span>
                            <button
                              onClick={nextPageFacebook}
                              disabled={
                                currentPageFacebook === totalPagesFacebook
                              }
                            >
                              <KeyboardDoubleArrowRightIcon />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            
          </>
        )}

        {isInstagramActive && (
          <>
          
          {visibleINResult && (
            <div className="insta_list_results_post_list_page">
              {loadingStateIN ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {showInstagramSection && (
                    <>
                      {language === "ar" ? (
                        <div
                          className={`header_list_results_post_list ${
                            theme === "dark" ? "dark_mode" : ""
                          }`}
                        >
                          <img
                            src={instagram_logo}
                            alt=""
                            id="icon_social_results_post_list"
                          />
                          <div className="items_header_result_list onelr">
                            <span>المصدر</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <span>هوية</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <span>المحتوى</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <span className="tooltip">
                              <CalendarMonthIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">تاريخ النشر</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByDateIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortByCreateIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={sortByDateIN === "asc" ? "active" : ""}
                                onClick={() => sortByCreateIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_header_result_list twolr">
                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">التعليقات</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_header_result_list twolr">
                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">اعجاب</span>
                            </span>
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={sortByLikeIN === "asc" ? "active" : ""}
                                onClick={() => sortLikesIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
                          </div>
                          <div className="items_header_result_list twolr">
                            <span>صورة المنشور</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`header_list_results_post_list_en ${
                            theme === "dark" ? "dark_mode" : ""
                          }`}
                        >
                          <img
                            src={instagram_logo}
                            alt=""
                            id="icon_social_results_post_list"
                          />
                          <div className="items_header_result_list onelr">
                            <span>Source</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <span>ID</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <span>Content</span>
                          </div>
                          <div className="items_header_result_list onelr">
                            <div className="items_left_header_result_list">
                              <div className="arrow_items_left_header_result_list">
                                <button
                                  className={
                                    sortByDateIN === "desc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateIN("desc")}
                                >
                                  <ArrowDropUpIcon />
                                </button>
                                <button
                                  className={
                                    sortByDateIN === "asc" ? "active" : ""
                                  }
                                  onClick={() => sortByCreateIN("asc")}
                                >
                                  <ArrowDropDownIcon />
                                </button>
                              </div>
  
                              <span className="tooltip">
                                <CalendarMonthIcon id="icon_sort_header_posts_section" />
                                <span className="tooltip_text">
                                  Publication Date
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="items_header_result_list twolr">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByReplyIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={
                                  sortByReplyIN === "asc" ? "active" : ""
                                }
                                onClick={() => sortReplyIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
  
                            <span className="tooltip">
                              <TextsmsOutlinedIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Replies</span>
                            </span>
                          </div>
                          <div className="items_header_result_list twolr">
                            <div className="arrow_items_left_header_result_list">
                              <button
                                className={
                                  sortByLikeIN === "desc" ? "active" : ""
                                }
                                onClick={() => sortLikesIN("desc")}
                              >
                                <ArrowDropUpIcon />
                              </button>
                              <button
                                className={sortByLikeIN === "asc" ? "active" : ""}
                                onClick={() => sortLikesIN("asc")}
                              >
                                <ArrowDropDownIcon />
                              </button>
                            </div>
  
                            <span className="tooltip">
                              <FavoriteBorderIcon id="icon_sort_header_posts_section" />
                              <span className="tooltip_text">Likes</span>
                            </span>
                          </div>
                          <div className="items_header_result_list twolr">
                            <span>Post Image</span>
                          </div>
                        </div>
                      )}
                      <div>
                        {currentInstagramPosts.map((posts) => {
                          const shouldTruncateIN =
                            posts.media_caption.length > 55;
                          return (
                            <div
                              className={`body_list_results_post_list ${
                                language === "ar"
                                  ? "body_list_results_post_list_ar"
                                  : ""
                              } ${theme === "dark" ? "dark_mode" : ""}`}
                              key={posts.id}
                            >
                              {language === "ar" ? (
                                <div className="items_body_list_results_post_list onelr pro_detail">
                                  <img
                                    src={
                                      posts.username
                                        ? instagram_profile_url +
                                          posts.username +
                                          ".png"
                                        : user_avatar
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user_avatar;
                                    }}
                                    alt=""
                                    className="img_avatar_list_results_post_list"
                                  />
                                  <div className="username_items_body_list_results_post_list">
                                    {/* <span className="tooltip">
                  <span>
                    {posts.name.length > 15
                      ? posts.name.slice(0, 15) + "..."
                      : posts.name}
                  </span>
                  <span className="tooltip_text">{posts.name}</span>
                </span> */}
                                    <span
                                      className="tooltip_bottom"
                                      onClick={() =>
                                        onUsernameClick(posts.username)
                                      }
                                      id="username_span_results_posts"
                                    >
                                      {posts.username.length > 12
                                        ? posts.username.slice(0, 12) + "..."
                                        : posts.username}
                                      <span className="tooltip_text_bottom">
                                        {posts.username}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="items_body_list_results_post_list onelr pro_detail en">
                                  <div className="username_items_body_list_results_post_list_en">
                                    {/* <span className="tooltip">
                  <span>
                    {posts.name.length > 15
                      ? posts.name.slice(0, 15) + "..."
                      : posts.name}
                  </span>
                  <span className="tooltip_text">{posts.name}</span>
                </span> */}
                                    <span className="tooltip_bottom">
                                      {posts.username.length > 12
                                        ? posts.username.slice(0, 12) + "..."
                                        : posts.username}
                                      <span className="tooltip_text_bottom">
                                        {posts.username}
                                      </span>
                                    </span>
                                  </div>
                                  <img
                                    src={
                                      posts.username
                                        ? instagram_profile_url +
                                          posts.username +
                                          ".png"
                                        : user_avatar
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = user_avatar;
                                    }}
                                    alt=""
                                    className="img_avatar_list_results_post_list"
                                  />
                                </div>
                              )}
                              <div className="items_body_list_results_post_list onelr scr">
                                <span>{posts.id}</span>
                              </div>
                              <div className="items_body_list_results_post_list onelr col">
                                {shouldTruncateIN ? (
                                  <React.Fragment>
                                    {/* {posts.media_caption.slice(0, 55)} ... */}
                                    {renderPostTextWithStickersIN(posts).slice(
                                      0,
                                      55
                                    )}{" "}
                                    ...
                                    {language === "ar" ? (
                                      <button
                                        className="btn_text_posts_body_result_list"
                                        onClick={() => handleOpenAllTextIN(posts)}
                                      >
                                        عرض المزيد
                                      </button>
                                    ) : (
                                      <button
                                        className="btn_text_posts_body_result_list"
                                        onClick={() => handleOpenAllTextIN(posts)}
                                      >
                                        Show More
                                      </button>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <>{renderPostTextWithStickersIN(posts)}</>
                                )}
                                <Dialog
                                  open={openAllTextIN}
                                  onClose={handleCloseAllTextIN}
                                >
                                  <DialogTitle className="header_popup_text_posts_body_result_list">
                                    <Button onClick={handleCloseAllTextIN}>
                                      <CloseIcon />
                                    </Button>
                                  </DialogTitle>
                                  <DialogContent className="textall_popup_text_posts_body_result_list">
                                    {selectedPostIN &&
                                      renderPostTextWithStickersIN(
                                        selectedPostIN
                                      )}
                                  </DialogContent>
                                </Dialog>
                              </div>
                              <div className="items_body_list_results_post_list onelr">
                                <span
                                  onClick={() =>
                                    onDateClick(formatDate(posts.create_date))
                                  }
                                >
                                  {posts.create_date &&
                                  posts.create_date.split("at")[0]
                                    ? new Date(
                                        posts.create_date.split("at")[0]
                                      ).toLocaleDateString(undefined, {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                    : ""}
                                </span>
                              </div>
                              <div className="items_body_list_results_post_list twolr">
                                <span>{posts.comment_count}</span>
                              </div>
                              <div className="items_body_list_results_post_list twolr">
                                <span>{posts.like_count}</span>
                              </div>
                              <div className="items_body_list_results_post_list twolr">
                                <img src={not_found_image} alt="" />
                              </div>
                            </div>
                          );
                        })}
                        {loadMoreStateIN ? (
                          <div className="spiner_postbox">
                            <HashLoader color="#458BDF" size={50} />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="pagination_posts_body_result_list">
                          <button
                            onClick={prevPageInstagram}
                            disabled={currentPageInstagram === 1}
                          >
                            <KeyboardDoubleArrowLeftIcon />
                          </button>
                          <span className="pagination_posts_body_result_list_span">
                            {currentPageInstagram}
                          </span>
                          <button
                            onClick={nextPageInstagram}
                            disabled={
                              currentPageInstagram === totalPagesInstagram
                            }
                          >
                            <KeyboardDoubleArrowRightIcon />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          </>
        )}
      </div>
    </div>
  );
}

export default ListResultsPostList;
