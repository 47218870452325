import axios from 'axios'
import { base_url, web_search_post_delete_url , tokenName} from '../config_backend';
import { getToken} from "../Login_backend"


export const deletePost = async (postid ,projectName ) => {


  
    postid = postid ? postid : '-1'
    projectName = projectName ? projectName : "default"


    var hasError = false;
    var isLoggedIn = true;
    var status = 200

    var errorText = "";
    var data = undefined

    if(localStorage.getItem(tokenName)){
      // await refreshToken()
    }else{
      isLoggedIn = false
      status = 401
      hasError = true
      return {hasError , errorText , data , status , isLoggedIn}
    }

    var url = base_url + web_search_post_delete_url
  


    await axios.get(url, {
        params: {
            postid,
            projectName,
        },
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            "Authorization": "Bearer " + String(getToken().access)
        },
    })
  
      .then(function (response) {
        if(response !== undefined) data = response
      }).catch(function (error) {
        var response = error.response
        errorText = response.request.response
      
        hasError = true
        status = response.status
        if (response.status === 500){
            errorText = "Server Error ... "
        }
        else if (errorText.includes("Authentication") || errorText.includes("credentials")){
            isLoggedIn = false
            localStorage.setItem("isLogged",  "false");

        }
        }

      )

      
      return {hasError , errorText , data , status , isLoggedIn}
}
