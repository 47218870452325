import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../../App";
import * as echarts from "echarts";

const SubjectsChartLineIN = ({ instagramPosts,onSubSubjectClick }) => {
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    const chart = echarts.init(
      document.getElementById("chart_subjects_bar_in_new")
    );

    chart.hideLoading();

    const data = [];

    const Subtopics = {
      sad: Object.keys(instagramPosts.topic_chart?.sad ?? {}),
      indifferent: Object.keys(instagramPosts.topic_chart?.indifferent ?? {}),
      happy: Object.keys(instagramPosts.topic_chart?.happy ?? {}),
    };

    Object.keys(Subtopics).forEach((parentKey, parentIndex) => {
      const parentTopics = Subtopics[parentKey];
      parentTopics.forEach((key, index) => {
        const post = instagramPosts.topic_chart?.[parentKey]?.[key];
        if (post) {
          // Check if it's a parent (has children)
          if (Object.keys(post.childs).length > 0) {
            // اضافه کردن فرزندان
            Object.keys(post.childs).forEach((childKey) => {
              const childItem = {
                name: childKey,
                value: post.childs[childKey],
                originalName: childKey,
                parent: key, // نام والد
                itemStyle: {
                  color: getColorByParent(parentKey, index),
                },
              };

              data.push(childItem);
            });
          }
        }
      });
    });

    function getColorByParent(parentKey, index) {
      const colors = {
        happy: "#7dd87d",
        sad: "#e84a5f",
        indifferent: "#cbcbcb",
      };

      const defaultColor = "#000000"; // رنگ پیش‌فرض

      const parentColor = colors[parentKey] || defaultColor;
      return parentColor;
    }

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    }

    const maxLength = 50; // حداکثر طول متن لیبل‌ها

    data.forEach((item) => {
      item.name = truncateText(item.name, maxLength);
    });

    const totalValue = data.reduce((sum, item) => sum + item.value, 0);

    if (data.length === 0) {
      chart.setOption({
        graphic: {
          elements: [
            {
              type: "group",
              left: "center",
              top: "middle",
              children: [
                {
                  type: "rect",
                  left: "center",
                  top: "middle",
                  shape: {
                    width: 300,
                    height: 45,
                    r: 12,
                  },
                  style: {
                    fill: "#f4f7fe",
                    stroke: "#458bdf",
                    lineWidth: 2,
                  },
                },
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  style: {
                    text:
                      language === "ar"
                        ? "ليس هنالك معلومات"
                        : "No data available",
                    font: 'bold 16px "Segoe UI", sans-serif',
                    fill: "#000",
                  },
                },
              ],
            },
          ],
        },
      });
    } else {
    const options = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: (params) => {
          const { originalName, value } = params.data;
          const percent = ((value / totalValue) * 100).toFixed(2);
          return `${originalName} (%${percent})`;
        },
      },
      grid: {
        top: "3%",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: [
        {
          type: "category",
          data: data.map((item) => item.name),
          axisLabel: {
            color: "#000",
            fontFamily: "Segoe UI",
            fontSize: 12,
          },
          position: "right",
          triggerEvent: true,
        },
      ],
      xAxis: [
        {
          type: "value",
          axisLabel: {
            color: "#000",
            fontFamily: "Segoe UI",
            fontSize: 1,
          },
          inverse: true, 
        },
      ],
      series: [
        {
          name: "الموضوعات",
          type: "bar",
          barWidth: 20,
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            show: true,
            position: "inside",
            formatter: (params) => {
              const { value } = params.data;
              const percent = ((value / totalValue) * 100).toFixed(2);
              return `${percent}%`;
            },
          },
        },
      ],
    };
    function getParentNames(childName) {
      const parentNames = [];
      Object.keys(Subtopics).forEach((parentKey) => {
        const parentTopics = Subtopics[parentKey];
        parentTopics.forEach((key) => {
          const post = instagramPosts?.topic_chart?.[parentKey]?.[key] || {};
          if (Object.keys(post.childs || {}).includes(childName)) {
            parentNames.push(key);
          }
        });
      });
      return parentNames;
    }

    chart.on("click", (params) => {
      if (params.componentType === "yAxis") {
        const subjectName = params.value;
        const parentNames = getParentNames(subjectName);
        parentNames.forEach((parentName) => {
          onSubSubjectClick(subjectName, parentName);
        });
      }
    });
    chart.setOption(options);
  }

    return () => {
      chart.dispose();
    };
  }, [instagramPosts, language,onSubSubjectClick]);

  return (
    <div
      id="chart_subjects_bar_in_new"
      style={{ width: "100%", height: "700px", direction:'rtl' }}
    ></div>
  );
};

export default SubjectsChartLineIN;