import React, { useEffect, useRef, useState, useContext } from "react";
import * as echarts from "echarts";
import { words } from "./WordsFilter";
import { LanguageContext } from "../../../App";

const CloudHashtagPlatformsChart = ({ facebookPosts }) => {
  const chartRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const { language } = useContext(LanguageContext);

  const wordCount = facebookPosts.word_chart.length;

  const handleClickShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (facebookPosts && facebookPosts.word_chart) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = facebookPosts.word_chart.map((wordItem) => ({
        name: wordItem[0],
        value: wordItem[1],
      }));

      const filteredWordCloudData = wordCloudData.filter(
        (wordItem) => !words.includes(wordItem.name)
      );

      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "value",
          position: "top",
        },
        yAxis: {
          type: "category",
          data: showMore
            ? filteredWordCloudData.map((item) => item.name)
            : filteredWordCloudData.slice(0, 10).map((item) => item.name),
          inverse: true,
          axisLabel: {
            fontSize: 15,
            fontFamily: "Noto",
          },
        },
        series: [
          {
            type: "bar",
            data: showMore
              ? filteredWordCloudData.map((item) => item.value)
              : filteredWordCloudData.slice(0, 10).map((item) => item.value),
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#118be4",
                  },
                  {
                    offset: 1,
                    color: "#118be4",
                  },
                ],
              },
            },
          },
        ],
      };

      chart.setOption(options);

      const resizeChart = () => {
        chart.resize();
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        window.removeEventListener("resize", resizeChart);
        chart.dispose();
      };
    }
  }, [wordCount, showMore, facebookPosts]);

  return (
    <div style={{ width: "95%" }}>
      <div ref={chartRef} style={{ height: showMore ? "1200px" : "400px" }} />
      <div className="show_more_btn_cloud_hashtag_chart">
        {!showMore && (
          <>
            {language === "ar" ? (
              <button onClick={handleClickShowMore}>عرض المزيد</button>
            ) : (
              <button onClick={handleClickShowMore}>Show More</button>
            )}
          </>
        )}
        {showMore && (
          <>
            {language === "ar" ? (
              <button onClick={handleClickShowMore}>عرض أقل</button>
            ) : (
              <button onClick={handleClickShowMore}>Show Less</button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CloudHashtagPlatformsChart;
