import MostEffectiveUsersTableLikeTw from "./MostEffectiveUsersTableLikeTW";
import MostEffectiveUsersTableViewTW from "./MostEffectiveUsersTableViewTW";
import MostEffectiveUsersTableRetwitteTW from "./MostEffectiveUsersTableRetwitteTW";
import EngagementtoContentRatioTW from "./EngagementtoContentRatioTW";
import FollowerstoEngagementRatioonContentTW from "./FollowerstoEngagementRatioonContentTW";

function MostEffectiveUsersTableTW({
  selectedOption,
  twitterPosts,
  loadingStateTW,
  onUsernameClick,
}) {
  let displayedComponnets;

  if (
    selectedOption === "مجموع إعادة نشر" ||
    selectedOption === "Total Retweet"
  ) {
    displayedComponnets = (
      <MostEffectiveUsersTableRetwitteTW
        twitterPosts={twitterPosts}
        loadingStateTW={loadingStateTW}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "مجموع مشاهدة" ||
    selectedOption === "Total view"
  ) {
    displayedComponnets = (
      <MostEffectiveUsersTableViewTW
        twitterPosts={twitterPosts}
        loadingStateTW={loadingStateTW}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة المتابعين إلى التفاعلات على المحتوى" ||
    selectedOption === "Followers to Engagement Ratio on Content"
  ) {
    displayedComponnets = (
      <FollowerstoEngagementRatioonContentTW
        twitterPosts={twitterPosts}
        loadingStateTW={loadingStateTW}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة التفاعلات إلى المحتوى" ||
    selectedOption === "Engagement to Content Ratio"
  ) {
    displayedComponnets = (
      <EngagementtoContentRatioTW
        twitterPosts={twitterPosts}
        loadingStateTW={loadingStateTW}
        onUsernameClick={onUsernameClick}
      />
    );
  } else {
    displayedComponnets = (
      <MostEffectiveUsersTableLikeTw
        twitterPosts={twitterPosts}
        loadingStateTW={loadingStateTW}
        onUsernameClick={onUsernameClick}
      />
    );
  }

  return (
    <div className="top_users_table">
      <div className="top_users_table_page">{displayedComponnets}</div>
    </div>
  );
}

export default MostEffectiveUsersTableTW;
