import React, { useContext, useState } from "react";
import { LanguageContext } from "../../App";
import logo from "../../images/new_logo_menu.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import tick_step from "../../images/tick_step_blue.png";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function ErrorPage(props) {
  const { saveLoginStatus, setIsSubmitted } = props;

  const { language, toggleLanguage } = useContext(LanguageContext);
  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  // progress bar
  const steps = [1, 2, 3, 4];

  const getCircleClass = (step) => {
    if (props.currentStep > step) {
      return "circle_profile_page_progressbar_completed";
    }
    return "circle_profile_page_progressbar";
  };

  const getCircleContent = (step) => {
    if (props.currentStep > step) {
      return <img src={tick_step} alt="Completed" className="checkmark_icon" />;
    }
    return step;
  };

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  return (
    <div className="body_massage_page">
      <div className="header_body_massage_page">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="petition_page_btn_top_header_right">
          <img src={logo} alt="" />
        </div>
      </div>
      {props.showProgress && (
        <div className="profile_page_progressbar">
          <div
            className={`progress_container ${
              language === "ar" ? "progress_container_ar" : ""
            }`}
          >
            <div
              className={`profile_page_progressbar_progress ${
                language === "ar" ? "profile_page_progressbar_progress_ar" : ""
              }`}
              style={{
                width: `${
                  props.currentStep === 4 ? 85 : (props.currentStep - 1) * 30
                }%`,
              }}
            ></div>
            {steps.map((step) => (
              <div
                key={step}
                className={`text_wrap_profile_page_progressbar ${
                  props.currentStep >= step ? "active" : ""
                }`}
              >
                <div className={getCircleClass(step)}>
                  {getCircleContent(step)}
                </div>
                {language === "en" ? (
                  <p className="text_profile_page_progressbar">
                    {step === 1 && "define project"}
                    {step === 2 && "verification"}
                    {step === 3 && "payment"}
                    {step === 4 && "data prep"}
                  </p>
                ) : (
                  <p className="text_profile_page_progressbar">
                    {step === 1 && "إنشاء المشروع"}
                    {step === 2 && "التأکيد"}
                    {step === 3 && "الدفع"}
                    {step === 4 && "إعداد المعلومات"}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        className={`card_body_massage_page_login ${
          language === "ar" ? "arabic" : ""
        }`}
      >
        <img src={props.children} alt="" />
        <strong>{props.title}</strong>
        <p>{props.para}</p>
        <p
          className={`card_footer_body_massage_page ${
            language === "ar" ? "arabic" : "english"
          }`}
        >
          {props.footer}
        </p>
      </div>
      <div className="logout_icon_steps_filed">
        <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
      </div>
    </div>
  );
}

export default ErrorPage;
