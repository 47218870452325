import multiple_red from "../../images/multiple_red.png";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2/src/sweetalert2.js";
import { HashLoader } from "react-spinners";
import {
  getProjectLists,
  deleteProjectLists,
} from "../../backend/Admin/ProjectsManager/ProjectsManager";
import {
  getAdminUserLists,
  getUserLists,
} from "../../backend/Admin/UserManager/UserManager";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  addSubTopic,
  addTopic,
  deleteSubTopic,
  deleteTopic,
} from "../../backend/Admin/TopicManager/TopicManager";

import {
  addUserAnalyzer,
  addUsersToProjects,
} from "../../backend/Admin/ProjectsManager/Analyzer";
import { toast } from "react-toastify";
import { TbPlaylistAdd } from "react-icons/tb";
import { LanguageContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/new_logo_menu.png";
import base_icon from "../../images/show_site_menu.png";
import { logout } from "../../backend/Login_backend";
import show_site_menu from "../../images/base_menu.png";
import admin_menu_icon from "../../images/admin_menu_icon.png";
import senior_menu_icon from "../../images/tools_menu_analysis_senior_35.png";
import logout_icon from "../../images/logout_menu.png";

function SeniorAnalyzerPage({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  isSeniorAnalyzer,
}) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchedApi, setfetchedApi] = useState(false);
  const [apiData, setapiData] = useState([]);
  // const [needLogin, setneedLogin] = useState(false);
  const [chooseUser, setChooseUser] = useState([]);

  const [apiDataUsersAdmin, setapiDataUsersAdmin] = useState(undefined);
  const [fetchedApiAdmins, setfetchedApiAdmins] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [fetchedApiEmotions, setfetchedApiEmotions] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [fetchedApiAxis, setfetchedApiAxis] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [apiDataUsers, setapiDataUsers] = useState(undefined);
  const [fetchedApiUsers, setfetchedApiUsers] = useState(false);

  const [nextLink, setNextLink] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleStart();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchedApi]);

  useEffect(() => {
    const fetchedApiUsers = async () => {
      try {
        await handleStartUsers();
      } finally {
        setIsLoading(false);
      }
    };
    const fetchedApiAdmins = async () => {
      try {
        await handleStartUsersAdmin();
      } finally {
        setIsLoading(false);
      }
    };

    fetchedApiUsers();
    fetchedApiAdmins();
  }, [fetchedApiUsers, fetchedApiAdmins]);

  const handleStart = async () => {
    try {
      var result = await getProjectLists();
      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var data = result.data.data;
        console.log("Data from API:", data);
        setNextLink(data.next);

        data = data.results.map((item) => {
          item["openTopicPopup"] = false;
          item["openAxisPopup"] = false;
          item["openEmotionsPopup"] = false;
          item["openAnalyzerPopup"] = false;
          item["openUsersPopup"] = false;
          item["openDetailPopup"] = false;
          return item;
        });
        setapiData(data);
        setfetchedApi(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const chooseUserAnalyzer = (user, setChooseUser) => {
    setChooseUser((x) => {
      let users = x;
      if (x.includes(user)) {
        users.splice(users.indexOf(user), 1);
      } else {
        users.push(user);
      }
      return users;
    });
  };

  const cleanChooseAnalyzer = (setChooseUser) => {
    setChooseUser([]);
  };

  const addUsersAnalyzer = (proIndex) => {
    setChooseUser(apiData[proIndex].users.map((x) => x.id));
    console.log(
      "🚀 ~ file: AddSubjects.js:132 ~ addUsersAnalyzer ~ apiData[proIndex].users:",
      apiData[proIndex].users.map((x) => x.id)
    );
  };

  const handleAddTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await addTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        // setfetchedApi(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleDeleteTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await deleteTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleDeleteSubTopic = async (
    nameProject,
    newMainTopic,
    newSubTubic
  ) => {
    try {
      var result = await deleteSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleAddSubTopic = async (nameProject, newMainTopic, newSubTubic) => {
    try {
      var result = await addSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleStartUsers = async () => {
    try {
      var result = await getUserLists(null, 2000);
      console.log("Got Users ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var users = result.data.data;
        setapiDataUsers(users.results);
        console.log(users);
        setfetchedApiUsers(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleStartUsersAdmin = async () => {
    try {
      var result = await getAdminUserLists(null, 2000);
      console.log("Got Users ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        var users = result.data.data;
        setapiDataUsersAdmin(users.results);
        console.log(users);
        setfetchedApiAdmins(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  const handleFetchData = async ({ func, ...inp }) => {
    try {
      var result = await func(inp);

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          window.alert("Server Error");
        } else {
          window.alert("Connection Error");
        }
      } else {
        // var users = result.data.data;
        // setapiDataUsers(users.results);
        setfetchedApi(false);
        setIsLoading(true);
        setfetchedApiUsers(true);
        setfetchedApiAdmins(true);
      }
    } catch (error) {
      window.alert("Something Went Wrong");
    }
  };

  // const handleDeleteProject = async (numberproject, nameproject) => {
  //   const result = await Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   });

  //   if (result.isConfirmed) {
  //     await deleteProjectLists(nameproject);
  //     await handleStart();
  //     Swal.fire("Deleted!", "Your project has been deleted.", "success");
  //   }
  // };

  const [topics, setTopics] = useState([]);
  const [newMainTopic, setNewMainTopic] = useState("");

  const [newSubTopic, setNewSubTopic] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    console.log(
      "🚀 ~ file: AddSubjects.js:157 ~ AddSubjects ~ fetchedApi:",
      apiData
    );
  }, [apiData]);

  const addMainTopic = (nameProject) => {
    if (newMainTopic.trim() !== "") {
      // const isDuplicate = apiData.find((project) => {
      //   if (
      //     project.nameproject === nameProject &&
      //     project.subjects.find((ele) => ele.name === newMainTopic)
      //   ) {
      //     return true;
      //   }
      // });
      const isDuplicate = apiData.find(
        (project) =>
          project.nameproject === nameProject &&
          project.subjects.find((ele) => ele.name === newMainTopic)
      );

      if (!isDuplicate) {
        handleAddTopic(nameProject, newMainTopic);
        setapiData(
          apiData.map((ele, index) => {
            if (ele.nameproject === nameProject) {
              return {
                ...ele,
                subjects: [
                  ...ele.subjects,
                  { name: newMainTopic, sub_subjects: [] },
                ],
              };
            }
            return ele;
          })
        );
        setNewMainTopic("");
      } else {
        alert("This Main topic is already in the list.");
      }
    }
  };

  const addSubtopic = (topicChoosed, nameProject) => {
    const topic = topicChoosed;
    handleAddSubTopic(nameProject, topic.name, newSubTopic);
    if (newSubTopic.trim() !== "") {
      if (topic) {
        const isDuplicate = topic.sub_subjects.includes(newSubTopic);
        if (!isDuplicate) {
          topic.sub_subjects.push(newSubTopic);
          setNewSubTopic("");
          setSelectedTopic({ ...topic });
          setapiData(
            apiData.map((ele, index) => {
              if (ele.nameproject === nameProject) {
                return {
                  ...ele,
                  subjects: ele.subjects.map((subject) => {
                    if (subject.name === topic.name) {
                      return topic;
                    }
                    return subject;
                  }),
                };
              }
              return ele;
            })
          );
        } else {
          alert(
            "This subtopic is already in the list for the selected main topic."
          );
        }
      }
    }
  };

  const handleRemoveMainTopic = (nameProject, indexOfSubject) => {
    const updatedTopics = [...topics];
    updatedTopics.splice(indexOfSubject, 1);
    setTopics(updatedTopics);

    setapiData(
      apiData.map((ele) => {
        if (ele.nameproject === nameProject) {
          const temp = [...ele.subjects];
          console.log(
            "🚀 ~ file: AddSubjects.js:335 ~ apiData.map ~ temp:",
            temp[indexOfSubject].name
          );
          handleDeleteTopic(nameProject, temp[indexOfSubject].name);
          temp.splice(indexOfSubject, 1);
          return { ...ele, subjects: [...temp] };
        }
        return ele;
      })
    );
  };

  const handleRemoveSubtopic = (nameProject, subIndex, topicChoosed) => {
    const topic = topicChoosed;
    handleDeleteSubTopic(nameProject, topic.name, topic.sub_subjects[subIndex]);
    topic.sub_subjects.splice(subIndex, 1);
    setapiData(
      apiData.map((ele, index) => {
        if (ele.nameproject === nameProject) {
          return {
            ...ele,
            subjects: ele.subjects.map((subject) => {
              if (subject.name === topic.name) {
                return topic;
              }
              return subject;
            }),
          };
        }
        return ele;
      })
    );
  };

  // اضافه کردن محور

  const handleOpenPopupAxis = () => {
    if (language === "en") {
      Swal.fire({
        icon: "warning",
        title: "Oops...!",
        text: "You do not have access to this section",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "أوه",
        text: "ليس لديك حق الوصول إلى هذا القسم",
        confirmButtonText: "حسناً",
      });
    }
  };

  // اضافه کردن عواطف

  const handleOpenPopupEmotions = () => {
    if (language === "en") {
      Swal.fire({
        icon: "warning",
        title: "Oops...!",
        text: "You do not have access to this section",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "أوه",
        text: "ليس لديك حق الوصول إلى هذا القسم",
        confirmButtonText: "حسناً",
      });
    }
  };

  // Pagination

  const [loadMoreState, setLoadMoreState] = useState(false);
  const postsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts = apiData
    ? apiData.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  const totalPages = apiData ? Math.ceil(apiData.length / postsPerPage) : 0;

  const handleLoadMoreClicked = async () => {
    if (loadMoreState || !nextLink) {
      return;
    }
    if (nextLink === null) {
      console.error("Next link is undefined or null!");
      return;
    }
    setLoadMoreState(true);
    try {
      var postsByWord = await getProjectLists(nextLink);
      if (postsByWord.hasError) {
        console.error("postsByWord Error");
      } else {
        var data = postsByWord.data.data;
        console.log("postsByWord", data);
        setNextLink(data.next);
        console.log("data.nextdata.nextdata.next", data.next);
        setapiData((prevData) => [...prevData, ...data.results]);
        console.log("Handle Load more clicked next link after", data.results);
        setLoadMoreState(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const nextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    if (currentPage === totalPages - 1) {
      console.log("Loading More ...");
      await handleLoadMoreClicked();
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // تابع تغییر صفحه
  // const handlePageChange = (event, page) => {
  //   setCurrentPage(page);
  // };

  // add users to project

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleUserCheckboxChange = (userId) => {
    // Toggle user selection
    if (selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((id) => id !== userId)
      );
    } else {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
    }
  };

  const handleOkButtonClick = async ({ projectName, users_ids }) => {
    try {
      console.log("Project Name:", projectName);
      console.log("User IDs:", users_ids);

      const result = await addUsersToProjects({
        projectName,
        users_ids,
      });

      if (result.hasError) {
        toast.error("Error while adding users to projects");
      } else {
        toast.success("User added successfully to Project");
      }

      // Close the popup
      handleClosePopup(false);
    } catch (error) {
      console.error("Error while adding users to projects:", error);
    }
  };

  // popup settings for open & close

  const [popupStates, setPopupStates] = useState([]);

  useEffect(() => {
    if (apiData.length > 0) {
      const initialPopupStates = apiData.map(() => ({
        openTopicPopup: false,
        openAnalyzerPopup: false,
      }));
      setPopupStates(initialPopupStates);
    }
  }, [apiData]);

  const handleOpenPopup = (index, popupType) => {
    if (index >= 0 && index < popupStates.length) {
      setPopupStates((prevStates) =>
        prevStates.map((state, i) => ({
          ...state,
          [popupType]: i === index ? !state[popupType] : state[popupType],
        }))
      );
    }
  };

  const handleClosePopup = (index, popupType) => {
    if (index >= 0 && index < popupStates.length) {
      setPopupStates((prevStates) =>
        prevStates.map((state, i) => ({
          ...state,
          [popupType]: i === index ? false : state[popupType],
        }))
      );
    }
  };

  // arabic

  const handleLanguageToggle = () => {
    toggleLanguage();
  };

  // logout

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();

    saveLoginStatus(false);
    setIsSubmitted(false);
    navigate("/");
  };

  return (
    <div className="senior_analyzer">
      <div
        className={`senior_analyzer_page ${
          language === "ar" ? "senior_analyzer_page_ar" : ""
        }`}
      >
        <div
          className={`senior_analyzer_page_left_menu ${
            language === "en" ? "left" : "right"
          }`}
        >
            <div
          className="left_menu_dashboard"
        >
          <img className="logo_dashboard" src={logo} alt="logo" />

          <div id="menu_submenu">
            <div className="contact_menu">
              <Link to="/base">
                <img src={base_icon} alt="" />
              </Link>
            </div>

            {isSeniorAnalyzer || isSuperUser ? (
              <section
                className={`sidebar_${language === "en" ? "left" : "right"}`}
              >
                <div id="active_bg">
                  <img src={senior_menu_icon} alt="" />
                  <div
                    className={`dropdown_${
                      language === "en" ? "left" : "right"
                    }`}
                  >
                    <Link to="/seniorpage">
                      <span>
                        {language === "en"
                          ? "Project Settings"
                          : "إعدادات المشروع"}
                      </span>
                    </Link>
                    <Link to="/seniorconfirm">
                      <span>
                        {" "}
                        {language === "en"
                          ? "Post Approval"
                          : "تأكيد المنشورات"}
                      </span>
                    </Link>
                  </div>
                </div>
              </section>
            ) : null}

            {isSuperUser ? (
              <div className="contact_menu">
                <Link to="/home">
                  <img src={show_site_menu} alt="" />
                </Link>
              </div>
            ) : null}

            {isSuperUser ? (
              <div className="contact_menu">
                <Link to="/admin">
                  <img src={admin_menu_icon} alt="" />
                </Link>
              </div>
            ) : null}

            <div className="contact_menu">
              <img src={logout_icon} alt="" onClick={handleLogout} />
            </div>
          </div>

          <div className="btn_container_toggle_language_base">
            <label className="switch btn-color-mode-switch-base">
              <input
                value="1"
                id="color_mode"
                name="color_mode"
                type="checkbox"
                onClick={handleLanguageToggle}
              />
              <label
                className="btn-color-mode-switch-inner-base"
                data-off="EN"
                data-on="AR"
                htmlFor="color_mode"
              ></label>
            </label>
          </div>
        </div>
        </div>

        <div className="add_subjects">
          <div className="add_subjects_page">
            <div className="header_bottom_scrape_data_page">
              <div className="header_bottom_scrape_data_page_row">
                <div className="Documents_btn_create_project_page">
                  <div className="Documents_btn_create_project_page_items_left">
                    <strong>ADD Emotions</strong>
                    <span>to all</span>
                    <strong>Projects</strong>
                  </div>

                  <div
                    onClick={handleOpenPopupEmotions}
                    className="Documents_btn_create_project_page_items_right"
                  >
                    <TbPlaylistAdd id="btn_icon_addsub_admin_page" />
                  </div>
                </div>
                <div className="Documents_btn_create_project_page instahram">
                  <div className="Documents_btn_create_project_page_items_left">
                    <strong>ADD Main Point</strong>
                    <span>to all</span>
                    <strong>Projects</strong>
                  </div>
                  <div
                    onClick={handleOpenPopupAxis}
                    className="Documents_btn_create_project_page_items_right"
                  >
                    <TbPlaylistAdd id="btn_icon_addsub_admin_page" />
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="spiner_postbox_new_admin">
                <HashLoader color="#458BDF" size={50} />
              </div>
            ) : (
              <>
                <div className="container_table_scrapped_project_list">
                  <div className="header_table_scrapped_project_list">
                    <span className="header_item_table_scrapped_project_list small_section">
                      Project ID
                    </span>
                    <span className="header_item_table_scrapped_project_list big_section">
                      Project Name
                    </span>
                    <span className="header_item_table_scrapped_project_list">
                      Creator
                    </span>
                    <span className="header_item_table_scrapped_project_list">
                      Topic & SubTopic
                    </span>
                    <span className="header_item_table_scrapped_project_list">
                      Analyzer Access
                    </span>
                    {/* <span className="header_item_table_scrapped_project_list">
                      Users Access
                    </span> */}
                    {/* <span className="header_item_table_scrapped_project_list small_section">
                      Delete
                    </span> */}
                  </div>
                  {currentPosts.length === 0 ? (
                    <div className="not_selected_btn_request_massage">
                      <p>No Data Available.</p>
                    </div>
                  ) : (
                    <>
                      {currentPosts.map((project, index) => {
                        return (
                          <div
                            className="projects_section_scrapped_project_list"
                            key={project.project_id}
                          >
                            <div className="projects_item_scrapped_project_list small_section">
                              {project.project_id}
                            </div>
                            <div className="projects_item_scrapped_project_list big_section">
                              <span className="tooltip_posts_box_top">
                                {project.name &&
                                project.name.length > 20
                                  ? project.name.slice(0, 20) + "..."
                                  : project.name && project.name}
                                <span className="tooltip_text_posts_box_top">
                                  {project.name}
                                </span>
                              </span>
                            </div>
                            <div className="projects_item_scrapped_project_list">
                              {project.creator}
                            </div>
                            <div className="projects_item_scrapped_project_list ">
                              <div className="items_rows_body_add_admin_page_horizintal_little">
                                <TbPlaylistAdd
                                  onClick={() =>
                                    handleOpenPopup(index, "openTopicPopup")
                                  }
                                  className="btn_add_topic_body_add_admin_page"
                                />

                                <Dialog
                                  className="popup_add_subject"
                                  // open={project.openTopicPopup}
                                  open={
                                    popupStates[index]?.openTopicPopup || false
                                  }
                                  onClose={() =>
                                    handleClosePopup(index, "openTopicPopup")
                                  }
                                >
                                  <DialogTitle className="header_popup_right_header_add_admin_page">
                                    <label>Add Topic and SubTopic</label>
                                    <button
                                      onClick={() =>
                                        handleClosePopup(
                                          index,
                                          "openTopicPopup"
                                        )
                                      }
                                      className="btn_close_header_popup_right_header_add_admin_page"
                                    >
                                      <CloseIcon />
                                    </button>
                                  </DialogTitle>
                                  <DialogContent className="body_popup_add_subject">
                                    <div className="rows_body_popup_add_subject_one">
                                      <input
                                        type="text"
                                        placeholder="Enter Topic"
                                        value={newMainTopic}
                                        onChange={(e) =>
                                          setNewMainTopic(e.target.value)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          addMainTopic(project.nameproject)
                                        }
                                      >
                                        ADD
                                      </button>
                                    </div>
                                    <div className="rows_body_popup_add_subject">
                                      <ul>
                                        {project.subjects.map(
                                          (topic, index) => (
                                            <li
                                              key={index}
                                              className="li_topic_body_popup_add_subject"
                                              style={{
                                                cursor: "pointer",
                                                background:
                                                  selectedTopic === topic
                                                    ? "#fafafa"
                                                    : "#fff",
                                              }}
                                              onClick={() =>
                                                setSelectedTopic(topic)
                                              }
                                            >
                                              {topic.name}
                                              <button
                                                onClick={() => {
                                                  console.log("delete");
                                                  handleRemoveMainTopic(
                                                    project.name,
                                                    index
                                                  );
                                                }}
                                                id="btn_remove_items_li_ul_add_topic"
                                              >
                                                <img
                                                  src={multiple_red}
                                                  alt=""
                                                />
                                              </button>
                                              <ul className="ul_topic_body_popup_add_subject">
                                                {/* TODO */}
                                                {topic.sub_subjects.map(
                                                  (subtopic, subIndex) => (
                                                    <li key={subIndex}>
                                                      {subtopic}
                                                      <button
                                                        onClick={() =>
                                                          handleRemoveSubtopic(
                                                            project.name,
                                                            subIndex,
                                                            topic
                                                          )
                                                        }
                                                        id="btn_remove_items_li_ul_add_topic"
                                                      >
                                                        <img
                                                          src={multiple_red}
                                                          alt=""
                                                        />
                                                      </button>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                              {selectedTopic === topic && (
                                                <div className="ul_topic_body_popup_add_subject_input">
                                                  <input
                                                    type="text"
                                                    placeholder="Enter SubTopic"
                                                    value={newSubTopic}
                                                    onChange={(e) =>
                                                      setNewSubTopic(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <button
                                                    onClick={() =>
                                                      addSubtopic(
                                                        topic,
                                                        project.name
                                                      )
                                                    }
                                                  >
                                                    ADD SubTopic
                                                  </button>
                                                </div>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </DialogContent>
                                  <DialogActions className="btns_bottom_search_simple">
                                    <button
                                      className="okay_btn_search_simple"
                                      onClick={() =>
                                        handleClosePopup(
                                          index,
                                          "openTopicPopup"
                                        )
                                      }
                                    >
                                      OK
                                    </button>
                                    <button
                                      className="deny_btn_search_simple"
                                      onClick={() =>
                                        handleClosePopup(
                                          index,
                                          "openTopicPopup"
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                            <div className="projects_item_scrapped_project_list">
                              <div className="items_rows_body_add_admin_page_horizintal_little">
                                <TbPlaylistAdd
                                  // onClick={handleOpenPopupAnalyzer}
                                  onClick={() => {
                                    addUsersAnalyzer(index);
                                    handleOpenPopup(index, "openAnalyzerPopup");
                                  }}
                                  className="btn_add_topic_body_add_admin_page"
                                />

                                <Dialog
                                  className="popup_right_header_add_admin_page"
                                  // open={project.openAnalyzerPopup}
                                  open={
                                    popupStates[index]?.openAnalyzerPopup ||
                                    false
                                  }
                                  onClose={() =>
                                    handleClosePopup(index, "openAnalyzerPopup")
                                  }
                                >
                                  <DialogTitle className="header_popup_right_header_add_admin_page">
                                    <label>Add Users To Analysis</label>
                                    <button
                                      onClick={() => {
                                        cleanChooseAnalyzer(setChooseUser);
                                        handleClosePopup(
                                          index,
                                          "openAnalyzerPopup"
                                        );
                                      }}
                                      className="btn_close_header_popup_right_header_add_admin_page"
                                    >
                                      <CloseIcon />
                                    </button>
                                  </DialogTitle>
                                  <DialogContent className="body_popup_add_admin">
                                    {apiDataUsersAdmin
                                      ? apiDataUsersAdmin.map((user) => {
                                          if (
                                            user.user_permissions &&
                                            !user.user_permissions.main_admin
                                          ) {
                                            return (
                                              <div
                                                className="show_admin_popup_add_subject"
                                                key={user.id}
                                              >
                                                <div>{user.username}</div>
                                                <label className="checkbox_container_add_analyzer_popup">
                                                  <input
                                                    className="custom_checkbox_add_analyzer_popup"
                                                    defaultChecked={chooseUser.includes(
                                                      user.id
                                                    )}
                                                    type="checkbox"
                                                    onClick={() => {
                                                      console.log(
                                                        "🚀 ~ file: AddSubjects.js:1117 ~ ?apiDataUsers.map ~ chooseUserAnalyzer:",
                                                        chooseUser
                                                      );
                                                      chooseUserAnalyzer(
                                                        user.id,
                                                        setChooseUser
                                                      );
                                                    }}
                                                  />
                                                  <span className="checkmark_add_analyzer_popup"></span>
                                                </label>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })
                                      : ""}
                                  </DialogContent>
                                  <DialogActions className="btns_bottom_search_simple">
                                    <button
                                      className="okay_btn_search_simple"
                                      onClick={() => {
                                        cleanChooseAnalyzer(setChooseUser);
                                        handleFetchData({
                                          func: addUserAnalyzer,
                                          projectName: project.name,
                                          users_ids: chooseUser,
                                        });
                                        handleClosePopup(
                                          index,
                                          "openAnalyzerPopup"
                                        );
                                      }}
                                    >
                                      OK
                                    </button>
                                    <button
                                      className="deny_btn_search_simple"
                                      onClick={() => {
                                        cleanChooseAnalyzer(setChooseUser);
                                        handleClosePopup(
                                          index,
                                          "openAnalyzerPopup"
                                        );
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                            {/* <div className="projects_item_scrapped_project_list">
                              <div className="items_rows_body_add_admin_page_horizintal_little">
                                <TbPlaylistAdd
                                  onClick={() => {
                                    handleOpenPopup(index, "openUsersPopup");
                                  }}
                                  className="btn_add_topic_body_add_admin_page"
                                />

                                <Dialog
                                  className="popup_right_header_add_admin_page"
                                  // open={project.openUsersPopup}
                                  open={
                                    popupStates[index]?.openUsersPopup || false
                                  }
                                  onClose={() =>
                                    handleClosePopup(index, "openUsersPopup")
                                  }
                                >
                                  <DialogTitle className="header_popup_right_header_add_admin_page">
                                    <label>Add Users To Project</label>
                                    <button
                                      onClick={() => {
                                        handleClosePopup(
                                          index,
                                          "openUsersPopup"
                                        );
                                      }}
                                      className="btn_close_header_popup_right_header_add_admin_page"
                                    >
                                      <CloseIcon />
                                    </button>
                                  </DialogTitle>
                                  <DialogContent className="body_popup_add_admin">
                                    {apiDataUsers
                                      ? apiDataUsers.map((user) => {
                                          if (
                                            user &&
                                            user.dbName === "default"
                                          ) {
                                            return (
                                              <div
                                                className="show_admin_popup_add_subject"
                                                key={user.id}
                                              >
                                                <div>{user.username}</div>
                                                <label className="checkbox_container_add_analyzer_popup">
                                                  <input
                                                    className="custom_checkbox_add_analyzer_popup"
                                                    type="checkbox"
                                                    onChange={() =>
                                                      handleUserCheckboxChange(
                                                        user.id
                                                      )
                                                    }
                                                    checked={selectedUsers.includes(
                                                      user.id
                                                    )}
                                                  />
                                                  <span className="checkmark_add_analyzer_popup"></span>
                                                </label>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })
                                      : ""}
                                  </DialogContent>
                                  <DialogActions className="btns_bottom_search_simple">
                                    <button
                                      className="okay_btn_search_simple"
                                      onClick={() =>
                                        handleOkButtonClick({
                                          projectName: project.nameproject,
                                          users_ids: selectedUsers,
                                        })
                                      }
                                    >
                                      OK
                                    </button>
                                    <button
                                      className="deny_btn_search_simple"
                                      onClick={() => {
                                        handleClosePopup(
                                          index,
                                          "openUsersPopup"
                                        );
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div> */}

                            {/* <div className="projects_item_scrapped_project_list small_section">
                              <IoMdRemoveCircleOutline
                                id="icons_admin_react_icon_remove"
                                onClick={() => {
                                  handleDeleteProject(
                                    project.numberproject,
                                    project.nameproject
                                  );
                                }}
                              />
                            </div> */}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                {loadMoreState ? (
                  <div className="spiner_postbox">
                    <HashLoader color="#458BDF" size={30} />
                  </div>
                ) : (
                  ""
                )}
                <div className="pagination_posts_body_result_list">
                  <button onClick={prevPage} disabled={currentPage === 1}>
                    <KeyboardDoubleArrowLeftIcon />
                  </button>
                  <span className="pagination_posts_body_result_list_span">
                    {currentPage}
                  </span>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeniorAnalyzerPage;
