export const toggleDropdown = () => {
    return {
      type: 'TOGGLE_DROPDOWN',
    };
  };
  
  export const selectItem = (selectedItem) => {
    return {
      type: 'SELECT_ITEM',
      payload: selectedItem,
    };
  };