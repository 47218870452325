import React, { useState, useContext, useEffect, useRef } from "react";
import "../../css/User/ProfileUser.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { addPersonalInformation } from "../../backend/Admin/UserManager/UserManager";
import { LanguageContext } from "../../App";
import logo from "../../images/new_logo_menu.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function PersonalInformation({ saveLoginStatus, setIsSubmitted }) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    MobileNumber: "",
    PhoneNumber: "",
    Gender: "",
    intersted: "",
    jobTitle: "",
    message: "",
    company_name: "",
    employees_no: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (
        !formValues.firstName ||
        !formValues.lastName ||
        !formValues.MobileNumber
      ) {
        if (language === "en") {
          Swal.fire({
            text: "Please fill in the required fields: First Name, Last Name, Mobile Number",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            text: "يرجى تعبئة كافة الحقول: الاسم الأول، اسم العائلة، رقم الهاتف المحمول.",
            icon: "warning",
            confirmButtonText: "حسناً",
          });
        }
        return;
      }
      toast.warning(
        language === "ar"
          ? "جاري إرسال طلبك. يرجى الانتظار لحظة"
          : "Your request is being sent. Please wait a moment"
      );

      const interstedToSend =
        selectedChooseIntersted ===
          "Interested in Data Khana products and services" ||
        selectedChooseIntersted === "مهتم بمنتجات و خدمات داتا خانة"
          ? "Interested in Data Khana products and services"
          : selectedChooseIntersted ===
              "Interested in cooperating as a partner or distributor" ||
            selectedChooseIntersted === "مهتم بالتعاون كـشريك او موزع"
          ? "Interested in cooperating as a partner or distributor"
          : selectedChooseIntersted === "Scientific researcher or student" ||
            selectedChooseIntersted === "باحث علمي أو طالب"
          ? "Scientific researcher or student"
          : selectedChooseIntersted === "looking for a job" ||
            selectedChooseIntersted === "أبحث عن وظيفة"
          ? "looking for a job"
          : "";

      const result = await addPersonalInformation(
        formValues.firstName,
        formValues.lastName,
        formValues.MobileNumber,
        formValues.PhoneNumber,
        interstedToSend,
        formValues.jobTitle,
        formValues.message,
        formValues.company_name,
        selectedChooseemployees
        // formValues.employees_no,
      );

      if (!result.hasError) {
        setFormValues({
          firstName: "",
          lastName: "",
          PhoneNumber: "",
          MobileNumber: "",
          jobTitle: "",
          message: "",
          company_name: "",
          employees_no: "",
          intersted: "",
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title:
            language === "ar"
              ? "تم تسجيل المشروع الجديد."
              : "The new project has been registered.",
          showConfirmButton: false,
          timer: 1500,
        });
        // window.location.href = "/";
        window.location.assign("/");
      } else {
        console.error("Request failed:", result.errorText);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  // gender menu

  const DropMenuGender = {
    submenu: [
      {
        Key: 0,
        Value:
          language === "en"
            ? "Interested in Data Khana products and services"
            : "مهتم بمنتجات و خدمات داتا خانة",
      },
      {
        Key: 1,
        Value:
          language === "en"
            ? "Interested in cooperating as a partner or distributor"
            : "مهتم بالتعاون كـشريك او موزع",
      },
      {
        Key: 2,
        Value:
          language === "en"
            ? "Scientific researcher or student"
            : "باحث علمي أو طالب",
      },
      {
        Key: 3,
        Value: language === "en" ? "looking for a job" : "أبحث عن وظيفة",
      },
    ],
  };

  const [isOpenIntersted, setIsOpenIntersted] = useState(false);
  const [selectedChooseIntersted, setSelectedChooseIntersted] = useState(
    language === "en" ? "I am ..." : "...أنا"
  );

  const InterstedRef = useRef(null);

  useEffect(() => {
    setSelectedChooseIntersted(language === "en" ? "I am ..." : "...أنا");
  }, [language]);

  const handleToggleDropdownIntersted = () => {
    setIsOpenIntersted(!isOpenIntersted);
  };

  const handleSelectIntersted = (value) => {
    const selectedOption = DropMenuGender.submenu.find(
      (option) => option.Key === value
    );
    if (selectedOption) {
      setSelectedChooseIntersted(selectedOption.Value);
      setIsOpenIntersted(false);
    } else {
      setSelectedChooseIntersted("");
    }
  };

  const handleClickOutsideIntersted = (event) => {
    if (InterstedRef.current && !InterstedRef.current.contains(event.target)) {
      setIsOpenIntersted(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideIntersted);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideIntersted);
    };
  }, []);

  // تعداد کارمندان

  const DropMenuemployees = {
    submenu: [
      {
        Key: 0,
        Value: "1-5",
      },
      {
        Key: 1,
        Value: "5-25",
      },
      {
        Key: 2,
        Value: "25-50",
      },
      {
        Key: 3,
        Value: "50-100",
      },
      {
        Key: 4,
        Value: "100-500",
      },
      {
        Key: 5,
        Value: "500-1000",
      },
      {
        Key: 6,
        Value: "+1000",
      },
    ],
  };

  const [isOpenemployees, setIsOpenemployees] = useState(false);
  const [selectedChooseemployees, setSelectedChooseemployees] = useState("1-5");

  const employeesRef = useRef(null);

  const handleToggleDropdownemployees = () => {
    setIsOpenemployees(!isOpenemployees);
  };

  const handleSelectemployees = (value) => {
    const selectedChooseemployees = DropMenuemployees.submenu.find(
      (option) => option.Key === value
    );
    if (selectedChooseemployees) {
      setSelectedChooseemployees(selectedChooseemployees.Value);
      setIsOpenemployees(false);
    } else {
      setSelectedChooseemployees("");
    }
  };

  const handleClickOutsideEmployees = (event) => {
    if (employeesRef.current && !employeesRef.current.contains(event.target)) {
      setIsOpenemployees(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideEmployees);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEmployees);
    };
  }, []);

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  return (
    <div className="send_request_user">
      <div className="header_body_massage_page">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="petition_page_btn_top_header_right">
          <img src={logo} alt="" />
        </div>
      </div>

      {language === "ar" ? (
        <div className="send_request_user_page">
          <span className="send_request_user_page_span_header">
            يرجى إدخال معلوماتك لبدء عملية إنشاء حسابك
          </span>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="lastName"
              >
                الإسم الثاني
              </label>
              <input
                className="input_send_request_user_page_row_one_item arabic"
                type="text"
                name="lastName"
                // placeholder="مثال: بغدادي"
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="firstName"
              >
                الإسم الأول
              </label>
              <input
                className="input_send_request_user_page_row_one_item arabic"
                type="text"
                name="firstName"
                // placeholder="مثال: محمد"
                value={formValues.firstName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="MobileNumber"
              >
                رقم الجوال
              </label>
              <input
                className="input_send_request_user_page_row_one_item arabic"
                type="text"
                name="MobileNumber"
                // placeholder="مثال: +964 80 000 "
                value={formValues.MobileNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="PhoneNumber"
              >
                رقم الهاتف
              </label>
              <input
                className="input_send_request_user_page_row_one_item arabic"
                type="text"
                name="PhoneNumber"
                // placeholder="مثال: +964 800 000000"
                value={formValues.PhoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <div
                className="send_request_user_page_row_one_item_menu_gender"
                ref={InterstedRef}
              >
                <label className="label_send_request_user_page_row_one_item arabic">
                  ...أنا
                </label>
                <div
                  className="dropdown_label_gender_profile_page arabic"
                  onClick={handleToggleDropdownIntersted}
                >
                  {selectedChooseIntersted}
                  <span
                    className={`arrow_filter_gender_chooise_arabic ${
                      isOpenIntersted ? "up" : "down"
                    }`}
                  >
                    ⮛
                  </span>
                </div>
                {isOpenIntersted && (
                  <ul className="dropdown_menu_gender_profile_page">
                    {DropMenuGender.submenu &&
                      DropMenuGender.submenu.map((e, key) => (
                        <li
                          className="dropdown_menu_header_chart_li arabic"
                          key={key}
                          onClick={() => handleSelectIntersted(e.Key)}
                        >
                          {e.Value}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="jobTitle"
              >
                المسمى الوظيفي
              </label>
              <input
                className="input_send_request_user_page_row_one_item arabic"
                type="text"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleInputChange}
                // onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_textarea">
              <label
                className="label_send_request_user_page_row_one_item arabic"
                htmlFor="message"
              >
                هل لديك رسالة معينة
              </label>
              <textarea
                className="input_send_request_user_page_row_one_item_textarea arabic"
                name="message"
                // placeholder="الرجاء إدخال سيرتك الذاتية..."
                value={formValues.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="send_request_user_page_row_one_hor">
              <div className="send_request_user_page_row_one_item_dubble margin">
                <label
                  className="label_send_request_user_page_row_one_item arabic"
                  htmlFor="company_name"
                >
                  إسم الشركة
                </label>
                <input
                  id="company_name"
                  className="input_send_request_user_page_row_one_item arabic"
                  type="text"
                  name="company_name"
                  // placeholder="مثال: +1 800 000000"
                  value={formValues.company_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="send_request_user_page_row_one_item_dubble">
                <div
                  className="send_request_user_page_row_one_item_menu_gender"
                  ref={employeesRef}
                >
                  <label className="label_send_request_user_page_row_one_item arabic">
                    عدد الموظفين
                  </label>
                  <div
                    className="dropdown_label_gender_profile_page arabic"
                    onClick={handleToggleDropdownemployees}
                  >
                    {selectedChooseemployees}
                    <span
                      className={`arrow_filter_gender_chooise_arabic ${
                        isOpenemployees ? "up" : "down"
                      }`}
                    >
                      ⮛
                    </span>
                  </div>
                  {isOpenemployees && (
                    <ul className="dropdown_menu_gender_profile_page">
                      {DropMenuemployees.submenu &&
                        DropMenuemployees.submenu.map((e, key) => (
                          <li
                            className="dropdown_menu_header_chart_li arabic"
                            key={key}
                            onClick={() => handleSelectemployees(e.Key)}
                          >
                            {e.Value}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="send_request_user_page_btn_send">
            <button
              onClick={handleSubmit}
              className="btn_send_request_user_page_btn_send"
            >
              <span>تاكيد</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="send_request_user_page">
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="firstName"
              >
                First Name
              </label>
              <input
                className="input_send_request_user_page_row_one_item"
                type="text"
                name="firstName"
                // placeholder="John"
                value={formValues.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="lastName"
              >
                Last Name
              </label>
              <input
                className="input_send_request_user_page_row_one_item"
                type="text"
                name="lastName"
                // placeholder="Smith"
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="MobileNumber"
              >
                Mobile Number
              </label>
              <input
                className="input_send_request_user_page_row_one_item"
                type="text"
                name="MobileNumber"
                // placeholder="+"
                value={formValues.MobileNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="PhoneNumber"
              >
                Phone Number
              </label>
              <input
                className="input_send_request_user_page_row_one_item"
                type="text"
                name="PhoneNumber"
                // placeholder="+964 "
                value={formValues.PhoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_item">
              <div
                className="send_request_user_page_row_one_item_menu_gender"
                ref={InterstedRef}
              >
                <label className="label_send_request_user_page_row_one_item">
                  I am ...
                </label>
                <div
                  className="dropdown_label_gender_profile_page"
                  onClick={handleToggleDropdownIntersted}
                >
                  {selectedChooseIntersted}
                  <span
                    className={`arrow_filter_gender_chooise ${
                      isOpenIntersted ? "up" : "down"
                    }`}
                  >
                    ⮛
                  </span>
                </div>
                {isOpenIntersted && (
                  <ul className="dropdown_menu_gender_profile_page">
                    {DropMenuGender.submenu &&
                      DropMenuGender.submenu.map((e, key) => (
                        <li
                          className="dropdown_menu_header_chart_li"
                          key={key}
                          onClick={() => handleSelectIntersted(e.Key)}
                        >
                          {e.Value}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="send_request_user_page_row_one_item">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="jobTitle"
              >
                job title
              </label>
              <input
                className="input_send_request_user_page_row_one_item"
                type="text"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="send_request_user_page_row_one">
            <div className="send_request_user_page_row_one_textarea">
              <label
                className="label_send_request_user_page_row_one_item"
                htmlFor="message"
              >
                Do you have a specific message?
              </label>
              <textarea
                className="input_send_request_user_page_row_one_item_textarea"
                name="message"
                // placeholder="الرجاء إدخال سيرتك الذاتية..."
                value={formValues.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="send_request_user_page_row_one_hor">
              <div className="send_request_user_page_row_one_item_dubble margin">
                <label
                  className="label_send_request_user_page_row_one_item"
                  htmlFor="company_name"
                >
                  company name
                </label>
                <input
                  id="company_name"
                  className="input_send_request_user_page_row_one_item"
                  type="text"
                  name="company_name"
                  // placeholder="مثال: +1 800 000000"
                  value={formValues.company_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="send_request_user_page_row_one_item_dubble">
                <div
                  className="send_request_user_page_row_one_item_menu_gender"
                  ref={employeesRef}
                >
                  <label className="label_send_request_user_page_row_one_item">
                    number of employees
                  </label>
                  <div
                    className="dropdown_label_gender_profile_page"
                    onClick={handleToggleDropdownemployees}
                  >
                    {selectedChooseemployees}
                    <span
                      className={`arrow_filter_gender_chooise ${
                        isOpenemployees ? "up" : "down"
                      }`}
                    >
                      ⮛
                    </span>
                  </div>
                  {isOpenemployees && (
                    <ul className="dropdown_menu_gender_profile_page">
                      {DropMenuemployees.submenu &&
                        DropMenuemployees.submenu.map((e, key) => (
                          <li
                            className="dropdown_menu_header_chart_li"
                            key={key}
                            onClick={() => handleSelectemployees(e.Key)}
                          >
                            {e.Value}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="send_request_user_page_btn_send">
            <button
              onClick={handleSubmit}
              className="btn_send_request_user_page_btn_send"
            >
              <span>SUBMIT</span>
            </button>
          </div>
        </div>
      )}
      <div className="logout_icon_steps_filed">
        <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
      </div>
    </div>
  );
}

export default PersonalInformation;
