import React, { useState, useContext } from "react";
import logo from "../../images/new_logo_menu.png";
import x_icon from "../../images/icon_menu_x_35.png";
import f_icon from "../../images/icon_menu_facebook_35.png";
import in_icon from "../../images/icon_menu_insta_35.png";
import logo_facebook from "../../images/icon_menu_facebook_35_blue.png";
import "../../css/Facebook/Dashboard.css";
import "../../css/Public/Base.css";
import { ThemeContext, LanguageContext } from "../../App";
import Twitter_icon from "../../images/icon_menu_x_35_black.png";
import { Link, useNavigate } from "react-router-dom";
import Instagram_logo from "../../images/icon_menu_insta_35_color.png";
import AnalysisSearchFB from "../AnalysisPage/AnalysisSearchFB";
import AnalysisSearchTW from "../AnalysisPage/AnalysisSearchTW";
import AnalysisSearchIN from "../AnalysisPage/AnalysisSearchIN";
import logout_icon from "../../images/logout_menu.png";
import show_site_menu from "../../images/base_menu.png";
import admin_menu_icon from "../../images/admin_menu_icon.png";
import senior_menu_icon from "../../images/tools_menu_analysis_senior_35.png";
import { logout } from "../../backend/Login_backend";

function BaseNew({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  isSeniorAnalyzer,
}) {
  const [activeMenuItem, setActiveMenuItem] = useState("fb");

  const handleMenuClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const renderComponent = () => {
    switch (activeMenuItem) {
      case "fb":
        return (
          <AnalysisSearchFB
            isSuperUser={isSuperUser}
            isSeniorAnalyzer={isSeniorAnalyzer}
          />
        );
      case "tw":
        return (
          <AnalysisSearchTW
            isSuperUser={isSuperUser}
            isSeniorAnalyzer={isSeniorAnalyzer}
          />
        );
      case "in":
        return (
          <AnalysisSearchIN
            isSuperUser={isSuperUser}
            isSeniorAnalyzer={isSeniorAnalyzer}
          />
        );

      default:
        return null;
    }
  };

  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language, toggleLanguage } = useContext(LanguageContext);

  const handleLanguageToggle = () => {
    toggleLanguage();
  };

  // logout

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    navigate("/");
  };

  return (
    <div className={`left_menu_${language === "en" ? "left" : "right"}`}>
      <div
        className={`left_menu_dashboard ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <img className="logo_dashboard" src={logo} alt="logo" />
        <div id="menu_submenu">
          <div className="contact_menu">
            <img
              src={activeMenuItem === "fb" ? logo_facebook : f_icon}
              alt=""
              onClick={() => handleMenuClick("fb")}
              className={activeMenuItem === "fb" ? "active" : ""}
            />
          </div>
          <div className="contact_menu">
            <img
              src={activeMenuItem === "tw" ? Twitter_icon : x_icon}
              alt=""
              onClick={() => handleMenuClick("tw")}
              className={activeMenuItem === "tw" ? "active" : ""}
            />
          </div>
          <div className="contact_menu">
            <img
              src={activeMenuItem === "in" ? Instagram_logo : in_icon}
              alt=""
              onClick={() => handleMenuClick("in")}
              className={activeMenuItem === "in" ? "active" : ""}
            />
          </div>

          {isSeniorAnalyzer || isSuperUser ? (
            <section
              className={`sidebar_${language === "en" ? "left" : "right"}`}
            >
              <div id="active_bg">
                <img src={senior_menu_icon} alt="" />
                <div
                  className={`dropdown_${language === "en" ? "left" : "right"}`}
                >
                  <Link to="/seniorpage">
                    <span>
                      {language === "en"
                        ? "Project Settings"
                        : "إعدادات المشروع"}
                    </span>
                  </Link>
                  <Link to="/seniorconfirm">
                    <span>
                      {" "}
                      {language === "en" ? "Post Approval" : "تأكيد المنشورات"}
                    </span>
                  </Link>
                </div>
              </div>
            </section>
          ) : null}

          {isSuperUser ? (
            <div className="contact_menu">
              <Link to="/home">
                <img src={show_site_menu} alt="" />
              </Link>
            </div>
          ) : null}

          

          <div className="contact_menu">
            <img src={logout_icon} alt="" onClick={handleLogout} />
          </div>
        </div>

        <div className="btn_container_toggle_language_base">
          <label className="switch btn-color-mode-switch-base">
            <input
              value="1"
              id="color_mode"
              name="color_mode"
              type="checkbox"
              onClick={handleLanguageToggle}
            />
            <label
              className="btn-color-mode-switch-inner-base"
              data-off="EN"
              data-on="AR"
              htmlFor="color_mode"
            ></label>
          </label>
        </div>
      </div>
      {renderComponent()}
    </div>
  );
}

export default BaseNew;
