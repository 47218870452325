import React, { useEffect } from "react";
import * as echarts from "echarts";

const ApproachSearchChartPieTW = ({ twitterPosts, onActionClick }) => {
  useEffect(() => {
    const chart = echarts.init(document.getElementById("chart_Approach_tw"));

    const data = [
      { value: twitterPosts.action_chart.happy, name: "إيجابي" },
      { value: twitterPosts.action_chart.indifferent, name: "محايد" },
      { value: twitterPosts.action_chart.sad, name: "سلبي" },
    ];

    const options = {
      tooltip: {
        trigger: "item",
        formatter: "{b} : ({d}%)",
      },
      legend: {
        orient: "horizontal",
        bottom: 10,
        left: 30,
        textStyle: {
          fontFamily: "Segoe UI",
          fontSize: 15,
        },
        itemGap: 20,
        itemWidth: 40,
        itemHeight: 25,
        data: ["إيجابي", "محايد", "سلبي"],
      },
      series: [
        {
          name: "الانطباع",
          type: "pie",
          radius: "70%",
          left: 70,
          bottom: 30,
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          // roseType: "radius",
          label: {
            color: "#123597",
            fontFamily: "Segoe UI",
            fontSize: "18",
            padding: 10,
            formatter: "{b} : %{d}",
          },
          emphasis: {
            label: {
              show: true,
              fontWeight: "bold",
              fontFamily: "Segoe UI",
              color: function (params) {
                var colorListlabel = [];
                return colorListlabel[params.dataIndex];
              },
            },
          },
          labelLine: {
            lineStyle: {
              color: "#32CCBC",
            },
            smooth: 0.3,
            length: 20,
            length2: 10,
          },
          itemStyle: {
            color: function (params) {
              var colorList = [ "#4caf50","#a7bcb9", "#E80505"];

              return colorList[params.dataIndex];
            },
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: function (idx) {
            return Math.random() * 200;
          },
          data: data,
        },
      ],
    };

    chart.setOption(options);

    chart.on("click", function (params) {
      const clickedSectionName = params.name;
      let englishText = "";
      switch (clickedSectionName) {
        case "إيجابي":
          englishText = "Happy";
          break;
        case "محايد":
          englishText = "Indifferent";
          break;
        case "سلبي":
          englishText = "Sad";
          break;
        default:
          englishText = "";
      }
      onActionClick(englishText);
    });

    return () => {
      chart.dispose();
    };
  }, [twitterPosts, onActionClick]);

  return (
    <div id="chart_Approach_tw" style={{ width: "55%", height: "400px" }}></div>
  );
};

export default ApproachSearchChartPieTW;
