import "../../css/Facebook/FaceBook.css";
import Excel from "../../images/excel.png";
import {
  DropMenu,
  DropMenuAr,
  ChooseRanking,
  ChooseRankingAr,
  ChooseNumber,
  ChooseNumberAr,
} from "../../components/Menu/MenuSearch";
import multiple_red from "../../images/multiple_red.png";
import Star from "../../images/Star.png";
import arrow_down from "../../images/arrow_down.png";
import Like from "../../images/like.png";
import Comment from "../../images/comment.png";
import filter_icon from "../../images/Filter4.png";
import filter_green from "../../images/filter_green.png";
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getMyShow } from "../../backend/Dashboard_backend";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getPostsByWord } from "../../backend/Junior_Post_Search_backend";
import { setPostAction } from "../../backend/Post_Action_backend";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import {
  base_url,
  junior_post_search_url,
  facebook_profile_url,
} from "../../backend/config_backend";
import axios from "axios";
import { getToken } from "../../backend/Login_backend";
import { ScaleLoader, BeatLoader } from "react-spinners";
import { useContext } from "react";
import { ThemeContext, LanguageContext } from "../../App";
import ExcelJS from "exceljs";
import Star_gold from "../../images/star_gold.png";
import Excel_green from "../../images/excel_green.png";
import broom_icon from "../../images/broom_icon.png";
import avatar_user from "../../images/avatar_user.png";
import checkbox_green from "../../images/checkbox_green.png";
import checkbox_gray from "../../images/checkbox_gray.png";
import DateTime from "react-datetime";
import Swal from "sweetalert2";
import Sad from "../../images/minus_gray.png";
import Sad_yellow from "../../images/minus_red.png";
import Happy from "../../images/plus_gray.png";
import Happy_yellow from "../../images/plus_green.png";
import indifferent from "../../images/zero_gray_light.png";
import indifferent_yellow from "../../images/zero_gray.png";
import tick_gray from "../../images/tick_gray_25.png";
import tick_green from "../../images/tick_green_25.png";
import sticker_square from "../../images/sticker_square.png";
import sticker_square_gray from "../../images/sticker_square_gray.png";
import broom_new from "../../images/broom_new.png";
import AnalysisMenuProjectSubject from "../../components/Menu/AnalysisMenuProjectSubject";
import AnalysisMenuMainTopic from "../../components/Menu/AnalysisMenuMainTopic";
import AnalysisMenuSubtopic from "../../components/Menu/AnalysisMenuSubtopic";
import AnalysisMenuEmotion from "../../components/Menu/AnalysisMenuEmotion";
import AnalysisMenuAxis from "../../components/Menu/AnalysisMenuAxis";
import AnalysisMenuAccent from "../../components/Menu/AnalysisMenuAccent";
import { getEmotionLists } from "../../backend/Admin/EmotionManager/EmotionManager";
import { getAxisLists } from "../../backend/Admin/AxisManager/AxisManager";
import { getAccentList } from "../../backend/Admin/AccentManager/AccentManager";
import {
  addSubTopic,
  addTopic,
  deleteSubTopic,
  deleteTopic,
} from "../../backend/Admin/TopicManager/TopicManager";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AnalysisSearchFB({ isSuperUser, isSeniorAnalyzer }) {
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getMonthAgoDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth()).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startingDate, setStartingDate] = useState(getMonthAgoDate());
  const [endingDate, setEndingDate] = useState(getTodayDate());
  const [searchWord, setsearchWord] = useState("");
  const [searchHashtag, setsearchHashtag] = useState("");
  const [subject_projects, setSubject_projects] = useState([]);
  const [emotionsData, setEmotionsData] = useState([]);
  const [axisData, setAxisData] = useState([]);
  const [accentData, setAccentData] = useState([]);

  const [fetchedSubjects, setFetchedSubjects] = useState([]);
  const [fetchedApi, setfetchedApi] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [apiData, setapiData] = useState(undefined);
  const [postByWordsData, setpostByWordsData] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");

  const [selectedSubProject, setSelectedSubProject] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedSubSubProject, setSelectedSubSubProject] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedEmotion, setSelectedEmotion] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedAxis, setSelectedAxis] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedAccent, setSelectedAccent] = useState("");

  const [needLogin, setneedLogin] = useState(false);

  const [loadingState, setLoadingState] = useState(true);

  const [loadMoreLoadingState, setLoadMoreLoadingState] = useState(false);

  const { language } = useContext(LanguageContext);

  const [loadedPostsNumber, setLoadedPostsNumber] = useState(100);

  const handleLoadedPostsNumberChange = (e) => {
    setLoadedPostsNumber(e.target.value);
  };

  const formatEmojiToNumber = (emoji) => {
    let dict = {
      happy: 1,
      sad: 2,
      indifferent: 3,
    };

    return dict[emoji];
  };

  const formatNumberToEmoji = (number) => {
    let dict = ["happy", "sad", "indifferent"];

    if (number > 0) return dict[number - 1];
    else return undefined;
  };

  const formatStickerToNumber = (sticker) => {
    let dict = {
      tick: 1,
      cross: 2,
    };

    return dict[sticker];
  };

  const formatNumberToSticker = (number) => {
    let dict = {
      1: "tick",
      2: "cross",
    };

    return dict[number];
  };

  const updatePostAction = async (
    postid,
    sticker,
    index,
    comment,
    selectedProject
  ) => {
    let action_state = selectedEmojis[postid];
    action_state = formatEmojiToNumber(action_state);
    let agreement_state = formatStickerToNumber(sticker);
    console.log(
      "🚀 ~ file: AnalysisSearchFB.js:142 ~ updatePostAction ~ postByWordsData:",
      postByWordsData.posts,
      index
    );
    let main_subject = postByWordsData.posts[index].main_subject;
    let sub_subject = postByWordsData.posts[index].sub_subject;
    let emotion = postByWordsData.posts[index].emotion;
    let axis = postByWordsData.posts[index].axis;
    let accent = postByWordsData.posts[index].accent;
    console.log("Hell is Here", selectedStuff);
    if (typeof main_subject === "undefined") {
      main_subject = selectedStuff[postid].main_subject
        ? selectedStuff[postid].main_subject
        : "default";
    }
    if (typeof sub_subject === "undefined") {
      sub_subject = selectedStuff[postid].sub_subject
        ? selectedStuff[postid].sub_subject
        : "default";
    }
    if (typeof emotion === "undefined") {
      emotion = selectedStuff[postid].emotion
        ? selectedStuff[postid].emotion
        : "default";
    }
    if (typeof axis === "undefined") {
      axis = selectedStuff[postid].axis
        ? selectedStuff[postid].axis
        : "default";
    }
    if (typeof accent === "undefined") {
      accent = selectedStuff[postid].accent
        ? selectedStuff[postid].accent
        : "default";
    }
    let dbName = selectedProject;
    console.log(agreement_state, sticker);
    comment = comment ? comment : "";
    var postsByWord = await setPostAction(
      postid,
      action_state,
      agreement_state,
      comment,
      main_subject,
      sub_subject,
      emotion,
      axis,
      accent,
      dbName
    );
    //TODO post sub and subsub and emotions
    if (postsByWord.hasError) {
      if (postsByWord.isLoggedIn === false) {
        setneedLogin(true);
      } else if (postsByWord.status === 500) {
        if (language === "en") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      } else {
        if (language === "en") {
          toast.error("Connection Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
    } else {
    }
  };

  const handleStartMyShow = async () => {
    var result = await getMyShow();

    if (result.hasError) {
      if (result.isLoggedIn === false) {
        setneedLogin(true);
      } else if (result.status === 500) {
        toast.error("Server Error");
      } else {
        toast.error("Connection Error");
      }
    } else {
      // var data = result.data.data;
      setSubject_projects(result.data.data.subject_projects);

      // setapiData(data);
    }
  };

  useEffect(() => {
    if (!fetchedApi) return;

    setSelectedSubProject("");
    setSelectedSubSubProject("");
    setSelectedEmotion("");
    setSelectedAxis("");
    setSelectedAccent("");

    // setLoadingState(true)
    handleSearchProject();
    if (subject_projects.length > 0 && selectedProject !== "") {
      console.log(subject_projects, selectedProject);
      setFetchedSubjects(
        subject_projects.find((x) => x.id === selectedProject).subjects
      );
    }

    // setLoadingState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, fetchedApi]);

  const handleFetchActions = (posts) => {
    var emojies = {};
    var stickersTick = {};
    var stickersSelected = [];

    if (posts)
      posts.forEach((element) => {
        if (element.action.id) {
          let action = element.action;
          let emoji = formatNumberToEmoji(action.action_state);
          let sticker = formatNumberToSticker(action.agreement_state);
          emojies[element.id] = emoji;
          if (sticker) {
            stickersTick[element.id] = true;
            stickersSelected.push(element.id);
          }
        }
      });
    setSelectedEmojis(emojies);
    setIsTickSelected(stickersTick);
    setSelectedTickPosts(stickersSelected);
    // console.log(emojies , stickersTick , stickersSelected)
  };

  useEffect(() => {
    const handleStartAxis = async () => {
      var result = await getAxisLists();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          toast.error("Server Error");
        } else {
          toast.error("Connection Error");
        }
      } else {
        var data = result.data.data;
        setAxisData(data);

        // setapiData(data);
      }
    };

    const handleStartEmotion = async () => {
      var result = await getEmotionLists();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        setEmotionsData(data);

        // setapiData(data);
      }
    };

    const handleStartAccent = async () => {
      var result = await getAccentList();

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        setAccentData(data);

        // setapiData(data);
      }
    };

    const fetchData = async () => {
      if (fetchedApi) {
        return;
      }
      console.log("fetchData handleStartMyShow");
      try {
        // await handleStart();
        await handleStartMyShow();
        await handleStartEmotion();
        await handleStartAxis();
        await handleStartAccent();
        setfetchedApi(true);
      } catch (error) {}
    };

    // Run fetchData initially
    try {
      // handleStartMyShow();
      fetchData();
      // handleFirstPostFetch();
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }

    // // Schedule fetchData to run every 1 minute
    // const interval = setInterval(fetchData, 1 * 10 * 1000);

    // // Cleanup function to clear the interval when the component unmounts or the dependency changes
    // return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedApi]);

  // useEffect(()=>{
  //   handleFirstPostFetch()
  //   console.log("🚀 ~ file: AnalysisSearchFB.js:246 ~ AnalysisSearchFB ~ subject_projects:", subject_projects)
  // },[selectedProject])

  const handleSearchProject = async () => {
    console.log("going to handleSearchProject");
    try {
      setLoadingState(true);

      var start = "1900-06-01";
      var end = endingDate.length > 0 ? endingDate : "2040-06-01";
      var word = searchWord.length > 0 ? searchWord : "";
      var hashtag = searchHashtag.length > 0 ? searchHashtag : "";
      var page_size = loadedPostsNumber;
      let projectName = "default";
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      } else if (subject_projects.length > 0) {
        setSelectedProject(subject_projects[0].id);
        setSelectedProjectName(subject_projects[0].name);
        projectName = subject_projects[0].name;
      }
      var orderBy =
        selectedRankingParameter.length > 0
          ? selectedRankingParameter
          : "delta_time";

      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + junior_post_search_url;
      await axios
        .get(url, {
          params: {
            word,
            startDate: start,
            endDate: end,
            hashtag,
            orderBy,
            page_size,
            projectName,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
          console.log("🚀 ~ file: AnalysisSearchFB.js:303 ~ data:", data);
          var stuf = {};

          // eslint-disable-next-line array-callback-return
          data.data.posts.map((post) => {
            stuf = { ...stuf, [post.id]: post.action };
          });
          setSelectedStuff(stuf);
          setpostByWordsData(data.data);
          handleFetchActions(data.data.posts);
        })
        .catch(function (error) {
          console.log(error);
          var response = error.response;
          errorText = response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          toast.error("Server Error");
        } else {
          toast.error("Connection Error");
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        // setpostByWordsData(data);
        setLoadingState(false);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleSearchClicked = async () => {
    try {
      setLoadingState(true);

      var start = startingDate.length > 0 ? startingDate : "2000-06-01";
      var end = endingDate.length > 0 ? endingDate : "2040-06-01";
      var word = searchWord.length > 0 ? searchWord : "";
      var hashtag = searchHashtag.length > 0 ? searchHashtag : "";
      var page_size = loadedPostsNumber ? loadedPostsNumber : 100;
      let projectName = "default";
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      }
      var orderBy =
        selectedRankingParameter.length > 0
          ? selectedRankingParameter
          : "delta_time";

      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + junior_post_search_url;
      await axios
        .get(url, {
          params: {
            word,
            startDate: start,
            endDate: end,
            hashtag,
            orderBy,
            page_size,
            projectName,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
        })
        .catch(function (error) {
          console.log(error);
          var response = error.response;
          errorText = response.request.response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "ar") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "ar") {
            toast.error("حدث خطأ ما");
          } else {
            toast.error("Something Went Wrong");
          }
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        console.log(
          "🚀 ~ file: AnalysisSearchFB.js:336 ~ handleSearchClicked ~ postsByWord.data.data:",
          postsByWord.data.data
        );
        setpostByWordsData(data);
        handleFetchActions(data.posts);

        setLoadingState(false);
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  const handleLoadMoreClicked = async (e) => {
    if (loadMoreLoadingState === true || loadMoreLoadingState === "") {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (!bottom) {
      return;
    }
    if (postByWordsData.next_link === null) {
      return;
    }
    // console.log("Loading...");
    setLoadMoreLoadingState(true);
    try {
      if (postByWordsData.next_link.startsWith("http://")) {
        postByWordsData.next_link = postByWordsData.next_link.replace("http://", "https://");
      }      
      var postsByWord = await getPostsByWord(postByWordsData.next_link);
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "ar") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        var data = postsByWord.data.data;
        data.posts = [...postByWordsData.posts, ...data.posts];
        var stuf = {};
        // eslint-disable-next-line array-callback-return
        data.posts.map((post) => {
          stuf = { ...stuf, [post.id]: post.action };
        });
        setSelectedStuff(stuf);
        setpostByWordsData(data);
        handleFetchActions(data.posts);
        setLoadMoreLoadingState(false);
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  const handleSearchWordChanged = (event) => {
    let word = event.target.value;
    //console.log("Word : " , word)
    word = word.replaceAll(" ", "-");
    //console.log("Replaced Word : " , word)
    setsearchWord(word);
  };

  const handleSearchHashtagChanged = (event) => {
    let word = event.target.value;
    //console.log("Word : " , word)
    word = word.replaceAll(" ", "-");
    //console.log("Replaced Word : " , word)
    setsearchHashtag(word);
  };

  const [departureDate, setDepartureDate] = useState(getTodayDate());

  const handleDepartureDateChanged = (event) => {
    setDepartureDate(event.target.value);
  };

  const handleEndDateChanged = (event) => {
    setEndingDate(event.target.value);
  };

  // const [selectedDate, setSelectedDate] = useState(getTodayDate());

  const handleStartDateChanged = (event) => {
    // setSelectedDate(event.target.value);
    setStartingDate(event.target.value);
    setDepartureDate(event.target.value);
  };

  //open onclick menu filter Choose Topic
  const [isOpenTopic, setIsOpenTopic] = useState(false);

  const handleToggleDropdownTopic = () => {
    setIsOpenTopic(!isOpenTopic);
  };

  // dropdown filter Choose Topic
  const [selectedChooseTopic, setSelectedChooseTopic] = useState(
    DropMenu.submenu[0]?.Value || ""
  );

  const handleSelectTopic = (value) => {
    const selectedChooseTopic = DropMenu.submenu.find(
      (option) => option.Key === value
    );
    if (selectedChooseTopic) {
      setSelectedChooseTopic(selectedChooseTopic.Value);
      setIsOpenTopic(false);
    } else {
      setSelectedChooseTopic("");
    }
  };

  // dropdown filter Choose Topic Arabic

  const [isOpenTopicAr, setIsOpenTopicAr] = useState(false);

  const handleToggleDropdownTopicAr = () => {
    setIsOpenTopicAr(!isOpenTopicAr);
  };

  const [selectedChooseTopicAr, setSelectedChooseTopicAr] = useState(
    DropMenuAr.submenu[0]?.Value || ""
  );

  const handleSelectTopicAr = (value) => {
    const selectedChooseTopicAr = DropMenuAr.submenu.find(
      (option) => option.Key === value
    );
    if (selectedChooseTopicAr) {
      setSelectedChooseTopicAr(selectedChooseTopicAr.Value);
      setIsOpenTopicAr(false);
    } else {
      setSelectedChooseTopicAr("");
    }
  };

  //open onclick menu filter Choose Ranking
  const [isOpenRanking, setIsOpenRanking] = useState(false);

  const handleToggleDropdownRanking = () => {
    setIsOpenRanking(!isOpenRanking);
  };

  // dropdown filter Choose Ranking
  const [selectedRanking, setSelectedRanking] = useState(
    ChooseRanking.submenu[0]?.Value || ""
  );

  const [selectedRankingParameter, setSelectedRankingParameter] = useState(
    ChooseRanking.submenu[0]?.parameter || "delta_time"
  );

  const handleSelectRaking = (value) => {
    const selectedRanking = ChooseRanking.submenu.find(
      (option) => option.Key === value
    );
    if (selectedRanking) {
      setSelectedRanking(selectedRanking.Value);
      setSelectedRankingParameter(selectedRanking.parameter);
      setIsOpenRanking(false);
    } else {
      setSelectedRanking("");
    }
  };

  // //open onclick menu filter Choose Ranking Arabic

  const [isOpenRankingAr, setIsOpenRankingAr] = useState(false);

  const handleToggleDropdownRankingAr = () => {
    setIsOpenRankingAr(!isOpenRankingAr);
  };

  // dropdown filter Choose Ranking Arabic
  const [selectedRankingAr, setSelectedRankingAr] = useState(
    ChooseRankingAr.submenu[0]?.Value || ""
  );

  const handleSelectRakingAr = (value) => {
    const selectedRankingAr = ChooseRankingAr.submenu.find(
      (option) => option.Key === value
    );
    if (selectedRankingAr) {
      setSelectedRankingAr(selectedRankingAr.Value);
      setSelectedRankingParameter(selectedRankingAr.parameter);
      setIsOpenRankingAr(false);
    } else {
      setSelectedRankingAr("");
    }
  };

  //open onclick menu filter Choose Number

  const [isOpenNumber, setIsOpenNumber] = useState(false);

  const handleToggleDropdownNumber = () => {
    setIsOpenNumber(!isOpenNumber);
  };

  // dropdown filter Choose Number
  const [selectedNumber, setSelectedNumber] = useState(
    ChooseNumber.submenu[0]?.Value || ""
  );

  const handleSelectNumber = (value) => {
    const selectedNumber = ChooseNumber.submenu.find(
      (option) => option.Key === value
    );
    if (selectedNumber) {
      setSelectedNumber(selectedNumber.Value);
      setIsOpenNumber(false);
    } else {
      setSelectedNumber("");
    }
  };

  //open onclick menu filter Choose Number Arabic
  const [isOpenNumberAr, setIsOpenNumberAr] = useState(false);

  const handleToggleDropdownNumberAr = () => {
    setIsOpenNumberAr(!isOpenNumberAr);
  };

  // dropdown filter Choose Ranking
  const [selectedNumberAr, setSelectedNumberAr] = useState(
    ChooseNumberAr.submenu[0]?.Value || ""
  );

  const handleSelectNumberAr = (value) => {
    const selectedNumberAr = ChooseNumberAr.submenu.find(
      (option) => option.Key === value
    );
    if (selectedNumberAr) {
      setSelectedNumberAr(selectedNumberAr.Value);
      setIsOpenNumberAr(false);
    } else {
      setSelectedNumberAr("");
    }
  };

  // show more text post

  const [expandedStates, setExpandedStates] = useState([]);

  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  // const formatFileName = (name) => {
  //   return name
  //     .replaceAll(".", "")
  //     .replaceAll("%", "")
  //     .replaceAll("?", "")
  //     .replaceAll("=", "")
  //     .replaceAll("/", "")
  //     .substring(0, 30)
  //     .toLowerCase();
  // };

  // Excel btn All Record Backend
  async function handleAllRecordsExport(recordsNumber) {
    try {
      console.log("handleAllRecordsExport recordsNumber", recordsNumber);
      toast.success("Please wait. It may take a moment", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        style: {
          fontSize: "16px",
          width: "350px",
          right: "25px",
        },
      });
      var start = "1900-06-01";
      var end = "2040-06-01";
      var word = "";
      var hashtag = "";
      let projectName = "default";
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      } else if (subject_projects.length > 0) {
        setSelectedProject(subject_projects[0].id);
        setSelectedProjectName(subject_projects[0].name);
        projectName = subject_projects[0].name;
      }

      var orderBy =
        selectedRankingParameter.length > 0
          ? selectedRankingParameter
          : "delta_time";
      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + junior_post_search_url;
      await axios
        .get(url, {
          params: {
            word,
            startDate: start,
            endDate: end,
            hashtag,
            orderBy,
            projectName,
            page_size: 1000 * 1000 * 1000,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
        })
        .catch(function (error) {
          // console.log(error);
          var response = error.response;
          errorText = response.request.response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        const checkedPosts = [];
        data.posts.forEach((element) => {
          const post = {
            username: element.username,
            name: element.name,
            text: element.texts,
            date: element.creates,
            likes: element.likes,
            comments: element.comments,
          };
          checkedPosts.push(post);
        });
        if (checkedPosts.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Posts");

          worksheet.columns = [
            { header: "Username", key: "username", width: 20 },
            { header: "Name", key: "name", width: 20 },
            { header: "Text", key: "text", width: 60 },
            { header: "Date", key: "date", width: 20 },
            { header: "Likes", key: "likes", width: 10 },
            { header: "Comments", key: "comments", width: 10 },
          ];

          checkedPosts.forEach((post) => {
            worksheet.addRow(post);
          });

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "posts.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  // Excel btn Record Backend

  async function handleRecordExportToExcel(recordsNumber) {
    try {
      // console.log("recordsNumber", recordsNumber);

      // var start = startingDate.length > 0 ? startingDate : "1900-06-01";
      // var end = endingDate.length > 0 ? endingDate : "2040-06-01";
      var word = searchWord.length > 0 ? searchWord : "";
      var hashtag = searchHashtag.length > 0 ? searchHashtag : "";
      let projectName = "default";
      if (selectedProject !== "") {
        projectName = subject_projects.find(
          (x) => x.id === selectedProject
        ).name;
      } else if (subject_projects.length > 0) {
        setSelectedProject(subject_projects[0].id);
        setSelectedProjectName(subject_projects[0].name);
        projectName = subject_projects[0].name;
      }

      var orderBy =
        selectedRankingParameter.length > 0
          ? selectedRankingParameter
          : "delta_time";
      var hasError = false;
      var status = 200;

      var errorText = "";
      var data = undefined;
      var url = base_url + junior_post_search_url;
      await axios
        .get(url, {
          params: {
            word,
            //startDate: start,
            //endDate: end,
            hashtag,
            orderBy,
            projectName,
            page_size: recordsNumber,
          },
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: "Bearer " + String(getToken().access),
          },
        })

        .then(function (response) {
          if (response) data = response;
        })
        .catch(function (error) {
          // console.log(error);
          var response = error.response;
          errorText = response.request.response;

          hasError = true;
          status = response.status;
          if (response.status === 500) {
            errorText = "Server Error ... ";
          } else if (
            errorText.includes("Authentication") ||
            errorText.includes("credentials")
          ) {
          }
        });
      var postsByWord = { hasError, errorText, data, status };
      if (postsByWord.hasError) {
        if (postsByWord.isLoggedIn === false) {
          setneedLogin(true);
        } else if (postsByWord.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        // console.log(postsByWord);
        data = postsByWord.data.data;
        const checkedPosts = [];
        data.posts.forEach((element) => {
          const post = {
            username: element.username,
            name: element.name,
            text: element.texts,
            date: element.creates,
            likes: element.likes,
            comments: element.comments,
          };
          checkedPosts.push(post);
        });
        if (checkedPosts.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Posts");

          worksheet.columns = [
            { header: "Username", key: "username", width: 20 },
            { header: "Name", key: "name", width: 20 },
            { header: "Text", key: "text", width: 60 },
            { header: "Date", key: "date", width: 20 },
            { header: "Likes", key: "likes", width: 10 },
            { header: "Comments", key: "comments", width: 10 },
          ];

          checkedPosts.forEach((post) => {
            worksheet.addRow(post);
          });

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "posts.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  // Excel btn Select front

  function handleExportToExcel() {
    const checkedCheckboxes = document.querySelectorAll(
      "#checkboc_icon:checked"
    );

    const checkedPosts = [];

    checkedCheckboxes.forEach((checkbox) => {
      const postElement = checkbox.closest(".items_posts_fb");
      const name = postElement.querySelector(
        ".tooltip_text_posts_box_top"
      ).textContent;
      const username = postElement
        .querySelector(".tooltip_text_posts_box_bottom")
        .textContent.substring(1);

      const postText = postElement.querySelector(
        ".text_post_fb_analysis_confirm span"
      ).textContent;
      const postDate = postElement.querySelector(
        ".items_left_posts_fb_analysis_one_confirm"
      ).textContent;
      const postLikes = postElement.querySelector(
        ".items_left_posts_fb_analysis_two span"
      ).textContent;
      const postComments = postElement.querySelector(
        ".items_left_posts_fb_analysis_three span"
      ).textContent;

      const post = {
        username: username,
        name: name,
        text: postText,
        date: postDate,
        likes: postLikes,
        comments: postComments,
      };

      checkedPosts.push(post);
    });

    // Export to Excel

    if (checkedPosts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Posts");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 60 },
        { header: "Date", key: "date", width: 20 },
        { header: "Likes", key: "likes", width: 10 },
        { header: "Comments", key: "comments", width: 10 },
      ];

      checkedPosts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "posts.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const exelIcon = document.querySelector("#exel-icon");
        if (checkedPosts.length > 0) {
          exelIcon.src = Excel_green;
        } else {
          exelIcon.src = Excel;
        }
      });
    } else {
      if (language === "en") {
        Swal.fire({
          icon: "warning",
          title: "Oops...!",
          text: "You have not selected a post yet",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "!...أوه",
          text: "لم تقم بعد باختيار أي منشور",
        });
      }
    }
  }

  // Excel btn current front

  function handleExportCurrentToExcel() {
    const currentPosts = document.querySelectorAll(".items_posts_fb");

    const posts = [];

    currentPosts.forEach((postElement) => {
      const name = postElement.querySelector(
        ".tooltip_text_posts_box_top"
      ).textContent;
      const username = postElement
        .querySelector(".tooltip_text_posts_box_bottom")
        .textContent.substring(1);

      const postText = postElement.querySelector(
        ".text_post_fb_analysis_confirm span"
      ).textContent;
      const postDate = postElement.querySelector(
        ".items_left_posts_fb_analysis_one_confirm"
      ).textContent;
      // const postLikesElement = postElement.querySelector(".items_left_posts_fb_analysis_two span");
      // const postLikes = postLikesElement ? postLikesElement.textContent : '';

      // const postCommentsElement = postElement.querySelector(".items_left_posts_fb_analysis_three span");
      // const postComments = postCommentsElement ? postCommentsElement.textContent : '';

      const post = {
        username: username,
        name: name,
        text: postText,
        date: postDate,
        // likes: postLikes,
        // comments: postComments,
      };

      posts.push(post);
    });

    // Export to Excel

    if (posts.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("CurrentPosts");

      worksheet.columns = [
        { header: "Username", key: "username", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "Text", key: "text", width: 100 },
        { header: "Date", key: "date", width: 20 },
        // { header: "Likes", key: "likes", width: 10 },
        // { header: "Comments", key: "comments", width: 10 },
      ];

      posts.forEach((post) => {
        worksheet.addRow(post);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "current_posts.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Swal.fire({
          icon: "success",
          title:
            language === "en"
              ? "Number of posts: "
              : "عدد المنشورات" + posts.length,
          showConfirmButton: false,
          timer: 3000,
        });
      });
    }
  }

  // change icon excel with checkbox

  function handleCheckboxChange(event) {
    const checkedCount = document.querySelectorAll(
      ".checkbox_input_analysis:checked"
    ).length;
    const exelIcon = document.querySelector("#exel-icon");
    const checkIcons = document.querySelectorAll("#checkboc_icon");

    if (checkedCount > 0) {
      exelIcon.src = Excel_green;
      checkIcons.forEach((checkIcon) => {
        checkIcon.src = checkbox_green;
      });
    } else {
      exelIcon.src = Excel;
      checkIcons.forEach((checkIcon) => {
        checkIcon.src = checkbox_gray;
      });
    }
  }

  // clear checkbox

  function handleClearAll() {
    const checkedCheckboxes = document.querySelectorAll(
      ".checkbox_input_analysis:checked"
    );
    checkedCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    const exelIcon = document.querySelector("#exel-icon");
    const checkIcons = document.querySelectorAll("#checkboc_icon");

    exelIcon.src = Excel;
    checkIcons.forEach((checkIcon) => {
      checkIcon.src = checkbox_gray;
    });
  }

  // star icon

  const [selectedPosts, setSelectedPosts] = useState([]);
  const [showOnlyStarredPosts, setShowOnlyStarredPosts] = useState(false);

  const handlePostStarClick = (postId) => {
    const isSelected = selectedPosts.includes(postId);
    if (isSelected) {
      setSelectedPosts(selectedPosts.filter((id) => id !== postId));
    } else {
      setSelectedPosts([...selectedPosts, postId]);
    }
  };

  // ستاره ی طلایی هدر پست ها

  const [isGold, setIsGold] = useState(false);

  const handleHeaderStarClick = () => {
    setShowOnlyStarredPosts(!showOnlyStarredPosts);
    setIsGold(!isGold);
  };

  // show k and m in like and comment

  // function formatNumber(num) {
  //   if (num < 1000) {
  //     return num.toString();
  //   } else if (num < 1000000) {
  //     return (num / 1000).toFixed(1) + "k";
  //   } else {
  //     return (num / 1000000).toFixed(1) + "m";
  //   }
  // }

  // clear filters posts

  const handleClearFiltersUser = () => {
    setUsernameFilter("");
    setNameFilter("");
    setCurrentFilterUsersImage(filter_icon);
  };

  const handleClearFiltersTextPost = () => {
    setTextFilter("");
    setExactTextFilter("");
    setNotTextFilter("");
    setNotExactTextFilter("");
    setStartParagraphFilter("");
    setEndParagraphFilter("");
    setCurrentFilterTextImage(filter_icon);
  };

  // const handleClearFiltersLikes = () => {
  //   setMinLikes("");
  //   setMaxLikes("");
  //   setCurrentFilterLikeImage(filter_icon);
  // };

  // const handleClearFiltersComments = () => {
  //   setMinComments("");
  //   setMaxComments("");
  //   setCurrentFilterCommentImage(filter_icon);
  // };

  const handleClearFiltersDates = () => {
    setFromDate("");
    setUntilDate("");
    setCurrentFilterDateImage(filter_icon);
  };

  // filter posts

  const [usernameFilter, setUsernameFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const [textFilter, setTextFilter] = useState("");
  const [exactTextFilter, setExactTextFilter] = useState("");

  const [notTextFilter, setNotTextFilter] = useState("");
  const [notExactTextFilter, setNotExactTextFilter] = useState("");

  const [startParagraphFilter, setStartParagraphFilter] = useState("");
  const [endParagraphFilter, setEndParagraphFilter] = useState("");

  // const [minLikes, setMinLikes] = useState("");
  // const [maxLikes, setMaxLikes] = useState("");

  // const [minComments, setMinComments] = useState("");
  // const [maxComments, setMaxComments] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [untilDate, setUntilDate] = useState("");

  const fromDateObject = new Date(fromDate);
  const untilDateObject = new Date(untilDate);

  // change icon filter

  // user inputs

  const [currentFilterUsersImage, setCurrentFilterUsersImage] =
    useState(filter_icon);

  const handleNameFilterChange = (e) => {
    const value = e.target.value;
    setNameFilter(value);
    if (value === "" && usernameFilter === "") {
      setCurrentFilterUsersImage(filter_icon);
    } else {
      setCurrentFilterUsersImage(filter_green);
    }
  };

  const handleUsernameFilterChange = (e) => {
    const value = e.target.value;
    setUsernameFilter(value);
    if (value === "" && nameFilter === "") {
      setCurrentFilterUsersImage(filter_icon);
    } else {
      setCurrentFilterUsersImage(filter_green);
    }
  };

  // text inputs

  const [currentFilterTextImage, setCurrentFilterTextImage] =
    useState(filter_icon);

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleExactTextFilterChange = (e) => {
    const value = e.target.value;
    setExactTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleNotTextFilterChange = (e) => {
    const value = e.target.value;
    setNotTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleNotExactTextFilterChange = (e) => {
    const value = e.target.value;
    setNotExactTextFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleStartParagraphFilterChange = (e) => {
    const value = e.target.value;
    setStartParagraphFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  const handleEndParagraphFilterChange = (e) => {
    const value = e.target.value;
    setEndParagraphFilter(value);
    if (value === "") {
      setCurrentFilterTextImage(filter_icon);
    } else {
      setCurrentFilterTextImage(filter_green);
    }
  };

  // Date inputs

  const [currentFilterDateImage, setCurrentFilterDateImage] =
    useState(filter_icon);

  const handleFromDateFilterChange = () => {
    if (fromDate !== "") {
      setCurrentFilterDateImage(filter_icon);
    } else {
      setCurrentFilterDateImage(filter_green);
    }
  };

  const handleUntilDateFilterChange = () => {
    if (untilDate !== "") {
      setCurrentFilterDateImage(filter_icon);
    } else {
      setCurrentFilterDateImage(filter_green);
    }
  };


  // menu user

  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);

  const [alertShown, setAlertShown] = useState({
    user: false,
    text: false,
    date: false,
    like: false,
    comment: false,
  });

  const closeAllMenus = () => {
    setIsOpenUser(false);
    setIsOpenText(false);
    setIsOpenDate(false);
  };

  const showInfoAlert = (key) => {
    if (!alertShown[key]) {
      const alertText =
        language === "en"
          ? "The filter is only applied to the loaded posts."
          : "تم تطبيق المرشح فقط على المشاركات المحملة.";

      Swal.fire({
        icon: "info",
        title: language === "en" ? "Attention" : "تنبيه",
        confirmButtonText: language === "en" ? "Ok" : "حسنًا",
        text: alertText,
      });
      setAlertShown({ ...alertShown, [key]: true });
    }
  };

  const toggleMenu = (isOpenState, setIsOpenState, key) => {
    if (!isOpenState) {
      closeAllMenus();
      showInfoAlert(key);
    }
    setIsOpenState(!isOpenState);
  };

  const toggleMenuUser = () => {
    toggleMenu(isOpenUser, setIsOpenUser, "user");
  };

  const toggleMenuText = () => {
    toggleMenu(isOpenText, setIsOpenText, "text");
  };

  const toggleMenuDate = () => {
    toggleMenu(isOpenDate, setIsOpenDate, "date");
  };


  // highlight users

  const highlightFilterUsers = (text) => {
    const filterKeywords = [nameFilter, usernameFilter];

    const escapeRegExp = (string) => {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    };

    let highlightedText = text;

    filterKeywords.forEach((keyword) => {
      if (keyword && keyword !== "") {
        const regex = new RegExp(escapeRegExp(keyword), "gi");
        highlightedText = highlightedText.replace(
          regex,
          (match) => `<mark>${match}</mark>`
        );
      }
    });

    return { __html: highlightedText };
  };

  // highlight text

  const highlightFilterKeywords = (text) => {
    const filterKeywords = [
      textFilter,
      exactTextFilter,
      startParagraphFilter,
      endParagraphFilter,
    ];

    const escapeRegExp = (string) => {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    };

    let highlightedText = text;

    filterKeywords.forEach((keyword) => {
      if (keyword && keyword !== "") {
        const regex = new RegExp(escapeRegExp(keyword), "gi");
        highlightedText = highlightedText.replace(
          regex,
          (match) => `<mark>${match}</mark>`
        );
      }
    });

    return { __html: highlightedText };
  };



  // select emoji posts

  const [selectedEmojis, setSelectedEmojis] = useState({});
  const [selectedStuff, setSelectedStuff] = useState({});

  const [isTickSelected, setIsTickSelected] = useState({});

  const handleEmojiClick = (postId, emojiId) => {
    // console.log("handleEmojiClick", postId, emojiId, isTickSelected[postId]);
    // if (!isTickSelected[postId]) {
    setSelectedEmojis((prevState) => ({
      ...prevState,
      [postId]: emojiId,
    }));
    // }
  };

  // selected tick posts
  const [selectedTickPosts, setSelectedTickPosts] = useState([]);
  const [selectedCrossPosts, setSelectedCrossPosts] = useState([]);
  const [showOnlyTickPosts, setShowOnlyTickPosts] = useState(false);

  const [isCrossSelected, setIsCrossSelected] = useState({});

  const handleStickerClick = (postId, emoji, needUpdate, index) => {
    if (!selectedEmojis[postId]) {
      return;
    }

    const isPostSelected = isTickSelected[postId] || isCrossSelected[postId];

    if (needUpdate)
      updatePostAction(postId, "tick", index, "-", selectedProjectName);
    toast.success("Send Request", {
      position: toast.POSITION.TOP_CENTER,
    });
    if (!isPostSelected) {
      if (emoji === "tick") {
        setIsTickSelected({ ...isTickSelected, [postId]: true });
        setIsCrossSelected({ ...isCrossSelected, [postId]: false });
        setSelectedTickPosts([...selectedTickPosts, postId]);
      } else if (emoji === "cross") {
        setIsTickSelected({ ...isTickSelected, [postId]: false });
        setIsCrossSelected({ ...isCrossSelected, [postId]: true });
        setSelectedCrossPosts([...selectedCrossPosts, postId]);
        setIsTickSelected((prevState) => ({
          ...prevState,
          [postId]: true,
        }));
      }
    }
  };

  // emoji header post

  const [selectedEmojiHeader, setSelectedEmojiHeader] = useState(null);
  const [showOnlyHappyPosts, setShowOnlyHappyPosts] = useState(false);
  const [showOnlySadPosts, setShowOnlySadPosts] = useState(false);
  const [showOnlyIndifferentPosts, setShowOnlyIndifferentPosts] =
    useState(false);

  const handleEmojiHeaderClick = (emojiHeaderId) => {
    if (emojiHeaderId === "happy") {
      setSelectedEmojiHeader("happy");
      setShowOnlyHappyPosts(true);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(false);
    } else if (emojiHeaderId === "sad") {
      setSelectedEmojiHeader("sad");
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(true);
      setShowOnlyIndifferentPosts(false);
    } else if (emojiHeaderId === "indifferent") {
      setSelectedEmojiHeader("indifferent");
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(true);
    } else {
      setSelectedEmojiHeader(null);
      setShowOnlyHappyPosts(false);
      setShowOnlySadPosts(false);
      setShowOnlyIndifferentPosts(false);
    }
    const filterOptions = {
      happy: { happy: true, sad: false, indifferent: false },
      sad: { happy: false, sad: true, indifferent: false },
      indifferent: { happy: false, sad: false, indifferent: true },
      clear: { happy: false, sad: false, indifferent: false },
    };

    const selectedOptions = filterOptions[emojiHeaderId] || filterOptions.clear;

    setSelectedEmojiHeader(emojiHeaderId);
    setShowOnlyHappyPosts(selectedOptions.happy);
    setShowOnlySadPosts(selectedOptions.sad);
    setShowOnlyIndifferentPosts(selectedOptions.indifferent);
  };

  const handleClearFiltersClick = () => {
    setSelectedEmojiHeader(null);
    setShowOnlyHappyPosts(false);
    setShowOnlySadPosts(false);
    setShowOnlyIndifferentPosts(false);
  };

  const [isOpenStickerHeader, setIsOpenStickerHeader] = useState(false);

  const toggleMenuStickerHeader = () => {
    if (!isOpenStickerHeader) {
      closeAllMenus();
    }
    setIsOpenStickerHeader(!isOpenStickerHeader);
  };

  // tick header post

  const [selectedTickHeader, setSelectedTickHeader] = useState(null);

  const handleTickHeaderClick = (tickHeaderId) => {
    if (selectedTickHeader === tickHeaderId) {
      setSelectedTickHeader(null);
      setShowOnlyTickPosts(false);
    } else {
      setSelectedTickHeader(tickHeaderId);
      setShowOnlyTickPosts(true);
    }
  };

  // filter tab open

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // show popup add topic and subtopic

  const handleAddTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await addTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          toast.error("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        // setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleDeleteTopic = async (nameProject, newMainTopic) => {
    try {
      var result = await deleteTopic(nameProject, newMainTopic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          toast.error("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleDeleteSubTopic = async (
    nameProject,
    newMainTopic,
    newSubTubic
  ) => {
    try {
      var result = await deleteSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          toast.error("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const handleAddSubTopic = async (nameProject, newMainTopic, newSubTubic) => {
    try {
      var result = await addSubTopic(nameProject, newMainTopic, newSubTubic);
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          toast.error("Connection Error");
        }
      } else {
        var data = result.data.data;
        // setapiData(data.results);
        console.log(data.results);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const [showPopupAddTopic, setShowPopupAddTopic] = useState(false);
  const [topics, setTopics] = useState([]);
  const [newMainTopic, setNewMainTopic] = useState("");

  const [newSubTopic, setNewSubTopic] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    console.log(
      "🚀 ~ file: AddSubjects.js:157 ~ AddSubjects ~ fetchedApi:",
      apiData
    );
  }, [apiData]);

  const handleClosePopupAddTopic = async () => {
    try {
      await handleStartMyShow();
      setShowPopupAddTopic(false);
    } catch (error) {
      console.log("error in handle close popup add topic", error);
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
    // setfetchedApi(false);
  };

  const handleOpenPopupAddTopic = () => {
    setShowPopupAddTopic(true);
  };

  const addMainTopic = async (nameProject) => {
    if (newMainTopic.trim() !== "") {
      // eslint-disable-next-line array-callback-return
      const isDuplicate = subject_projects.find((project) => {
        if (
          project.name === nameProject &&
          project.subjects.find((ele) => ele.subject_id.name === newMainTopic)
        ) {
          return true;
        }
      });
      if (!isDuplicate) {
        await handleAddTopic(nameProject, newMainTopic);

        setFetchedSubjects([
          ...fetchedSubjects,
          {
            subject_id: {
              name: newMainTopic,
              subSubjects: [],
            },
          },
        ]);
        setNewMainTopic("");
        // await updateSubjects()
      } else {
        if (language === "en") {
          Swal.fire({
            text: "This Main topic is already in the list",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            text: "هذا الموضوع الرئيسي موجود في القائمة",
            icon: "warning",
            confirmButtonText: "حسناً",
          });
        }
      }
    }
  };

  const addSubtopic = async (topicChoosed, nameProject) => {
    const topic = topicChoosed;
    await handleAddSubTopic(nameProject, topic.subject_id.name, newSubTopic);
    if (newSubTopic.trim() !== "") {
      if (topic) {
        // console.log("addSubtopic" , topic)
        const isDuplicate =
          topic.subject_id.subSubjects !== undefined
            ? topic.subject_id.subSubjects.includes(newSubTopic)
            : false;
        if (!isDuplicate) {
          topic.subject_id.subSubjects.push({ name: newSubTopic });
          setNewSubTopic("");
          setSelectedTopic({ ...topic });
          var temp = fetchedSubjects;
          temp = temp.map((element) => {
            if (element.subject_id.name === topic.name) {
              return topic;
            } else {
              return element;
            }
          });
          setFetchedSubjects(temp);
        } else {
          if (language === "en") {
            Swal.fire({
              text: "This subtopic is already in the list for the selected main topic",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              text: "هذا الموضوع الفرعي موجود حاليا في قائمة الموضوع الرئيسي المحدد",
              icon: "warning",
              confirmButtonText: "حسناً",
            });
          }
        }
      }
    }
  };

  const handleRemoveMainTopic = async (nameProject, indexOfSubject) => {
    const updatedTopics = [...topics];
    updatedTopics.splice(indexOfSubject, 1);
    setTopics(updatedTopics);

    const temp = [...fetchedSubjects];
    console.log(
      "🚀 ~ file: AddSubjects.js:335 ~ apiData.map ~ temp:",
      temp[indexOfSubject].subject_id.name
    );
    await handleDeleteTopic(nameProject, temp[indexOfSubject].subject_id.name);
    temp.splice(indexOfSubject, 1);
    setFetchedSubjects(temp);
  };

  const handleRemoveSubtopic = async (nameProject, subIndex, topicChoosed) => {
    const topic = { ...topicChoosed };
    await handleDeleteSubTopic(
      nameProject,
      topic.subject_id.name,
      topic.subject_id.subSubjects[subIndex].name
    );
    console.log("handleRemoveSubtopic", topic);
    topic.subject_id.subSubjects.splice(subIndex, 1);
    var temp = fetchedSubjects;
    temp = temp.map((element) => {
      if (element.subject_id.name === topic.name) {
        return topic;
      } else {
        return element;
      }
    });
    setFetchedSubjects(temp);
  };

  // sort weight post

  const [sortWeightPosts, setSortWeightPosts] = useState("asc");

  const sortByWeightPosts = (type) => {
    setSortWeightPosts(type);
    postByWordsData.posts.sort((a, b) => {
      if (type === "asc") {
        return a.weight_number - b.weight_number;
      } else {
        return b.weight_number - a.weight_number;
      }
    });
  };

  // emoji convert

  const convertUnicodeToEmoji = (unicode) => {
    try {
      const emoji = String.fromCodePoint(
        parseInt(unicode.replace("U+", ""), 16)
      );
      return emoji;
    } catch (error) {
      console.error("Failed to convert Unicode to Emoji:", error.message);
      return null;
    }
  };

  const renderPostTextWithStickers = (post) => {
    const parts = post.texts.split(" ");
    const textWithStickers = parts
      .map((part) => {
        if (part.startsWith("U+")) {
          return convertUnicodeToEmoji(part);
        } else {
          const cleanedPart = part.replace(/^-|-$/g, "");
          return cleanedPart;
        }
      })
      .join(" ");
    return textWithStickers;
  };

  const handleFilterPosts = () => {
    var posts = postByWordsData.posts.filter(
      (post) =>
        (!usernameFilter || post.username.includes(usernameFilter)) &&
        (!nameFilter || post.name.includes(nameFilter)) &&
        (!textFilter || post.texts.includes(textFilter)) &&
        (!exactTextFilter || post.texts.split(" ").includes(exactTextFilter)) &&
        (!notTextFilter || !post.texts.includes(notTextFilter)) &&
        (!notExactTextFilter ||
          post.texts.split(" ").includes(notExactTextFilter)) &&
        (!startParagraphFilter ||
          post.texts.startsWith(startParagraphFilter)) &&
        (!endParagraphFilter || post.texts.endsWith(endParagraphFilter)) &&
        // (minLikes === "" ||
        //   minLikes === null ||
        //   post.likes >= parseInt(minLikes)) &&
        // (maxLikes === "" ||
        //   maxLikes === null ||
        //   post.likes <= parseInt(maxLikes)) &&
        // (minComments === "" ||
        //   minComments === null ||
        //   post.comments >= parseInt(minComments)) &&
        // (maxComments === "" ||
        //   maxComments === null ||
        //   post.comments <= parseInt(maxComments)) &&
        (fromDate === "" ||
          new Date(post.creates.split("at")[0]) >= fromDateObject) &&
        (untilDate === "" ||
          new Date(post.creates.split("at")[0]) <= untilDateObject)
    );

    if (posts && posts.length > 0) {
      return posts.map((element, index) => {
        const isExpanded = expandedStates[index] || false;

        const handleToggleExpand = () => {
          const newExpandedStates = [...expandedStates];
          newExpandedStates[index] = !isExpanded;
          setExpandedStates(newExpandedStates);
        };

        const maxCharacters = 100;

        if (
          (selectedPosts.includes(element.id) || !showOnlyStarredPosts) &&
          (selectedTickPosts.includes(element.id) || !showOnlyTickPosts)
        ) {
          if (showOnlyHappyPosts && selectedEmojis[element.id] !== "happy") {
            return null;
          }

          if (showOnlySadPosts && selectedEmojis[element.id] !== "sad") {
            return null;
          }

          if (
            showOnlyIndifferentPosts &&
            selectedEmojis[element.id] !== "indifferent"
          ) {
            return null;
          }

          return (
            <div
              className={`items_posts_fb ${
                language === "ar" ? "items_posts_fb_ar" : ""
              }`}
              key={element.id}
            >
              <div
                className={`body_posts_fb ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                <div
                  className={`user_fb_post ${
                    language === "ar" ? "user_fb_post_ar" : ""
                  }`}
                >
                  <div
                    className={`user_avatar_fb_post_analysis_confirm ${
                      language === "ar"
                        ? "user_avatar_fb_post_analysis_confirm_ar"
                        : ""
                    }`}
                  >
                    <a
                      href={element.postid}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          element.username
                            ? facebook_profile_url + element.username + ".png"
                            : avatar_user
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = avatar_user;
                        }}
                        alt=""
                        className={`post_image ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      />
                    </a>
                    <div
                      className={`spans_user_posts ${
                        language === "ar" ? "spans_user_posts_ar" : ""
                      }`}
                    >
                      <span
                        className={`name_user_fb ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <span className="tooltip_posts_box_top">
                          {element.name && element.name.length > 17
                            ? element.name.slice(0, 17) + "..."
                            : element.name && (
                                <span
                                  dangerouslySetInnerHTML={highlightFilterUsers(
                                    element.name
                                  )}
                                />
                              )}
                          <span className="tooltip_text_posts_box_top">
                            {element.name}
                          </span>
                        </span>
                      </span>

                      <span className="tooltip_posts_box_bottom">
                        {element.username && "@"}
                        {element.username &&
                          (element.username.length > 17 ? (
                            element.username.slice(0, 17) + "..."
                          ) : (
                            <span
                              dangerouslySetInnerHTML={highlightFilterUsers(
                                element.username
                              )}
                            />
                          ))}
                        <span className="tooltip_text_posts_box_bottom">
                          {element.username}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="text_body_user_fb_post_analysis">
                    <p className="text_post_fb_analysis_confirm">
                      {isExpanded || element.texts.length <= maxCharacters ? (
                        <span
                          dangerouslySetInnerHTML={highlightFilterKeywords(
                            renderPostTextWithStickers(element)
                          )}
                        />
                      ) : (
                        <span>
                          <span
                            dangerouslySetInnerHTML={highlightFilterKeywords(
                              renderPostTextWithStickers(element).slice(
                                0,
                                maxCharacters
                              )
                            )}
                          />
                          ...
                        </span>
                      )}
                      <button
                        className="more_text_btn_fb"
                        onClick={handleToggleExpand}
                      >
                        {isExpanded ? "Less" : "More..."}
                      </button>
                    </p>

                    {isExpanded && (
                      <div className="rowtwo_post_text_body_user_fb_post">
                        <div className="items_left_posts_fb_analysis_two">
                          <img src={Like} alt="" />
                          <span>{element.likes}</span>
                        </div>
                        <div className="items_left_posts_fb_analysis_three">
                          <img src={Comment} alt="" />
                          <span>{element.comments}</span>
                        </div>

                        <div className="items_left_posts_fb_analysis_four">
                          <input
                            type="checkbox"
                            className={`checkbox_input_analysis ${
                              language === "ar"
                                ? "checkbox_input_analysis_ar"
                                : ""
                            }`}
                            id="checkboc_icon"
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="items_left_posts_fb_analysis_five">
                          <div onClick={() => handlePostStarClick(element.id)}>
                            <img
                              src={Star}
                              alt=""
                              className={`star-icon ${
                                selectedPosts.includes(element.id)
                                  ? "star-selected"
                                  : ""
                              }`}
                              style={{
                                display: selectedPosts.includes(element.id)
                                  ? "none"
                                  : "block",
                              }}
                            />
                            <img
                              src={Star_gold}
                              alt=""
                              className={`star-icon ${
                                selectedPosts.includes(element.id)
                                  ? "star-selected"
                                  : ""
                              }`}
                              style={{
                                display: selectedPosts.includes(element.id)
                                  ? "block"
                                  : "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="items_left_posts_fb_analysis_one_parent">
                    <div className="items_left_posts_fb_analysis_one_confirm">
                      {new Date(
                        element.creates.split("at")[0]
                      ).toLocaleDateString(undefined, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </div>
                  </div>
                  <div className="items_left_posts_fb_analysis_one_parent">
                    <div className="items_left_posts_fb_analysis_one_confirm">
                      {element.weight_number}
                    </div>
                  </div>
                  <div className="top_left_bottom_posts_btn_fb_confirm">
                    <img
                      src={
                        selectedEmojis[element.id] === "happy"
                          ? Happy_yellow
                          : Happy
                      }
                      alt=""
                      onClick={() =>
                        handleEmojiClick(element.id, "happy", true)
                      }
                    />

                    <img
                      src={
                        selectedEmojis[element.id] === "indifferent"
                          ? indifferent_yellow
                          : indifferent
                      }
                      alt=""
                      onClick={() =>
                        handleEmojiClick(element.id, "indifferent", true)
                      }
                    />

                    <img
                      src={
                        selectedEmojis[element.id] === "sad" ? Sad_yellow : Sad
                      }
                      alt=""
                      onClick={() => handleEmojiClick(element.id, "sad", true)}
                    />
                  </div>
                  <div className="analysis_menu_bottom_fb_analysis_search_parent">
                    <div className="analysis_menu_bottom_fb_analysis_search_new">
                      <AnalysisMenuAxis
                        data={axisData}
                        setSelected={setSelectedAxis}
                        setPosts={setpostByWordsData}
                        post={element}
                        index={index}
                      />
                    </div>
                    <div className="analysis_menu_bottom_fb_analysis_search_new">
                      <AnalysisMenuEmotion
                        data={emotionsData}
                        setSelected={setSelectedEmotion}
                        setPosts={setpostByWordsData}
                        post={element}
                        index={index}
                      />
                    </div>
                    <div className="analysis_menu_bottom_fb_analysis_search_new">
                      <AnalysisMenuAccent
                        data={accentData}
                        setSelected={setSelectedAccent}
                        setPosts={setpostByWordsData}
                        post={element}
                        index={index}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom_body_user_fb_post_analysis">
                  <div className="menu_bottom_posts_analysis_search">
                    <div className="bottom_left_bottom_posts_btn_fb">
                      <img
                        src={
                          selectedTickPosts.includes(element.id)
                            ? tick_green
                            : tick_gray
                        }
                        alt=""
                        onClick={() =>
                          handleStickerClick(element.id, "tick", true, index)
                        }
                      />
                    </div>
                    <div className="analysis_menu_bottom_fb_analysis_search">
                      <AnalysisMenuSubtopic
                        data={subject_projects.find(
                          (x) => x.id === selectedProject
                        )}
                        setSelected={setSelectedSubSubProject}
                        subject={selectedSubProject}
                        setPosts={setpostByWordsData}
                        post={element}
                        index={index}
                      />
                    </div>
                    <div className="analysis_menu_bottom_fb_analysis_search">
                      <AnalysisMenuMainTopic
                        data={subject_projects.find(
                          (x) => x.id === selectedProject
                        )}
                        setSelected={setSelectedSubProject}
                        setPosts={setpostByWordsData}
                        index={index}
                        post={element}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return "";
        }
      });
    } else {
      return "No Posts Founded";
    }
  };

  const renderAnalysisSearchFB = (
    <>
      <div
        className={`facebook_page_analysis ${
          language === "ar" ? "facebook_page_analysis_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <div
          className={`filter_box_fb_analysis ${
            theme === "dark" ? "dark_mode" : ""
          }`}
        >
          <div
            className={`header_left_facebook_analysis ${
              language === "ar" ? "header_left_facebook_analysis_ar" : ""
            } ${theme === "dark" ? "dark_mode" : ""}`}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            {language === "en" ? (
              <span
                className={`filter_box_fb_header_span ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                Filter
              </span>
            ) : (
              <span
                className={`filter_box_fb_header_span_ar ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                فلتر
              </span>
            )}
            <div
              className={`img_header_filter_fb ${
                language === "ar" ? "img_header_filter_fb_ar" : ""
              } ${isFilterOpen ? "rotate_up_arrow_analysis" : ""}`}
            >
              <img src={arrow_down} alt="" />
            </div>
          </div>
          <div style={{ display: isFilterOpen ? "block" : "none"}}>
            <div
              className={`analysis_filter_body_facebook_page ${
                language === "ar" ? "analysis_filter_body_facebook_page_ar" : ""
              } ${theme === "dark" ? "dark_mode" : ""}`}
            >
              <div
                className={`items_analysis_filter_body ${
                  language === "ar" ? "items_analysis_filter_body_ar" : ""
                }`}
              >
                <form
                // className={`search_fb_page ${
                //   language === "ar" ? "search_fb_page_ar" : ""
                // } ${theme === "dark" ? "dark_mode" : ""}`}
                >
                  {language === "en" ? (
                    <div className="row_fileds_fb">
                      <label>Search</label>
                      <input
                        type="search"
                        placeholder="Search"
                        onChange={handleSearchWordChanged}
                        className={`search_input_fb_analysis ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      />
                    </div>
                  ) : (
                    <div className="row_fileds_fb">
                      <label>البحث</label>
                      <input
                        type="search"
                        placeholder="بحث"
                        onChange={handleSearchWordChanged}
                        className={`search_input_fb_analysis_ar ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      />
                    </div>
                  )}
                  {/* <img
                  className={`search_icon_fb ${
                    language === "ar" ? "search_icon_fb_ar" : ""
                  }`}
                  src={Search}
                  alt=""
                /> */}
                </form>
              </div>

              <div
                className={`items_analysis_filter_body ${
                  language === "ar" ? "items_analysis_filter_body_ar" : ""
                }`}
              >
                {language === "en" ? (
                  <div className="row_fileds_fb">
                    <label>Starting Date</label>
                    <input
                      type="date"
                      value={startingDate}
                      onChange={handleStartDateChanged}
                      className={`date_inputs_filter_fb ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                ) : (
                  <div className="row_fileds_fb">
                    <label>تاريخ البدء</label>
                    <input
                      type="date"
                      value={startingDate}
                      onChange={handleStartDateChanged}
                      className={`date_inputs_filter_fb ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                )}
              </div>
              <div
                className={`items_analysis_filter_body ${
                  language === "ar" ? "items_analysis_filter_body_ar" : ""
                }`}
              >
                {language === "en" ? (
                  <div className="row_fileds_fb">
                    <label>End To Date</label>
                    <input
                      type="date"
                      value={endingDate}
                      onChange={handleEndDateChanged}
                      className={`date_inputs_filter_fb ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                ) : (
                  <div className="row_fileds_fb">
                    <label>تاريخ الانتهاء</label>
                    <input
                      type="date"
                      value={endingDate}
                      onChange={handleEndDateChanged}
                      className={`date_inputs_filter_fb ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                )}
              </div>
              <div
                className={`items_analysis_filter_body ${
                  language === "ar" ? "items_analysis_filter_body_ar" : ""
                }`}
              >
                {language === "en" ? (
                  <div className="row_fileds_fb">
                    <label>Hashtag Search</label>
                    <input
                      type="text"
                      placeholder="Enter Word..."
                      // value={searchHashtag}
                      onChange={handleSearchHashtagChanged}
                      className={`date_inputs_filter_fb ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                ) : (
                  <div className="row_fileds_fb">
                    <label>البحث عن الهاشتاق</label>
                    <input
                      type="text"
                      placeholder="... أدخل كلمة"
                      // value={searchHashtag}
                      onChange={handleSearchHashtagChanged}
                      className={`date_inputs_filter_fb arabic ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    />
                  </div>
                )}
              </div>
          
            
            
         
            </div>
            <div className="row_three_filter_box_fb_analysis">
              <button
                id="btn_row_three_filter_box_fb_analysis"
                onClick={handleSearchClicked}
              >
                تایید
              </button>
            </div>
          </div>
        </div>

        <div className="body_facebook_page_analysis">
          <div
            className={`header_posts_fb ${
              language === "ar" ? "header_posts_fb_ar" : ""
            } ${theme === "dark" ? "dark_mode" : ""}`}
          >
            {language === "en" ? (
              <span
                className={`header_posts_fb_span ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                Posts
              </span>
            ) : (
              <span
                className={`header_posts_fb_span_ar ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
              >
                المنشورات
              </span>
            )}

            <div
              className={`found_post_number ${
                theme === "dark" ? "dark_mode" : ""
              }`}
            >
              {language === "en" ? (
                !loadingState && postByWordsData ? (
                  <span className="found_post_number_span en">
                    Posts Found :&nbsp;<span> {postByWordsData.allCount}</span>{" "}
                  </span>
                ) : (
                  "Posts Found : 0"
                )
              ) : !loadingState && postByWordsData ? (
                <span className="found_post_number_span">
                  عدد المنشورات :&nbsp;<span> {postByWordsData.allCount} </span>{" "}
                </span>
              ) : (
                "عدد المنشورات : 0 "
              )}
            </div>

            <div className="input_loaded_post">
              <input
                type="number"
                className={`input_loaded_post_input ${
                  theme === "dark" ? "dark_mode" : ""
                }`}
                defaultValue={loadedPostsNumber}
                placeholder="Set Number loaded posts"
                onChange={handleLoadedPostsNumberChange}
              />
            </div>
            <div className="analysis_menu_header_project_subject">
              <AnalysisMenuProjectSubject
                data={subject_projects.map((x) => ({
                  Key: x.id,
                  Value: x.name,
                }))}
                setSelectedProject={setSelectedProject}
                setSelectedProjectName={setSelectedProjectName}
              />
            </div>
            {isSeniorAnalyzer || isSuperUser ? (
              <>
                <div
                  onClick={handleOpenPopupAddTopic}
                  className="btn_add_subjects_analysis"
                >
                  <AddCircleOutlineIcon id="btn_add_subjects_analysis_icon" />
                  <span className="btn_add_subjects_analysis_tooltip">
                    Add Topic & SubTopic
                  </span>
                </div>
                <Dialog
                  className="popup_add_subject"
                  // open={project.openTopicPopup}
                  open={showPopupAddTopic}
                  // onClose={() =>
                  //   handleClosePopup(index, "openTopicPopup")
                  // }
                  onClose={() => handleClosePopupAddTopic(false)}
                >
                  <DialogTitle className="header_popup_right_header_add_admin_page">
                    <label>Add Topic and SubTopic</label>
                    <button
                      // onClick={() =>
                      //   handleClosePopup(index, "openTopicPopup")
                      // }
                      onClick={() => handleClosePopupAddTopic(false)}
                      className="btn_close_header_popup_right_header_add_admin_page"
                    >
                      <CloseIcon />
                    </button>
                  </DialogTitle>
                  <DialogContent className="body_popup_add_subject">
                    {selectedProjectName ? (
                      <div className="rows_body_popup_add_subject_one">
                        <input
                          type="text"
                          placeholder="Enter Topic"
                          value={newMainTopic}
                          onChange={(e) => setNewMainTopic(e.target.value)}
                        />
                        <button
                          onClick={() => addMainTopic(selectedProjectName)}
                        >
                          ADD
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="rows_body_popup_add_subject">
                      <ul>
                        {fetchedSubjects
                          ? fetchedSubjects.map((topic, index) => (
                              <li
                                key={index}
                                className="li_topic_body_popup_add_subject"
                                style={{
                                  cursor: "pointer",
                                  background:
                                    selectedTopic === topic
                                      ? "#fafafa"
                                      : "#fff",
                                }}
                                onClick={() => setSelectedTopic(topic)}
                              >
                                {topic.name}
                                <button
                                  onClick={() => {
                                    handleRemoveMainTopic(
                                      selectedProjectName,
                                      index
                                    );
                                  }}
                                  id="btn_remove_items_li_ul_add_topic"
                                >
                                  <img src={multiple_red} alt="" />
                                </button>
                                <ul className="ul_topic_body_popup_add_subject">
                                  {topic.subject_id.name}
                                  {topic.subject_id.subSubjects.map(
                                    (subtopic, subIndex) => (
                                      <li key={subIndex}>
                                        {subtopic.name}
                                        <button
                                          onClick={() =>
                                            handleRemoveSubtopic(
                                              selectedProjectName,
                                              subIndex,
                                              topic
                                            )
                                          }
                                          id="btn_remove_items_li_ul_add_topic"
                                        >
                                          <img src={multiple_red} alt="" />
                                        </button>
                                      </li>
                                    )
                                  )}
                                </ul>
                                {selectedTopic === topic && (
                                  <div className="ul_topic_body_popup_add_subject_input">
                                    <input
                                      type="text"
                                      placeholder="Enter SubTopic"
                                      value={newSubTopic}
                                      onChange={(e) =>
                                        setNewSubTopic(e.target.value)
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        addSubtopic(topic, selectedProjectName)
                                      }
                                    >
                                      ADD SubTopic
                                    </button>
                                  </div>
                                )}
                              </li>
                            ))
                          : ""}
                      </ul>
                    </div>
                  </DialogContent>
                  <DialogActions className="btns_bottom_search_simple">
                    <button
                      className="okay_btn_search_simple"
                      // onClick={() =>
                      //   handleClosePopup(index, "openTopicPopup")
                      // }
                      onClick={() => handleClosePopupAddTopic(false)}
                    >
                      OK
                    </button>
                    <button
                      className="deny_btn_search_simple"
                      // onClick={() =>
                      //   handleClosePopup(index, "openTopicPopup")
                      // }
                      onClick={() => handleClosePopupAddTopic(false)}
                    >
                      Cancel
                    </button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
            {language === "en" ? (
              <label className="dropdown_exel_btn_fb">
                <img src={Excel} id="exel-icon" alt="" />
                <input type="checkbox" className="ch_input_exel_btn_fb" />

                <ul
                  className={`submenu_exel_btn_fb ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  <li onClick={() => handleRecordExportToExcel(10)}>
                    10 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(50)}>
                    50 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(100)}>
                    100 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(1000)}>
                    1000 Record
                  </li>
                  <li className="divider_exel_btn"></li>
                  <li onClick={handleExportToExcel}>Selected records</li>
                  <li onClick={handleExportCurrentToExcel}>Current records</li>
                  <li onClick={() => handleAllRecordsExport(1000)}>
                    All records
                  </li>
                </ul>
              </label>
            ) : (
              <label className="dropdown_exel_btn_fb_ar">
                <img src={Excel} id="exel-icon" alt="" />
                <input type="checkbox" className="ch_input_exel_btn_fb_ar" />

                <ul
                  className={`submenu_exel_btn_fb_ar ${
                    theme === "dark" ? "dark_mode" : ""
                  }`}
                >
                  <li onClick={() => handleRecordExportToExcel(10)}>
                    10 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(50)}>
                    50 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(100)}>
                    100 Record
                  </li>
                  <li onClick={() => handleRecordExportToExcel(1000)}>
                    1000 Record
                  </li>
                  <li className="divider_exel_btn"></li>
                  <li onClick={handleExportToExcel}>Selected records</li>
                  <li onClick={handleExportCurrentToExcel}>Current records</li>
                  <li onClick={() => handleAllRecordsExport(1000)}>
                    All records
                  </li>
                </ul>
              </label>
            )}
          </div>

          <div
            className={`header_bottom_posts_fb_analysis ${
              language === "ar" ? "header_bottom_posts_fb_analysis_ar" : ""
            } ${theme === "dark" ? "dark_mode" : ""}`}
          >
            <div
              className={`user_avatar_fb_post_analysis_header ${
                language === "ar"
                  ? "user_avatar_fb_post_analysis_header_ar"
                  : "user_avatar_fb_post_analysis_header_en"
              }`}
            >
              {language === "en" ? (
                <div className="profile_body_facebook_page_analysis">
                  <img
                    src={broom_icon}
                    alt=""
                    title="Clear All Filter Users"
                    onClick={handleClearFiltersUser}
                  />

                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterUsersImage}
                      alt=""
                      onClick={toggleMenuUser}
                    />
                    {isOpenUser && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Name"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By UserName"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Users</span> */}
                </div>
              ) : (
                <div className="profile_body_facebook_page_analysis">
                  {/* <span>المستخدمون</span> */}
                  <div className="dropdown_filter_items_posts_fb_ar">
                    <img
                      src={currentFilterUsersImage}
                      alt=""
                      onClick={toggleMenuUser}
                    />
                    {isOpenUser && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="تصفية حسب الاسم"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="التصفية حسب اسم المستخدم"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt=""
                    title="مسح كافة تصفية المستخدمين"
                    onClick={handleClearFiltersUser}
                  />
                </div>
              )}
            </div>
            <div
              className={`text_body_user_fb_post_header_analysis ${
                language === "ar"
                  ? "text_body_user_fb_post_header_analysis_ar"
                  : "text_body_user_fb_post_header_analysis_en"
              }`}
            >
              {language === "en" ? (
                <div className="text_post_body_facebook_page_analysis">
                  <img
                    src={broom_icon}
                    alt=""
                    title="Clear All Filter Text"
                    onClick={handleClearFiltersTextPost}
                  />
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterTextImage}
                      alt=""
                      onClick={toggleMenuText}
                    />

                    {isOpenText && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Likes"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={textFilter}
                            // onChange={(e) => setTextFilter(e.target.value)}
                            onChange={handleTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Exact"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={exactTextFilter}
                            onChange={handleExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Lack"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notTextFilter}
                            onChange={handleNotTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Exact Word Negation"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notExactTextFilter}
                            onChange={handleNotExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By First Word"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={startParagraphFilter}
                            onChange={handleStartParagraphFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Filter By Last Word"
                            className={`menu_input_filter_header_fb ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={endParagraphFilter}
                            onChange={handleEndParagraphFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Posts Text</span> */}
                </div>
              ) : (
                <div className="text_post_body_facebook_page_analysis">
                  {/* <span>نص المنشورات</span> */}
                  <div className="dropdown_filter_items_posts_fb_ar">
                    <img
                      src={currentFilterTextImage}
                      alt=""
                      onClick={toggleMenuText}
                    />

                    {isOpenText && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الإعجابات"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={textFilter}
                            // onChange={(e) => setTextFilter(e.target.value)}
                            onChange={handleTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على كلمة محددة"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={exactTextFilter}
                            onChange={handleExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على النقص"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notTextFilter}
                            onChange={handleNotTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الكلمة الأولى"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={notExactTextFilter}
                            onChange={handleNotExactTextFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="تصفية حسب الكلمة الأولى"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={startParagraphFilter}
                            onChange={handleStartParagraphFilterChange}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="فلتر بناءً على الكلمة الأخيرة"
                            className={`menu_input_filter_header_fb_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            value={endParagraphFilter}
                            onChange={handleEndParagraphFilterChange}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt=""
                    title="مسح كافة تصفية النص"
                    onClick={handleClearFiltersTextPost}
                  />
                </div>
              )}
            </div>
            <div className="items_left_posts_fb_analysis_one_header">
              {language === "en" ? (
                <div className="items_left_posts_fb_analysis_header_rows">
                  <img
                    src={broom_icon}
                    alt="Clear Date Filter"
                    title="Clear Date Filter"
                    onClick={handleClearFiltersDates}
                  />
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterDateImage}
                      alt=""
                      onClick={toggleMenuDate}
                    />

                    {isOpenDate && (
                      <ul
                        className={`submenu_filter_btn_fb show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "From Date" }}
                            value={fromDate}
                            // onChange={(date) => setFromDate(date)}
                            onChange={(date) => {
                              setFromDate(date);
                              handleFromDateFilterChange();
                            }}
                          />
                        </li>
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "Until Date" }}
                            value={untilDate}
                            // onChange={(date) => setUntilDate(date)}
                            onChange={(date) => {
                              setUntilDate(date);
                              handleUntilDateFilterChange();
                            }}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* <span>Date</span> */}
                </div>
              ) : (
                <div className="items_left_posts_fb_analysis_header_rows">
                  {/* <span>تاریخ</span> */}
                  <div className="dropdown_filter_items_posts_fb">
                    <img
                      src={currentFilterDateImage}
                      alt=""
                      onClick={toggleMenuDate}
                    />

                    {isOpenDate && (
                      <ul
                        className={`submenu_filter_btn_fb_ar show ${
                          theme === "dark" ? "dark_mode" : ""
                        }`}
                      >
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "من التاريخ" }}
                            value={fromDate}
                            onChange={(date) => {
                              setFromDate(date);
                              handleFromDateFilterChange();
                            }}
                          />
                        </li>
                        <li>
                          <DateTime
                            className={`menu_input_filter_header_fb_date_ar ${
                              theme === "dark" ? "dark_mode" : ""
                            }`}
                            inputFormat="YYYY_MM_DD"
                            dateFormat="YYYY_MM_DD"
                            timeFormat={false}
                            inputProps={{ placeholder: "تا التاريخ" }}
                            value={untilDate}
                            onChange={(date) => {
                              setUntilDate(date);
                              handleUntilDateFilterChange();
                            }}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                  <img
                    src={broom_icon}
                    alt="مسح مرشح التاريخ"
                    title="مسح مرشح التاريخ"
                    onClick={handleClearFiltersDates}
                  />
                </div>
              )}
            </div>
            <div className="items_left_posts_fb_analysis_one_header_sort">
              <div className="arrow_items_header_result_list_analysis">
                <button
                  className={sortWeightPosts === "desc" ? "active" : "inactive"}
                  onClick={() => sortByWeightPosts("desc")}
                >
                  <ArrowDropUpIcon className="arrow_items_header_result_list_analysis_icon" />
                </button>
                <button
                  className={sortWeightPosts === "asc" ? "active" : "inactive"}
                  onClick={() => sortByWeightPosts("asc")}
                >
                  <ArrowDropDownIcon className="arrow_items_header_result_list_analysis_icon" />
                </button>
              </div>
            </div>

            <div
              className={`items_left_posts_fb_analysis_five_header ${
                language === "en"
                  ? "items_left_posts_fb_analysis_five_header_en"
                  : ""
              }`}
            >
              <img
                src={checkbox_gray}
                alt=""
                className={`checkbox_header ${
                  language === "ar"
                    ? "checkbox_header_ar"
                    : "checkbox_header_en"
                }`}
                id="checkboc_icon"
                onClick={handleClearAll}
              />
            </div>
            <div className="items_left_posts_fb_analysis_four_header">
              <img
                onClick={handleHeaderStarClick}
                src={isGold ? Star_gold : Star}
                alt=""
              />
            </div>
            <div
              className={`items_left_posts_fb_analysis_six_header ${
                language === "ar"
                  ? "items_left_posts_fb_analysis_six_header_ar"
                  : "items_left_posts_fb_analysis_six_header_en"
              }`}
            >
              <div className="emoji_icon_header">
                <div className="dropdown_filter_items_posts_fb">
                  <img
                    src={
                      selectedEmojiHeader === "happy" ||
                      selectedEmojiHeader === "sad" ||
                      selectedEmojiHeader === "indifferent"
                        ? sticker_square
                        : sticker_square_gray
                    }
                    alt=""
                    onClick={toggleMenuStickerHeader}
                  />
                  {isOpenStickerHeader && (
                    <ul
                      className={`submenu_filter_btn_fb show ${
                        theme === "dark" ? "dark_mode" : ""
                      }`}
                    >
                      <li>
                        <img
                          src={
                            selectedEmojiHeader === "happy"
                              ? Happy_yellow
                              : Happy
                          }
                          alt=""
                          onClick={() => handleEmojiHeaderClick("happy")}
                        />
                      </li>
                      <li>
                        <img
                          src={selectedEmojiHeader === "sad" ? Sad_yellow : Sad}
                          alt=""
                          onClick={() => handleEmojiHeaderClick("sad")}
                        />
                      </li>
                      <li>
                        <img
                          src={
                            selectedEmojiHeader === "indifferent"
                              ? indifferent_yellow
                              : indifferent
                          }
                          alt=""
                          onClick={() => handleEmojiHeaderClick("indifferent")}
                        />
                      </li>
                      <li>
                        <img
                          src={broom_new}
                          alt=""
                          onClick={handleClearFiltersClick}
                        />
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`items_left_posts_fb_analysis_seven_header ${
                language === "ar"
                  ? "items_left_posts_fb_analysis_seven_header_ar"
                  : "items_left_posts_fb_analysis_seven_header_en"
              }`}
            >
              <div className="tick_icon_header">
                <img
                  src={
                    selectedTickHeader === "tick_green" ? tick_green : tick_gray
                  }
                  alt=""
                  onClick={() => handleTickHeaderClick("tick_green")}
                />
              </div>
            </div>
          </div>
          <div
            className={`display_posts_fb_analysis_search ${
              theme === "dark" ? "dark_mode" : ""
            }`}
            onScroll={handleLoadMoreClicked}
          >
            {!loadingState && postByWordsData ? (
              handleFilterPosts()
            ) : (
              <BeatLoader color="#458BDF" size={20} />
            )}

            {loadMoreLoadingState ? (
              <BeatLoader color="#458BDF" size={20} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div
      className={`facebook_page_body ${theme === "dark" ? "dark_mode" : ""}`}
    >
      {needLogin ? <Navigate to="/" /> : null}
      {fetchedApi ? (
        renderAnalysisSearchFB
      ) : (
        <div className="loading-spinner">
          <ScaleLoader color="#458BDF" size={100} />
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
}

export default AnalysisSearchFB;
