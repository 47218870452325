import { useState, useContext } from "react";
import { LanguageContext } from "../../../App";
import chart_icon from "../../../images/cloud_icon_figma.png";

function SubjectsMenu({ onSelect }) {
  // arabic

  const { language } = useContext(LanguageContext);

  const [selectedItem, setSelectedItem] = useState("subjects_line");

  const btnSelectSubjectsStyles = {
    backgroundColor:
      selectedItem === "subjects_pie"
        ? "rgb(221, 238, 255)"
        : "rgb(255, 255, 255)",
  };

  const handlebtnSelectEmotionalAnalysis = () => {
    setSelectedItem(
      selectedItem === "subjects_line" ? "subjects_pie" : "subjects_line"
    );
    onSelect(
      selectedItem === "subjects_line" ? "subjects_pie" : "subjects_line"
    );
  };

  return (
    <div className="componnets_drop_result_search">
      {language === "ar" ? (
        <button
          className="btn_twiiter_colors_result"
          style={btnSelectSubjectsStyles}
          onClick={handlebtnSelectEmotionalAnalysis}
        >
          <img src={chart_icon} alt="" />{" "}
        </button>
      ) : (
        <button
          className="btn_twiiter_colors_result_en"
          style={btnSelectSubjectsStyles}
          onClick={handlebtnSelectEmotionalAnalysis}
        >
          <img src={chart_icon} alt="" />{" "}
        </button>
      )}
    </div>
  );
}

export default SubjectsMenu;
