import { combineReducers } from 'redux';

const isDropdownOpenReducer = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_DROPDOWN':
      return !state;
    default:
      return state;
  }
};

const selectedItemReducer = (state = null, action) => {
  switch (action.type) {
    case 'SELECT_ITEM':
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  isDropdownOpen: isDropdownOpenReducer,
  selectedItem: selectedItemReducer,
});

export default rootReducer;