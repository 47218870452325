import React from "react";

const TempOrangeBulletin = ({ children }) => (
  <div style={styles.container}>
    <div style={styles.beforeContent}></div>
    <div style={styles.content}>{children}</div>
  </div>
);

const styles = {
  container: {
    width: "96%",
    height: "100%",
    background:
      "linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,rgb(255, 211, 195) 40%, rgba(255, 127, 80, 0.603) 48%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
    position: "relative",
    overflow: "hidden",
  },

  beforeContent: {
    width: "150px",
    height: "150px",
    content: "",
    background:
      "linear-gradient(to right,rgb(255, 211, 195),rgba(255, 127, 80, 0.603))",
    position: "absolute",
    zIndex: "1",
    borderRadius: "50%",
    right: "-2rem",
    top: "-25%",
  },
  content: {
    maxWidth: "600px",
    textAlign: "center",
    margin: "0 auto",
  },
};

export default TempOrangeBulletin;
