import React, { useEffect, useRef, useContext } from "react";
import * as echarts from "echarts";
import { words } from "../CloudHashtagPlatformsTab/WordsFilter";
import { LanguageContext } from "../../../App";

const CloudPlatformsExistChartAll = ({ facebookPosts }) => {
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (facebookPosts && facebookPosts.entity_chart_action) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = [];
      const colors = {
        happy: "#4caf50", // سبز
        indifferent: "#a7bcb9", // خاکستری
        sad: "#E80505", // قرمز
      };

      Object.keys(facebookPosts.entity_chart_action).forEach((category) => {
        const data = facebookPosts.entity_chart_action[category];
        Object.keys(data).forEach((key) => {
          wordCloudData.push({
            name: key,
            value: data[key],
            category,
          });
        });
      });

      const filteredWordCloudData = wordCloudData.filter(
        (wordItem) => !words.includes(wordItem.name)
      );

      // گروه بندی داده‌ها
      const groupedData = {};
      filteredWordCloudData.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = { happy: 0, indifferent: 0, sad: 0 };
        }
        groupedData[item.name][item.category] += item.value;
      });

      // فیلتر کردن کلمات با دو یا سه رنگ
      const filteredGroupedData = Object.keys(groupedData)
        .filter(name => {
          const counts = Object.values(groupedData[name]);
          const nonZeroCounts = counts.filter(count => count > 0).length;
          return nonZeroCounts >= 2;
        })
        .slice(0, 20); // محدود کردن به 20 کلمه

      if (filteredGroupedData.length > 0) {
        const names = filteredGroupedData;
        const seriesData = {
          happy: [],
          indifferent: [],
          sad: []
        };

        names.forEach((name) => {
          seriesData.happy.push(groupedData[name].happy);
          seriesData.indifferent.push(groupedData[name].indifferent);
          seriesData.sad.push(groupedData[name].sad);
        });

        const categoryNames = {
          happy: language === "ar" ? "إيجابي" : "Happy",
          indifferent: language === "ar" ? "محايد" : "Indifferent",
          sad: language === "ar" ? "سلبي" : "Sad",
        };
        
        const series = Object.keys(seriesData).map((category) => ({
          name: categoryNames[category],
          type: 'bar',
          stack: 'total',
          data: seriesData[category],
          itemStyle: { color: colors[category] }
        }));

        const options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: {
            type: "category",
            data: names,
            axisLabel: {
              fontSize: 10,
              fontFamily: "Noto",
              rotate: 45,
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
          },
          series,
        };

        chart.setOption(options);

        const resizeChart = () => {
          chart.resize();
        };

        window.addEventListener("resize", resizeChart);

        return () => {
          window.removeEventListener("resize", resizeChart);
          chart.dispose();
        };
      } else {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text: language === "ar" ? "ليس هنالك معلومات" : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      }
    } else {
      const chart = echarts.init(chartRef.current);
      chart.setOption({
        graphic: {
          elements: [
            {
              type: "group",
              left: "center",
              top: "middle",
              children: [
                {
                  type: "rect",
                  left: "center",
                  top: "middle",
                  shape: {
                    width: 300,
                    height: 45,
                    r: 12,
                  },
                  style: {
                    fill: "#f4f7fe",
                    stroke: "#458bdf",
                    lineWidth: 2,
                  },
                },
                {
                  type: "text",
                  left: "center",
                  top: "middle",
                  style: {
                    text: language === "ar" ? "ليس هنالك معلومات" : "No data available",
                    font: 'bold 16px "Segoe UI", sans-serif',
                    fill: "#000",
                  },
                },
              ],
            },
          ],
        },
      });
    }
  }, [facebookPosts, language]);

  return (
    <div style={{ width: "100%" }}>
      <div ref={chartRef} style={{ height: "600px" }} />
    </div>
  );
};

export default CloudPlatformsExistChartAll;
