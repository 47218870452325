import React from 'react';

const TempBlueBulletin = ({ children }) => (
    <div style={styles.container}>
    <div style={styles.beforeContent}></div>
    <div style={styles.content}>
      {children}
    </div>
  </div>
);

const styles = {
  container: {
    width: '96%',
    height: '100%',
    background: 'linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255))',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
  },

  beforeContent: {
    width: '150px',
    height: '150px',
    content: "",
    background: 'linear-gradient(to right,rgb(142, 110, 255),rgb(208, 195, 255))',
    position: 'absolute',
    zIndex: '1',
    borderRadius: '50%',
    right: '0',
    top: '-25%',
  },
  content: {
    maxWidth: '600px',
    textAlign:"center",
    margin: '0 auto', 
  },
};

export default TempBlueBulletin;
