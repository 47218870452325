import React, { useEffect, useState, useContext } from "react";
import "../../css/User/ProfileUser.css";
import { showPriceUser } from "../../backend/Admin/UserManager/SendTaskManager";
import { ScaleLoader } from "react-spinners";
import { LanguageContext } from "../../App";
import { toast } from "react-toastify";
import tick_step from "../../images/tick_step_blue.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import logo_mesbar from "../../images/new_logo_menu.png";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../backend/Login_backend";

function PaymentUser({ saveLoginStatus, setIsSubmitted }) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [fetchedApi, setFetchedApi] = useState(false);
  const [apiData, setApiData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleStart();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedApi]);

  const handleStart = async () => {
    try {
      var result = await showPriceUser();
      console.log("Got Projects ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setNeedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        var data = result.data.data;
        console.log("🚀 price projects page result:", result);
        setApiData(data);
        setFetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  // useEffect(() => {
  //   const fetchDataRef = setInterval(() => {
  //     setFetchedApi(true);
  //   }, 5000);

  //   return () => {
  //     clearInterval(fetchDataRef);
  //   };
  // }, []);

  // progress bar

  let activeStep = 3;

  useEffect(() => {
    const progress = document.getElementById(
      "profile_page_progressbar_progress"
    );
    progress.style.width = `${(activeStep - 1) * 30}%`;
  }, [activeStep]);

  const getCircleClass = (step) => {
    return activeStep > step
      ? "circle_profile_page_progressbar_completed"
      : "circle_profile_page_progressbar";
  };

  const getCircleContent = (step) => {
    if (activeStep > step) {
      return <img src={tick_step} alt="Completed" className="checkmark_icon" />;
    }
    return step;
  };

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    window.location.assign("/");
  };

  const today = new Date();

  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="payment_user">
      <div className="login_page_header">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <img src={logo_mesbar} alt="" />
        </div>
      </div>

      <div className="profile_page_progressbar">
        <div
          className={`progress_container ${
            language === "ar" ? "progress_container_ar" : ""
          }`}
        >
          <div
            className={`profile_page_progressbar_progress ${
              language === "ar" ? "profile_page_progressbar_progress_ar" : ""
            }`}
            id="profile_page_progressbar_progress"
          ></div>
          {[1, 2, 3, 4].map((step) => (
            <div
              key={step}
              className={`text_wrap_profile_page_progressbar ${
                activeStep >= step ? "active" : ""
              }`}
            >
              <div className={getCircleClass(step)}>
                {getCircleContent(step)}
              </div>
              {language === "en" ? (
                <p className="text_profile_page_progressbar">
                  {step === 1 && "define project"}
                  {step === 2 && "verification"}
                  {step === 3 && "payment"}
                  {step === 4 && "data prep"}
                </p>
              ) : (
                <p className="text_profile_page_progressbar">
                  {step === 1 && "إنشاء المشروع"}
                  {step === 2 && "التأکيد"}
                  {step === 3 && "الدفع"}
                  {step === 4 && "إعداد المعلومات"}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="payment_user_page">
        <div className="right_payment_user">
          {language === "ar" ? (
            <div className="header_right_payment_user">
              <div className="header_right_payment_user_rows arabic">
                <span>التاریخ : </span>
                <span>{formattedDate}</span>
              </div>
              <div className="header_right_payment_user_rows arabic">
                <span>طريقة الدفع: </span>
                <span>VISA CARD</span>
              </div>
              <div className="header_right_payment_user_rows arabic">
                <span>رقم الطلب: </span>
                <span>12458</span>
              </div>
            </div>
          ) : (
            <div className="header_right_payment_user">
              <div className="header_right_payment_user_rows">
                <span>DATE</span>
                <span>{formattedDate}</span>
              </div>
              <div className="header_right_payment_user_rows">
                <span>PAYMENT METHOD</span>
                <span>VISA CARD</span>
              </div>
              <div className="header_right_payment_user_rows">
                <span>ORDER NUMBER </span>
                <span>12546</span>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="spinner_postbox_admin_page">
              <div>
                <ScaleLoader color="#36d7b7" size={120} />
              </div>
            </div>
          ) : (
            <div className="payment_user_page_body">
              {language === "ar" ? (
                <p className="payment_user_page_body_p_text">
                  التكاليف المتعلقة بطلبك هي كما يلي
                </p>
              ) : (
                <p className="payment_user_page_body_p_text">
                  please check your service bill below
                </p>
              )}
              {Object.keys(apiData).length > 0 ? (
                <div className="box_right_payment_user">
                  {language === "en" ? (
                    <div className="header_box_right_payment_user">
                      <span>Your services</span>
                      <span>Fee</span>
                    </div>
                  ) : (
                    <div className="header_box_right_payment_user">
                      <span>الرسوم</span>
                      <span>خدمتك</span>
                    </div>
                  )}

                  {language === "en" ? (
                    <div className="body_box_right_payment_user">
                      <div className="rows_body_box_right_payment_user_left">
                        <span>Facebook</span>
                        <span>X</span>
                        <span>Instagram</span>
                      </div>
                      <div className="rows_body_box_right_payment_user_right">
                        <span>{apiData.price_facebook} $</span>
                        <span>{apiData.price_x} $</span>
                        <span>{apiData.price_instagram} $</span>
                      </div>
                    </div>
                  ) : (
                    <div className="body_box_right_payment_user">
                      <div className="rows_body_box_right_payment_user_right">
                        <span>{apiData.price_facebook} $</span>
                        <span>{apiData.price_x} $</span>
                        <span>{apiData.price_instagram} $</span>
                      </div>
                      <div className="rows_body_box_right_payment_user_left">
                        <span>فيسبوك</span>
                        <span>إکس</span>
                        <span>إنستغرام</span>
                      </div>
                    </div>
                  )}
                  {/* <div className="rows_body_box_right_payment_user">
                      {language === "en" ? (
                        <div className="rows_body_box_right_payment_user">
                          <span>Facebook</span>
                          <span>{apiData.price_facebook} $</span>
                        </div>
                      ) : (
                        <div className="rows_body_box_right_payment_user">
                          <span>{apiData.price_facebook} $</span>
                          <span>فيسبوك</span>
                        </div>
                      )}
                    </div>
                    <div className="rows_body_box_right_payment_user">
                      {language === "en" ? (
                        <div className="rows_body_box_right_payment_user">
                          <span>X</span>
                          <span>{apiData.price_x} $</span>
                        </div>
                      ) : (
                        <div className="rows_body_box_right_payment_user">
                          <span>{apiData.price_x} $</span>
                          <span>إکس</span>
                        </div>
                      )}
                    </div>
                    <div className="rows_body_box_right_payment_user">
                      {language === "en" ? (
                        <div className="rows_body_box_right_payment_user">
                          <span>Instagram</span>
                          <span>{apiData.price_instagram} $</span>
                        </div>
                      ) : (
                        <div className="rows_body_box_right_payment_user">
                          <span>{apiData.price_instagram} $</span>
                          <span>إنستغرام</span>
                        </div>
                      )}
                    </div> */}

                    {language === "en" ? (
                  <div className="footer_box_right_payment_user">
                      <span>Total fee</span>
                      <span>{apiData.total} $</span>
                      </div>
                    ) : (
                      <div className="footer_box_right_payment_user">
                       <span>{apiData.total} $</span>
                      <span>إجمالي الرسوم</span>
                    </div>
                    )}
                   
                </div>
              ) : (
                <div className="massage_premium_account_searchall_peyment">
                  {language === "en" ? (
                    <p>Please wait while the price is being calculated...</p>
                  ) : (
                    <p>...يرجى الانتظار بينما يتم حساب السعر</p>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="btn_right_payment_user">
            {language === "en" ? (
              <button>Pay</button>
            ) : (
              <button>تأكيد عملية الدفع</button>
            )}
          </div>
        </div>
      </div>
      <div className="logout_icon_steps_filed">
        <TbLogout id="logout_icon_steps_filed_icon" onClick={handleLogout} />
      </div>
    </div>
  );
}

export default PaymentUser;
