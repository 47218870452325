import React, { useEffect, useRef, useContext } from "react";
import { LanguageContext } from "../../../App";
import * as echarts from "echarts";

const AxializationChartRadarFB = ({ facebookPosts, onAxisClick }) => {
  const chartRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (facebookPosts && facebookPosts.axis_chart) {
      const chart = echarts.init(chartRef.current);

      const wordCloudData = Object.keys(facebookPosts.axis_chart).map(
        (key) => ({
          name: key,
          value: facebookPosts.axis_chart[key],
        })
      );

      const maxEmotionValue = Math.max(
        ...wordCloudData.map((item) => item.value)
      );

      if (wordCloudData.length === 0) {
        chart.setOption({
          graphic: {
            elements: [
              {
                type: "group",
                left: "center",
                top: "middle",
                children: [
                  {
                    type: "rect",
                    left: "center",
                    top: "middle",
                    shape: {
                      width: 300,
                      height: 45,
                      r: 12,
                    },
                    style: {
                      fill: "#f4f7fe",
                      stroke: "#458bdf",
                      lineWidth: 2,
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "middle",
                    style: {
                      text:
                        language === "ar"
                          ? "ليس هنالك معلومات"
                          : "No data available",
                      font: 'bold 16px "Segoe UI", sans-serif',
                      fill: "#000",
                    },
                  },
                ],
              },
            ],
          },
        });
      } else {
        const option = {
          radar: {
            indicator: wordCloudData.map((item) => ({
              name: item.name,
              max: maxEmotionValue,
            })),
            shape: "polygon",
            radius: [30, "80%"],
            axisName: {
              fontFamily: "Segoe UI",
              fontSize: 16,
              borderRadius: 3,
              padding: [5, 25],
              color: "#222831",
            },
            triggerEvent: true,
          },
          series: [
            {
              name: "Emotion Analysis",
              type: "radar",
              data: [
                {
                  value: wordCloudData.map((item) => item.value),
                  name: "Emotion :",
                  areaStyle: {
                    color: "rgba(24, 119, 242, 0.6)",
                  },
                  lineStyle: {
                    color: "#5f5dbd",
                    width: 3,
                  },
                  itemStyle: {
                    color: "#5f5dbd",
                    borderColor: "#5f5dbd",
                    borderWidth: 2,
                  },

                  label: {
                    show: true,
                    fontSize: 14,
                    color: "#430f58",
                    position: "inside",
                    padding: 5,
                    backgroundColor: "rgba(245, 249, 238, 0.8)",
                    formatter: function (params) {
                      const total = wordCloudData.reduce(
                        (sum, item) => sum + item.value,
                        0
                      );
                      const percent =
                        ((params.value / total) * 100).toFixed(2) + "%";
                      return `${percent}`;
                    },
                  },
                },
              ],
            },
          ],
        };
        chart.on("click", (params) => {
          onAxisClick(params.name);
        });

        chart.setOption(option);

        chart.resize();
      }
      return () => {
        chart.dispose();
      };
    }
  }, [facebookPosts, language, onAxisClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "400px",
        borderBottom: "2px solid #ccc",
        paddingBottom: "30px",
        marginBottom: "30px",
      }}
    />
  );
};

export default AxializationChartRadarFB;
