import React, { useState, useContext } from "react";
import twitter_icon from "../../../images/twitter_x_35.png";
import ReleaseProcessHeaderMenu from "./ReleaseProcessHeaderMenu";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AllTwitteReleaseProcessChart from "./AllTwitteReleaseProcessChart";
// import SeparationReleaseProcessChart from "./SeparationReleaseProcessChart";
import LikeReleaseProcessChart from "./LikeReleaseProcessChart";
import LikeReleaseProcessChartFB from "./LikeReleaseProcessChartFB";
// import SourceReleaseProcessChart from "./SourceReleaseProcessChart";
import facebook_icon from "../../../images/facebook.png";
import AllPostReleaseProcessChartFB from "./AllPostReleaseProcessChartFB";
import instagram_icon from "../../../images/instagram_35.png";
import AllPostReleaseProcessChartIN from "./AllPostReleaseProcessChartIN";
import LikeReleaseProcessChartIN from "./LikeReleaseProcessChartIN";
import { ThemeContext, LanguageContext } from "../../../App";
import { HashLoader } from "react-spinners";
import ReleaseProcessHeaderMenuTw from "./ReleaseProcessHeaderMenuTw";
import ReleaseProcessHeaderMenuIN from "./ReleaseProcessHeaderMenuIN";

function ReleaseProcess({
  facebookPosts,
  twitterPosts,
  showAllBtnReleaseProcess,
  instagramPosts,
  onDateClick,
  loadingStateIN,
  loadingStateFB,
  loadingStateTW,
  showTwitterSection,
  showFacebookSection,
  showInstagramSection,
  visibleTWReleaseProcess,
  visibleFBReleaseProcess,
  visibleINReleaseProcess,
  isTwitterActive,
  isFacebookActive,
  isInstagramActive,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  const [dropDownProcess, setDropDownProcess] = React.useState(null);

  const [selectedDateRangeFacebook, setSelectedDateRangeFacebook] =
    React.useState(0);
  const [selectedDateRangeInstagram, setSelectedDateRangeInstagram] =
    React.useState(0);
  const [selectedDateRangeTwitter, setSelectedDateRangeTwitter] =
    React.useState(0);

  const handleClickProcess = (event) => {
    setDropDownProcess(event.currentTarget);
  };

  const handleCloseProcess = () => {
    setDropDownProcess(null);
  };

  const open = Boolean(dropDownProcess);
  const id = open ? "simple-popover" : undefined;

  // tabs state twitter

  const [activeReleaseProcessTabs, setActiveReleaseProcessTabs] =
    useState("alltwitte");

  const handleAllTwitteReleaseProcess = () => {
    setActiveReleaseProcessTabs("alltwitte");
  };

  // const handleSeparationReleaseProcess = () => {
  //   setActiveReleaseProcessTabs("separation");
  // };

  const handleLikeReleaseProcess = () => {
    setActiveReleaseProcessTabs("like");
  };

  // const handleSourceReleaseProcess = () => {
  //   setActiveReleaseProcessTabs("source");
  // };

  // tabs state facebook

  const [activeReleaseProcessTabsFB, setActiveReleaseProcessTabsFB] =
    useState("allpostfb");

  const handleAllPostReleaseProcessFB = () => {
    setActiveReleaseProcessTabsFB("allpostfb");
  };

  const handleLikeReleaseProcessFB = () => {
    setActiveReleaseProcessTabsFB("likefb");
  };

  // tabs state instagram

  const [activeReleaseProcessTabsIN, setActiveReleaseProcessTabsIN] =
    useState("allpostinsta");

  const handleAllPostReleaseProcessIN = () => {
    setActiveReleaseProcessTabsIN("allpostinsta");
  };

  const handleLikeReleaseProcessIN = () => {
    setActiveReleaseProcessTabsIN("likeinsta");
  };

  return (
    <div className={`release_process ${theme === "dark" ? "dark_mode" : ""}`}>
      {showAllBtnReleaseProcess && (
        <div className="release_process_body">
          {isTwitterActive && (
            <>
              {loadingStateTW ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleTWReleaseProcess && showTwitterSection && (
                    <div className="charts_release_process_body">
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              إكس
                            </span>
                            <img src={twitter_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts && twitterPosts.word && (
                                <span>{twitterPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  إضافة إلى النشرة
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenuTw
                              language={language}
                              twitterPosts={twitterPosts}
                              setSelectedDateRange={setSelectedDateRangeTwitter}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <img src={twitter_icon} alt="" />
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              X
                            </span>
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts && twitterPosts.word && (
                                <span>{twitterPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  Adding to the bulletin
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenuTw
                              language={language}
                              twitterPosts={twitterPosts}
                              setSelectedDateRange={setSelectedDateRangeTwitter}
                            />
                          </div>
                        </div>
                      )}
                      <div className="body_page_release_process">
                        <div className="header_tabs_body_page_release_process">
                          {language === "ar" ? (
                            <div className="search_section_search_btn_release_process">
                              <button
                                className={
                                  activeReleaseProcessTabs === "alltwitte"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllTwitteReleaseProcess}
                              >
                                جميع التغريدات
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabs === "like"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcess}
                              >
                                اعجاب
                              </button>
                            </div>
                          ) : (
                            <div className="search_section_search_btn_release_process_en">
                              <button
                                className={
                                  activeReleaseProcessTabs === "alltwitte"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllTwitteReleaseProcess}
                              >
                                All Tweets
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabs === "like"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcess}
                              >
                                Likes
                              </button>
                            </div>
                          )}

                          <div className="simple_search_release_process">
                            {activeReleaseProcessTabs === "alltwitte" && (
                              <AllTwitteReleaseProcessChart
                                twitterPosts={twitterPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeTwitter}
                                onDateClick={onDateClick}
                              />
                            )}

                            {activeReleaseProcessTabs === "like" && (
                              <LikeReleaseProcessChart
                                twitterPosts={twitterPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeTwitter}
                                onDateClick={onDateClick}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {isFacebookActive && (
            <>
              {loadingStateFB ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleFBReleaseProcess && showFacebookSection && (
                    <div className="charts_release_process_body">
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              فيسبوك
                            </span>
                            <img src={facebook_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {facebookPosts && facebookPosts.word && (
                                <span>{facebookPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  إضافة إلى النشرة
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenu
                              language={language}
                              facebookPosts={facebookPosts}
                              setSelectedDateRange={
                                setSelectedDateRangeFacebook
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <img src={facebook_icon} alt="" />
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              Facebook
                            </span>
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {facebookPosts && facebookPosts.word && (
                                <span>{facebookPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  Adding to the bulletin
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenu
                              language={language}
                              facebookPosts={facebookPosts}
                              setSelectedDateRange={
                                setSelectedDateRangeFacebook
                              }
                            />                           
                          </div>
                        </div>
                      )}
                      <div className="body_page_release_process">
                        <div className="header_tabs_body_page_release_process">
                          {language === "ar" ? (
                            <div className="search_section_search_btn_release_process">
                              <button
                                className={
                                  activeReleaseProcessTabsFB === "allpostfb"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllPostReleaseProcessFB}
                              >
                                جميع المنشور
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabsFB === "likefb"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcessFB}
                              >
                                اعجاب
                              </button>
                            </div>
                          ) : (
                            <div className="search_section_search_btn_release_process_en">
                              <button
                                className={
                                  activeReleaseProcessTabsFB === "allpostfb"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllPostReleaseProcessFB}
                              >
                                All Posts
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabsFB === "likefb"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcessFB}
                              >
                                Likes
                              </button>
                            </div>
                          )}
                          <div className="simple_search_release_process">
                            {activeReleaseProcessTabsFB === "allpostfb" && (
                              <AllPostReleaseProcessChartFB
                                facebookPosts={facebookPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeFacebook}
                                onDateClick={onDateClick}
                              />
                            )}

                            {activeReleaseProcessTabsFB === "likefb" && (
                              <LikeReleaseProcessChartFB
                                facebookPosts={facebookPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeFacebook}
                                onDateClick={onDateClick}
                              
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {isInstagramActive && (
            <>
              {loadingStateIN ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleINReleaseProcess && showInstagramSection && (
                    <div className="charts_release_process_body">
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              إنستغرام
                            </span>
                            <img src={instagram_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {instagramPosts && instagramPosts.word && (
                                <span>{instagramPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  إضافة إلى النشرة
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenuIN
                              language={language}
                              instagramPosts={instagramPosts}
                              setSelectedDateRange={
                                setSelectedDateRangeInstagram
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <img src={instagram_icon} alt="" />
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              Instagram
                            </span>
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {instagramPosts && instagramPosts.word && (
                                <span>{instagramPosts.word}</span>
                              )}
                            </span>
                          </div>
                          <div className="items_header_release_process_body_dlbtn">
                            <Button
                              className="btn_dl_items_header_release_process_body"
                              onClick={handleClickProcess}
                              startIcon={<MoreVertIcon />}
                            />

                            <Popover
                              id={id}
                              open={open}
                              anchorEl={dropDownProcess}
                              onClose={handleCloseProcess}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuList>
                                <MenuItem onClick={handleCloseProcess}>
                                  Adding to the bulletin
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </div>
                          <div className="items_header_release_process_body_menu">
                            <ReleaseProcessHeaderMenuIN
                              language={language}
                              instagramPosts={instagramPosts}
                              setSelectedDateRange={
                                setSelectedDateRangeInstagram
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="body_page_release_process">
                        <div className="header_tabs_body_page_release_process">
                          {language === "ar" ? (
                            <div className="search_section_search_btn_release_process">
                              <button
                                className={
                                  activeReleaseProcessTabsIN === "allpostinsta"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllPostReleaseProcessIN}
                              >
                                جميع المنشور
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabsIN === "likeinsta"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcessIN}
                              >
                                اعجاب
                              </button>
                            </div>
                          ) : (
                            <div className="search_section_search_btn_release_process_en">
                              <button
                                className={
                                  activeReleaseProcessTabsIN === "allpostinsta"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleAllPostReleaseProcessIN}
                              >
                                All Posts
                              </button>

                              <button
                                className={
                                  activeReleaseProcessTabsIN === "likeinsta"
                                    ? "active"
                                    : ""
                                }
                                onClick={handleLikeReleaseProcessIN}
                              >
                                Likes
                              </button>
                            </div>
                          )}

                          <div className="simple_search_release_process">
                            {activeReleaseProcessTabsIN === "allpostinsta" && (
                              <AllPostReleaseProcessChartIN
                                instagramPosts={instagramPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeInstagram}
                                onDateClick={onDateClick}
                              />
                            )}

                            {activeReleaseProcessTabsIN === "likeinsta" && (
                              <LikeReleaseProcessChartIN
                                instagramPosts={instagramPosts}
                                language={language}
                                selectedDateRange={selectedDateRangeInstagram}
                                onDateClick={onDateClick}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ReleaseProcess;
