import { ThemeContext, LanguageContext } from "../../../App";
import React, { useContext } from "react";

function OfflineRecoues() {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  return (
    <div className={`offline_recoues ${theme === "dark" ? "dark_mode" : ""}`}>
      <div className="offline_recoues_page">
        {language === "ar" ? (
          <div className="message_disable_site">
            <p className="massage_disable_tabs_searchall">
              هذه الصفحة قيد الإنشاء
            </p>
          </div>
        ) : (
          <div className="message_disable_site">
            <p className="massage_disable_tabs_searchall">
              This page is under construction. Please be patient.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default OfflineRecoues;
