import React, { useState, useEffect, useContext } from "react";
import twitter_icon from "../../../images/twitter_x_35.png";
import { Button, Popover, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import SubjectsChartPie from "./SubjectsChartPie";
import SubjectsChartLineFB from "./SubjectsChartLineFB";
import { ThemeContext, LanguageContext } from "../../../App";
import { HashLoader } from "react-spinners";
import facebook_icon from "../../../images/facebook.png";
// import SubjectsChartPieFB from "./SubjectsChartPieFB";
// import SubjectsChartPieTW from "./SubjectsChartPieTW";
import insta_icon from "../../../images/instagram_35.png";
// import SubjectsChartPieIN from "./SubjectsChartPieIN";
import SubjectsChartLineTW from "./SubjectsChartLineTW";
import SubjectsChartLineIN from "./SubjectsChartLineIN";
import SubjectsChartPieTopic from "./SubjectsChartPieTopic";
import SubjectsChartPieTopicTW from "./SubjectsChartPieTopicTW";
import SubjectsChartPieTopicIN from "./SubjectsChartPieTopicIN";
// import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// import filter_icon from "../../../images/filter_icon.png";

function Subjects({
  selectedItem,
  visibleFBSubjects,
  loadingStateFB,
  facebookPosts,
  isFacebookActive,
  showAllBtnSubjects,
  twitterPosts,
  isTwitterActive,
  loadingStateTW,
  visibleTWSubjects,
  loadingStateIN,
  visibleINSubjects,
  isInstagramActive,
  instagramPosts,
  isDefultDB,
  onMainSubjectClick,
  onSubSubjectClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  // popup Adding to the bulletin
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // تعیین وضعیت اولیه لود دو کامپوننت

  const [currentSelectedSubjects, setCurrentSelectedSubjects] =
    useState(selectedItem);

  useEffect(() => {
    setCurrentSelectedSubjects(selectedItem);
  }, [selectedItem]);

  // filter date Facebook

  //  const [startDate, setStartDate] = useState(null);
  //  const [endDate, setEndDate] = useState(null);

  //  const handleStartDateChange = (date) => {
  //    setStartDate(date);
  //  };

  //  const handleEndDateChange = (date) => {
  //    setEndDate(date);
  //  };

  // filter date Twitter

  //  const [startDateTW, setStartDateTW] = useState(null);
  //  const [endDateTW, setEndDateTW] = useState(null);

  //  const handleStartDateChangeTW = (date) => {
  //    setStartDateTW(date);
  //  };

  //  const handleEndDateChangeTW = (date) => {
  //    setEndDateTW(date);
  //  };

  // filter date Instagram

  //  const [startDateIN, setStartDateIN] = useState(null);
  //  const [endDateIN, setEndDateIN] = useState(null);

  //  const handleStartDateChangeIN = (date) => {
  //    setStartDateIN(date);
  //  };

  //  const handleEndDateChangeIN = (date) => {
  //    setEndDateIN(date);
  //  };

  //  const [isFilterOpenFB, setIsFilterOpenFB] = useState(false);
  //  const [isFilterOpenTW, setIsFilterOpenTW] = useState(false);
  //  const [isFilterOpenIN, setIsFilterOpenIN] = useState(false);

  return (
    <div className={`Subjects ${theme === "dark" ? "dark_mode" : ""}`}>
      {isDefultDB === "default" || isDefultDB === "demo" ? (
        <>
          {language === "ar" ? (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                يمنحك الاشتراك المميز الحصول على هذه الخدمة
              </p>
            </div>
          ) : (
            <div className="message_disable_site">
              <p className="massage_disable_tabs_searchall">
                To View This Section, You Need to Purchase a Premium Account.
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {showAllBtnSubjects && (
            <div className="Subjects_page">
              {currentSelectedSubjects === "subjects_line" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <div className="facebook_approach_search">
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWSubjects && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إكس
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClick}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      X Post
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClick}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenTW(!isFilterOpenTW)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenTW ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateTW}
                                      onChange={handleStartDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateTW}
                                      onChange={handleEndDateChangeTW}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <SubjectsChartPieTopicTW
                                    twitterPosts={twitterPosts}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                  <SubjectsChartLineTW
                                    twitterPosts={twitterPosts}
                                    onSubSubjectClick={onSubSubjectClick}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isFacebookActive && (
                    <>
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBSubjects && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>فيسبوك</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>Post Facebook</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenFB(!isFilterOpenFB)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenFB ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_most_effective_users_page">
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <SubjectsChartPieTopic
                                    facebookPosts={facebookPosts}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                  <SubjectsChartLineFB
                                    facebookPosts={facebookPosts}
                                    onSubSubjectClick={onSubSubjectClick}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isInstagramActive && (
                    <div className="facebook_approach_search">
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINSubjects && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "حتی التاريخ",
                                      }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "من التاريخ" }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram Post
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                  {/* <div className="items_header_release_process_body_img">
                                <img
                                  src={filter_icon}
                                  alt=""
                                  onClick={() =>
                                    setIsFilterOpenIN(!isFilterOpenIN)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: isFilterOpenIN ? "block" : "none",
                                  width: "70%",
                                }}
                              >
                                <div className="inputs_date_charts_search_home_dateinput_en">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDateIN}
                                      onChange={handleStartDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDateIN}
                                      onChange={handleEndDateChangeIN}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              </div> */}
                                </div>
                              )}

                              <div className="body_cloud_hashtag_platforms_page">
                                <div className="items_body_cloud_hashtag_platforms_page">
                                  <SubjectsChartPieTopicIN
                                    instagramPosts={instagramPosts}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                  <SubjectsChartLineIN
                                    instagramPosts={instagramPosts}
                                    onSubSubjectClick={onSubSubjectClick}
                                    onMainSubjectClick={onMainSubjectClick}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}

              {currentSelectedSubjects === "subjects_pie" && (
                <div className="body_cloud_hashtag_cloud_words">
                  {isTwitterActive && (
                    <div className="facebook_approach_search">
                      {loadingStateTW ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleTWSubjects && showTwitterSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إكس
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClick}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      X Post
                                    </span>
                                    <img src={twitter_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {twitterPosts && twitterPosts.word && (
                                        <span>{twitterPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClick}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              )}

                              <div className="inputs_date_charts_search_home">
                                {/* {language === "ar" ? (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              )} */}
                                <div className="body_cloud_hashtag_platforms_page">
                                  {/* <SubjectsChartPieTW
                                  twitterPosts={twitterPosts}
                                /> */}
                                  {language === "ar" ? (
                                    <div className="message_disable_site">
                                      <p className="massage_disable_tabs_searchall">
                                        هذا الرسم البياني قيد الإنشاء
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="message_disable_site">
                                      <p className="massage_disable_tabs_searchall">
                                        This chart is under construction
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isFacebookActive && (
                    <>
                      {loadingStateFB ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleFBSubjects && showFacebookSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>فيسبوك</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              ) : (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span>Post Facebook</span>
                                    <img src={facebook_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {facebookPosts && facebookPosts.word && (
                                        <span>{facebookPosts.word}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              )}

                              <div className="inputs_date_charts_search_home">
                                {/* {language === "ar" ? (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              )} */}
                                <div className="body_most_effective_users_page">
                                  <div className="items_body_cloud_hashtag_platforms_page">
                                    {/* <SubjectsChartPieFB
                                    facebookPosts={facebookPosts}
                                  /> */}
                                    {language === "ar" ? (
                                      <div className="message_disable_site">
                                        <p className="massage_disable_tabs_searchall">
                                          هذا الرسم البياني قيد الإنشاء
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="message_disable_site">
                                        <p className="massage_disable_tabs_searchall">
                                          This chart is under construction
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {isInstagramActive && (
                    <div className="facebook_approach_search">
                      {loadingStateIN ? (
                        <div className="spiner_postbox">
                          <HashLoader color="#458BDF" size={50} />
                        </div>
                      ) : (
                        <>
                          {visibleINSubjects && showInstagramSection && (
                            <>
                              {language === "ar" ? (
                                <div className="header_release_process_body">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      إنستغرام
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          إضافة إلى النشرة
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              ) : (
                                <div className="header_release_process_body_en">
                                  <div className="items_header_release_process_body">
                                    <span
                                      className={`items_header_release_process_body_span ${
                                        theme === "dark" ? "dark_mode" : ""
                                      }`}
                                    >
                                      Instagram Post
                                    </span>
                                    <img src={insta_icon} alt="" />
                                  </div>
                                  <div className="items_header_release_process_body">
                                    <span id="search_word_items_header_release_process_body">
                                      {instagramPosts &&
                                        instagramPosts.word && (
                                          <span>{instagramPosts.word}</span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="items_header_release_process_body_dlbtn">
                                    <Button
                                      className="btn_dl_items_header_release_process_body"
                                      onClick={handleClick}
                                      startIcon={<MoreVertIcon />}
                                    />

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem onClick={handleClose}>
                                          Adding to the bulletin
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </div>
                              )}

                              <div className="inputs_date_charts_search_home">
                                {/* {language === "ar" ? (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>بحث</button>
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : حتى </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput">
                                    <label> : من </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="inputs_date_charts_search_home_dateinput">
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>From : </label>
                                    <DateTime
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "From Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_en">
                                    <label>Until : </label>
                                    <DateTime
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Until Date" }}
                                    />
                                  </div>
                                  <div className="col_inputs_date_charts_search_home_dateinput_btn">
                                    <button>Search</button>
                                  </div>
                                </div>
                              )} */}
                                <div className="body_cloud_hashtag_platforms_page">
                                  {/* <SubjectsChartPieIN
                                  instagramPosts={instagramPosts}
                                /> */}
                                  {language === "ar" ? (
                                    <div className="message_disable_site">
                                      <p className="massage_disable_tabs_searchall">
                                        هذا الرسم البياني قيد الإنشاء
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="message_disable_site">
                                      <p className="massage_disable_tabs_searchall">
                                        This chart is under construction
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Subjects;
