import React, { useState, useContext } from "react";
import twitter_icon from "../../../images/twitter_x_35.png";
// import { Button, Popover, MenuItem, MenuList } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import MostEffectiveUsersTable from "./MostEffectiveUsersTable";
import MostEffectiveUsersMenuHeader from "./MostEffectiveUsersMenuHeader";
import MostEffectiveUsersMenuHeaderTw from "./MostEffectiveUsersMenuHeaderTW";
import FacebookIcon from "../../../images/facebook.png";
import MostEffectiveUsersTableTW from "./MostEffectiveUsersTableTW";
import instagram_icon from "../../../images/instagram_35.png";
import MostEffectiveUsersMenuHeaderIN from "./MostEffectiveUsersMenuHeaderIN";
import MostEffectiveUsersTableIN from "./MostEffectiveUsersTableIN";
import { ThemeContext, LanguageContext } from "../../../App";
import { HashLoader } from "react-spinners";

function MostEffectiveUsers({
  twitterPosts,
  facebookPosts,
  isTwitterActive,
  isFacebookActive,
  loadingStateFB,
  loadingStateTW,
  visibleTWMostEffectiveUsers,
  visibleFBMostEffectiveUsers,
  visibleINMostEffectiveUsers,
  loadingStateIN,
  instagramPosts,
  isInstagramActive,
  showAllBtnMostEffectiveUsers,
  onUsernameClick,
  showFacebookSection,
  showInstagramSection,
  showTwitterSection,
}) {
  const { language } = useContext(LanguageContext);

  const { theme } = useContext(ThemeContext);

  // popup download
  // const [hashtagPlatformsdl, setHashtagPlatformsDl] = React.useState(null);

  // const handleClickHashtagPlatformsdl = (event) => {
  //   setHashtagPlatformsDl(event.currentTarget);
  // };

  // const handleCloseHashtagPlatformsdl = () => {
  //   setHashtagPlatformsDl(null);
  // };

  // const open = Boolean(hashtagPlatformsdl);
  // const id = open ? "dropdl_top_users" : undefined;

  // متصل کردن منو هدر به نمایش پست ها توییتر

  const [selectedOptionTW, setSelectedOptionTW] = useState("مجموع الاعجابات");

  const handleSelectedOptionTW = (option) => {
    setSelectedOptionTW(option);
  };

  // متصل کردن منو هدر به نمایش پست ها فیسبوک

  const [selectedOptionFB, setSelectedOptionFB] = useState("مجموع الاعجابات");

  const handleSelectedOptionFB = (option) => {
    setSelectedOptionFB(option);
  };

  // متصل کردن منو هدر به نمایش پست ها اینستاگرام

  const [selectedOptionIN, setSelectedOptionIN] = useState("مجموع الاعجابات");

  const handleSelectedOptionIN = (option) => {
    setSelectedOptionIN(option);
  };

  return (
    <div
      className={`most_effective_users ${theme === "dark" ? "dark_mode" : ""}`}
    >
      {showAllBtnMostEffectiveUsers && (
        <div className="most_effective_users_page">
          {isTwitterActive && (
            <div className="facebook_most_effective_users">
              {loadingStateTW ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleTWMostEffectiveUsers && showTwitterSection && (
                    <>
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              إكس
                            </span>
                            <img src={twitter_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts &&
                                twitterPosts.word &&
                                !facebookPosts && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              {!twitterPosts &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              {twitterPosts &&
                                twitterPosts.word &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>
                                    {twitterPosts.word === facebookPosts.word
                                      ? twitterPosts.word
                                      : `${twitterPosts.word} (توییتر) و ${facebookPosts.word} (فیسبوک)`}
                                  </span>
                                )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            تحميل
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              X
                            </span>
                            <img src={twitter_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts &&
                                twitterPosts.word &&
                                !facebookPosts && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              {!twitterPosts &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              {twitterPosts &&
                                twitterPosts.word &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>
                                    {twitterPosts.word === facebookPosts.word
                                      ? twitterPosts.word
                                      : `${twitterPosts.word} (توییتر) و ${facebookPosts.word} (فیسبوک)`}
                                  </span>
                                )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            Download
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      )}
                      <div className="body_most_effective_users_page">
                        <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <MostEffectiveUsersMenuHeaderTw
                            onSelectedOption={handleSelectedOptionTW}
                          />
                        </div>
                        <div className="items_body_most_ef_users">
                          <MostEffectiveUsersTableTW
                            selectedOption={selectedOptionTW}
                            twitterPosts={twitterPosts}
                            loadingStateTW={loadingStateTW}
                            onUsernameClick={onUsernameClick}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {isFacebookActive && (
            <div className="facebook_most_effective_users">
              {loadingStateFB ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleFBMostEffectiveUsers && showFacebookSection && (
                    <div className="facebook_most_effective_users_border">
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              فيسبوك
                            </span>
                            <img src={FacebookIcon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts &&
                                twitterPosts.word &&
                                !facebookPosts && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              {!twitterPosts &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              {twitterPosts &&
                                twitterPosts.word &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>
                                    {twitterPosts.word === facebookPosts.word
                                      ? twitterPosts.word
                                      : `${twitterPosts.word} (توییتر) و ${facebookPosts.word} (فیسبوک)`}
                                  </span>
                                )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            تحميل
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              Facebook
                            </span>
                            <img src={FacebookIcon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {twitterPosts &&
                                twitterPosts.word &&
                                !facebookPosts && (
                                  <span>{twitterPosts.word}</span>
                                )}
                              {!twitterPosts &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>{facebookPosts.word}</span>
                                )}
                              {twitterPosts &&
                                twitterPosts.word &&
                                facebookPosts &&
                                facebookPosts.word && (
                                  <span>
                                    {twitterPosts.word === facebookPosts.word
                                      ? twitterPosts.word
                                      : `${twitterPosts.word} (توییتر) و ${facebookPosts.word} (فیسبوک)`}
                                  </span>
                                )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            Download
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      )}
                      <div className="body_most_effective_users_page">
                        <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <MostEffectiveUsersMenuHeader
                            onSelectedOption={handleSelectedOptionFB}
                          />
                        </div>
                        <div className="items_body_most_ef_users">
                          <MostEffectiveUsersTable
                            selectedOption={selectedOptionFB}
                            loadingStateFB={loadingStateFB}
                            facebookPosts={facebookPosts}
                            onUsernameClick={onUsernameClick}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {isInstagramActive && (
            <div className="facebook_most_effective_users">
              {loadingStateIN ? (
                <div className="spiner_postbox">
                  <HashLoader color="#458BDF" size={50} />
                </div>
              ) : (
                <>
                  {visibleINMostEffectiveUsers && showInstagramSection && (
                    <div className="facebook_most_effective_users_border">
                      {language === "ar" ? (
                        <div className="header_release_process_body">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              إنستغرام
                            </span>
                            <img src={instagram_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {instagramPosts && instagramPosts.word && (
                                <span>{instagramPosts.word}</span>
                              )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            تحميل
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      ) : (
                        <div className="header_release_process_body_en">
                          <div className="items_header_release_process_body">
                            <span
                              className={`items_header_release_process_body_span ${
                                theme === "dark" ? "dark_mode" : ""
                              }`}
                            >
                              Instagram
                            </span>
                            <img src={instagram_icon} alt="" />
                          </div>
                          <div className="items_header_release_process_body">
                            <span id="search_word_items_header_release_process_body">
                              {instagramPosts && instagramPosts.word && (
                                <span>{instagramPosts.word}</span>
                              )}
                            </span>
                          </div>
                          {/* <div className="items_header_release_process_body_dlbtn">
                      <Button
                        className="btn_dl_items_header_release_process_body"
                        onClick={handleClickHashtagPlatformsdl}
                        startIcon={<MoreVertIcon />}
                      />

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={hashtagPlatformsdl}
                        onClose={handleCloseHashtagPlatformsdl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuList>
                          <MenuItem onClick={handleCloseHashtagPlatformsdl}>
                            Download
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </div> */}
                        </div>
                      )}
                      <div className="body_most_effective_users_page">
                        <div className="items_body_cloud_hashtag_platforms_page_menu">
                          <MostEffectiveUsersMenuHeaderIN
                            onSelectedOption={handleSelectedOptionIN}
                          />
                        </div>
                        <div className="items_body_most_ef_users">
                          <MostEffectiveUsersTableIN
                            selectedOption={selectedOptionIN}
                            loadingStateIN={loadingStateIN}
                            instagramPosts={instagramPosts}
                            onUsernameClick={onUsernameClick}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MostEffectiveUsers;
