import axios from "axios";
import { base_url, tokenName, get_news_paper } from "./config_backend";
import { getToken } from "./Login_backend";

export const getNewsPaper = async (date) => {
  var hasError = false;
  var isLoggedIn = true;
  var status = 200;

  var errorText = "";

  var data = undefined;

  if (localStorage.getItem(tokenName)) {
    // await refreshToken()
  } else {
    isLoggedIn = false;
    status = 401;
    hasError = true;
    return { hasError, errorText, data, status, isLoggedIn };
  }

  var url = base_url + get_news_paper;

  await axios
    .get(url, {
      withCredentials: true,
      params: { startDate: date, endDate: date },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        Authorization: "Bearer " + String(getToken().access),
      },
    })

    .then(function (response) {
      if (response !== undefined) data = response;
    })
    .catch(function (error) {
      var response = error.response;
      errorText = response.request.response;
      hasError = true;
      status = response.status;
      if (response.status === 500) {
        errorText = "Server Error ... ";
      } else if (response.status === 401) {
        isLoggedIn = false;
        localStorage.setItem("isLogged", "false");
      }
    });

  return { hasError, errorText, data, status, isLoggedIn };
};
