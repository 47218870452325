import EngagementtoContentRatioIN from "./EngagementtoContentRatioIN";
import FollowerstoEngagementRatioonContentIN from "./FollowerstoEngagementRatioonContentIN";
import MostEffectiveUsersTableCommentIN from "./MostEffectiveUsersTableCommentIN";
import MostEffectiveUsersTableLikeIN from "./MostEffectiveUsersTableLikeIN";
import MostEffectiveUsersTableMediaCountIn from "./MostEffectiveUsersTableMediaCountIn";

function MostEffectiveUsersTableIN({
  selectedOption,
  loadingStateIN,
  instagramPosts,
  onUsernameClick,
}) {
  let displayedComponent;

  if (selectedOption === "مجموع الردود" || selectedOption === "Total Replies") {
    displayedComponent = (
      <MostEffectiveUsersTableCommentIN
        instagramPosts={instagramPosts}
        loadingStateIN={loadingStateIN}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "مجموع وسائط" ||
    selectedOption === "Total shares"
  ) {
    displayedComponent = (
      <MostEffectiveUsersTableMediaCountIn
        instagramPosts={instagramPosts}
        loadingStateIN={loadingStateIN}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة المتابعين إلى التفاعلات على المحتوى" ||
    selectedOption === "Followers to Engagement Ratio on Content"
  ) {
    displayedComponent = (
      <FollowerstoEngagementRatioonContentIN
        instagramPosts={instagramPosts}
        loadingStateIN={loadingStateIN}
        onUsernameClick={onUsernameClick}
      />
    );
  } else if (
    selectedOption === "نسبة التفاعلات إلى المحتوى" ||
    selectedOption === "Engagement to Content Ratio"
  ) {
    displayedComponent = (
      <EngagementtoContentRatioIN
        instagramPosts={instagramPosts}
        loadingStateIN={loadingStateIN}
        onUsernameClick={onUsernameClick}
      />
    );
  } else {
    displayedComponent = (
      <MostEffectiveUsersTableLikeIN
        instagramPosts={instagramPosts}
        loadingStateIN={loadingStateIN}
        onUsernameClick={onUsernameClick}
      />
    );
  }

  return (
    <div className="top_users_table">
      <div className="top_users_table_page">{displayedComponent}</div>
    </div>
  );
}

export default MostEffectiveUsersTableIN;
