import boltan_img from "../../images/bulletin_menu_30.png";
import boltan_img_blue from "../../images/bulletin_menu_30_blue.png";
import filter_img from "../../images/filter_menu_30.png";
import filter_menu_30_blue from "../../images/filter_menu_30_blue.png";
import news_paper from "../../images/news_paper_30.png";
import news_paper_blue from "../../images/news_paper_30_blue.png";
import list_icon from "../../images/list_30.png";
import list_icon_blue from "../../images/list_30_blue.png";
// import dashboard from "../../images/dashboard_layout_30.png";
import template_icon from "../../images/template_30.png";
import template_icon_blue from "../../images/template_30_blue.png";
import media_icon from "../../images/media_30.png";
// import media_icon_blue from "../../images/media_30_blue.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import RemoveIcon from "@mui/icons-material/Remove";
import { logout } from "../../backend/Login_backend";
import base_icon from "../../images/analysis_menu_30.png";
import logout_icon from "../../images/logout_menu.png";
import admin_menu_icon from "../../images/admin_settings_30.png";
import senior_menu_icon from "../../images/analyzer_menu_30.png";
import logo from "../../images/new_logo_menu.png";
import Avatar from "../../images/avatar_figma.png";
// import Sun from "../../images/sun_btn.png";
// import Moon from "../../images/moon_btn.png";
import confirm_list from "../../images/confirm_list_30.png";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext, LanguageContext } from "../../App";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import home_icon from "../../images/home_menu_30.png";
import home_icon_blue from "../../images/home_menu_30_blue.png";

function HomeMenu({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  isDefultDB,
  isDemoChecked
}) {
  // dark mode
  // const { theme, toggleTheme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  // logout

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    navigate("/");
  };

  // sidebar items drop

  const [isSubMenuOpenSenior, setIsSubMenuOpenSenior] = useState(false);

  const handleMenuItemClickSenior = () => {
    setIsSubMenuOpenSenior(!isSubMenuOpenSenior);
  };

  const [isSubMenuOpenBulletin, setIsSubMenuOpenBulletin] = useState(false);

  const handleMenuItemClickBulletin = () => {
    setIsSubMenuOpenBulletin(!isSubMenuOpenBulletin);
  };

  const location = useLocation();

  return (
    <div className="side_bar_search">
      <div
        className={`left_menu_search_${language === "en" ? "left" : "right"}`}
      >
        <img className="logo_dashboard" src={logo} alt="logo" />

        {language === "ar" ? (
          <>
            {isSuperUser ? (
              <>
                <div className="menu_item_left_menu_search tooltip_ar">
                  <Link to="/base">
                    <img src={base_icon} alt="" />
                  </Link>
                  <div className="tooltip_container">
                    <span className="tooltiptext_ar">صفحة التحليل</span>
                  </div>
                </div>

             
                <div
                  className="menu_item_left_menu_search tooltip_ar"
                  onClick={handleMenuItemClickSenior}
                >
                  <span
                    className={`felesh_icon_menu ${
                      isSubMenuOpenSenior ? "up" : "down"
                    }`}
                  >
                    <ArrowLeftIcon id="felesh_icon_menu_svg" />
                  </span>
                  <img src={senior_menu_icon} alt="" />
                  <span className="tooltiptext_ar">صفحة المدير</span>
                </div>
                {isSubMenuOpenSenior && (
                  <div className="submenu_left_menu_search">
                    <div className="submenu_item_left_menu_search tooltip_ar">
                      <Link to="/seniorpage">
                        <img src={list_icon} alt="" />
                        <RemoveIcon id="remove_icon_menu_home_page" />
                      </Link>
                      <span className="tooltiptext_ar">قائمة المشاريع</span>
                    </div>
                    <div className="submenu_item_left_menu_search tooltip_ar">
                      <Link to="/seniorconfirm">
                        <img src={confirm_list} alt="" />
                        <RemoveIcon id="remove_icon_menu_home_page" />
                      </Link>
                      <span className="tooltiptext_ar">
                        تأكيد قائمة المنشورات
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/home">
                <img
                  src={
                    location.pathname === "/home" ? home_icon_blue : home_icon
                  }
                  alt="Home Icon"
                />
              </Link>
              <span className="tooltiptext_ar">الصفحة الرئيسية</span>
            </div>
            {/* <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/home/dashboard/">
                <img src={dashboard} alt="" />
              </Link>
              <span className="tooltiptext_ar">لوحة المشرف</span>
            </div> */}

            <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/home/filter/">
                <img
                  src={
                    location.pathname === "/home/filter/"
                      ? filter_menu_30_blue
                      : filter_img
                  }
                  alt="Filter Icon"
                />
              </Link>
              <span className="tooltiptext_ar">انشاء استعلام</span>
            </div>

            <div
              className="menu_item_left_menu_search tooltip_ar"
              onClick={handleMenuItemClickBulletin}
            >
              <span
                className={`felesh_icon_menu ${
                  isSubMenuOpenBulletin ? "up" : "down"
                }`}
              >
                <ArrowLeftIcon id="felesh_icon_menu_svg" />
              </span>
              <img
                src={
                  location.pathname === "/home/bulletin/" ||
                  location.pathname === "/home/temp_bulletin/"
                    ? boltan_img_blue
                    : boltan_img
                }
                alt=""
              />

              <span className="tooltiptext_ar">إعداد التقرير</span>
            </div>
            {isSubMenuOpenBulletin && (
              <div className="submenu_left_menu_search">
                <div className="submenu_item_left_menu_search tooltip_ar">
                  <Link to="/home/bulletin/">
                    <img
                      src={
                        location.pathname === "/home/bulletin/"
                          ? list_icon_blue
                          : list_icon
                      }
                      alt=""
                    />
                    <RemoveIcon id="remove_icon_menu_home_page" />
                  </Link>
                  <span className="tooltiptext_ar">قائمة النشرات</span>
                </div>
                <div className="submenu_item_left_menu_search tooltip_ar">
                  {/* <Link to="/home/temp_bulletin/"> */}
                    <img
                      src={
                        location.pathname === "/home/temp_bulletin/"
                          ? template_icon_blue
                          : template_icon
                      }
                      alt=""
                    />
                    <RemoveIcon id="remove_icon_menu_home_page" />
                    <span className="tooltiptext_ar">قوالب</span>
                  {/* </Link> */}
                </div>
                <div className="submenu_item_left_menu_search tooltip_ar">
                  <img src={media_icon} alt="" />
                  {/* <img
                    src={
                      location.pathname === "/home/temp_bulletin/"
                        ? media_icon_blue
                        : media_icon
                    }
                    alt=""
                  /> */}
                  <RemoveIcon id="remove_icon_menu_home_page" />
                  <span className="tooltiptext_ar">درس تعليمي</span>
                </div>
              </div>
            )}

            <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/home/news/">
                <img
                  src={
                    location.pathname === "/home/news/"
                      ? news_paper_blue
                      : news_paper
                  }
                  alt=""
                />
                <span className="tooltiptext_ar">صحف</span>
              </Link>
            </div>
            {isDemoChecked && (

            <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/profile">
                <img src={Avatar} alt="" />
              </Link>
              <span className="tooltiptext_ar">الملف الشخصي</span>
            </div>
            )}
            <div className="menu_item_left_menu_search tooltip_ar">
              <img src={logout_icon} alt="" onClick={handleLogout} />
              <span className="tooltiptext_ar">تسجيل الخروج</span>
            </div>
          </>
        ) : (
          <>
            {isSuperUser ? (
              <>
                <div className="menu_item_left_menu_search tooltip">
                  <Link to="/base">
                    <img src={base_icon} alt="" />
                  </Link>
                  <span className="tooltiptext">Analyst Page</span>
                </div>

                

                <div
                  className="menu_item_left_menu_search tooltip"
                  onClick={handleMenuItemClickSenior}
                >
                  <img src={senior_menu_icon} alt="" />
                  <span
                    className={`felesh_icon_menu_en ${
                      isSubMenuOpenSenior ? "up" : "down"
                    }`}
                  >
                    <ArrowLeftIcon id="felesh_icon_menu_svg" />
                  </span>

                  <span className="tooltiptext">Senior Page</span>
                </div>
                {isSubMenuOpenSenior && (
                  <div className="submenu_left_menu_search">
                    <div className="submenu_item_left_menu_search tooltip">
                      <Link to="/seniorpage">
                        <RemoveIcon id="remove_icon_menu_home_page_en" />
                        <img src={list_icon} alt="" />
                      </Link>
                      <span className="tooltiptext">Projects List</span>
                    </div>
                    <div className="submenu_item_left_menu_search tooltip">
                      <Link to="/seniorconfirm">
                        <RemoveIcon id="remove_icon_menu_home_page_en" />
                        <img src={confirm_list} alt="" />
                      </Link>
                      <span className="tooltiptext">Confirm Post List</span>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <div className="menu_item_left_menu_search tooltip_ar">
              <Link to="/home">
                <img
                  src={
                    location.pathname === "/home" ? home_icon_blue : home_icon
                  }
                  alt="Home Icon"
                />
              </Link>
              <span className="tooltiptext_ar">Home</span>
            </div>

            {/* <div className="menu_item_left_menu_search tooltip">
              <Link to="/home/dashboard/">
                <img src={dashboard} alt="" />
              </Link>
              <span className="tooltiptext">Dashboard</span>
            </div> */}

            <div className="menu_item_left_menu_search tooltip">
              <Link to="/home/filter/">
                <img
                  src={
                    location.pathname === "/home/filter/"
                      ? filter_menu_30_blue
                      : filter_img
                  }
                  alt="Filter Icon"
                />
              </Link>
              <span className="tooltiptext">Filter Maker</span>
            </div>

            <div
              className="menu_item_left_menu_search tooltip"
              onClick={handleMenuItemClickBulletin}
            >
              <img
                src={
                  location.pathname === "/home/bulletin/" ||
                  location.pathname === "/home/temp_bulletin"
                    ? boltan_img_blue
                    : boltan_img
                }
                alt=""
              />
              <span className="tooltiptext">Bulletin</span>
              <span
                className={`felesh_icon_menu_en ${
                  isSubMenuOpenBulletin ? "up" : "down"
                }`}
              >
                <ArrowLeftIcon id="felesh_icon_menu_svg" />
                {/* &#11207; */}
              </span>
            </div>
            {isSubMenuOpenBulletin && (
              <div className="submenu_left_menu_search">
                <div className="submenu_item_left_menu_search tooltip">
                  <Link to="/home/bulletin/">
                    <RemoveIcon id="remove_icon_menu_home_page_en" />
                    <img
                      src={
                        location.pathname === "/home/bulletin/"
                          ? list_icon_blue
                          : list_icon
                      }
                      alt=""
                    />
                  </Link>
                  <span className="tooltiptext">Bulletin List</span>
                </div>
                <div className="submenu_item_left_menu_search tooltip">
                  <RemoveIcon id="remove_icon_menu_home_page_en" />
                  {/* <Link to="/home/temp_bulletin"> */}
                    <img
                      src={
                        location.pathname === "/home/temp_bulletin"
                          ? template_icon_blue
                          : template_icon
                      }
                      alt=""
                    />
                    <span className="tooltiptext">Templates</span>
                  {/* </Link> */}
                </div>
                <div className="submenu_item_left_menu_search tooltip">
                  <RemoveIcon id="remove_icon_menu_home_page_en" />
                  <img src={media_icon} alt="" />
                  <span className="tooltiptext">Bulletin Tutorial</span>
                </div>
              </div>
            )}

            <div className="menu_item_left_menu_search tooltip">
              <Link to="/home/news/">
                <img
                  src={
                    location.pathname === "/home/news/"
                      ? news_paper_blue
                      : news_paper
                  }
                  alt=""
                />
              </Link>
              <span className="tooltiptext">News Paper</span>
            </div>
            {/* <div className="menu_item_left_menu_search tooltip">
              <Link to="/profile">
                <img src={Avatar} alt="" />
              </Link>
              <span className="tooltiptext">Profile</span>
            </div> */}
            <div className="menu_item_left_menu_search tooltip">
              <img src={logout_icon} alt="" onClick={handleLogout} />
              <span className="tooltiptext">LogOut</span>
            </div>
          </>
        )}

        {/* <div className="btn_container_toggle_language_base">
          <label className="switch btn-color-mode-switch-base">
            <input
              value="1"
              id="color_mode"
              name="color_mode"
              type="checkbox"
              onClick={handleLanguageToggle}
            />
            <label
              className="btn-color-mode-switch-inner-base"
              data-off="EN"
              data-on="AR"
              htmlFor="color_mode"
            ></label>
          </label>
        </div> */}

        {/* <div className="darkmode_btn_base_facebook">
          <div
            className={`dark_mode_btn_base_fb ${
              theme === "dark" ? "dark" : ""
            }`}
            onClick={toggleTheme}
          >
            <img src={theme === "dark" ? Moon : Sun} alt="/" />
          </div>
        </div> */}

        
      </div>
    </div>
  );
}

export default HomeMenu;
