import React from "react";

function NotFound() {
  return (
    <div className="not_found_page">
      <div className="not_found_page_body">
        <div id="clouds_not_found_page">
          <div className="cloud x1_not_found_page"></div>
          <div className="cloud x1_5_not_found_page"></div>
          <div className="cloud x2_not_found_page"></div>
          <div className="cloud x3_not_found_page"></div>
          <div className="cloud x4_not_found_page"></div>
          <div className="cloud x5_not_found_page"></div>
        </div>
        <div className="c_not_found_page">
          <div className="c_404_not_found_page">404</div>
          <hr className="hr_not_found_page" />
          <div className="c_1_not_found_page">THE PAGE</div>
          <div className="c_2_not_found_page">WAS NOT FOUND</div>
          <a className="btn_not_found_page" href="/">
            BACK TO HOME
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
