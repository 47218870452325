import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LikeReleaseProcessChartFB = ({
  facebookPosts,
  selectedDateRange,
  // onDateClick,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (facebookPosts) {
      const chart = echarts.init(chartRef.current);

      let facebookData = [];
      if (selectedDateRange === 0 && facebookPosts.all_likes_chart) {
        facebookData = facebookPosts.all_likes_chart;
      } else if (
        selectedDateRange === 1 &&
        facebookPosts.all_likes_chart_week
      ) {
        facebookData = facebookPosts.all_likes_chart_week;
      } else if (
        selectedDateRange === 2 &&
        facebookPosts.all_likes_chart_monthly
      ) {
        facebookData = facebookPosts.all_likes_chart_monthly;
      }

      const formattedData = facebookData.map((item) => ({
        date: selectedDateRange === 0 ? item[0] : item[0].replace("/", "-"),
        value: item[1],
      }));

      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: formattedData.map((data) => data.date),
          axisLabel: {
            fontSize: 12,
            fontFamily: "Segoe UI",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            fontSize: 11,
            fontWeight: "bold",
            fontFamily: "Segoe UI",
          },
        },
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 100,
          },
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            data: formattedData.map((data) => data.value),
            symbolSize: 10,
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 99, 132, 1)",
            },
            lineStyle: {
              width: 2,
              color: "rgba(255, 99, 132, 1)",
            },
            areaStyle: {
              color: "transparent",
            },
          },
        ],
      };

      chart.setOption(options);

      // chart.on("click", (params) => {
      //   const clickedDate = params.name;
      //   onDateClick(clickedDate);
      // });
    }
  }, [facebookPosts, selectedDateRange]);

  return <div ref={chartRef} style={{ width: "100%", height: "300px" }} />;
};

export default LikeReleaseProcessChartFB;
