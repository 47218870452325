import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { ThemeContext, LanguageContext } from "../../../App";
import { useContext } from "react";
import { logout } from "../../../backend/Login_backend";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

function MenuTop({ username, saveLoginStatus, setIsSubmitted }) {
  const { theme } = useContext(ThemeContext);

  const { language, toggleLanguage } = useContext(LanguageContext);

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    saveLoginStatus(false);
    setIsSubmitted(false);
    navigate("/");
  };

  // const [activeMenuItem, setActiveMenuItem] = useState(0);

  return (
    <div
      className={`menutop_com ${language === "ar" ? "menutop_com_ar" : ""} ${
        theme === "dark" ? "dark_mode" : ""
      }`}
    >
      {language === "en" ? (
        <>
          <div className="username_menutop_com">
            <Avatar
              className="simple_menu"
              alt="User Avatar"
              src="/path-to-your-image.jpg"
            />
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
            >
              {username ? username : ""}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className="menu_items_headermenu">
                <Avatar
                  className="logout_icon_menutop_img"
                  alt="User Avatar"
                  src="/path-to-your-image.jpg"
                />
                {username ? username : ""}
              </MenuItem>
              <MenuItem
                className="menu_items_headermenu"
                onClick={handleLogout}
              >
                <LogoutIcon className="logout_icon_menutop" />
                Logout
              </MenuItem>
            </Menu>
          </div>
          <div className="right_menutop_com">
            <div className="language-toggle-menu">
              <div className="language-toggle-container">
                <div className="language-toggle-button" onClick={toggleMenu}>
                  {language === "ar" ? "العربية" : "English (US)"}
                  {islanguageMenuOpen ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
                {islanguageMenuOpen && (
                  <div
                    className={`language-menu ${
                      islanguageMenuOpen ? "show" : ""
                    }`}
                    id="language_menu"
                  >
                    <div
                      className={language === "ar" ? "active" : ""}
                      onClick={() => handleLanguageToggle("ar")}
                    >
                      العربية
                    </div>
                    <div
                      className={language === "en" ? "active" : ""}
                      onClick={() => handleLanguageToggle("en")}
                    >
                      English (US)
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="username_menutop_com">
            <Avatar
              className="simple_menu"
              alt="User Avatar"
              src="/path-to-your-image.jpg"
            />
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
            >
              {username ? username : ""}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className="menu_items_headermenu_ar">
                {username ? username : ""}
                <Avatar
                  className="logout_icon_menutop_img_ar"
                  alt="User Avatar"
                  src="/path-to-your-image.jpg"
                />
              </MenuItem>
              <MenuItem
                className="menu_items_headermenu_ar"
                onClick={handleLogout}
              >
                تسجيل الخروج
                <LogoutIcon className="logout_icon_menutop_ar" />
              </MenuItem>
            </Menu>
          </div>
          <div className="right_menutop_com">
            <div className="language-toggle-menu">
            <div className="language-toggle-container">
                <div className="language-toggle-button" onClick={toggleMenu}>
                  {language === "ar" ? "العربية" : "English (US)"}
                  {islanguageMenuOpen ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
                {islanguageMenuOpen && (
                  <div
                    className={`language-menu ${
                      islanguageMenuOpen ? "show" : ""
                    }`}
                    id="language_menu"
                  >
                    <div
                      className={language === "ar" ? "active" : ""}
                      onClick={() => handleLanguageToggle("ar")}
                    >
                      العربية
                    </div>
                    <div
                      className={language === "en" ? "active" : ""}
                      onClick={() => handleLanguageToggle("en")}
                    >
                      English (US)
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default MenuTop;
